// External Dependencies
import dayjs from 'dayjs';
import { Text } from '@raptormaps/text';
import { Tabs } from '@raptormaps/tabs';
import { TextInput } from '@raptormaps/text-input';
import { Tooltip } from '@raptormaps/tooltip';
import { Row, Stack } from '@raptormaps/layout';
import { Icon } from '@raptormaps/icon';
import theme from '@raptormaps/theme';

// Components
import {
  ParagraphWithTabs,
  FullWidthGridSection,
  QuarterWidthTextInput,
  MinMax,
} from '@/pages/MissionPlanner/components/MissionPlanningSidebar/MissionPlanningSidebar.styles';

// Constants
import {
  CAMERA_ANGLE_MODE_TO_DISPLAY_NAME,
  DISPLAY_NAME_TO_CAMERA_ANGLE_MODE,
} from '@/shared/constants/missionLookups';
import { NUMBER_INPUT_STEP } from '@/pages/MissionPlanner/constants/missionConstants';

// types
import {
  CameraAngleMode,
  FlightModeType,
} from '@raptormaps/raptor-flight-client-ts';
import { FormParameters } from '@/shared/types/missions';
import { CombinedFlightModeType } from '@/shared/types/tempMissions';

// Utils
import { handleInputScroll } from '@/pages/MissionPlanner/utils/utils';

interface CourseAndCameraAngleInputProps {
  cameraAngleMode: CameraAngleMode;
  startTime: string;
  flightAngle: number;
  cameraAngle: number;
  flightMode: CombinedFlightModeType;
  formParameters: FormParameters;
  errors: { flightAngle?: string; cameraAngle?: string };
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleEnterPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur: () => void;
}

const CourseAndCameraAngleInput = ({
  cameraAngleMode,
  flightAngle,
  startTime,
  cameraAngle,
  flightMode,
  formParameters,
  errors,
  handleChange,
  handleEnterPress,
  onBlur,
}: CourseAndCameraAngleInputProps) => {
  const continuousOperationsEnabled =
    flightMode == FlightModeType.Standard ||
    flightMode == FlightModeType.Comprehensive;

  const cameraAngleModeOptions: CameraAngleMode[] = continuousOperationsEnabled
    ? [CameraAngleMode.Manual, CameraAngleMode.FollowTracker]
    : [CameraAngleMode.Manual];

  const CAMERA_ANGLE_TABS = cameraAngleModeOptions.map(
    opt => CAMERA_ANGLE_MODE_TO_DISPLAY_NAME[opt],
  );

  return (
    <>
      <ParagraphWithTabs>
        <Text
          style={{ display: 'flex', gap: '1em' }}
          variant={'paragraph_small'}
        >
          Course and Camera Angle
        </Text>
        {CAMERA_ANGLE_TABS.length > 1 && (
          <Tabs
            handleClick={index => {
              handleChange({
                target: {
                  name: 'cameraAngleMode',
                  value:
                    DISPLAY_NAME_TO_CAMERA_ANGLE_MODE[CAMERA_ANGLE_TABS[index]],
                },
              } as React.ChangeEvent<HTMLInputElement>);
            }}
            items={CAMERA_ANGLE_TABS}
            activeIndex={CAMERA_ANGLE_TABS.indexOf(
              CAMERA_ANGLE_MODE_TO_DISPLAY_NAME[cameraAngleMode],
            )}
          />
        )}
      </ParagraphWithTabs>

      {cameraAngleMode === CameraAngleMode.FollowTracker && (
        <Stack>
          <Stack align="start" gutter="xxs">
            <Row>
              <Text htmlFor="followTrackerTime" variant="paragraph_small">
                {' '}
                Mission Start Time (UTC){' '}
              </Text>
              <Tooltip
                colorVariant="dark"
                delay={100}
                placement={{
                  align: 'center',
                  side: 'right',
                }}
                tip={
                  <Stack gutter={'none'}>
                    <Text
                      variant={'paragraph_small'}
                      color={theme.colors.white}
                    >
                      Follow Tracker automatically aligns the camera angle with
                      the angle of the sun, ensuring it always points at the
                      trackers.
                    </Text>
                    <Text
                      variant={'paragraph_small'}
                      color={theme.colors.white}
                    >
                      ATTN: requires setting the correct flight start time.
                    </Text>
                  </Stack>
                }
                shouldWrapChildren={true}
              >
                <Icon
                  aria-label="Follow Tracker Information"
                  icon="CircleInfo"
                  size={'medium'}
                  style={{ marginLeft: '-5px' }}
                />
              </Tooltip>
            </Row>
            <TextInput
              style={{ width: '225px' }}
              placeholder="DD/MM/YYYY hh:mm"
              inputSize="small"
              inputStyle="grey-box"
              type="datetime-local"
              step={1}
              value={startTime}
              onChange={handleChange}
              name="startTime"
            />
            <Text variant={'paragraph_small'}>
              Current UTC time: {dayjs.utc().format('YYYY-MM-DD hh:mm:ss A')}
            </Text>
          </Stack>
          <FullWidthGridSection
            style={
              errors.flightAngle && errors.cameraAngle
                ? { marginBottom: '50px' }
                : null
            }
          >
            <>
              <Row
                style={{
                  gridColumn: '1 / span 2',
                  gridRow: '1',
                  marginBottom: '-10px',
                }}
              >
                <Text htmlFor="flightAngle" variant="paragraph_small">
                  Course Angle (deg){' '}
                </Text>
                <Tooltip
                  colorVariant="dark"
                  delay={100}
                  placement={{
                    align: 'center',
                    side: 'right',
                  }}
                  tip="Course Angle controls the drone's pointing direction relative to north: 0° for north, 90° for east, 180° for south, and 270° for west."
                  shouldWrapChildren={true}
                >
                  <Icon
                    aria-label="Course Angle Information"
                    icon="CircleInfo"
                    size={'medium'}
                  />
                </Tooltip>
              </Row>
              <QuarterWidthTextInput
                inputSize="small"
                inputStyle="white-box"
                inputType="number"
                value={flightAngle}
                onWheel={handleInputScroll}
                onBlur={onBlur}
                step={NUMBER_INPUT_STEP}
                min={formParameters.flightAngleInput.min}
                max={formParameters.flightAngleInput.max}
                onChange={handleChange}
                onKeyDownCapture={handleEnterPress}
                gridRow={2}
                name="flightAngle"
                helperText={errors.flightAngle}
                helperTextStyle={'top: 30px;'}
                className={`${errors.flightAngle ? 'is-invalid' : ''}`}
              />
              <MinMax gridColumn={2} marginTop="0px">
                <Text variant="paragraph_small">
                  Min: {formParameters.flightAngleInput.min}
                </Text>
                <Text variant="paragraph_small">
                  Max: {formParameters.flightAngleInput.max}
                </Text>
              </MinMax>
            </>
          </FullWidthGridSection>
        </Stack>
      )}
      {cameraAngleMode !== CameraAngleMode.FollowTracker && (
        <FullWidthGridSection
          style={
            errors.flightAngle && errors.cameraAngle
              ? { marginBottom: '25px' }
              : null
          }
        >
          <>
            <Row
              style={{
                gridColumn: '1 / span 2',
                gridRow: '1',
                marginBottom: '-10px',
              }}
            >
              <Text htmlFor="flightAngle" variant="paragraph_small">
                Course Angle (deg){' '}
              </Text>
              <Tooltip
                colorVariant="dark"
                delay={100}
                placement={{
                  align: 'center',
                  side: 'right',
                }}
                tip="Course Angle controls the drone's pointing direction relative to north: 0° for north, 90° for east, 180° for south, and 270° for west."
                shouldWrapChildren={true}
              >
                <Icon
                  aria-label="Course Angle Information"
                  icon="CircleInfo"
                  size={'medium'}
                />
              </Tooltip>
            </Row>
            <QuarterWidthTextInput
              inputSize="small"
              inputStyle="white-box"
              inputType="number"
              value={flightAngle}
              onWheel={handleInputScroll}
              onBlur={onBlur}
              step={NUMBER_INPUT_STEP}
              min={formParameters.flightAngleInput.min}
              max={formParameters.flightAngleInput.max}
              onChange={handleChange}
              onKeyDownCapture={handleEnterPress}
              gridRow={2}
              name="flightAngle"
              helperText={errors.flightAngle}
              helperTextStyle={'top: 30px;'}
              className={`${errors.flightAngle ? 'is-invalid' : ''}`}
              data-testid="flightAngle"
            />
            <MinMax gridColumn={2} marginTop="0px">
              <Text variant="paragraph_small">
                Min: {formParameters.flightAngleInput.min}
              </Text>
              <Text variant="paragraph_small">
                Max: {formParameters.flightAngleInput.max}
              </Text>
            </MinMax>
          </>
          <Row
            style={{
              gridColumn: '3 / span 2',
              gridRow: '1',
              marginBottom: '-10px',
            }}
          >
            <Text htmlFor="cameraAngle" variant="paragraph_small">
              Camera Angle (deg){' '}
            </Text>
            <Tooltip
              colorVariant="dark"
              delay={100}
              placement={{
                align: 'center',
                side: 'right',
              }}
              tip={
                <Stack gutter={'none'}>
                  <Text variant={'paragraph_small'} color={theme.colors.white}>
                    Camera Angle controls the gimbal pitch: -90° pointing
                    straight down (nadir) and 0° parallel to the ground.
                  </Text>
                  <Text variant={'paragraph_small'} color={theme.colors.white}>
                    ATTN: Camera gimbal pitch range may vary depending on device
                    and payload capabilities
                  </Text>
                </Stack>
              }
              shouldWrapChildren={true}
            >
              <Icon
                aria-label="Camera Angle Information"
                icon="CircleInfo"
                size={'medium'}
              />
            </Tooltip>
          </Row>
          <QuarterWidthTextInput
            inputSize="small"
            inputStyle="white-box"
            inputType="number"
            value={cameraAngle}
            onWheel={handleInputScroll}
            onBlur={onBlur}
            step={NUMBER_INPUT_STEP}
            min={formParameters.pitchAngleInput.min}
            max={formParameters.pitchAngleInput.max}
            onChange={handleChange}
            onKeyDownCapture={handleEnterPress}
            name="cameraAngle"
            helperText={errors.cameraAngle}
            helperTextStyle={`left: -233px; ${
              errors.flightAngle && errors.cameraAngle ? null : 'top: 30px;'
            }`}
            className={`${errors.cameraAngle ? 'is-invalid' : ''}`}
            data-testid="cameraAngle"
          />
          <MinMax gridColumn={4} marginTop="0px">
            <Text variant="paragraph_small">
              Min: {formParameters.pitchAngleInput.min}
            </Text>
            <Text variant="paragraph_small">
              Max: {formParameters.pitchAngleInput.max}
            </Text>
          </MinMax>
        </FullWidthGridSection>
      )}
    </>
  );
};

export default CourseAndCameraAngleInput;
