import { Layers } from 'mapbox-gl';
import { PYRANOMETER_SOURCE_ID } from '../sources/PyranometerSources';

export const PYRANOMETER_LAYER_ID = 'pyranometer-layer';

const PyranometerLayer: Layers = [
  {
    id: PYRANOMETER_LAYER_ID,
    type: 'symbol',
    source: PYRANOMETER_SOURCE_ID,
    minzoom: 16,
    layout: {
      'symbol-placement': 'point',
      'icon-image': 'pyranometer-off',
      visibility: 'visible',
    },
  },
];

const PyranometerLayers: Layers = [...PyranometerLayer];

export default PyranometerLayers;
