import { Tooltip } from '@raptormaps/tooltip';
import { Text } from '@raptormaps/text';

export const TableValueWithToolTip = ({
  textContent,
}: {
  textContent: string;
}) => {
  return (
    <Tooltip
      colorVariant="dark"
      delay={100}
      placement={{
        align: 'center',
        side: 'left',
      }}
      tip={textContent}
    >
      <Text>{textContent}</Text>
    </Tooltip>
  );
};
