import React from 'react';
import { createRoot } from 'react-dom/client';
import mapboxgl from 'mapbox-gl';
import moment from 'moment';
import { Point, FeatureCollection } from '@turf/helpers';
import SolarFarmPopup from '../../shared/components/Map/components/SolarFarmPopup';

export const calculateZoom = boundingBox => {
  /* Calculates the appropriate zoom level for a map based on a given bounding box.
   * The array should contain four numbers representing the [west, south, east, and north] bounds of the box, in that order.
   * this function returns the zoom level as a number for the map to contain all the solarfarm markers.
   */

  const ZOOM_MAX = 15;

  const mapWidth = window.innerWidth * 0.6;
  const mapHeight = window.innerHeight;

  const ne = { lat: boundingBox[3], lng: boundingBox[2] };
  const sw = { lat: boundingBox[1], lng: boundingBox[0] };

  const paddingFactor = 0.51; // this is to offest the logaritmic scale of the zoom level

  const latDiff = Math.abs(ne.lat - sw.lat) * paddingFactor;
  const lngDiff = Math.abs(ne.lng - sw.lng) * paddingFactor;

  // these convert the lat lng degree to radians and then computes the log in base 2 ( because mapbox zoom is in base 2)
  const latZoom = Math.log(mapHeight / latDiff / Math.PI) / Math.LN2;
  const lngZoom = Math.log(mapWidth / lngDiff / Math.PI) / Math.LN2;

  return Math.min(latZoom, lngZoom, ZOOM_MAX);
};

export const calculateCenter = boundingBox => {
  const [west, south, east, north] = boundingBox;
  return { lng: (west + east) / 2, lat: (south + north) / 2 };
};

export const addSolarFarmMarkers = (
  map: mapboxgl.Map,
  geoJSON: FeatureCollection<Point>,
  markersRef: React.MutableRefObject<mapboxgl.Marker[]>,
  handleClickPopup: (arg) => void,
) => {
  if (!map || !geoJSON) return;
  // remove existing markers
  markersRef.current.forEach(marker => marker.remove());
  markersRef.current = [];

  // add new markers
  geoJSON.features.forEach(feature => {
    const [longitude, latitude] = feature.geometry.coordinates;

    if (
      latitude < -90 ||
      latitude > 90 ||
      longitude < -180 ||
      longitude > 180
    ) {
      // if a marker has an invalid lat or lng, skip it.
      return;
    }

    const popUpContainer = document.createElement('div');
    createRoot(popUpContainer).render(
      React.createElement(SolarFarmPopup, {
        solarFarm: feature.properties,
        onClick: solarFarmId => {
          handleClickPopup(solarFarmId);
        },
      }),
    );

    const raptorPin = document.createElement('div');
    raptorPin.style.backgroundImage = 'url(/mapbox-icons/raptor-pin.png)';
    raptorPin.style.width = '19px';
    raptorPin.style.height = '25px';
    raptorPin.style.backgroundSize = 'contain';
    raptorPin.style.cursor = 'pointer';

    const marker = new mapboxgl.Marker(raptorPin)
      .setLngLat([longitude, latitude])
      .setPopup(
        new mapboxgl.Popup({
          className: 'solar-farm-popup',
          closeButton: false,
        }).setDOMContent(popUpContainer),
      )
      .addTo(map);
    markersRef.current.push(marker);
  });
};

export const unixToDate = (unixTime: number) => {
  return moment.unix(unixTime).format('MMM D, YYYY');
};
