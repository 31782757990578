import {
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalTitle,
} from '@raptormaps/modal';
import { Button } from '@raptormaps/button';
import { Row, Stack } from '@raptormaps/layout';
import { Text } from '@raptormaps/text';
import { ModalProps } from '@/shared/types/page.d';

export const ConfirmationModal = ({
  open,
  onClose,
  handleCancel,
  handleConfirm,
  title,
  description,
  confirmText,
  closeText,
}: ModalProps) => {
  return (
    <Modal open={open} onClose={onClose} data-testid="confirmation-modal">
      <ModalContent>
        <Stack align="center" justify="center" style={{ minWidth: 375 }}>
          <ModalCloseButton />
          <ModalTitle>{title}</ModalTitle>
          <Text>{description}</Text>
          <Row align="center" justify="center">
            <Button variant="secondary" onClick={handleCancel}>
              {closeText}
            </Button>
            <Button
              className="leave-button"
              onClick={handleConfirm}
              data-testid="confirmation-modal-confirm-button"
            >
              {confirmText}
            </Button>
          </Row>
        </Stack>
      </ModalContent>
    </Modal>
  );
};
