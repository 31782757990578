// External Dependencies
import { useState, useRef, useEffect } from 'react';
import { UseFormReturn, Controller } from 'react-hook-form';
// RaptorUI
import { Row, Stack } from '@raptormaps/layout';
import { TextInput } from '@raptormaps/text-input';
import { Icon } from '@raptormaps/icon';
import theme from '@raptormaps/theme';

// Internal Dependencies
import { InputHelperText } from '@/shared/styles/text.styles';
import { IconButtonAdornment } from '@/shared/styles/input.styles';
import { MISSION_TASK_FORM_FIELDS } from '@/pages/MissionTasks/MissionTaskPlanner/missionTaskFormConstants';

interface MissionTaskNameInputProps {
  missionTaskForm: UseFormReturn;
}

export const MissionTaskNameInput = ({
  missionTaskForm,
}: MissionTaskNameInputProps) => {
  const {
    setValue,
    watch,
    formState: { errors },
    trigger,
  } = missionTaskForm;

  const missionTaskNameValue = watch(MISSION_TASK_FORM_FIELDS.missionTaskName);
  const [disableMissionTaskNameInput, setDisableMissionTaskNameInput] =
    useState<boolean>(!!missionTaskNameValue);

  useEffect(() => {
    // triggers validation on initial render
    trigger(MISSION_TASK_FORM_FIELDS.missionTaskName);
  }, []);

  const missionTaskNameRow = useRef(null);
  useEffect(() => {
    missionTaskNameRow.current?.querySelector('input').focus();
  }, [disableMissionTaskNameInput]);

  return (
    <Stack style={{ width: '100%', height: '78px' }}>
      <Row style={{ width: '100%' }} justify="space-between">
        <Row
          align="center"
          noWrap
          gutter="medium"
          style={{ width: '82%', textAlign: 'left' }}
          ref={missionTaskNameRow}
        >
          <Controller
            name={MISSION_TASK_FORM_FIELDS.missionTaskName}
            control={missionTaskForm.control}
            render={({ field: { onChange, value, name } }) => (
              <TextInput
                label="Task Name"
                name={name}
                inputSize="small"
                placeholder="Add task name..."
                value={value}
                disabled={disableMissionTaskNameInput}
                onChange={e => onChange(e.target.value)}
                onBlur={e => {
                  setValue(
                    MISSION_TASK_FORM_FIELDS.missionTaskName,
                    e.target.value.trim(),
                    {
                      shouldValidate: true,
                      shouldDirty: true,
                    },
                  );
                  value && value !== ' '
                    ? setDisableMissionTaskNameInput(true)
                    : setDisableMissionTaskNameInput(false);
                }}
                className={`${errors.missionTaskName ? 'is-invalid' : ''}`}
                style={{ width: '100%', color: theme.colors.black }}
              />
            )}
          />
          {disableMissionTaskNameInput && (
            <IconButtonAdornment
              aria-label="Rename Mission"
              icon="Pen"
              variant="tertiary"
              onClick={() => {
                setDisableMissionTaskNameInput(false);
              }}
              onMouseDown={e => {
                e.preventDefault();
              }}
            />
          )}
        </Row>
        <Icon
          icon="RulerTriangle"
          size="medium"
          style={{ marginTop: '30px' }}
        />
      </Row>
      {errors.missionTaskName && (
        <InputHelperText color={theme.colors.red_400}>
          {errors.missionTaskName.message}
        </InputHelperText>
      )}
    </Stack>
  );
};
