import { mapboxgl } from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import GeospatialRowEventListeners from './GeospatialRowEventListeners';
import { LayerEvent } from '../map.d';
import { DrawToolModes } from '@/shared/constants/mapConstants';

export const initEvents = (
  map: mapboxgl.Map,
  setActiveRowId: (id: number | null) => void,
  drawtool: MapboxDraw,
) => {
  const withMap = handler => e => handler(e, map);

  const isDrawtoolActive = () => {
    const drawToolCursor = drawtool?.getMode();
    // check if draw tool is set to interact with a polygon
    return (
      drawToolCursor === DrawToolModes.Polygon ||
      drawToolCursor === DrawToolModes.Line ||
      drawToolCursor === DrawToolModes.DirectSelect
    );
  };

  const LayerListeners = [
    ...GeospatialRowEventListeners(setActiveRowId, isDrawtoolActive),
  ];
  LayerListeners.map((event: LayerEvent) => {
    event.layerIds.map((layer: string) => {
      map.on(event.type, layer, withMap(event.handler));
      map.on(
        'mouseenter',
        layer,
        withMap((e, map) =>
          isDrawtoolActive()
            ? e.preventDefault()
            : (map.getCanvas().style.cursor = 'pointer'),
        ),
      );
      map.on(
        'mouseleave',
        layer,
        withMap(
          (e, map) =>
            // remove the mapbox css so that drawtool can again control the cursor
            (map.getCanvas().style.cursor = ''),
        ),
      );
    });
  });
};
