const MixpanelEvents = {
  LandingPageLoaded: 'rf.landing_page_loaded',
  LandingUploadMission: 'rf.landing.upload_mission',
  LandingChooseExisting: 'rf.landing.choose_existing',
  LandingCreateNewMission: 'rf.landing.create_new_mission',
  UploadPageLoaded: 'rf.upload_page_loaded',
  CalibrationPageLoaded: 'rf.calibration_page_loaded',
  PlanPageLoaded: 'rf.plan_page_loaded',
  PlanCreateTakeoffPoint: 'rf.plan.create_takeoff_point',
  PlanCalibrationToggled: 'rf.plan.calibration_toggled',
  PlanSaveMission: 'rf.plan.save_mission',
  PlanPolygonDrawChange: 'rf.plan.polygon_draw_change',
  PlanPolygonDrawDelete: 'rf.plan.polygon_draw_delete',
  PlanDownloadMissionModal: 'rf.plan.download_mission_modal',
  PlanDownloadMission: 'rf.plan.download_mission_modal.download',
  PlanWaypointIconToggle: 'rf.plan.waypoint_icon_toggle',
  MissionsPageLoaded: 'rf.missions_page_loaded',
  MissionsUpload: 'rf.missions.upload',
  MissionsArchive: 'rf.missions.archive',
  MissionsBack: 'rf.missions.back',
};

export default MixpanelEvents;
