import { Row } from '@raptormaps/layout';
import { PillType } from '@raptormaps/pill';

// Components
import { StyledFlashingStatusPill } from '../Overview.styled';
import { StaleTelemetryIcon } from './StaleTelemetryIcon';

// Telemetry
import { DockDeviceTelemetry } from '@/shared/types/telemetry.d';
import { getDockCoverState } from '@/shared/utils/telemetryUtils';
import { useDockDeviceTelemetryValue } from '@/shared/stores/TelemetryWebsocket/TelemetryWebsocketStore';

// Constants
import { DOCK_COVER_STATE_TO_DISPLAY } from '@/shared/constants/deviceConstants';
import { TELEMETRY_KEY_TO_DISPLAY_CONFIG } from '@/shared/constants/telemetryConstants';

interface DockCoverStateTelemetryProps {
  deviceSn: string;
}

export const DockCoverStateTelemetry = ({
  deviceSn,
}: DockCoverStateTelemetryProps) => {
  const coverStateKey: keyof DockDeviceTelemetry = 'coverState';
  const emergencyStopStateKey: keyof DockDeviceTelemetry = 'emergencyStopState';

  const {
    value: coverStateValue,
    timestamp,
    isStale,
    lastMonitored,
  } = useDockDeviceTelemetryValue({
    deviceSn,
    key: coverStateKey,
    properties: { isStale: true, timestamp: true, lastMonitored: true },
  });

  const emergencyStopStateTelemetry = useDockDeviceTelemetryValue({
    deviceSn,
    key: emergencyStopStateKey,
    properties: { isStale: true, timestamp: true, lastMonitored: true },
  });

  const value = getDockCoverState(
    emergencyStopStateTelemetry.value as string,
    coverStateValue as string,
  );

  const { toDisplay } = TELEMETRY_KEY_TO_DISPLAY_CONFIG[coverStateKey];
  return (
    <Row align="center" noWrap>
      {isStale && (
        <StaleTelemetryIcon
          timestamp={timestamp}
          lastMonitored={lastMonitored}
        />
      )}
      <StyledFlashingStatusPill
        iconPosition="right"
        size="small"
        isFlashing={DOCK_COVER_STATE_TO_DISPLAY[value as string].isFlashing}
        variant={DOCK_COVER_STATE_TO_DISPLAY[value].pillColor as PillType}
      >
        {toDisplay(value)}
      </StyledFlashingStatusPill>
    </Row>
  );
};
