import { ThreeCircles } from 'react-loader-spinner';
import { Icon } from '@raptormaps/icon';
import theme from '@raptormaps/theme';
import { Text } from '@raptormaps/text';
import {
  SolarSiteCardContainer,
  SolarSiteCardContent,
  SelectSolarSiteButton,
  SolarSiteCardRow,
  SolarSiteCardHeadingRow,
  SolarSiteCardStatsRow,
  HighlightText,
} from '../SolarSiteList.styles';
import { SolarFarm } from '../../../shared/types/missions';
import { unixToDate } from '../siteList.utils';

interface SolarSiteCardProps {
  solarFarm?: SolarFarm;
  handleSelect: (option?: SolarFarm) => void;
  isLoading: boolean;
}

const SolarSiteCard = ({
  solarFarm,
  handleSelect,
  isLoading,
}: SolarSiteCardProps) => {
  return (
    <SolarSiteCardContainer id={solarFarm.id} data-testid="solar-site-card">
      {solarFarm.id === -1 ? (
        <SolarSiteCardContent
          style={{ display: 'flex', justifyContent: 'space-evenly' }}
        >
          <SolarSiteCardHeadingRow>
            <Text
              variant="h3"
              style={{ display: 'flex', justifyContent: 'space-around' }}
              title={solarFarm.name}
            >
              {solarFarm.name}{' '}
              {isLoading && (
                <ThreeCircles
                  height="35"
                  width="35"
                  color={theme.colors.primary_400}
                  visible={true}
                  ariaLabel="loading spinner"
                />
              )}
            </Text>
          </SolarSiteCardHeadingRow>
        </SolarSiteCardContent>
      ) : (
        <>
          <SolarSiteCardContent
            onClick={() => handleSelect(solarFarm as SolarFarm)}
            role="button"
          >
            <SolarSiteCardHeadingRow>
              <Text variant="h3" title={solarFarm.name}>
                {solarFarm.name}
              </Text>
            </SolarSiteCardHeadingRow>
            <SolarSiteCardRow title={solarFarm.address}>
              {solarFarm.address}
            </SolarSiteCardRow>
            <SolarSiteCardStatsRow>
              <Text
                title={`Commissioning Date: ${unixToDate(
                  solarFarm.commissioningTsecs,
                )}`}
              >
                <Icon
                  className="solar-site-card-icon"
                  icon="Calendar"
                  size="medium"
                />
                {unixToDate(solarFarm.commissioningTsecs) === 'Invalid date'
                  ? 'No Date Provided'
                  : unixToDate(solarFarm.commissioningTsecs)}
              </Text>
              <Text title={`DC Power: ${solarFarm.size} MWdc`}>
                <Icon
                  className="solar-site-card-icon"
                  icon="BoltLightning"
                  size="medium"
                />
                <HighlightText $fillcolor={theme.colors.neutral_50}>
                  <b>{solarFarm.size}</b>MWdc
                </HighlightText>
              </Text>
            </SolarSiteCardStatsRow>
          </SolarSiteCardContent>
          <SelectSolarSiteButton
            onClick={() => handleSelect(solarFarm as SolarFarm)}
          >
            <Icon icon="ArrowRightToArc" size="large" />
          </SelectSolarSiteButton>
        </>
      )}
    </SolarSiteCardContainer>
  );
};

export default SolarSiteCard;
