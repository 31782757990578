import { useEffect, Dispatch, useMemo, useState } from 'react';

// Map
import mapboxgl from 'mapbox-gl';
import {
  DEFAULT_MAP_CENTER,
  DEFAULT_ZOOM,
  MapboxStyles,
} from '@/shared/constants/mapConstants';
import {
  handleUpdateFlightSource,
  initMissionTaskSources,
} from '@/shared/components/Map/sources';
import { useRenderAsBuilts } from '@/shared/hooks/useAsBuilt';
import { useEquipmentSourcesOnMap } from '@/shared/hooks/useEquipment';
import { featureCollection } from '@turf/helpers';
import {
  DOCK_SOURCE_ID,
  DRONE_DIRECTION_SOURCE_ID,
  DRONE_SOURCE_ID,
} from '@/shared/components/Map/sources/DeviceSources';
import { initMissionTaskImages } from '@/shared/components/Map/images';
import { initEvents } from '@/shared/components/Map/EventListeners/InitEvents';
import {
  initTerrainSource,
  initNavControl,
} from '@/shared/components/Map/utils';
import { MapDetails } from '@/shared/components/MapDetailsPopover/MapDetailsPopover';
import { MapDetailsPopoverAnchor } from '@/shared/components/MapDetailsPopover/MapDetailsPopover.styles';
import { Popover } from '@raptormaps/popover';

// Styles
import './SiteOverviewMap.css';

// Types
import {
  DeviceType,
  SolarFarmResponse,
} from '@raptormaps/raptor-flight-client-ts';

// Constants
import { MAPBOX_ACCESS_TOKEN } from '@/shared/constants/mapConstants';
import { initMissionTaskLayers } from '@/shared/components/Map/layers';

// Hooks
import {
  TelemetryWebsocketState,
  useTelemetryStore,
} from '@/shared/stores/TelemetryWebsocket/TelemetryWebsocketStore';
import { useShallow } from 'zustand/react/shallow';
import { useParams } from 'react-router-dom';

interface SiteOverviewMapProps {
  map?: mapboxgl.Map;
  solarFarm?: SolarFarmResponse;
  setMap: Dispatch<mapboxgl.Map>;
}

const SiteOverviewMap = ({ map, solarFarm, setMap }: SiteOverviewMapProps) => {
  mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;
  const [activeRowId, setActiveRowId] = useState<number>(null);
  const params = useParams();
  const dockIsSelected = useMemo(() => !!params?.deviceId, [params]);

  useEquipmentSourcesOnMap(map, solarFarm, activeRowId);
  useRenderAsBuilts(map, solarFarm);

  const deviceLocations = useTelemetryStore(
    useShallow((state: TelemetryWebsocketState) => state.deviceLocations),
  );
  const dockLocations = deviceLocations.filter(
    feature => feature.properties.deviceType === DeviceType.Dock,
  );
  const droneLocations = deviceLocations.filter(
    feature => feature.properties.deviceType === DeviceType.AerialDrone,
  );

  useEffect(() => {
    if (map) return;

    const center = solarFarm
      ? { lat: solarFarm.latitude, lng: solarFarm.longitude }
      : DEFAULT_MAP_CENTER;

    const newMap = new mapboxgl.Map({
      container: 'map-container',
      style: MapboxStyles.StreetsV12,
      center,
      zoom: DEFAULT_ZOOM,
    });

    initNavControl(newMap);

    newMap.on('load', () => {
      initMissionTaskSources(newMap);
      initMissionTaskLayers(newMap);
      initMissionTaskImages(newMap);
      initEvents(newMap, setActiveRowId, null /* draw tool */);
      setMap(newMap);
    });

    newMap.on('style.load', () => {
      initTerrainSource(newMap);
    });

    return () => {
      newMap.remove();
      setMap(null);
    };
  }, []);

  useEffect(() => {
    if (!map || !solarFarm) return;
    map.setCenter([solarFarm.longitude, solarFarm.latitude]);
  }, [map, solarFarm]);

  useEffect(() => {
    if (dockLocations.length && map) {
      handleUpdateFlightSource(
        map,
        DOCK_SOURCE_ID,
        featureCollection(dockLocations),
      );
    }
  }, [deviceLocations, map]);

  useEffect(() => {
    if (dockLocations.length && map && dockIsSelected) {
      handleUpdateFlightSource(
        map,
        DRONE_SOURCE_ID,
        featureCollection(droneLocations),
      );
      handleUpdateFlightSource(
        map,
        DRONE_DIRECTION_SOURCE_ID,
        featureCollection(droneLocations),
      );
    } else if (deviceLocations.length && map && !dockIsSelected) {
      handleUpdateFlightSource(map, DRONE_SOURCE_ID, featureCollection([]));
      handleUpdateFlightSource(
        map,
        DRONE_DIRECTION_SOURCE_ID,
        featureCollection([]),
      );
    }
  }, [droneLocations, map, dockIsSelected]);

  return (
    <div className="map">
      <div id="map-container" className="map-container" />
      <Popover>
        <MapDetailsPopoverAnchor />
        <MapDetails
          map={map}
          solarFarmId={solarFarm?.id}
          activeRowId={activeRowId}
          useFlightDetails={false}
        />
      </Popover>
    </div>
  );
};

export default SiteOverviewMap;
