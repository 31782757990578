import { MapMouseEvent } from 'mapbox-gl';

const handlers = {
  handleRowClick: (
    e: MapMouseEvent,
    setActiveRowId: (id: number) => void,
    isDrawtoolActive: () => boolean,
  ) => {
    isDrawtoolActive() ? null : setActiveRowId(e.features[0].id);
  },
};

export default handlers;
