import { GeoJSONSource } from '../map.d';
export const MODULE_SOURCE_ID = 'raptor-modules';

const ModuleSource: GeoJSONSource = {
  id: MODULE_SOURCE_ID,
  source: {
    type: 'geojson',
    data: { type: 'FeatureCollection', features: [] },
  },
};
const ModuleSources: GeoJSONSource[] = [ModuleSource];

export default ModuleSources;
