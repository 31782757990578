import mapboxgl from 'mapbox-gl';
import BaseSources, { MapSource } from './BaseSources';
import FlightSources from './FlightSources';
import GeospatialRowSources from './GeospatialRowSources';
import { Feature, FeatureCollection } from '@turf/helpers';
import CombinerSources from './CombinerSources';
import InverterSources from './InverterSources';
import PyranometerSources from './PyranometerSources';
import StringSources from './StringSources';
import ModuleSources from './ModuleSources';
import InverterBlockSources from './InverterBlockSources';
import DeviceSources from './DeviceSources';

const baseSources: MapSource[] = [...BaseSources];
const flightSources: MapSource[] = [...FlightSources];
const digitalTwinSources: MapSource[] = [
  ...InverterBlockSources,
  ...GeospatialRowSources,
  ...CombinerSources,
  ...InverterSources,
  ...PyranometerSources,
  ...StringSources,
  ...ModuleSources,
];
const deviceSources: MapSource[] = [...DeviceSources];

export const initSources = (map: mapboxgl.Map) => {
  const sources = [...baseSources, ...flightSources, ...digitalTwinSources];
  sources.forEach(source => map.addSource(source.id, source.source));
};
export const initDigitalTwinSources = (map: mapboxgl.Map) => {
  const sources = [...baseSources, ...digitalTwinSources];
  sources.forEach(source => map.addSource(source.id, source.source));
};

export const initMissionTaskSources = (map: mapboxgl.Map) => {
  const sources = [
    ...baseSources,
    ...flightSources,
    ...digitalTwinSources,
    ...deviceSources,
  ];
  sources.forEach(source => map.addSource(source.id, source.source));
};

export const handleUpdateFlightSource = (
  map: mapboxgl.Map,
  sourceId: string,
  data?: Feature | FeatureCollection,
) => {
  map.getSource(sourceId)?.setData(
    data
      ? data
      : {
          type: 'FeatureCollection',
          features: [],
        },
  );
};

export const updateEquipmentSource = (
  map: mapboxgl.Map,
  sourceId: string,
  data?: GeoJSON.FeatureCollection,
) => {
  map.getSource(sourceId)?.setData(
    data
      ? data
      : {
          type: 'FeatureCollection',
          features: [],
        },
  );
};
