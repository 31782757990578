import {
  DroneType,
  MissionResponse,
} from '@raptormaps/raptor-flight-client-ts';
import {
  MissionInput,
  FeatureCollectionInput,
  FlightModeType,
  OverlapType,
  CameraAngleMode,
} from '@raptormaps/raptor-flight-client-ts';
import {
  MISSION_JSON_VERSION,
  DRONE_CAMERA_DEFAULTS,
} from '@/pages/MissionPlanner/constants/missionConstants';
import {
  AreaMissionFormikValues,
  LinearMissionFormikValues,
  GeospatialObject,
  MissionFileType,
  PointType,
  MannedAirplaneFormikValues,
} from '@/shared/types/missions.d';

import { getFieldOfViewSpacing } from './utils';
import { featureCollection, point, Polygon, Feature } from '@turf/helpers';

export interface GenerateMissionInputFromArea {
  missionName?: string;
  values: AreaMissionFormikValues;
  useSafeTakeoffAltitude: boolean;
  geospatial: GeospatialObject;
  device: DroneType;
  zoom: number;
  center: PointType;
}

export const generateMissionInputFromArea = ({
  missionName,
  values,
  useSafeTakeoffAltitude,
  device,
  geospatial,
  center,
  zoom,
}: GenerateMissionInputFromArea): MissionInput => {
  const { fieldOfViewHorizontal, fieldOfViewVertical } =
    DRONE_CAMERA_DEFAULTS[device][values.cameraType];

  const frontSpacing = getFieldOfViewSpacing(
    values.altitude,
    fieldOfViewHorizontal,
    values.frontOverlap,
  );

  const sideSpacing = getFieldOfViewSpacing(
    values.altitude,
    fieldOfViewVertical,
    values.sideOverlap,
  );

  const safeTakeoffAltitude = useSafeTakeoffAltitude
    ? values.safeTakeoffAltitude
    : null;

  const altitudeOffset = values.altitudeOffsetBool ? values.altitudeOffset : 0;

  const { polygon, markerLngLat, waypoints } = geospatial;
  const flightMap = featureCollection([polygon]);
  const mapCenter = point([center.lng, center.lat]).geometry;
  const takeoffPoint = markerLngLat
    ? point([markerLngLat.lng, markerLngLat.lat]).geometry
    : null;

  return {
    missionName: missionName ? missionName : missionName,
    altitude: values.altitude,
    altitudeOffset: altitudeOffset,
    altitudeOffsetBool: values.altitudeOffsetBool,
    cameraInterval: values.cameraInterval,
    cameraAngleMode: values.cameraAngleMode,
    cameraPitchAngle: values.cameraAngle,
    drone: device,
    flightAngle: values.flightAngle,
    flightMap,
    flightSpeed: values.flightSpeed,
    frontOverlap: values.frontOverlap,
    frontSpacing: frontSpacing,
    mapCenter,
    mapZoom: zoom,
    mode: values.flightMode as FlightModeType,
    overlapMode: OverlapType.Overlap,
    safeTakeoffAltitude,
    sensorFovHeight: fieldOfViewVertical,
    sensorFovWidth: fieldOfViewHorizontal,
    sensorName: values.cameraType,
    sideOverlap: values.sideOverlap,
    sideSpacing: sideSpacing,
    speedControl: values.speedControlMode,
    takeoffPoint,
    terrainFollow: values.terrainFollowBool,
    units: values.userUnits,
    transitSpeed: values?.transitSpeed,
    version: MISSION_JSON_VERSION,
    waypoints: waypoints as unknown as FeatureCollectionInput,
  };
};

export interface GenerateMissionInputFromLinear {
  missionName?: string;
  values: LinearMissionFormikValues;
  useSafeTakeoffAltitude: boolean;
  geospatial: GeospatialObject;
  device: DroneType;
  zoom: number;
  center: PointType;
}

export const generateMissionInputFromLinear = ({
  missionName,
  values,
  useSafeTakeoffAltitude,
  device,
  geospatial,
  center,
  zoom,
}: GenerateMissionInputFromLinear): MissionInput => {
  const { fieldOfViewHorizontal, fieldOfViewVertical } =
    DRONE_CAMERA_DEFAULTS[device][values.cameraType];

  const frontSpacing = getFieldOfViewSpacing(
    values.altitude,
    fieldOfViewHorizontal,
    values.frontOverlap,
  );

  const sideSpacing = getFieldOfViewSpacing(
    values.altitude,
    fieldOfViewVertical,
    values.sideOverlap,
  );

  const safeTakeoffAltitude = useSafeTakeoffAltitude
    ? values.safeTakeoffAltitude
    : null;

  const altitudeOffset = values.altitudeOffsetBool ? values.altitudeOffset : 0;

  const { polygon, markerLngLat, waypoints } = geospatial;
  const flightMap = featureCollection([polygon]);
  const mapCenter = point([center.lng, center.lat]).geometry;
  const takeoffPoint = markerLngLat
    ? point([markerLngLat.lng, markerLngLat.lat]).geometry
    : null;

  return {
    missionName: missionName ? missionName : missionName,
    altitude: values.altitude,
    altitudeOffset: altitudeOffset,
    altitudeOffsetBool: values.altitudeOffsetBool,
    cameraInterval: values.cameraInterval,
    cameraAngleMode: values.cameraAngleMode,
    cameraPitchAngle: values.cameraAngle,
    drone: device,
    flightAngle: values.flightAngle,
    flightMap,
    flightSpeed: values.flightSpeed,
    frontOverlap: values.frontOverlap,
    frontSpacing: frontSpacing,
    mapCenter,
    mapZoom: zoom,
    mode: values.flightMode as FlightModeType,
    overlapMode: OverlapType.Overlap,
    safeTakeoffAltitude,
    sensorFovHeight: fieldOfViewVertical,
    sensorFovWidth: fieldOfViewHorizontal,
    sensorName: values.cameraType,
    sideOverlap: values.sideOverlap,
    sideSpacing: sideSpacing,
    speedControl: values.speedControlMode,
    takeoffPoint,
    terrainFollow: values.terrainFollowBool,
    units: values.userUnits,
    version: MISSION_JSON_VERSION,
    transitSpeed: values?.transitSpeed,
    waypoints: waypoints as unknown as FeatureCollectionInput,
  };
};

export interface GenerateMissionInputFromMannedAirplane {
  missionName?: string;
  values: MannedAirplaneFormikValues;
  geospatial: GeospatialObject;
  device: DroneType;
  zoom: number;
  center: PointType;
}

export const generateMissionInputFromMannedAirplane = ({
  missionName,
  values,
  device,
  geospatial,
  center,
  zoom,
}: GenerateMissionInputFromMannedAirplane): MissionInput => {
  const { fieldOfViewHorizontal, fieldOfViewVertical } =
    DRONE_CAMERA_DEFAULTS[device][values.cameraType];

  const frontSpacing = getFieldOfViewSpacing(
    values.altitude,
    fieldOfViewHorizontal,
    values.frontOverlap,
  );

  const sideSpacing = getFieldOfViewSpacing(
    values.altitude,
    fieldOfViewVertical,
    values.sideOverlap,
  );

  const { polygon, markerLngLat, waypoints } = geospatial;
  const flightMap = featureCollection([polygon]);
  const mapCenter = point([center.lng, center.lat]).geometry;
  const takeoffPoint = markerLngLat
    ? point([markerLngLat.lng, markerLngLat.lat]).geometry
    : null;

  return {
    missionName: missionName ? missionName : missionName,
    altitude: values.altitude,
    altitudeOffset: 0,
    altitudeOffsetBool: false,
    cameraInterval: values.cameraInterval,
    cameraAngleMode: values.cameraAngleMode,
    cameraPitchAngle: values.cameraAngle,
    drone: device,
    flightAngle: values.flightAngle,
    flightMap,
    flightSpeed: values.flightSpeed,
    frontOverlap: values.frontOverlap,
    frontSpacing: frontSpacing,
    mapCenter,
    mapZoom: zoom,
    mode: values.flightMode as FlightModeType,
    overlapMode: OverlapType.Overlap,
    safeTakeoffAltitude: null,
    sensorFovHeight: fieldOfViewVertical,
    sensorFovWidth: fieldOfViewHorizontal,
    sensorName: values.cameraType,
    sideOverlap: values.sideOverlap,
    sideSpacing: sideSpacing,
    speedControl: values.speedControlMode,
    takeoffPoint,
    terrainFollow: false,
    units: values.userUnits,
    version: MISSION_JSON_VERSION,
    waypoints: waypoints as unknown as FeatureCollectionInput,
  };
};

export const parseToMissionFileType = (
  missionResponse: MissionResponse,
): MissionFileType => {
  if (!missionResponse) return null;
  if (missionResponse.mode === FlightModeType.LinearCustom) {
    return;
  }
  return {
    mission: {
      missionName: missionResponse.missionName,
      flightMode: missionResponse.mode,
      drone: missionResponse.drone,
      altitude: missionResponse.altitude,
      safeTakeoffAltitude: missionResponse.safeTakeoffAltitude as number | null,
      altitudeOffset: missionResponse.altitudeOffset,
      altitudeOffsetBool: missionResponse.altitudeOffsetBool,
      cameraAngle: missionResponse.cameraPitchAngle,
      // This conditional is incase we need to roll back the DB to before we supported cameraAngleMode
      cameraAngleMode: missionResponse.cameraAngleMode
        ? missionResponse.cameraAngleMode
        : CameraAngleMode.Manual,
      cameraInterval: missionResponse.cameraInterval,
      flightAngle: missionResponse.flightAngle,
      flightMap: missionResponse.flightMap.features[0] as Feature<Polygon>,
      flightSpeed: missionResponse.flightSpeed,
      frontOverlap: missionResponse.frontOverlap,
      frontSpacing: missionResponse.frontSpacing,
      mapCenter: {
        lng: missionResponse.mapCenter?.coordinates[0],
        lat: missionResponse.mapCenter?.coordinates[1],
      },
      mapZoom: missionResponse.mapZoom,
      overlapMode: missionResponse.overlapMode,
      sensor: {
        fieldOfViewHeight: missionResponse.sensorFovHeight,
        fieldOfViewWidth: missionResponse.sensorFovWidth,
        name: missionResponse.sensorName,
      },
      sideOverlap: missionResponse.sideOverlap,
      sideSpacing: missionResponse.sideSpacing,
      solarFarm: {
        id: missionResponse.solarFarmId,
        name: missionResponse.solarFarmId?.toString(),
      },
      speedControlMode: missionResponse.speedControl,
      takeOffPointCenter: missionResponse.takeoffPoint
        ? {
            lng: missionResponse.takeoffPoint?.coordinates[0],
            lat: missionResponse.takeoffPoint?.coordinates[1],
          }
        : null,
      terrainFollowBool: missionResponse.terrainFollow,
      userUnits: missionResponse.units,
      transitSpeed: missionResponse?.transitSpeed,
    },
    version: MISSION_JSON_VERSION,
    waypoints: null,
  };
};
