// External Dependencies
import { DropdownMenu, DropdownOption } from '@raptormaps/dropdown-menu';
import { useMemo, useEffect } from 'react';
import { Stack } from '@raptormaps/layout';

// Components
import { FormLabel } from '@/pages/MissionPlanner/components/MissionPlanningSidebar/MissionPlanningSidebar.styles';
import { DropdownButton } from '@/shared/styles/input.styles';

// Types
import { CombinedFlightModeType } from '@/shared/types/tempMissions';

// Constants
import { FLIGHT_MODE_TO_DISPLAY_NAME } from '@/shared/constants/missionLookups';
import { DataCollectionType } from '@/shared/types/dataCollection';

interface FlightModeDropdownProps {
  mode: CombinedFlightModeType;
  collectionType: DataCollectionType;
  flightModes: CombinedFlightModeType[];
  handleChange: (mode: CombinedFlightModeType) => void;
}

const FlightModeDropdown = ({
  mode,
  collectionType,
  flightModes,
  handleChange,
}: FlightModeDropdownProps) => {
  const flightModeOptions: DropdownOption[] = useMemo(
    () =>
      flightModes.map(flightMode => ({
        onSelect: () => {
          handleChange(flightMode);
        },
        value: FLIGHT_MODE_TO_DISPLAY_NAME[flightMode],
        variant: 'option',
        style: { fontSize: '14px', justifyContent: 'start' },
      })),
    [collectionType],
  );

  // Use defaults if active flight mode is not in collection type
  useEffect(() => {
    if (!flightModes.includes(mode)) {
      handleChange(flightModes[0]);
    }
  }, [collectionType]);

  return (
    <Stack style={{ flexGrow: 1 }} gutter="xxs">
      <FormLabel variant="label_small">Inspection Type</FormLabel>
      <DropdownMenu
        menuContentProps={{
          align: 'start',
          sideOffset: 0,
          side: 'bottom',
          style: {
            maxHeight: '70vh',
            width: '219px',
            minWidth: '120px',
            marginTop: 0,
          },
        }}
        items={flightModeOptions}
        modal={false}
      >
        <DropdownButton
          size="small"
          variant="secondary"
          id="inspection-type-dropdown-trigger"
          data-testid="inspection-type-dropdown-trigger"
        >
          {FLIGHT_MODE_TO_DISPLAY_NAME[mode]}
        </DropdownButton>
      </DropdownMenu>
    </Stack>
  );
};

export default FlightModeDropdown;
