import { MapSource } from './BaseSources';

export const DOCK_SOURCE_ID = 'dock-source';
export const SELECTED_DOCK_SOURCE_ID = 'selected-dock-source';
export const DRONE_SOURCE_ID = 'drone-source';
export const DRONE_DIRECTION_SOURCE_ID = 'drone-direction-source';

const DroneSource: MapSource = {
  id: DRONE_SOURCE_ID,
  source: {
    type: 'geojson',
    data: { type: 'FeatureCollection', features: [] },
  },
};

const DroneDirectionSource: MapSource = {
  id: DRONE_DIRECTION_SOURCE_ID,
  source: {
    type: 'geojson',
    data: { type: 'FeatureCollection', features: [] },
  },
};

const SelectedDockSource: MapSource = {
  id: SELECTED_DOCK_SOURCE_ID,
  source: {
    type: 'geojson',
    data: { type: 'FeatureCollection', features: [] },
  },
};

const DockSource: MapSource = {
  id: DOCK_SOURCE_ID,
  source: {
    type: 'geojson',
    data: { type: 'FeatureCollection', features: [] },
  },
};

export default [
  DroneSource,
  DroneDirectionSource,
  SelectedDockSource,
  DockSource,
];
