// External Dependancies
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { GrowthbookFlags } from '@/shared/utils/GrowthbookUtils';

// RaptorUI
import { Stack } from '@raptormaps/layout';
import { Button } from '@raptormaps/button';

// Components
import { SectionCollapsible } from '@/pages/MissionPlanner/components/MissionPlanningSidebar/MissionPlanningSidebar.styles';

// Hooks
import {
  useDockCloseDebugMode,
  useDockOpenDebugMode,
  useDockReboot,
} from '@/shared/hooks/useDockDeveloperTools';
import { useDockLensSwitch } from '@/shared/hooks/useLivestream';
import { FullWidthRow } from '@/shared/styles/sidebar.styles';

interface DockDeveloperToolsProps {
  dockSn: string;
  videoId: string;
}

export const DockDeveloperTools = ({
  dockSn,
  videoId,
}: DockDeveloperToolsProps) => {
  const showDockDeveloperTools = useFeatureIsOn(
    GrowthbookFlags.DOCK_DEVELOPER_TOOLS,
  );
  const { mutateAsync: closeDebugMode } = useDockCloseDebugMode();
  const { mutateAsync: openDebugMode } = useDockOpenDebugMode();
  const { mutateAsync: rebootDock } = useDockReboot();
  const { mutateAsync: lensSwitch } = useDockLensSwitch();

  return showDockDeveloperTools ? (
    <SectionCollapsible
      title="Developer Tools"
      id="developer-tools-section"
      iconColor="neutral_900"
      variant="ghost"
    >
      <Stack>
        <Button
          variant="warning"
          onClick={() => {
            openDebugMode({ dockSn });
          }}
        >
          Open Debug Mode
        </Button>
        <FullWidthRow>
          <Button
            style={{ width: '48%' }}
            onClick={() => {
              lensSwitch({ dockSn, videoId, videoType: 'ir' });
            }}
          >
            IR Camera
          </Button>
          <Button
            style={{ width: '48%' }}
            onClick={() => {
              lensSwitch({ dockSn, videoId, videoType: 'wide' });
            }}
          >
            Wide
          </Button>
        </FullWidthRow>
        <Button
          variant="error"
          onClick={() => {
            rebootDock({ dockSn });
          }}
        >
          Reboot Dock
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            closeDebugMode({ dockSn });
          }}
        >
          Close Debug Mode
        </Button>
      </Stack>
    </SectionCollapsible>
  ) : (
    <></>
  );
};
