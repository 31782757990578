import { Layers } from 'mapbox-gl';
import theme from '@raptormaps/theme';
import { MODULE_SOURCE_ID } from '../sources/ModuleSources';

export const MODULE_LAYER_ID = 'module-layer';

const ModuleLayer: Layers = [
  {
    id: MODULE_LAYER_ID,
    type: 'line',
    source: MODULE_SOURCE_ID,
    minzoom: 20,
    paint: {
      'line-color': theme.colors.neutral_700,
      'line-width': 1.5,
      'line-opacity': 1,
    },
  },
];

const ModuleLayers: Layers = [...ModuleLayer];

export default ModuleLayers;
