import styled from 'styled-components';
import { Row, Stack } from '@raptormaps/layout';
import { IconButton } from '@raptormaps/button';
import theme from '@raptormaps/theme';

export const Divider = styled(Row)`
  height: 1px;
  width: 100%;
  background-color: ${theme.colors.grey_300};
  border-bottom: 2px solid ${theme.colors.grey_300};
`;

export const SidebarBottomPadding = styled.div`
  margin-bottom: 0.5rem;
`;

export const CollapsibleTopPadding = styled.div`
  margin: 0;
`;

export const SideBarContent = styled(Stack)`
  padding: 5px 20px;
`;

export const PopoverCloseButton = styled(IconButton)`
  svg {
    width: 12px;
  }
`;

export const FullWidthRow = styled(Row)`
  width: 100%;
`;

export const FullWidthStack = styled(Stack)`
  width: 100%;
`;
