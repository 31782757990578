import { useEffect } from 'react';
import { useQuery } from 'react-query';
import mapboxgl from 'mapbox-gl';
import {
  CustomerFarmsApi,
  TileMap,
} from '@raptormaps/customer-farm-api-client-ts';
import { SolarFarmResponse } from '@raptormaps/raptor-flight-client-ts';
import { useAppContext } from '../context/AppContext';
import { useApiLegacy } from './useApiLegacy';
import { getFarmTileMapUrl } from '../components/Map/utils';
import { useToast } from '@raptormaps/toast';

export const useGetAsBuilt = (solarFarmId: number) => {
  const { user } = useAppContext();
  const orgId = user.latest_org_id;
  const CustomerFarmApi = useApiLegacy(CustomerFarmsApi, {
    basePath: window.REACT_APP_FARMBUILDER_API_ENDPOINT,
  });
  return useQuery({
    queryKey: ['as_built', orgId, solarFarmId],
    queryFn: async () => {
      if (!orgId || !solarFarmId) return null;
      const response = await CustomerFarmApi.getFarmTileMaps({
        solarFarmId: solarFarmId,
        orgId: orgId,
      });
      return response;
    },
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
};

export const useRenderAsBuilts = (
  map: mapboxgl.Map,
  solarFarm: SolarFarmResponse,
) => {
  const toast = useToast();
  const { user } = useAppContext();

  const { data: asBuilts, error: asBuiltError } = useGetAsBuilt(solarFarm?.id);

  useEffect(() => {
    if (!map || !solarFarm) return;
    Object.keys(map?.getStyle().sources).forEach(sourceID => {
      if (sourceID.startsWith('raptor-as-built-tiles-')) {
        map.removeLayer(sourceID);
        map.removeSource(sourceID);
      }
    });
    if (asBuilts?.length > 0) {
      asBuilts.map((tile: TileMap) => {
        // create a unique name fo each as built layer
        const tileMapId = `raptor-as-built-tiles-${tile.id}`;
        // if there is already a source with the "unique" id abort
        if (!map?.getSource(tileMapId)) {
          //add a source with the unique id to the map so we can associate the tileSet data with the map
          map.addSource(tileMapId, {
            type: 'raster',
            // Fetch the tileSet based on the tileMap -> mapbox requires a tile set to add the data to a source
            tiles: [getFarmTileMapUrl(tile, solarFarm, user.latest_org_id)],
            tileSize: 256,
            minzoom: 1,
            maxzoom: tile.maxZoom,
            scheme: 'tms',
            bounds: [
              tile.longitudeWest,
              tile.latitudeSouth,
              tile.longitudeEast,
              tile.latitudeNorth,
            ],
          });
        }

        if (!map?.getLayer(tileMapId)) {
          // Get all map layers
          const layers = map.getStyle().layers;
          // add a layer to put the data from the source created above on the map
          map.addLayer(
            {
              id: tileMapId,
              type: 'raster',
              source: tileMapId,
            },
            // move this layer to position 3 in the total layer stack so it appears below the other layers we are adding
            layers[2].id,
          );
        }
        //update the tile map opacity regardless
        if (map?.getSource(tileMapId) && tile.setting) {
          map?.setPaintProperty(tileMapId, 'raster-opacity', 1);
        }
      });
    }
    if (asBuiltError) {
      toast.error(`Error fetching as built for ${solarFarm?.name}`, {
        duration: 5000,
      });
    }
  }, [asBuilts, asBuiltError, map]);

  return asBuilts;
};
