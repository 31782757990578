import { useMemo } from 'react';

// Raptor UI
import { Text } from '@raptormaps/text';
import { Icon } from '@raptormaps/icon';
import { Button } from '@raptormaps/button';
import { Row } from '@raptormaps/layout';
import { DropdownItem, DropdownMenu } from '@raptormaps/dropdown-menu';
import { DropdownButtonText } from './DockOverview.styled';
import { Tooltip } from '@raptormaps/tooltip';

// Telemetry
import { useTelemetryStore } from '@/shared/stores/TelemetryWebsocket/TelemetryWebsocketStore';
import { useShallow } from 'zustand/react/shallow';

// Constants
import {
  DJI_VIDEO_QUALITY_TO_RM_VIDEO_QUALITY,
  DJI_VIDEO_TYPE_TO_RM_VIDEO_TYPE,
  DJILiveCapacity,
  DJILiveStatus,
} from '@/shared/constants/djiTelemetryConstants';
import { UNAVAILABLE_VALUE } from '@/shared/constants/deviceConstants';

// Types
import { DockDevice, LiveCapacityStatus } from '@/shared/types/devices';
import {
  DeviceResponse,
  DeviceType,
} from '@raptormaps/raptor-flight-client-ts';

// Utils
import { DJILiveCapacityConverter } from '@/shared/utils/telemetryUtils';

interface LivestreamViewerModalHeaderProps {
  dockDevice: DockDevice;
  handleSwitchDeviceCamera: (deviceSn: string, deviceType: DeviceType) => void;
}
export const LivestreamViewerModalHeader = ({
  dockDevice,
  handleSwitchDeviceCamera,
}: LivestreamViewerModalHeaderProps) => {
  // Telemetry
  const telemetryLiveCapacityState: DJILiveCapacity = useTelemetryStore(
    useShallow(
      state =>
        state?.devicesTelemetry[dockDevice.deviceSn]?.liveCapacity?.value,
    ),
  );

  const telemetryLivestreamStatus: DJILiveStatus[] = useTelemetryStore(
    useShallow(
      state =>
        state?.devicesTelemetry[dockDevice.deviceSn]?.livestreamStatus?.value,
    ),
  );

  // Converted Values
  const devicesLiveCapacityState: LiveCapacityStatus[] = useMemo(() => {
    return DJILiveCapacityConverter(telemetryLiveCapacityState);
  }, [telemetryLiveCapacityState]);

  const videoQuality: string = useMemo(() => {
    if (!telemetryLivestreamStatus?.length) {
      return '-';
    }
    const djiVideoQuality = telemetryLivestreamStatus[0].video_quality;
    return DJI_VIDEO_QUALITY_TO_RM_VIDEO_QUALITY[djiVideoQuality];
  }, [telemetryLivestreamStatus]);

  const videoType: string = useMemo(() => {
    if (!telemetryLivestreamStatus?.length) {
      return '-';
    }
    const djiVideoType = telemetryLivestreamStatus[0].video_type;
    return DJI_VIDEO_TYPE_TO_RM_VIDEO_TYPE[djiVideoType];
  }, [telemetryLivestreamStatus]);

  const activeDevice: DeviceResponse = useMemo(() => {
    if (!telemetryLivestreamStatus?.length) {
      return null;
    }

    const allDevices = [dockDevice, ...dockDevice.devices];
    const currentStreamingLiveStatus = telemetryLivestreamStatus[0];
    const currentStreamDeviceSn =
      currentStreamingLiveStatus.video_id.split('/')[0];

    return allDevices.find(
      (device: DeviceResponse) => device.deviceSn === currentStreamDeviceSn,
    );
  }, [telemetryLivestreamStatus]);

  const getDropdownItems = () => {
    const allDevices = [dockDevice, ...dockDevice.devices];

    if (!devicesLiveCapacityState || !dockDevice) {
      return [];
    }

    const availableDevicesSns = devicesLiveCapacityState.map(
      device => device.sn,
    );

    const availableDevices = allDevices.filter(device =>
      availableDevicesSns.includes(device.deviceSn),
    );

    return availableDevices.map(device => {
      return {
        value: device.deviceName,
        onSelect: () =>
          handleSwitchDeviceCamera(device.deviceSn, device.deviceType),
        variant: 'option',
      };
    }) as DropdownItem[];
  };

  const availableCamerasDropdownItems = getDropdownItems();

  return (
    <Row justify="space-between" align="center" gutter="sm">
      <Icon icon="CameraRetro" size="large" />
      <Text>{videoType}</Text>
      <Icon icon="HighDefinition" size="large" />
      <Text>{videoQuality}</Text>
      <Tooltip
        colorVariant="dark"
        delay={100}
        placement={{
          align: 'center',
          side: 'left',
        }}
        tip="If connection is lost, stream will continue attempting to reconnect. If stream is not re-established, please close and reopen the modal again to retry."
        shouldWrapChildren={true}
      >
        <Icon icon="CircleInfo" size="large" />
      </Tooltip>
      <DropdownMenu items={availableCamerasDropdownItems} modal={false}>
        <Button variant="secondary" icon="CameraRotate" iconPosition="left">
          <DropdownButtonText>
            {activeDevice ? activeDevice.deviceName : UNAVAILABLE_VALUE}
          </DropdownButtonText>
        </Button>
      </DropdownMenu>
    </Row>
  );
};
