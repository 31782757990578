import { Icon } from '@raptormaps/icon';
import theme from '@raptormaps/theme';
import { Tooltip } from '@raptormaps/tooltip';
import { convertSecondsToDisplay } from '@/shared/utils/telemetryUtils';

interface StaleTelemetryIconProps {
  timestamp: number;
  lastMonitored: number;
}

export const StaleTelemetryIcon = ({
  timestamp,
  lastMonitored,
}: StaleTelemetryIconProps) => {
  const delta = Math.round((lastMonitored - timestamp) / 1000);

  return (
    <Tooltip
      colorVariant="dark"
      delay={100}
      placement={{
        align: 'center',
        side: 'right',
      }}
      tip={`Data last reported ${convertSecondsToDisplay(delta)} ago`}
      shouldWrapChildren={true}
    >
      <Icon
        icon={'TriangleExclamation'}
        size="large"
        color={theme.colors.yellow_500}
      />
    </Tooltip>
  );
};
