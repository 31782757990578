//external dependancies
import { useMutation, useQueryClient, useQuery } from 'react-query';
import {
  ResponseError,
  FlightsApi,
  FlightUploadResponse,
  FlightResponse,
  FlightInput,
} from '@raptormaps/raptor-flight-client-ts';
import { useToast } from '@raptormaps/toast';

// local dependancies
import { useApi } from './useApi';
import { useAppContext } from '../context/AppContext';
import { ERROR_TOAST_DURATION } from '@/shared/constants/appConstants';

export const useCreateKmzUpload = (solarFarmId: number) => {
  const { user } = useAppContext();
  const orgId = user.latest_org_id;
  const queryClient = useQueryClient();

  const api = useApi(FlightsApi, {
    basePath: window.REACT_APP_FLIGHT_API_ENDPOINT,
  });

  return useMutation<FlightUploadResponse, ResponseError, File>({
    mutationFn: async file => {
      return api.uploadKmzFlightV1FlightsKmzUploadPost({
        orgId,
        solarFarmId,
        file,
      });
    },
    onSuccess: result => {
      queryClient.setQueryData(
        ['kmz_upload', orgId, solarFarmId, result.fileUrl],
        result,
      );
    },
  });
};

export const useCreateFlight = (solarFarmId: number) => {
  const toast = useToast();
  const { user } = useAppContext();
  const orgId = user.latest_org_id;
  const queryClient = useQueryClient();

  const api = useApi(FlightsApi, {
    basePath: window.REACT_APP_FLIGHT_API_ENDPOINT,
  });

  return useMutation<FlightResponse, ResponseError, FlightInput>({
    mutationFn: async (flightInput: FlightInput) => {
      return api.createFlightV1FlightsPost({
        orgId,
        solarFarmId,
        flightInput,
      });
    },
    onSuccess: result => {
      queryClient.setQueryData(
        ['flight', orgId, solarFarmId, result.id],
        result,
      );
    },
    onError: () => {
      toast.error(`Uploading Flight to flight api`, {
        duration: ERROR_TOAST_DURATION,
        dismissable: true,
      });
    },
  });
};

export const useGetFlight = ({
  solarFarmId,
  flightId,
}: {
  solarFarmId: number;
  flightId: number;
}) => {
  const toast = useToast();
  const handleFlightError = () =>
    toast.error(`Error Fetching Flight with id: ${flightId}`, {
      duration: ERROR_TOAST_DURATION,
      dismissable: true,
    });

  const { user } = useAppContext();
  const orgId = user.latest_org_id;

  const api = useApi(FlightsApi, {
    basePath: window.REACT_APP_FLIGHT_API_ENDPOINT,
  });

  return useQuery({
    queryKey: ['flight', orgId, solarFarmId, flightId],
    queryFn: async () => {
      if (!orgId || !solarFarmId || !flightId) return null;
      const result = await api.getFlightV1FlightsIdGet({
        orgId,
        solarFarmId,
        id: flightId,
      });
      return result;
    },
    retry(failureCount, error: ResponseError) {
      if ([403, 404].includes(error.response.status)) return false;
      return failureCount < 3;
    },
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    onError: handleFlightError,
  });
};
