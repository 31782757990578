//external dependancies
import { useQuery, useQueryClient } from 'react-query';
import {
  DeviceModelsApi,
  DeviceModelResponseLite,
} from '@raptormaps/raptor-flight-client-ts';

// local dependancies
import { useApi } from './useApi';
import { useAppContext } from '../context/AppContext';

export const GET_DEVICE_MODELS_PAGINATION_LIMIT = 100;

type GetDeviceModelsParams = {
  solarFarmId?: number;
  limit?: number;
  offset?: number;
  onError?: () => void;
};
export const useGetDeviceModels = ({
  solarFarmId,
  limit,
  offset,
  onError,
}: GetDeviceModelsParams) => {
  const { user } = useAppContext();
  const orgId = user.latest_org_id;
  const queryClient = useQueryClient();
  const queryKey = ['device_models', orgId, solarFarmId, offset, limit];
  const api = useApi(DeviceModelsApi, {
    basePath: window.REACT_APP_FLIGHT_API_ENDPOINT,
  });
  const cachedData =
    queryClient.getQueryData<DeviceModelResponseLite[]>(queryKey);

  const query = useQuery({
    queryKey: queryKey,
    queryFn: async () => {
      if (!orgId || !solarFarmId) return null;
      return await api.getAllDeviceModelsV1DeviceModelsGet({
        solarFarmId,
        orgId,
        limit: limit ? limit : GET_DEVICE_MODELS_PAGINATION_LIMIT,
        offset: offset ? offset : 0,
      });
    },
    initialData: cachedData,
    enabled: !cachedData,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    onError: onError,
  });

  const cachedQueries = queryClient.getQueriesData<
    Array<DeviceModelResponseLite>
  >(['device_models', orgId, solarFarmId]);

  const deviceModels: DeviceModelResponseLite[] = cachedQueries?.reduce(
    (acc, queryData) => {
      if (!queryData) return acc;
      const [key, data] = queryData;
      const offsetKey = key[3] as number;

      if (!data || offsetKey > offset) return acc;
      return [...acc, ...data];
    },
    [] as DeviceModelResponseLite[],
  );

  return {
    ...query,
    data: {
      deviceModels,
    },
  };
};
