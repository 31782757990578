// Styled Components
import { Row } from '@raptormaps/layout';
import { Icon } from '@raptormaps/icon';
import { Text } from '@raptormaps/text';
import { ContentBlock } from '@raptormaps/content-block';

// Components
import { IconTelemetry } from '../Shared/IconTelemetry';
import { TextIconTelemetry } from '../Shared/TextIconTelemetry';
import { RemainingImagesTelemetry } from '../Shared/RemainingImagesTelemetry';
import { AircraftStateTelemetry } from '../Shared/AircraftStateTelemetry';
import { DroneLocationButton } from './DroneLocationButton';
import { BatteryPercentTelemetry } from '../Shared/BatteryPercentTelemetry';

// Models
import { DeviceResponse } from '@raptormaps/raptor-flight-client-ts';
import { Feature } from '@turf/helpers';

interface DroneSidebarTelemetryProps {
  droneDevice: DeviceResponse;
  dockSn: string;
  navigateToDroneLocation: (droneLocation: Feature) => void;
}

const DroneSidebarTelemetry = ({
  droneDevice,
  navigateToDroneLocation,
  dockSn,
}: DroneSidebarTelemetryProps) => {
  const deviceSn = droneDevice.deviceSn;

  return (
    <>
      <ContentBlock columns={1} padding="medium" variant="grey">
        <Row align="center" justify="space-between">
          <Row align="center" justify="start" gutter="xxs">
            <Icon icon="HouseDrone" />
            <Text variant="paragraph_medium" align="left">
              {droneDevice.deviceName}
            </Text>
          </Row>
          <AircraftStateTelemetry dockSn={dockSn} aircraftSn={deviceSn} />
        </Row>
      </ContentBlock>
      <ContentBlock
        columns={2}
        padding="large"
        variant="white"
        style={{ boxShadow: 'unset' }}
      >
        <BatteryPercentTelemetry
          dockSn={dockSn}
          aircraftSn={deviceSn}
          showIcon
        />
        <IconTelemetry
          telemetryKey="aircraftDistanceFromDock"
          deviceSn={droneDevice.deviceSn}
        />
        <TextIconTelemetry
          telemetryKey="altitudeRelativeTakeoff"
          deviceSn={droneDevice.deviceSn}
        />
        <TextIconTelemetry
          telemetryKey="altitudeRelativeTerrain"
          deviceSn={droneDevice.deviceSn}
          textIconStyle={{ marginLeft: -5 }}
        />
        <TextIconTelemetry
          telemetryKey="aircraftVerticalSpeed"
          deviceSn={droneDevice.deviceSn}
        />
        <TextIconTelemetry
          telemetryKey="aircraftHorizontalSpeed"
          deviceSn={droneDevice.deviceSn}
        />
        <TextIconTelemetry
          telemetryKey="aircraftRoll"
          deviceSn={droneDevice.deviceSn}
        />
        <TextIconTelemetry
          telemetryKey="aircraftPitch"
          deviceSn={droneDevice.deviceSn}
        />
        <TextIconTelemetry
          deviceSn={droneDevice.deviceSn}
          telemetryKey="cameraGimbalRoll"
        />
        <TextIconTelemetry
          telemetryKey="cameraGimbalPitch"
          deviceSn={droneDevice.deviceSn}
        />
        <IconTelemetry
          telemetryKey="memoryCapacity"
          deviceSn={droneDevice.deviceSn}
        />
        <RemainingImagesTelemetry deviceSn={droneDevice.deviceSn} />
        <IconTelemetry
          deviceSn={droneDevice.deviceSn}
          telemetryKey="remainingFlightTime"
        />
      </ContentBlock>
      <DroneLocationButton
        droneSn={deviceSn}
        navigateToDroneLocation={navigateToDroneLocation}
      />
    </>
  );
};

export default DroneSidebarTelemetry;
