import {
  MissionTaskStatusType,
  MissionTaskStatusObject,
} from './missionTasksTable.d';

export const COLUMNS: Array<{
  name: string;
  key: string;
}> = [
  {
    name: 'Executed Time',
    key: 'executedTime',
  },
  {
    name: 'Status',
    key: 'status',
  },
  {
    name: 'Task Name',
    key: 'taskName',
  },
  {
    name: 'Drone',
    key: 'drone',
  },
  {
    name: 'Dock',
    key: 'dock',
  },
  { name: 'Mission Type', key: 'missionType' },
  { name: 'Mission Name', key: 'missionName' },
  { name: '', key: 'more' },
];

export enum MISSION_TASK_STATUS {
  Pending = 'pending',
  InProgress = 'in_progress',
  Completed = 'completed',
  Failed = 'failed',
  Cancelled = 'cancelled',
}

export const MissionTaskStatus: Record<
  MissionTaskStatusType,
  MissionTaskStatusObject
> = {
  [MISSION_TASK_STATUS.Pending]: {
    displayName: 'Pending',
    pillColor: 'grey',
  },
  [MISSION_TASK_STATUS.InProgress]: {
    displayName: 'In Progress',
    pillColor: 'blue',
  },
  [MISSION_TASK_STATUS.Completed]: {
    displayName: 'Completed',
    pillColor: 'green',
  },
  [MISSION_TASK_STATUS.Failed]: {
    displayName: 'Failed',
    pillColor: 'red',
  },
  [MISSION_TASK_STATUS.Cancelled]: {
    displayName: 'Cancelled',
    pillColor: 'yellow',
  },
};
