//External Dependancies
import { useState, useEffect, useCallback } from 'react';
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
} from 'react-router-dom';
import { ThreeCircles } from 'react-loader-spinner';
import { debounce } from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { MissionTaskOverviewResponse } from '@raptormaps/raptor-flight-client-ts';
// RaptorUI
import { Text } from '@raptormaps/text';
import { Button } from '@raptormaps/button';
import { Pill } from '@raptormaps/pill';
import { Row } from '@raptormaps/layout';
import { useToast } from '@raptormaps/toast';
import theme from '@raptormaps/theme';

// Internal Dependancies
import { PageProps } from '@/shared/types/page.d';
import { useUrlSearchParams } from '@/shared/hooks/useUrlParams';
import {
  useGetMissionTasks,
  GET_MISSION_TASKS_PAGINATION_LIMIT,
  useDeleteMissionTask,
} from '@/shared/hooks/useMissionTasks';
import { useGetDevices } from '@/shared/hooks/useDevices';
import { COLUMNS, MISSION_TASK_STATUS } from './missionTasksTableConstants';
import { ERROR_TOAST_DURATION } from '@/shared/constants/appConstants';
// Components
import {
  MissionTasksTableContainer,
  MissionTaskTable,
  FetchingMessage,
} from './MissionTasksTable.styles';
import { TableValueWithToolTip } from './components/TableValueWithToolTip';
import { TaskStatus } from './components/TaskStatus';
import { StartAndEndTime } from './components/StartAndEndTime';
import BreadcrumbNavigation from '@/shared/components/BreadCrumbs/Breadcrumbs';
import { NoResults } from '@/shared/components/EmptyTable/EmptyTable';
import RowMoreMenu from '@/shared/components/RowMoreMenu/RowMoreMenu';
import { ArchiveModal } from '@/shared/components/ArchiveModal/ArchiveModal';
import { ComponentLoader } from '@/shared/components/ComponentLoader/ComponentLoader';

// makes all times in UTC
dayjs.extend(utc);

export const MissionTasksTable = ({ solarFarmId: solarFarmId }: PageProps) => {
  const navigate = useNavigate();
  const setSearchParams = useSearchParams()[1];
  const toast = useToast();

  const searchQueryParam = useUrlSearchParams<string>('search', 'string') || '';

  const [search, setSearch] = useState<string>(searchQueryParam);
  const [offset, setOffset] = useState(0);
  const [missionTask, setMissionTask] =
    useState<MissionTaskOverviewResponse | null>(null);

  const { data: devices, isFetching: isFetchingDevices } = useGetDevices({
    solarFarmId,
  });
  const deleteMissionTask = useDeleteMissionTask(solarFarmId);
  const {
    data: missionTasksResponse,
    isFetching,
    isLoading,
  } = useGetMissionTasks(
    solarFarmId,
    searchQueryParam,
    GET_MISSION_TASKS_PAGINATION_LIMIT,
    offset,
  );

  useEffect(() => {
    handleSearch(search);
    return () => {
      handleSearch.cancel();
    };
  }, [search]);

  const handleSearch = useCallback(
    debounce((query: string): void => {
      setSearchParams({
        solar_farm_id: solarFarmId?.toString() || '',
        search: query,
      });
      setOffset(0);
    }, 750),
    [solarFarmId, setSearchParams],
  );

  const populateDevice = (device_id: number) => {
    return device_id
      ? devices?.solarFarmDevices?.find(device => device.id === device_id)
          ?.deviceName
      : 'N/A';
  };

  const getSortedMissionTasks = (missionTasks: MissionTaskOverviewResponse[]) =>
    missionTasks
      // group by day
      .reduce((acc, missionTask) => {
        const day = dayjs(missionTask.startTsecs * 1000).format('MM/DD/YYYY');
        if (!acc[day]) {
          acc[day] = [];
        }
        acc[day].push({
          executedTime: <StartAndEndTime missionTask={missionTask} />,
          status: <TaskStatus missionTask={missionTask} />,
          taskName: (
            <TableValueWithToolTip textContent={missionTask.taskName} />
          ),
          drone: (
            <TableValueWithToolTip
              textContent={populateDevice(missionTask.droneId)}
            />
          ),
          dock: (
            <TableValueWithToolTip
              textContent={populateDevice(missionTask.dockId)}
            />
          ),
          missionType: missionTask.missions.length > 0 && (
            <Pill>{missionTask.missions[0].mode}</Pill>
          ),

          missionName: (
            <Row
              gutter="none"
              justify="space-between"
              noWrap={true}
              align="center"
            >
              <TableValueWithToolTip
                textContent={
                  missionTask.missions.length > 0 &&
                  missionTask.missions[0].missionName
                }
              />
              <RowMoreMenu
                record={missionTask}
                setRecord={setMissionTask}
                disableArchive={
                  missionTask.taskStatus != MISSION_TASK_STATUS.Pending
                }
              />
            </Row>
          ),

          id: missionTask.id,
        });
        return acc;
      }, {}) || {};

  const missionTaskTableItems =
    /*
      Seperates the day groupings by creating a row with all empty values except for the executedTime
    */
    Object.entries(
      getSortedMissionTasks(missionTasksResponse?.missionTasks || []),
    ).reduce(
      (
        acc: (
          | MissionTaskOverviewResponse
          | { executedTime: string }
          | { taskName: JSX.Element }
        )[],
        [day, tasks]: [string, MissionTaskOverviewResponse[]],
      ) => {
        acc.push({ executedTime: day });
        acc.push(...tasks);
        return acc;
      },
      [],
    );

  // Add custom pagination message to accept adjusted values for row count
  missionTasksResponse?.total &&
    missionTaskTableItems.push({
      taskName: (
        <Text variant="paragraph_small">
          {!isLoading || !isFetching ? (
            `You've viewed ${missionTasksResponse?.missionTasks.length} out of ${missionTasksResponse.total} items`
          ) : (
            <ComponentLoader message="" />
          )}
        </Text>
      ),
    });

  const handleClickRow = original => {
    if (!original.id) return;
    if (!original.missionType) {
      toast.error(
        `Cannot load Mission Task: ${original.taskName.props.textContent} because it has no missions associated to it`,
        {
          dismissable: true,
          duration: ERROR_TOAST_DURATION,
        },
      );
      return;
    }
    navigate({
      pathname: `/plan-task/${original.id}`,
      search: createSearchParams({
        solar_farm_id: solarFarmId.toString(),
      }).toString(),
    });
  };

  return (
    <MissionTasksTableContainer>
      <BreadcrumbNavigation
        crumbs={[
          ['Solar Site Select', '/'],
          ['Mission Task', '/tasks'],
        ]}
      />
      <Text align="left" variant="h2">
        Mission Task
      </Text>
      <Button
        style={{ alignSelf: 'flex-end' }}
        onClick={() =>
          navigate({
            pathname: '/plan-task',
            search: `solar_farm_id=${solarFarmId}`,
          })
        }
        role="link"
        icon="PlaneDeparture"
        iconPosition="right"
        size="medium"
        variant="primary"
      >
        {' '}
        Create Mission Task
      </Button>
      <MissionTaskTable
        cols={COLUMNS}
        data={missionTaskTableItems}
        enableSearch={true}
        fetchData={async () => {
          // Fetch data executes on initial render and when handleSearch is called.
          // This prevents the default behavior.
          if (isLoading || isFetching || search !== searchQueryParam) return;
          setOffset(offset + GET_MISSION_TASKS_PAGINATION_LIMIT);
        }}
        filters={{
          activeFilters: [],
          filterComponent: () => {
            return (
              <Button
                icon="Sliders"
                iconPosition="left"
                size="medium"
                variant="secondary"
                disabled={true}
              >
                Filters
              </Button>
            );
          },
        }}
        handleSearch={e => setSearch(e.target.value)}
        handleRowClick={({ original }) => handleClickRow(original)}
        noDataMessage={
          isFetching || isFetchingDevices ? (
            <FetchingMessage justify="center">
              <ThreeCircles
                height="40"
                width="40"
                color={theme.colors.primary_400}
                visible={true}
                ariaLabel="loading spinner"
              />
              Retrieving Mission Tasks...
            </FetchingMessage>
          ) : (
            <NoResults />
          )
        }
        paginationPageSize={GET_MISSION_TASKS_PAGINATION_LIMIT}
        searchPlaceholder={'Search by mission task name'}
        searchValue={search || ''}
        shadow={true}
        tableHeight={600}
        totalRowCount={
          isLoading || isFetching || !missionTasksResponse?.total
            ? 0
            : missionTasksResponse.total
        }
      />
      <ArchiveModal
        open={!!missionTask}
        onClose={() => setMissionTask(null)}
        handleCancel={() => setMissionTask(null)}
        handleConfirm={() => {
          deleteMissionTask.mutate(missionTask.id, {
            onSuccess: () => {
              setOffset(0);
              setMissionTask(null);
            },
          });
        }}
        title="Archive Mission Task?"
        description="Archiving this mission task will remove it from the mission task library."
        confirmText="Archive"
        closeText="Cancel"
      />
    </MissionTasksTableContainer>
  );
};
