import { Layers } from 'mapbox-gl';
import { COMBINER_SOURCE_ID } from '../sources/CombinerSources';

export const COMBINER_LAYER_ID = 'combiner-layer';

const CombinerLayer: Layers = [
  {
    id: COMBINER_LAYER_ID,
    type: 'symbol',
    source: COMBINER_SOURCE_ID,
    minzoom: 16,
    layout: {
      'symbol-placement': 'point',
      'icon-image': 'combiner-off',
      visibility: 'visible',
    },
  },
];

const CombinerLayers: Layers = [...CombinerLayer];

export default CombinerLayers;
