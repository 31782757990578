import { Source } from 'mapbox-gl';
import { MapboxStyles } from '../../../constants/mapConstants';

export interface MapSource {
  id: string;
  source: Source;
}

export const Terrain: MapSource = {
  id: 'mapbox-terrain',
  source: {
    type: 'vector',
    url: MapboxStyles.TerrainV2,
  },
};

export default [Terrain];
