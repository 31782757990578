import mapboxgl, { LayerSpecification } from 'mapbox-gl';
import BaseLayers from './BaseLayers';
import FlightLayers from './FlightLayers';
import GeospatialRowLayers from './GeospatialRowLayers';
import InverterLayers from './InverterLayers';
import CombinerLayers from './CombinerLayers';
import PyranometerLayers from './PyranometerLayers';
import ModuleLayers from './ModuleLayers';
import StringLayers from './StringLayers';
import InverterBlockLayers from './InverterBlockLayers';
import DeviceLayers from './DeviceLayers';

const baseLayers: LayerSpecification[] = [...BaseLayers];

const flightLayers: LayerSpecification[] = [...FlightLayers];
const deviceLayers: LayerSpecification[] = [...DeviceLayers];

const digitalTwinLayers: LayerSpecification[] = [
  ...StringLayers,
  ...ModuleLayers,
  ...InverterBlockLayers,
  ...GeospatialRowLayers,
  ...CombinerLayers,
  ...InverterLayers,
  ...PyranometerLayers,
];

export const initLayers = (map: mapboxgl.Map) => {
  //order matters here the start of this list is the layer that is on top of all the layers
  const layers = [...flightLayers, ...digitalTwinLayers, ...baseLayers];
  layers.forEach(layer => {
    const layers = map.getStyle().layers;
    // this is layers[3] because we are automatically adding the as-builts to layers[2]
    map.addLayer(layer, layers[3].id);
  });
};

export const initDigitalTwinLayers = (map: mapboxgl.Map) => {
  const layers = [...digitalTwinLayers, ...baseLayers];
  layers.forEach(layer => {
    const layers = map.getStyle().layers;
    map.addLayer(layer, layers[3].id);
  });
};

export const initMissionTaskLayers = (map: mapboxgl.Map) => {
  const layers = [
    ...flightLayers,
    ...digitalTwinLayers,
    ...baseLayers,
    ...deviceLayers,
  ];
  layers.forEach(layer => {
    const layers = map.getStyle().layers;
    map.addLayer(layer, layers[3].id);
  });
};
