import { ThreeCircles } from 'react-loader-spinner';
import { Row } from '@raptormaps/layout';
import { Text } from '@raptormaps/text';
import theme from '@raptormaps/theme';

interface ComponentLoaderProps {
  message: string;
}

export const ComponentLoader = ({ message }: ComponentLoaderProps) => {
  return (
    <Row gutter="small">
      <ThreeCircles
        height="15"
        width="15"
        color={theme.colors.primary_400}
        visible={true}
        ariaLabel="loading spinner"
      />
      <Text>{message}</Text>
    </Row>
  );
};
