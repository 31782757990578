import { useMemo } from 'react';
import { Icon } from '@raptormaps/icon';
import theme from '@raptormaps/theme';
import { FeatureCollection, Point } from '@turf/helpers';
import {
  checkWaypointsNegativeRelativeAltitude,
  checkWaypointsSignificantAltitudeChange,
} from '@/pages/MissionPlanner/utils/flightPlanningUtils';
import './WaypointsWarningBanner.css';

interface WaypointsWarningBannerProps {
  waypoints: FeatureCollection<Point>;
  altitude: number;
}

const WaypointsWarningBanner = ({
  waypoints,
  altitude,
}: WaypointsWarningBannerProps) => {
  const negativeWaypoints = useMemo(
    () => (waypoints ? checkWaypointsNegativeRelativeAltitude(waypoints) : []),
    [waypoints],
  );
  const signficantAltitudeChangeWaypoints = useMemo(
    () =>
      waypoints
        ? checkWaypointsSignificantAltitudeChange(waypoints, altitude)
        : [],
    [waypoints, altitude],
  );
  return (
    <>
      {negativeWaypoints.length > 0 ||
      signficantAltitudeChangeWaypoints.length > 0 ? (
        <div className="waypoint-warning waypoint-errors">
          <div className="">
            <div className="waypoint-warning-Card waypoint-list">
              <div className={'Error'}>
                <div className="waypoint-warning-Card-heading">
                  <Icon
                    className="icon-label-icon"
                    icon="TriangleExclamation"
                    size="large"
                    color={theme.colors.yellow_500}
                  />
                  Check the Following Waypoints
                </div>
                {negativeWaypoints.length > 0 && (
                  <p className="waypoint-warning-Card-text">
                    Negative Altitudes Detected: The flight mission contains
                    negative altitudes relative to the takeoff point. If this is
                    incorrect, please redraw the flight area or disable Terrain
                    Following. Negative altitudes are listed below.
                  </p>
                )}
                {signficantAltitudeChangeWaypoints.length > 0 && (
                  <p className="waypoint-warning-Card-text">
                    Significant Changes in Altitude Detected: The flight mission
                    contains significant changes in altitude that are greater
                    than 10% throughout the mission. If this is incorrect,
                    please redraw the flight area or disable Terrain Following.
                    Significant differences are listed below.
                  </p>
                )}
                <div className="waypoint-list-container">
                  {negativeWaypoints.length > 0 && (
                    <>
                      <div className="waypoint-warning-values underlined">
                        Negative Values:
                      </div>
                      <ul>
                        {negativeWaypoints.map((negWay, index) => (
                          <li className="invalid" key={index}>
                            <h5>Waypoint: {negWay.properties.name}</h5>
                            <p>
                              Altitude: {negWay.properties.altitude.toFixed(2)}{' '}
                              meters
                            </p>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                  {signficantAltitudeChangeWaypoints.length > 0 && (
                    <>
                      <div className="waypoint-warning-values underlined">
                        Significant Difference
                      </div>
                      <ul>
                        {signficantAltitudeChangeWaypoints.map(
                          (sigWay, index) => (
                            <li className="invalid" key={index}>
                              <br />
                              <p>
                                Waypoint {sigWay[0].properties.name} to Waypoint{' '}
                                {Number(sigWay[0].properties.name) + 1} (
                                {sigWay[1].toFixed(2)}
                                meters)
                              </p>
                            </li>
                          ),
                        )}
                      </ul>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default WaypointsWarningBanner;
