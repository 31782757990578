import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuPortal,
  DropdownMenuGroup,
} from '@radix-ui/react-dropdown-menu';
import { Text, TextVariant } from '@raptormaps/text';
import { Gutter, Stack } from '@raptormaps/layout';
import { Icon } from '@raptormaps/icon';
import {
  DropdownContentContainer,
  MenuLabel,
  MenuDivider,
} from './AccountOrgSwitcher.style';
import { AccountOrgTrigger } from './AccountOrgTrigger';
import { OrgList } from './OrgList';
import { User } from '../../../../types/missions.d';
import { useAppContext } from '../../../../context/AppContext';
import { formatUsernameForDisplay } from '../../../../../pages/MissionTable/missionTable.utils';

interface AccountOrgSwitcherProps {
  user: User;
  changeOrg: (orgId: number) => void;
}

export const AccountOrgSwitcher = ({
  user,
  changeOrg,
}: AccountOrgSwitcherProps) => {
  const { activeOrg } = useAppContext();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <AccountOrgTrigger>
          <Icon icon="User" size="medium" />
        </AccountOrgTrigger>
      </DropdownMenuTrigger>

      <DropdownMenuPortal>
        <div id="OrgListDropdown">
          <DropdownContentContainer
            style={{
              borderRadius: '0px 0px 4px 4px',
            }}
          >
            <Stack as={DropdownMenuGroup} gutter={Gutter.None}>
              <MenuLabel as={Text} variant={TextVariant.paragraph_medium_bold}>
                {formatUsernameForDisplay(user.name)}
              </MenuLabel>
              <MenuLabel as={Text} variant={TextVariant.paragraph_medium}>
                {activeOrg?.name}
              </MenuLabel>
            </Stack>
            <MenuDivider />
            {user.orgs && user.orgs.length > 0 ? (
              <>
                <OrgList
                  orgs={user.orgs}
                  currentOrgId={activeOrg?.id}
                  onOrgOptionClick={changeOrg}
                />
              </>
            ) : null}
          </DropdownContentContainer>
        </div>
      </DropdownMenuPortal>
    </DropdownMenu>
  );
};
