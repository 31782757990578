// Styled Components
import { Row, Stack } from '@raptormaps/layout';
import { Text } from '@raptormaps/text';

// Components
import { StaleTelemetryIcon } from './StaleTelemetryIcon';

// Telemetry
import { useDockDeviceTelemetryValue } from '@/shared/stores/TelemetryWebsocket/TelemetryWebsocketStore';
import { DockDeviceTelemetry } from '@/shared/types/telemetry';

// Constants
import { TELEMETRY_KEY_TO_DISPLAY_CONFIG } from '@/shared/constants/telemetryConstants';

interface TextIconTelemetryProps {
  telemetryKey: keyof DockDeviceTelemetry;
  deviceSn: string;
  textIconStyle?: object;
}

export const TextIconTelemetry = ({
  telemetryKey,
  deviceSn,
  textIconStyle = {},
}: TextIconTelemetryProps) => {
  const { value, isStale, timestamp, lastMonitored } =
    useDockDeviceTelemetryValue({
      deviceSn,
      key: telemetryKey,
      properties: { isStale: true, timestamp: true, lastMonitored: true },
    });

  const { toDisplay, iconTextName, title } =
    TELEMETRY_KEY_TO_DISPLAY_CONFIG[telemetryKey];

  return (
    <Row align="center" noWrap>
      {isStale ? (
        <StaleTelemetryIcon
          timestamp={timestamp}
          lastMonitored={lastMonitored}
        />
      ) : (
        <Text variant="paragraph_small_bold" style={textIconStyle}>
          {iconTextName}
        </Text>
      )}
      <Stack gutter="xxs">
        <Text variant="paragraph_small" align="left">
          {title}
        </Text>
        <Text variant="paragraph_large_bold" align="left">
          {toDisplay(value)}
        </Text>
      </Stack>
    </Row>
  );
};
