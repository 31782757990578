export enum AircraftDeviceStatus {
  Flying = 'Flying',
  Docked = 'Docked',
  Charging = 'Charging',
  Idle = 'Idle',
}

export enum DeviceStatus {
  Open = 'Open',
  Closed = 'Closed',
}

export enum LiveStreamStatus {
  Success = 0,
  Error = -1,
}
export interface ParsedVideoDetails {
  serialNumber: string;
  cameraIndex: string;
  videoIndex: string;
}

// Mock data for stream lens video type using DJI documentation for reference
export enum VideoLensType {
  'ir' = 'IR',
  'normal' = 'Default',
  'wide' = 'Wide-angle',
  'zoom' = 'Zoom',
}

export enum UrlType {
  'Agora',
  'RTMP',
  'GB28181',
  'WebRTC',
}

export enum VideoQuality {
  'Adaptive' = 'Auto',
  'Smooth' = '480p',
  'SD' = '720p',
  'HD' = '1080p',
  'UHD' = '4K',
}
export interface CameraLens {
  videoId: string;
  videoLensType: VideoLensType;
}

export interface StartLiveStreamResponse {
  urlType: UrlType;
  url: string;
  videoId: string;
  videoQuality: VideoQuality;
}

export const MOCK_STREAMING_LENSES: CameraLens[] = [
  {
    videoId: '1581F5BMD228Q00A82XX/39-0-7/zoom-0',
    videoLensType: VideoLensType.zoom,
  },
  {
    videoId: '1581F5BMD228Q00A82XX/39-0-7/ir-1',
    videoLensType: VideoLensType.ir,
  },
  {
    videoId: '1581F5BMD228Q00A82XX/39-0-7/normal-2',
    videoLensType: VideoLensType.normal,
  },
  {
    videoId: '1581F5BMD228Q00A82XX/39-0-7/wide-3',
    videoLensType: VideoLensType.wide,
  },
];

export const MOCK_LIVE_STREAM_RESPONSE: StartLiveStreamResponse = {
  urlType: UrlType.Agora,
  url: 'https://www.sample.ex',
  videoId: '1581F5BMD228Q00A82XX/39-0-7/ir-1',
  videoQuality: VideoQuality.SD,
};

export interface ChangeVideoQualityRequest {
  videoId: string;
  videoQuality: VideoQuality;
}

export const VIDEO_QUALITY_OPTIONS = [
  VideoQuality.Adaptive,
  VideoQuality.Smooth,
  VideoQuality.SD,
  VideoQuality.HD,
  VideoQuality.UHD,
];

/**
 * Parses the videoId to get the serial number, camera index, and video index from the videoId
 * returned by DJI Cloud Api SDK
 * @param videoId
 * @returns
 */
export const ParsedVideoDetails = (videoId: string): ParsedVideoDetails => {
  if (!videoId) {
    return;
  }
  const trimmedInput = videoId.replace(/^['"]|['"]$/g, '');

  const parts = trimmedInput.split('/');

  if (parts.length !== 3) {
    return;
  }

  const [serialNumber, cameraIndex, videoIndex] = parts;

  return {
    serialNumber,
    cameraIndex,
    videoIndex,
  };
};

/**
 * Parses the Lens type from the videoId returned by DJI Cloud Api SDK
 * @param videoId
 * @returns
 */
export const parseVideoLensType = (videoId: string): VideoLensType => {
  if (!videoId) {
    return;
  }
  const { videoIndex } = ParsedVideoDetails(videoId);
  const lensType = videoIndex.split('-')[0];
  return VideoLensType[lensType];
};

export enum VideoPlayerStatus {
  'playing',
  'loading',
  'stopped',
}
