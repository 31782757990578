import { GeoJSONSource } from '../map.d';
export const GEOSPATIAL_ROW_SOURCE_ID = 'raptor-geospatial-rows';
export const GEOSPATIAL_ROW_LABEL_SOURCE_ID = 'raptor-geospatial-rows-label';
export const GEOSPATIAL_ROW_FILL_SOURCE_ID = 'raptor-geospatial-row-fill';

const GeospatialRowSource: GeoJSONSource = {
  id: GEOSPATIAL_ROW_SOURCE_ID,
  source: {
    type: 'geojson',
    data: { type: 'FeatureCollection', features: [] },
  },
};

const GeospatialRowFillSource: GeoJSONSource = {
  id: GEOSPATIAL_ROW_FILL_SOURCE_ID,
  source: {
    type: 'geojson',
    data: { type: 'FeatureCollection', features: [] },
  },
};

const GeospatialRowLabelSource: GeoJSONSource = {
  id: GEOSPATIAL_ROW_LABEL_SOURCE_ID,
  source: {
    type: 'geojson',
    data: { type: 'FeatureCollection', features: [] },
  },
};

const GeospatialRowSources: GeoJSONSource[] = [
  GeospatialRowSource,
  GeospatialRowFillSource,
  GeospatialRowLabelSource,
];

export default GeospatialRowSources;
