import React from 'react';
import { Button } from '@raptormaps/button';

export const AccountOrgTrigger = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<typeof Button>
>((props, ref) => (
  <div ref={ref}>
    <Button className="org-dropdown" data-testid="org-dropdown" {...props} />
  </div>
));
