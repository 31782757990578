// Styled Components
import { Card, CardTitle } from '@raptormaps/card';
import { Row } from '@raptormaps/layout';
import { Icon } from '@raptormaps/icon';
import { Pill } from '@raptormaps/pill';
import { Text } from '@raptormaps/text';
import { Tooltip } from '@raptormaps/tooltip';

import {
  MissionCardSimpleStyledStack,
  CardTitleRowWithEllipsis,
} from './MissionCard.styles';
import {
  FLIGHT_MODE_TO_DISPLAY_NAME,
  DRONE_TYPE_TO_DISPLAY_NAME,
} from '@/shared/constants/missionLookups';
import { MissionResponseLite } from '@raptormaps/raptor-flight-client-ts';

interface MissionCardSimpleProps {
  mission: MissionResponseLite;
  isSelected: boolean;
}

export const MissionCardSimple = ({
  mission,
  isSelected,
}: MissionCardSimpleProps) => {
  return (
    <Card>
      <Row
        justify="space-between"
        align="center"
        gutter="none"
        noWrap={true}
        style={{ margin: '0' }}
      >
        <MissionCardSimpleStyledStack gutter="lg">
          <Row align="center" justify="space-between" noWrap={true}>
            <Tooltip
              colorVariant="dark"
              delay={100}
              placement={{
                align: 'center',
                side: 'right',
              }}
              tip={mission.missionName}
              shouldWrapChildren={true}
            >
              <CardTitleRowWithEllipsis
                align="center"
                justify="flex-start"
                noWrap={true}
                gutter={'xxs'}
                pointer={!isSelected}
              >
                <Icon icon="CodePullRequest" />
                <CardTitle variant="paragraph_medium_bold" align="left">
                  {mission.missionName}
                </CardTitle>
              </CardTitleRowWithEllipsis>
            </Tooltip>
            <Pill>{FLIGHT_MODE_TO_DISPLAY_NAME[`${mission.mode}`]}</Pill>
          </Row>
          <Row align="right" justify="space-between">
            <Row justify="flex-start" gutter="xxs">
              <Icon icon="Drone" />
              <Text variant="paragraph_medium" align="left">
                {DRONE_TYPE_TO_DISPLAY_NAME[`${mission.drone}`]}
              </Text>
            </Row>
            <Row justify="flex-start" gutter="xxs">
              <Icon icon="Stopwatch" />
              <Text variant="paragraph_medium" align="left">
                {/* TODO replace this with a calculation */}
                1h 21' 10"
              </Text>
            </Row>
          </Row>
        </MissionCardSimpleStyledStack>
      </Row>
    </Card>
  );
};
