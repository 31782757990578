// External Dependencies
import { useState, Dispatch, SetStateAction } from 'react';
import { UseFormReturn } from 'react-hook-form';
import {
  DeviceResponse,
  MissionResponse,
  DockDeviceIdsResponse,
} from '@raptormaps/raptor-flight-client-ts';
// RaptorUI
import { Text } from '@raptormaps/text';
import { Button } from '@raptormaps/button';
import { Row, Stack } from '@raptormaps/layout';
import { ThreeCircles } from 'react-loader-spinner';
import theme from '@raptormaps/theme';

// Internal Dependencies
import { DockDevice } from '@/shared/types/devices.d';
import { getDockAerialDrone } from '@/pages/MissionTasks/MissionTaskPlanner/missionTaskUtils';
import { MissionTaskPopovers } from '../../../../missionTask';
import { MISSION_TASK_FORM_FIELDS } from '@/pages/MissionTasks/MissionTaskPlanner/missionTaskFormConstants';
// Components
import { DockDeviceCard } from '@/shared/components/DockDeviceCard/DockDeviceCard';
import { SidebarBottomButtonContainer } from '../../CreateMissionTask.styles';
import { PopoverCloseButton } from '@/shared/styles/sidebar.styles';
import { DeviceCardListContainer } from './DeviceListPopover.styles';
import {
  TelemetryWebsocketState,
  useTelemetryStore,
} from '@/shared/stores/TelemetryWebsocket/TelemetryWebsocketStore';
import { useShallow } from 'zustand/react/shallow';

interface MissionListProps {
  solarFarmId: number;
  missionTaskForm: UseFormReturn;
  activeMission: MissionResponse;
  setBackButtonPopover: (backButtonPopover: MissionTaskPopovers) => void;
  setVisiblePopover: (visiblePopover: MissionTaskPopovers | null) => void;
  setIsSideBarOpen: (isSideBarOpen: boolean) => void;
  devices: DeviceResponse[];
  dockDeviceIds: DockDeviceIdsResponse[];
  dockDevices: DockDevice[];
  isLoading: boolean;
  setPreviewDeviceId: Dispatch<SetStateAction<number>>;
}

export const DeviceListPopover = ({
  solarFarmId,
  missionTaskForm,
  activeMission,
  setVisiblePopover,
  setIsSideBarOpen,
  devices,
  dockDeviceIds,
  dockDevices,
  isLoading,
  setPreviewDeviceId,
}: MissionListProps) => {
  const { setValue } = missionTaskForm;
  const [preSelectedDockId, setPreSelectedDockId] = useState<number | null>(
    null,
  );

  const unsubscribeFromDevices = useTelemetryStore(
    useShallow(
      (state: TelemetryWebsocketState) => state.actions.unsubscribeFromDevices,
    ),
  );

  const handleSelectDeviceClick = () => {
    const dockDroneId = getDockAerialDrone({
      devices,
      dockDeviceIds,
      dockId: preSelectedDockId,
    });
    setValue(MISSION_TASK_FORM_FIELDS.dockId, preSelectedDockId, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue(MISSION_TASK_FORM_FIELDS.droneId, dockDroneId, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setIsSideBarOpen(true);
    setVisiblePopover(null);
    unsubscribeFromNonSelectedDevices();
  };

  const unsubscribeFromNonSelectedDevices = () => {
    const nonSelectedDockDevices = dockDevices.filter(
      dockDevice => dockDevice.id !== preSelectedDockId,
    );

    const nonSelectedDockDevicesIds = nonSelectedDockDevices.map(
      dockDevice => dockDevice.id,
    );

    unsubscribeFromDevices(nonSelectedDockDevicesIds, solarFarmId);
  };

  const handleRenderDeviceCards = (
    devices: DeviceResponse[],
    dockDeviceIds: DockDeviceIdsResponse[],
  ) => {
    if (isLoading) {
      return (
        <Stack align="center" style={{ width: '100%' }}>
          <ThreeCircles
            height="40"
            width="40"
            color={theme.colors.primary_400}
            visible={true}
            ariaLabel="loading spinner"
          />
        </Stack>
      );
    }

    if (!dockDeviceIds?.length) {
      return (
        <Text
          variant="paragraph_medium"
          align="center"
          style={{ width: '100%' }}
        >
          No Devices Found
        </Text>
      );
    }

    return dockDevices.map((dockDevice: DockDevice) => (
      <DockDeviceCard
        dockDevice={dockDevice}
        key={dockDevice.id}
        activeMissionDevice={activeMission?.drone}
        isSelected={
          preSelectedDockId
            ? preSelectedDockId === dockDevice.id
            : missionTaskForm.getValues(MISSION_TASK_FORM_FIELDS.dockId) ===
              dockDevice.id
        }
        handleClick={() => {
          setPreSelectedDockId(dockDevice.id);
          setPreviewDeviceId(dockDevice.id);
        }}
      />
    ));
  };

  return (
    <>
      <Row data-testid="device-list" align="center" justify="space-between">
        <Text variant="h4">Device List</Text>
        <PopoverCloseButton
          onClick={() => {
            setIsSideBarOpen(true);
            setVisiblePopover(null);
            setPreviewDeviceId(null);
          }}
          variant="tertiary"
          size="small"
          icon="Xmark"
          aria-label="Close Device List Popover"
        />
      </Row>
      <DeviceCardListContainer>
        {handleRenderDeviceCards(devices, dockDeviceIds)}
      </DeviceCardListContainer>
      <SidebarBottomButtonContainer
        justify="space-around"
        style={{ marginBotom: '30px' }}
      >
        <Button
          style={{ width: '100%' }}
          onClick={handleSelectDeviceClick}
          disabled={!preSelectedDockId}
        >
          Select Device
        </Button>
      </SidebarBottomButtonContainer>
    </>
  );
};
