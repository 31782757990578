import { LayerSpecification } from 'mapbox-gl';

const Elevation: LayerSpecification = {
  id: 'contour',
  source: 'mapbox-terrain',
  'source-layer': 'contour',
  type: 'line',
  paint: {
    'line-color': '#ffffff',
  },
};

const ElevationText: LayerSpecification = {
  layout: {
    'text-field': '{ele}',
    'symbol-placement': 'line',
    'text-rotation-alignment': 'map',
    'text-font': ['Tisa Offc Pro Medium', 'Arial Unicode MS Regular'],
    'text-size': {
      base: 1,
      stops: [
        [9, 9],
        [14, 12],
      ],
    },
  },

  type: 'symbol',
  source: 'mapbox-terrain',
  id: 'contour elevation',
  paint: {
    'text-color': '#62b0f0',
    'text-halo-color': 'rgba(255,255,255,0.8)',
    'text-halo-width': 2,
  },
  'source-layer': 'contour',
};

export default [Elevation, ElevationText];
