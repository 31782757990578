import styled from 'styled-components';
import { Pill } from '@raptormaps/pill';
import { Card } from '@raptormaps/card';
import { Text } from '@raptormaps/text';
import { Stack } from '@raptormaps/layout';
import { Tooltip } from '@raptormaps/tooltip';

// Internal Dependencies
import { FullWidthRow } from '@/shared/styles/sidebar.styles';
import { ComponentLoader } from '@/shared/components/ComponentLoader/ComponentLoader';
import { MissionTaskDetailedResponse } from '@raptormaps/raptor-flight-client-ts';

const MissionTaskName = styled(Text)`
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MissionTaskCard = ({
  isLoading,
  missionTask,
}: {
  isLoading: boolean;
  missionTask: MissionTaskDetailedResponse;
}) => {
  return isLoading ? (
    <ComponentLoader message="Loading Mission Task ..." />
  ) : (
    <Card style={{ width: '100%' }}>
      <Stack>
        <FullWidthRow
          justify="space-between"
          align="center"
          style={{ paddingRight: '10px' }}
        >
          <Text variant="paragraph_medium_bold">Mission Task Name </Text>
          <Tooltip
            colorVariant="dark"
            delay={100}
            placement={{
              align: 'center',
              side: 'right',
            }}
            tip={`${missionTask?.taskName}`}
            shouldWrapChildren={true}
          >
            <MissionTaskName variant="paragraph_medium" align="right">
              {missionTask?.taskName}
            </MissionTaskName>
          </Tooltip>
        </FullWidthRow>
        <FullWidthRow justify="space-between" align="center">
          <Text variant="paragraph_medium_bold">Mission Task ID</Text>
          <Pill> {missionTask?.id} </Pill>
        </FullWidthRow>
      </Stack>
    </Card>
  );
};
