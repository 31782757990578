import { Text, TextVariant } from '@raptormaps/text';
import { Gutter, Row } from '@raptormaps/layout';
import {
  DropdownMenuGroup,
  DropdownMenuItem,
} from '@radix-ui/react-dropdown-menu';
import { OrgOption, MenuLabel } from './AccountOrgSwitcher.style';
import { Org } from '../../../../types/missions.d';

interface OrgListProps {
  orgs: Org[];
  currentOrgId: number;
  /**
   * Callback when a new org is selected.
   * Will not trigger if `newOrgId` matches `currentOrgId`.
   */
  onOrgOptionClick: (newOrgId: number) => void;
}

export const OrgList = ({
  orgs,
  currentOrgId,
  onOrgOptionClick,
}: OrgListProps) => {
  return (
    <DropdownMenuGroup>
      <MenuLabel as={Text} variant={TextVariant.label_small_allcaps}>
        Organizations
      </MenuLabel>
      {orgs.map(org => (
        <Row
          key={org.id}
          gutter={Gutter.XXS}
          // capture the bubbled click event of the Link and OrgOption button
          onClick={
            org.id !== currentOrgId ? () => onOrgOptionClick(org.id) : undefined
          }
        >
          <DropdownMenuItem
            asChild
            role="radio"
            aria-checked={org.id === currentOrgId}
            // prevent the menu from closing if selecting the already selected org
            onSelect={
              org.id === currentOrgId ? e => e.preventDefault() : undefined
            }
          >
            <OrgOption>{org.name}</OrgOption>
          </DropdownMenuItem>
        </Row>
      ))}
    </DropdownMenuGroup>
  );
};
