import { GeoJSONSource } from '../map.d';

export const INVERTER_BLOCK_SOURCE_ID = 'raptor-inverter-blocks';

const RowCluster: GeoJSONSource = {
  id: INVERTER_BLOCK_SOURCE_ID,
  source: {
    type: 'geojson',
    data: { type: 'FeatureCollection', features: [] },
  },
};

const InverterBlockSources: GeoJSONSource[] = [RowCluster];

export default InverterBlockSources;
