// State Management
import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { produce } from 'immer';
import { useShallow } from 'zustand/react/shallow';

// Types
import {
  DockDeviceTelemetry,
  TelemetryApiMessage,
  TelemetryApiRouteKey,
  TelemetryByDeviceSN,
  TelemetryValue,
} from '@/shared/types/telemetry.d';
import { DockDevice } from '@/shared/types/devices.d';
import { DeviceType } from '@raptormaps/raptor-flight-client-ts';

// Utils
import {
  extractTelemetryFromMessage,
  stitchParsedTelemetryData,
  handleDeviceLocation,
  handleLivestreamEvents,
  emitTelemetryEvents,
} from '../../utils/websocketUtils';
import { Feature } from '@turf/helpers';
import {
  modifyStaleTelemetryState,
  getAllDevicesFromDocks,
} from '@/shared/utils/telemetryUtils';
import { DOCK_DEVICE_TELEMETRY_INITIAL_STATE } from './constants';

export interface TelemetryWebsocketInitialState {
  devicesTelemetry: TelemetryByDeviceSN | null;
  socketIsOpen: boolean;
  subscribedDevices: DockDevice[];
  token: string | null;
  socket: WebSocket | null;
  monitorTelemetryIntervalId: number | null;
  deviceLocations: Feature[];
}

export interface TelemetryWebsocketState
  extends TelemetryWebsocketInitialState {
  actions: {
    openSocket: (token: string) => void;
    closeSocket: () => void;
    subscribeToDevices: (devices: DockDevice[], solarFarmId: number) => void;
    startMonitoringTelemetry: () => void;
    stopMonitoringTelemetry: () => void;
    setStaleToastId: (toastId: string) => void;
    unsubscribeFromDevices: (deviceIds: number[], solarFarmId: number) => void;
  };
}

const initialState: TelemetryWebsocketInitialState = {
  devicesTelemetry: {},
  socketIsOpen: false,
  subscribedDevices: [],
  token: null,
  socket: null,
  monitorTelemetryIntervalId: null,
  deviceLocations: [],
};

// Mutations to handle state updates and WebSocket events
const mutations = (setState, getState) => {
  return {
    actions: {
      openSocket(token) {
        const socketInstance = getState().socket;

        if (socketInstance) {
          return;
        }

        setState({ token });
        const socket = new WebSocket(
          window.ROBOTICS_HUB_TELEMETRY_API_WEBSOCKET_ENDPOINT,
        );

        socket.onopen = () => {
          setState({ socketIsOpen: true, socket });
        };

        socket.onclose = () => {
          setState({ ...initialState });
        };

        socket.onerror = error => {
          console.log(error); //TODO: sc-77991
        };

        socket.onmessage = event => {
          if (!event.data) {
            return;
          }

          const devicesTelemetry = getState().devicesTelemetry;

          const data = JSON.parse(event.data);

          // Camera payload index is used to determine which camera telemetry to parse
          let cameraPayloadIndex = null;
          if (data?.topic) {
            const sn = data?.topic?.split('/')[4];

            cameraPayloadIndex =
              devicesTelemetry?.[sn]?.cameraPayloadIndex?.value;
          }

          extractTelemetryFromMessage(data, cameraPayloadIndex).then(
            parsedTelemetry => {
              const deviceSn = parsedTelemetry.deviceSn;
              if (!deviceSn) {
                return;
              }

              handleDeviceLocation(getState, setState, parsedTelemetry);
              handleLivestreamEvents(getState, parsedTelemetry);
              emitTelemetryEvents(data?.topic, data);

              setState(
                produce((initialState: TelemetryWebsocketInitialState) => {
                  const newDockDeviceTelemetry = stitchParsedTelemetryData(
                    parsedTelemetry,
                    devicesTelemetry[deviceSn] ??
                      DOCK_DEVICE_TELEMETRY_INITIAL_STATE,
                  );

                  // Immer allows direct mutation of initial state
                  initialState.devicesTelemetry[deviceSn] =
                    newDockDeviceTelemetry;
                }),
              );
            },
          );
        };
      },
      closeSocket() {
        const socket = getState().socket;

        if (!socket || socket.readyState === WebSocket.CLOSED) {
          return;
        }

        socket.close();
        setState({ socketIsOpen: false, socket: null, devicesTelemetry: {} });

        // Close monitoring interval
        const stopMonitoringTelemetry =
          getState().actions.stopMonitoringTelemetry;
        stopMonitoringTelemetry();
        setState({ ...initialState });
      },

      subscribeToDevices(devices: DockDevice[], solarFarmId: number) {
        const state = getState();
        const token = state.token;
        const socket = state.socket;

        if (!socket || !token || socket.readyState !== WebSocket.OPEN) {
          return;
        }

        const devicesSns = devices.map(device => device.deviceSn);
        const message: TelemetryApiMessage = {
          action: TelemetryApiRouteKey.subscribe,
          solar_farm_id: solarFarmId,
          gateway_sns: devicesSns,
          Authorization: token,
        };

        socket.send(JSON.stringify(message));
        setState(
          produce((initialState: TelemetryWebsocketInitialState) => {
            const updatedSubscribedDevicesById = [
              ...initialState.subscribedDevices,
              ...devices,
            ].reduce((acc, device) => {
              acc[device.deviceSn] = device;
              return acc;
            }, {});

            // Updates subscribed devices to be unique by deviceSn
            initialState.subscribedDevices = Object.values(
              updatedSubscribedDevicesById,
            );
          }),
        );
      },
      unsubscribeFromDevices(devicesIds: number[], solarFarmId: number) {
        const state = getState();
        const token = state.token;
        const subscribedDevices = state.subscribedDevices;
        const deviceLocations = state.deviceLocations;
        const socket = state.socket;

        if (!token || !socket || socket.readyState !== WebSocket.OPEN) {
          return;
        }

        const devicesSns = subscribedDevices
          .filter(device => devicesIds.includes(device.id))
          .map(device => device.deviceSn);

        const message = {
          action: TelemetryApiRouteKey.unsubscribe,
          solar_farm_id: solarFarmId,
          gateway_sns: devicesSns,
          Authorization: token,
        };

        socket.send(JSON.stringify(message));

        setState(
          produce((initialState: TelemetryWebsocketInitialState) => {
            initialState.subscribedDevices = subscribedDevices.filter(
              sns => !devicesSns.includes(sns),
            );
            initialState.deviceLocations = deviceLocations.filter(
              feature => !devicesSns.includes(feature.properties.deviceSn),
            );
          }),
        );
      },
      startMonitoringTelemetry() {
        // Enforce single instance of monitoring interval
        let monitorTelemetryIntervalId = getState().monitorTelemetryIntervalId;

        if (monitorTelemetryIntervalId) {
          return;
        }

        monitorTelemetryIntervalId = setInterval(() => {
          const subscribedDevices = getState().subscribedDevices;
          const allDevices = getAllDevicesFromDocks(subscribedDevices);
          const updatedState = produce(
            (initialState: TelemetryWebsocketState) => {
              allDevices.forEach((device: DockDevice) => {
                const deviceTelemetry: DockDeviceTelemetry =
                  initialState.devicesTelemetry?.[device.deviceSn];
                if (!deviceTelemetry) return;

                modifyStaleTelemetryState(deviceTelemetry);
              });
            },
          );
          setState(updatedState);
        }, 1000); // Check every second
        setState({ monitorTelemetryIntervalId });
      },
      stopMonitoringTelemetry() {
        const monitorTelemetryIntervalId =
          getState().monitorTelemetryIntervalId;
        if (monitorTelemetryIntervalId) {
          clearInterval(monitorTelemetryIntervalId);
          setState({ monitorTelemetryIntervalId: null });
        }
      },
    },
  };
};

// Create the store using zustand with combine to merge state and mutations
export const useTelemetryStore = create(combine(initialState, mutations));

interface useTelemetryValueInput {
  deviceSn: string;
  key: keyof DockDeviceTelemetry;
  properties: {
    timestamp?: boolean;
    isStale?: boolean;
    lastMonitored?: boolean;
  };
}

interface useTelemetryValueResponse {
  value: TelemetryValue;
  timestamp?: number;
  isStale?: boolean;
  lastMonitored?: number;
}

/**
 * Hook to get specific telemetry value from a device. Uses shallow to prevent unnecessary re-renders
 */
export const useDockDeviceTelemetryValue = <T extends TelemetryValue>({
  deviceSn,
  key,
  properties,
}: useTelemetryValueInput): useTelemetryValueResponse => {
  return useTelemetryStore(
    useShallow(state => {
      const { timestamp, isStale, lastMonitored } = properties;

      return {
        value: state.devicesTelemetry?.[deviceSn]?.[key]?.value as T,
        timestamp: timestamp
          ? state.devicesTelemetry?.[deviceSn]?.[key]?.timestamp
          : null,
        isStale: isStale
          ? state.devicesTelemetry?.[deviceSn]?.[key]?.isStale
          : null,
        lastMonitored: lastMonitored
          ? state.devicesTelemetry?.[deviceSn]?.[key]?.lastMonitored
          : null,
      };
    }),
  );
};

export const useGetDockLocations = (): Feature[] => {
  return useTelemetryStore(
    useShallow((state: TelemetryWebsocketState) =>
      state.deviceLocations.filter(
        (feature: Feature) =>
          feature?.properties?.deviceType === DeviceType.Dock,
      ),
    ),
  );
};

export const useGetDroneLocation = (droneSn: string): Feature => {
  return useTelemetryStore(
    useShallow((state: TelemetryWebsocketState) =>
      state.deviceLocations.find(
        (feature: Feature) =>
          feature?.properties?.deviceType === DeviceType.AerialDrone &&
          feature?.properties?.deviceSn === droneSn,
      ),
    ),
  );
};

export const useGetDockLocation = (dockSn: string): Feature => {
  return useTelemetryStore(
    useShallow((state: TelemetryWebsocketState) =>
      state.deviceLocations.find(
        (feature: Feature) =>
          feature?.properties?.deviceType === DeviceType.Dock &&
          feature?.properties?.deviceSn === dockSn,
      ),
    ),
  );
};
