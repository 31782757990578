import * as Yup from 'yup';
import {
  droneFormParameters,
  mannedFormParameters,
} from '../constants/FormParameters';
import { FormParameters } from '../../../shared/types/missions';
import { CameraType, DroneType } from '@raptormaps/raptor-flight-client-ts';
import { DRONE_TRANSIT_SPEED_DEFAULTS } from '../constants/missionConstants';

interface FlightPlanSchemaProps {
  aircraftFormParameters: FormParameters;
  device?: DroneType;
}

export const FlightPlanSchema = ({
  aircraftFormParameters,
  device,
}: FlightPlanSchemaProps) => {
  let schema = Yup.object().shape({
    altitude: Yup.number()
      .required('Altitude is required')
      .label('Altitude')
      .min(aircraftFormParameters.altitudeInput.min)
      .max(aircraftFormParameters.altitudeInput.max),
    safeTakeoffAltitude: Yup.number()
      .label('Safe Takeoff Altitude')
      .min(aircraftFormParameters.safeTakeoffAltitudeInput.min)
      .max(aircraftFormParameters.safeTakeoffAltitudeInput.max)
      .nullable(),
    terrainFollowBool: Yup.boolean(),
    frontOverlap: Yup.number()
      .required('Front Overlap is required')
      .label('Front Overlap')
      .min(
        aircraftFormParameters.frontOverlapInput.min,
        `Front Overlap must be a percentage that is greater than ${
          aircraftFormParameters.frontOverlapInput.min * 100
        }%`,
      )
      .max(
        aircraftFormParameters.frontOverlapInput.max,
        `Front Overlap must be a percentage that is less than ${
          aircraftFormParameters.frontOverlapInput.max * 100
        }%`,
      ),
    sideOverlap: Yup.number()
      .required('Side Overlap is required')
      .label('Side Overlap')
      .min(
        aircraftFormParameters.sideOverlapInput.min,
        `Side Overlap must be a percentage that is greater than ${
          aircraftFormParameters.sideOverlapInput.min * 100
        }%`,
      )
      .max(
        aircraftFormParameters.sideOverlapInput.max,
        `Side Overlap must be a percentage that is less than ${
          aircraftFormParameters.sideOverlapInput.max * 100
        }%`,
      ),
    cameraAngle: Yup.number()
      .required('Camera Angle (deg) is required')
      .label('Camera Angle')
      .min(aircraftFormParameters.pitchAngleInput.min)
      .max(aircraftFormParameters.pitchAngleInput.max),
    flightAngle: Yup.number()
      .required('Course Angle is required')
      .label('Course Angle')
      .min(aircraftFormParameters.flightAngleInput.min)
      .max(aircraftFormParameters.flightAngleInput.max),
    flightSpeed: Yup.number()
      .required('Flight Speed is required')
      .label('Flight Speed')
      .min(aircraftFormParameters.flightSpeedInput.min)
      .max(aircraftFormParameters.flightSpeedInput.max),
    cameraInterval: Yup.number()
      .required('Camera Interval is required')
      .label('Camera Interval')
      .min(aircraftFormParameters.cameraIntervalInput.min)
      .max(aircraftFormParameters.cameraIntervalInput.max),
    altitudeOffset: Yup.number()
      .label('Altitude Offset')
      .min(droneFormParameters.altitudeOffset.min)
      .max(droneFormParameters.altitudeOffset.max),
    altitudeOffsetBool: Yup.boolean(),
    cameraAngleMode: Yup.string()
      .required('Camera Angle Mode cannot be undefined')
      .label('Camera Angle Mode'),
  });

  if (
    device &&
    (device === DroneType.M30t ||
      device === DroneType.M30tDock ||
      device === DroneType.M3tdDock ||
      device === DroneType.M3t)
  ) {
    schema = schema.shape({
      transitSpeed: Yup.number()
        .label('Transit Speed')
        .required('Transit Speed is required')
        .min(DRONE_TRANSIT_SPEED_DEFAULTS[device].min)
        .max(DRONE_TRANSIT_SPEED_DEFAULTS[device].max),
    });
  }

  return schema;
};

export const MannedFlightPlanSchema = FlightPlanSchema({
  aircraftFormParameters: mannedFormParameters,
});
export const DroneFlightPlanSchema = (device: DroneType) =>
  FlightPlanSchema({
    aircraftFormParameters: droneFormParameters,
    device,
  });

interface UploadSchemaProps {
  aircraftFormParameters: FormParameters;
  device?: DroneType;
}

export const UploadSchema = ({
  aircraftFormParameters,
  device,
}: UploadSchemaProps) => {
  let schema = Yup.object().shape({
    missionName: Yup.string()
      .required('Mission Name is required')
      .label('Mission Name'),
    altitude: Yup.number()
      .required('Altitude is required')
      .label('Altitude')
      .typeError(
        `Altitude must be a number between ${aircraftFormParameters.altitudeInput.min} and ${aircraftFormParameters.altitudeInput.max}`,
      )
      .max(aircraftFormParameters.altitudeInput.max)
      .min(aircraftFormParameters.altitudeInput.min),
    safeTakeoffAltitude: Yup.number()
      .label('Safe Takeoff Altitude')
      .typeError(
        `Safe altitude must be a number between ${aircraftFormParameters.safeTakeoffAltitudeInput.min} and ${aircraftFormParameters.safeTakeoffAltitudeInput.max}`,
      )
      .min(aircraftFormParameters.safeTakeoffAltitudeInput.min)
      .max(aircraftFormParameters.safeTakeoffAltitudeInput.max)
      .nullable(),
    terrainFollowBool: Yup.boolean(),
    frontOverlap: Yup.number()
      .required('Front Overlap is required')
      .label('Front Overlap')
      .typeError(
        `Front Overlap must be a number between ${aircraftFormParameters.frontOverlapInput.min} and ${aircraftFormParameters.frontOverlapInput.max}`,
      )
      .min(aircraftFormParameters.frontOverlapInput.min)
      .max(aircraftFormParameters.frontOverlapInput.max),
    sideOverlap: Yup.number()
      .required('Side Overlap is required')
      .label('Side Overlap')
      .typeError(
        `Side Overlap must be a number between ${aircraftFormParameters.sideOverlapInput.min} and ${aircraftFormParameters.sideOverlapInput.max}`,
      )
      .min(aircraftFormParameters.sideOverlapInput.min)
      .max(aircraftFormParameters.sideOverlapInput.max),
    sensor: Yup.object()
      .shape({
        id: Yup.string().nullable(),
        name: Yup.string()
          .required('Sensor name is required')
          .label('Sensor name')
          .oneOf(Object.values(CameraType)),
      })
      .typeError(
        'Sensor should be an object, this should include sensor name and both fields of view',
      )
      .noUnknown(),
    cameraAngle: Yup.number()
      .required('Camera Angle is required')
      .label('Camera Angle')
      .typeError(
        `Camera Angle must be a number between ${aircraftFormParameters.pitchAngleInput.min} and ${aircraftFormParameters.pitchAngleInput.max}`,
      )
      .min(aircraftFormParameters.pitchAngleInput.min)
      .max(aircraftFormParameters.pitchAngleInput.max),
    flightAngle: Yup.number()
      .required('Course Angle is required')
      .label('Course Angle')
      .typeError(
        `Course Angle must be a number between ${aircraftFormParameters.flightAngleInput.min} and ${aircraftFormParameters.flightAngleInput.max}`,
      )
      .min(aircraftFormParameters.flightAngleInput.min)
      .max(aircraftFormParameters.flightAngleInput.max),
    flightSpeed: Yup.number()
      .required('Flight Speed is required')
      .label('Flight Speed')
      .typeError(
        `Flight Speed must be a number between ${aircraftFormParameters.flightSpeedInput.min} and ${aircraftFormParameters.flightSpeedInput.max}`,
      )
      .min(aircraftFormParameters.flightSpeedInput.min)
      .max(aircraftFormParameters.flightSpeedInput.max),
    cameraInterval: Yup.number()
      .required('Camera Interval is required')
      .label('Camera Interval')
      .typeError(
        `Camera Interval must be a number between ${aircraftFormParameters.cameraIntervalInput.min} and ${aircraftFormParameters.cameraIntervalInput.max}`,
      )
      .min(aircraftFormParameters.cameraIntervalInput.min)
      .max(aircraftFormParameters.cameraIntervalInput.max),
    altitudeOffset: Yup.number()
      .label('Altitude Offset')
      .typeError(
        `Altitude Offset must be a number between ${aircraftFormParameters.altitudeOffset.min} and ${aircraftFormParameters.altitudeOffset.max}`,
      )
      .min(aircraftFormParameters.altitudeOffset.min)
      .max(aircraftFormParameters.altitudeOffset.max),
    altitudeOffsetBool: Yup.boolean().typeError(
      `Altitude Offset Bool must be a boolean`,
    ),
  });

  if (
    device &&
    (device === DroneType.M30t ||
      device === DroneType.M30tDock ||
      device === DroneType.M3tdDock ||
      device === DroneType.M3t)
  ) {
    schema = schema.shape({
      transitSpeed: Yup.number()
        .label('Transit Speed')
        .min(DRONE_TRANSIT_SPEED_DEFAULTS[device].min)
        .max(DRONE_TRANSIT_SPEED_DEFAULTS[device].max),
    });
  }

  return schema;
};

export const MannedUploadSchema = UploadSchema({
  aircraftFormParameters: mannedFormParameters,
});
export const DroneUploadSchema = (device: DroneType) =>
  UploadSchema({ aircraftFormParameters: droneFormParameters, device });
