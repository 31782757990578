import mapboxgl from 'mapbox-gl';
import Mixpanel from '@/shared/utils/mixpanel/utils';
import MixpanelEvents from '@/shared/utils/mixpanel/events';
import clone from '@turf/clone';
import JSZip from 'jszip';
import {
  Feature,
  FeatureCollection,
  Polygon,
  LineString,
  Point,
  featureCollection,
} from '@turf/helpers';
import transformTranslate from '@turf/transform-translate';
import bearing from '@turf/bearing';
import distance from '@turf/distance';
import {
  createFlightPathFromPolygon,
  createStraightFlightPathFromPolygon,
  createWaypointsFromFlightPath,
  createWaypointsFromPerimeterFlightPath,
  createInspectionFlightPathFromPolygon,
  createFlightPathFromPolygonContinuousOperation,
  createFlightPathFromPolygonAirplane,
  createSquareOrbitalPoints,
  createSquareOrbitalLines,
} from './utils';
import {
  generateDJITemplateFlightFunctionalWPML,
  generateDJITemplateKML,
  generateDJITemplateWPML,
} from '@/shared/utils/flightfiles/dji';
import { generateForeflightKML } from '@/shared/utils/flightfiles/foreflight';
import {
  ActionTriggerType,
  AltitudeOffsetObject,
  IntervalModeObject,
  MissionFileType,
  PointType,
  IntervalometerObject,
  GeospatialObject,
  WaypointNames,
} from '@/shared/types/missions.d';
import { CombinedFlightModeType } from '@/shared/types/tempMissions';
import {
  MISSION_JSON_VERSION,
  LAT_LONG_PRECISION,
} from '@/pages/MissionPlanner/constants/missionConstants';

import bbox from '@turf/bbox';
import * as SunCalc from 'suncalc3';
import { round } from 'lodash';

import {
  DroneType,
  FlightModeType,
  CameraAngleMode,
  UnitType,
  SpeedControlModeType,
  CameraType,
  OverlapType,
  FeatureCollectionInput,
  MissionInput,
  SolarFarmResponse,
} from '@raptormaps/raptor-flight-client-ts';

import { getFieldOfViewSpacing } from '@/pages/MissionPlanner/utils/utils';

const takeoffPointAdderGridline = (
  gridLine: Feature<LineString>,
  markerLngLat: Array<number>,
): Feature<LineString> => {
  // Adds the takeoff point to the gridline
  if (markerLngLat != gridLine.geometry.coordinates[0]) {
    gridLine.geometry.coordinates.splice(0, 0, markerLngLat);
  }
  return gridLine;
};

const takeoffPointAdderWaypoints = (
  waypoints: FeatureCollection<Point>,
  markerLngLat: Array<number>,
): FeatureCollection<Point> => {
  // Adds the takeoff point as an additional waypoint
  const waypointsCopy = JSON.parse(JSON.stringify(waypoints)); // copy to avoid editing original
  const takeoffWaypoint = clone(waypointsCopy.features[0]);
  takeoffWaypoint.geometry.coordinates = markerLngLat;
  takeoffWaypoint.properties.name = 'Relative Altitude Point';
  const takeoffBearing = bearing(
    takeoffWaypoint.geometry.coordinates,
    waypointsCopy.features[0].geometry.coordinates,
  );
  takeoffWaypoint.properties.gimbalHeading = takeoffBearing;
  takeoffWaypoint.properties.rotation = takeoffBearing;

  waypointsCopy.features.splice(0, 0, takeoffWaypoint);
  return waypointsCopy;
};

const addSafeTakeoffAltitudeGridline = (
  // adds an additional waypoint to gridline before first inspection waypoint that is set at safe takeoff altitude
  gridLine: Feature<LineString>,
  waypoints: FeatureCollection<Point>,
): Feature<LineString> => {
  // if a takeoff pt exists, insert new waypoint after it
  if (waypoints.features[0].properties.name == 'Relative Altitude Point') {
    gridLine.geometry.coordinates.splice(
      1,
      0,
      waypoints.features[1].geometry.coordinates,
    );
  } else {
    gridLine.geometry.coordinates.splice(
      0,
      0,
      waypoints.features[0].geometry.coordinates,
    );
  }
  return gridLine;
};

export const addSafeTakeoffAltitudeWaypoint = (
  // adds an additional waypoint before first inspection waypoint that is set at safe takeoff altitude
  waypoints: FeatureCollection<Point>,
  safeTakeoffAltitude: number,
): FeatureCollection<Point> => {
  const waypointsCopy = JSON.parse(JSON.stringify(waypoints)); // copy to avoid editing original
  const safeTakeoffWaypoint = clone(waypointsCopy.features[0]);
  safeTakeoffWaypoint.properties.name = 'Safe Takeoff Altitude';
  safeTakeoffWaypoint.properties.altitude = safeTakeoffAltitude;

  // if a takeoff pt exists, insert new waypoint after it
  if (waypointsCopy.features[0].properties.name == 'Relative Altitude Point') {
    waypointsCopy.features[0].properties.altitude = safeTakeoffAltitude; // also change takeoff point's altitude
    safeTakeoffWaypoint.geometry.coordinates =
      waypointsCopy.features[1].geometry.coordinates;
    waypointsCopy.features.splice(1, 0, safeTakeoffWaypoint);
  } else {
    safeTakeoffWaypoint.geometry.coordinates =
      waypointsCopy.features[0].geometry.coordinates;
    waypointsCopy.features.splice(0, 0, safeTakeoffWaypoint);
  }

  return waypointsCopy;
};

const generateFlightPathFromPolygon = (
  polygon: Feature<Polygon>,
  altitude: number,
  fieldOfViewVertical: number,
  fieldOfViewHorizontal: number,
  pitchAngle: number,
  flightAngle: number,
  sideOverlap: number,
  flightMode: CombinedFlightModeType,
  continuousOperationsEnabled: boolean,
) => {
  let gridLine;
  if (flightMode == FlightModeType.Perimeter) {
    // Perimeter inspection
    gridLine = createInspectionFlightPathFromPolygon(
      polygon,
      altitude,
      fieldOfViewVertical,
      pitchAngle,
    );
  } else if (flightMode == FlightModeType.Cablines) {
    // Cabline inspection
    gridLine = createStraightFlightPathFromPolygon(polygon, flightAngle);
  } else if (flightMode == FlightModeType.Airplane) {
    gridLine = createFlightPathFromPolygonAirplane(
      polygon,
      flightAngle,
      altitude,
      fieldOfViewVertical,
      sideOverlap,
      pitchAngle,
    );
  } else if (flightMode == FlightModeType.SquareOrbital) {
    gridLine = createSquareOrbitalLines(
      polygon,
      altitude,
      fieldOfViewVertical,
      pitchAngle,
      fieldOfViewHorizontal,
    );
  } else if (continuousOperationsEnabled) {
    const maximumPanelAngle = 52;
    gridLine = createFlightPathFromPolygonContinuousOperation(
      polygon,
      flightAngle,
      altitude,
      fieldOfViewVertical,
      sideOverlap,
      maximumPanelAngle,
    );
  } else {
    // Serpentine path inspection
    gridLine = createFlightPathFromPolygon(
      polygon,
      flightAngle,
      altitude,
      fieldOfViewVertical,
      sideOverlap,
      pitchAngle,
    );
  }
  return gridLine;
};

const generateWaypointsFromGridline = (
  perimeterModeBool: boolean,
  gridLine,
  altitude: number,
  fieldOfViewHorizontal: number,
  frontOverlap: number,
  cablineBool: boolean,
  flightAngle: number,
  flightMode: CombinedFlightModeType,
  cameraInterval: number,
  flightSpeed: number,
) => {
  let waypoints: FeatureCollection<Point>;
  if (perimeterModeBool) {
    waypoints = createWaypointsFromPerimeterFlightPath(
      gridLine,
      altitude,
      fieldOfViewHorizontal,
      frontOverlap,
    );
  } else if (cablineBool) {
    // Cabline inspection
    waypoints = createWaypointsFromFlightPath(
      gridLine,
      altitude,
      fieldOfViewHorizontal,
      frontOverlap,
      flightAngle,
    );
  } else if (flightMode == FlightModeType.SquareOrbital) {
    waypoints = createSquareOrbitalPoints(
      gridLine,
      frontOverlap,
      altitude,
      fieldOfViewHorizontal,
      cameraInterval,
      flightSpeed,
    );
  } else {
    // Solar Panel Inspection
    waypoints = createWaypointsFromFlightPath(
      gridLine,
      altitude,
      fieldOfViewHorizontal,
      frontOverlap,
      flightAngle,
    );
  }
  return waypoints;
};

interface CalculateWaypointDataProps {
  map: mapboxgl.Map | null;
  flightMode: CombinedFlightModeType;
  polygon: Feature<Polygon>;
  altitude: number;
  fieldOfViewHorizontal: number;
  fieldOfViewVertical: number;
  pitchAngle: number;
  flightAngle: number | null;
  sideOverlap: number;
  frontOverlap: number;
  safeTakeoffAltitude: number;
  safeTakeoffAltitudeBool: boolean;
  markerLngLat: Array<number>;
  calibrationBool: boolean;
  calibrationBearing: number;
  calibrationDistance: number;
  continuousOperationsEnabled: boolean;
  cameraInterval: number;
  flightSpeed: number;
  waypointReductionBool?: boolean;
}
// returns an the feature collection of waypoints that make up a flight.
export const calculateWaypointData = async ({
  map,
  flightMode,
  polygon,
  altitude,
  fieldOfViewHorizontal,
  fieldOfViewVertical,
  pitchAngle,
  flightAngle,
  sideOverlap,
  frontOverlap,
  safeTakeoffAltitude,
  safeTakeoffAltitudeBool,
  markerLngLat,
  calibrationBool,
  calibrationBearing,
  calibrationDistance,
  continuousOperationsEnabled,
  cameraInterval,
  flightSpeed,
  waypointReductionBool,
}: CalculateWaypointDataProps): Promise<{
  waypoints: FeatureCollection<Point>;
  flightPath: Feature;
  nonCalibratedFlightPath?: Feature;
}> => {
  let flightPath = null;
  let waypoints = null;
  let nonCalibratedFlightPath = null;
  const perimeterModeBool = flightMode === FlightModeType.Perimeter;
  const cablineBool = flightMode === FlightModeType.Cablines;
  try {
    if (calibrationBool) {
      // If calibrating, generate the non calibrated flight path then
      // shift the polygon to its calibrated location
      nonCalibratedFlightPath = generateFlightPathFromPolygon(
        polygon,
        altitude,
        fieldOfViewVertical,
        fieldOfViewHorizontal,
        pitchAngle,
        flightAngle,
        sideOverlap,
        flightMode,
        continuousOperationsEnabled,
      );

      polygon = transformTranslate(
        polygon,
        calibrationDistance,
        calibrationBearing,
      );
    }

    flightPath = generateFlightPathFromPolygon(
      polygon,
      altitude,
      fieldOfViewVertical,
      fieldOfViewHorizontal,
      pitchAngle,
      flightAngle,
      sideOverlap,
      flightMode,
      continuousOperationsEnabled,
    );
    if (!flightPath || Object.keys(flightPath).length === 0) {
      return { waypoints, flightPath, nonCalibratedFlightPath };
    }
    waypoints = generateWaypointsFromGridline(
      perimeterModeBool,
      flightPath,
      altitude,
      fieldOfViewHorizontal,
      frontOverlap,
      cablineBool,
      flightAngle,
      flightMode,
      cameraInterval,
      flightSpeed,
    );

    if (markerLngLat) {
      // visually add the takeoff point
      flightPath = takeoffPointAdderGridline(flightPath, markerLngLat);
      waypoints = takeoffPointAdderWaypoints(waypoints, markerLngLat);
    }

    const terrainElevations = await getElevations(map, waypoints);
    waypoints = generateTerrainFollowingAltitudes(terrainElevations, altitude);

    // adds an additional waypoint if safe takeoff altitude is toggled
    if (
      safeTakeoffAltitudeBool &&
      flightMode != FlightModeType.Airplane &&
      altitude != safeTakeoffAltitude
    ) {
      flightPath = addSafeTakeoffAltitudeGridline(flightPath, waypoints);
      waypoints = addSafeTakeoffAltitudeWaypoint(
        waypoints,
        safeTakeoffAltitude,
      );
    }

    if (waypointReductionBool) {
      waypoints = reduceWaypoints(waypoints, flightAngle);
    }
    return { waypoints, flightPath, nonCalibratedFlightPath };
  } catch (err) {
    console.error(err);
  }
};

export interface TerrainElevation {
  waypoint: Feature<Point>;
  elevation: number;
}

function getElevations(
  map: mapboxgl.Map,
  waypoints: FeatureCollection<Point>,
): Promise<TerrainElevation[]> {
  return new Promise((resolve, reject) => {
    try {
      const roundedBbox = roundBbox(bbox(waypoints), 5); // precision = ~1m
      // Fits polygon into map to guarantee elevation data can be returned.
      map.fitBounds(roundedBbox, {
        animate: false,
        maxZoom: map.getZoom(), // won't zoom in further than is already the case
      });
      map.once('idle', async () => {
        const terrainElevations: TerrainElevation[] = waypoints.features.map(
          waypoint => {
            const elevation: number = map.queryTerrainElevation(
              [
                round(waypoint.geometry.coordinates[0], LAT_LONG_PRECISION),
                round(waypoint.geometry.coordinates[1], LAT_LONG_PRECISION),
              ],
              { exaggerated: false },
            );
            return { waypoint, elevation };
          },
        );
        resolve(terrainElevations);
      });
    } catch (error) {
      reject(error);
    }
  });
}

export function generateTerrainFollowingAltitudes(
  terrainElevations: TerrainElevation[],
  startingAltitude: number,
): FeatureCollection<Point> {
  let previousAltitude: number;
  const waypointsWithAltitudes: Feature<Point>[] = terrainElevations.map(
    ({ waypoint: wp, elevation }, index) => {
      // Makes a copy of the waypoint to avoid editing the original
      const waypoint = JSON.parse(JSON.stringify(wp));
      if (index == 0) {
        // for the first waypoint just use the altitude set by settings
        waypoint.properties.altitude = startingAltitude;
      } else {
        // calculates difference in elevation between current and previous waypoint and applies that difference to the previous waypoints altitude to get the altitude of the current waypoint
        const { elevation: previousElevation } = terrainElevations[index - 1];
        waypoint.properties.altitude =
          previousAltitude + (elevation - previousElevation);
      }
      previousAltitude = waypoint.properties.altitude;
      return waypoint;
    },
  );
  return featureCollection(waypointsWithAltitudes);
}

export function checkWaypointsNegativeRelativeAltitude(
  waypoints: FeatureCollection<Point>,
) {
  const negativeWaypoints: Feature[] = waypoints.features.filter(
    waypoint => waypoint.properties.altitude < 0,
  );
  return negativeWaypoints;
}

export function checkWaypointsSignificantAltitudeChange(
  waypoints: FeatureCollection<Point>,
  altitude: number,
) {
  const significantChangeWaypoints: [Feature, number][] = [];
  const altitudeChangeThreshhold = altitude / 10;
  waypoints.features.forEach((waypoint, index) => {
    if (index > 0) {
      const currentAltitude = waypoint.properties.altitude;
      const previousWaypoint = waypoints.features[index - 1];
      const previousWaypointAltitude = previousWaypoint.properties.altitude;
      const altitudeDifference = previousWaypointAltitude - currentAltitude;
      if (altitudeDifference > altitudeChangeThreshhold) {
        significantChangeWaypoints.push([waypoint, altitudeDifference]);
      }
    }
  });
  return significantChangeWaypoints;
}

export function reduceWaypoints(
  waypoints: FeatureCollection<Point>,
  flightAngle: number,
) {
  const waypointsCopy = JSON.parse(JSON.stringify(waypoints)); // copy to avoid editing original
  const result = [];

  // tests whether the bearing is within 0.1 degrees of the correct direction
  function validBearing(bearing: number, flightAngle: number) {
    return (
      Math.abs(
        ((((bearing + 90) % 180) + 180) % 180) -
          (((flightAngle % 180) + 180) % 180),
      ) < 0.1
    );
  }
  const specialWaypoints = [
    WaypointNames.relativeAltitudePoint,
    WaypointNames.safeTakeoffAltitude,
    '0',
  ];
  const startingAltitude = waypointsCopy.features[0].properties.altitude;
  const altitudeAccuracy = startingAltitude * 0.05;
  waypointsCopy.features.forEach((waypoint: Feature, index: number) => {
    if (
      index == 0 ||
      index == waypointsCopy.features.length - 1 ||
      specialWaypoints.includes(waypoint.properties.name)
    ) {
      // first, last, and special waypoint always get added
      result.push(waypoint);
    } else {
      // Look at previous, current, and next waypoint of the original waypoints to determine whether to add the current waypoint
      const currentBearing = bearing(
        (waypointsCopy.features[index - 1].geometry as Point).coordinates,
        (waypoint.geometry as Point).coordinates,
      );
      const nextBearing = bearing(
        (waypoint.geometry as Point).coordinates,
        (waypointsCopy.features[index + 1].geometry as Point).coordinates,
      );

      const isCurrentBearingValid: boolean = validBearing(
        currentBearing,
        flightAngle,
      );
      const isNextBearingValid: boolean = validBearing(
        nextBearing,
        flightAngle,
      );

      if (isCurrentBearingValid && isNextBearingValid) {
        // if all three points are in a line, only add if the altitude change is large since the last waypoint added to the result
        const altitude = waypoint.properties.altitude;
        const previousAddedWaypoint = result.slice(-1)[0];
        if (
          Math.abs(altitude - previousAddedWaypoint.properties.altitude) >
          Math.abs(altitudeAccuracy)
        ) {
          result.push(waypoint);
        }
      } else if (
        (isCurrentBearingValid && !isNextBearingValid) ||
        // if the next waypoint is not in line with the current and last, add the current as its the last in this row
        (!isCurrentBearingValid && isNextBearingValid)
      ) {
        // if the last waypoint is not in line with the current and next, add the current as its the first in this row
        result.push(waypoint);
      }
    }
  });
  const filteredPoints: FeatureCollection<Point> = {
    type: 'FeatureCollection',
    features: result,
  };
  return filteredPoints;
}

export function generatePitch(
  waypoints: FeatureCollection<Point>,
  flightSpeed: number,
  startTime: string,
  takeoffBearing?: number,
) {
  // Adds gimbal pitch to each waypoint (only works for East/West oriented solar farms)
  const waypointsWithPitch: Feature<Point>[] = [];
  const maximumPanelAngle = 52;
  const waypointDatetime = new Date(startTime + 'Z');
  const waypointsCopy = JSON.parse(JSON.stringify(waypoints)); // copy to avoid editing original
  console.log(
    `Calculating gimbal pitch starting at ${waypointDatetime}, ${startTime}`,
  );
  console.log(
    SunCalc.getSunTimes(
      waypointDatetime,
      waypointsCopy.features[0].geometry.coordinates[1],
      waypointsCopy.features[0].geometry.coordinates[0],
    ),
  );
  waypointsCopy.features.forEach((waypoint, index) => {
    const lonLatCoords = waypoint.geometry.coordinates;
    if (index > 0) {
      // Calculates the distance between two waypoints to find the time it takes. This is increased by 1.25 to account for battery charging time.
      const FLIGHT_TIME_INCREASE = 1.25;
      const distanceBetweenPoints = distance(
        waypointsCopy.features[index - 1],
        waypoint,
        { units: 'meters' },
      );
      const timeIncrement =
        (distanceBetweenPoints / flightSpeed) * (1 + FLIGHT_TIME_INCREASE);
      waypointDatetime.setUTCSeconds(
        waypointDatetime.getUTCSeconds() + timeIncrement,
      );
    }

    // Position of sun based on lat lon
    const sunPosition = SunCalc.getPosition(
      waypointDatetime,
      lonLatCoords[1],
      lonLatCoords[0],
    );

    // Math
    const psiAngle =
      (Math.atan(
        (Math.cos(sunPosition.altitude) * Math.sin(sunPosition.azimuth)) /
          Math.sin(sunPosition.altitude),
      ) *
        180) /
      Math.PI;
    const calculatedGimbalAngle =
      -90 +
      Math.abs(
        (Math.atan(
          (Math.cos(sunPosition.altitude) * Math.sin(sunPosition.azimuth)) /
            Math.sin(sunPosition.altitude),
        ) *
          180) /
          Math.PI,
      );
    const maxGimbalAngle = -(90 - maximumPanelAngle);

    // Set pitch and heading for each waypoint
    if (calculatedGimbalAngle < maxGimbalAngle) {
      waypoint.properties.gimbalPitchAngle = calculatedGimbalAngle;
    } else {
      waypoint.properties.gimbalPitchAngle = maxGimbalAngle;
    }
    // if the waypoint is the takeoff pt, camera angle is calculated separately
    if (waypoint.properties.name == 'Relative Altitude Point') {
      waypoint.properties.gimbalHeading = takeoffBearing;
    } else if (psiAngle > 0) {
      // if not a takeoff point, gimbal heading is set based off psi angle
      waypoint.properties.gimbalHeading = -90; // Morning
    } else {
      waypoint.properties.gimbalHeading = 90; // Afternoon
    }

    waypointsWithPitch.push(waypoint);
  });
  console.log(`Finished pitch generation to time: ${waypointDatetime}`);

  return featureCollection(waypointsWithPitch);
}

export const clickDownloadDjiKmz = (kml, filename) => {
  try {
    // Create a new instance of JSZip
    const zip = new JSZip();
    zip.file('template.kml', kml);

    // Add KML content to file and add to zip folder
    zip.generateAsync({ type: 'blob' }).then(content => {
      // Create a link to download the Blob as a file
      const link = document.createElement('a');
      link.href = URL.createObjectURL(content);
      link.download = filename + '.kmz';

      // Append the link to the document and click it to download the file
      document.body.appendChild(link);
      link.click();

      // Clean up by removing the link
      document.body.removeChild(link);
    });
  } catch (err) {
    console.error(err);
    return;
  }
};

export const clickDownloadDjiWpml = (kml, wpml, filename) => {
  try {
    // Workaround for incorrect timestamps for files:
    const currDate = new Date();
    const dateWithOffset = new Date(
      currDate.getTime() - currDate.getTimezoneOffset() * 60000,
    );

    // Create a new instance of JSZip
    const zip = new JSZip();
    zip.folder('wpmz').file('template.kml', kml, { date: dateWithOffset });
    zip.folder('wpmz').file('waylines.wpml', wpml, { date: dateWithOffset });

    // Add KML content to file and add to zip folder
    zip.generateAsync({ type: 'blob' }).then(content => {
      // Create a link to download the Blob as a file
      const link = document.createElement('a');
      link.href = URL.createObjectURL(content);
      link.download = filename + '.kmz';

      // Append the link to the document and click it to download the file
      document.body.appendChild(link);
      link.click();

      // Clean up by removing the link
      document.body.removeChild(link);
    });
  } catch (err) {
    console.error(err);
    return;
  }
};

export const clickDownloadKML = (kml, filename) => {
  try {
    // // Add KML content to file and add to zip folder
    const blob = new Blob([kml], {
      type: 'application/vnd.google-earth.kml+xml',
    });
    // Create a link to download the Blob as a file
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename + '.kml';

    // Append the link to the document and click it to download the file
    document.body.appendChild(link);
    link.click();

    // Clean up by removing the link
    document.body.removeChild(link);
  } catch (err) {
    console.error(err);
    return;
  }
};

interface CreateDJIFilesInput {
  filetype: DJIFileType;
  map: mapboxgl.Map | null;
  polygon: Feature<Polygon>;
  flightMode: CombinedFlightModeType;
  altitude: number;
  flightAngle: number;
  flightSpeed: number;
  droneType: DroneType;
  terrainFollowBool: boolean;
  pitchAngle: number;
  altitudeOffsetObject: AltitudeOffsetObject;
  waypoints: FeatureCollection<Point>;
  intervalModeObject: IntervalModeObject;
  waypointReductionBool: boolean;
  continuousOperationsEnabled: boolean;
  safeTakeoffAltitudeBool: boolean;
  safeTakeoffAltitude?: number;
  continuousOperationStartTime?: string;
  transitSpeed?: number;
  transitSpeedBool?: boolean;
}
// returns an object contains the kml that is ready for download and the feature collection of all the waypoints each one is access by .kml or .waypoints.

export enum DJI_FILE_TYPES {
  kml = 'KML',
  wpml = 'WPML',
  legacy_wpml = 'LEGACY_WPML',
}
type DJIFileType =
  | DJI_FILE_TYPES.kml
  | DJI_FILE_TYPES.wpml
  | DJI_FILE_TYPES.legacy_wpml;

// returns an object contains the kml that is ready for download and the feature collection of all the waypoints each one is access by .kml or .waypoints.
export async function createDJIFiles({
  filetype,
  map,
  polygon,
  flightMode,
  altitude,
  flightAngle,
  flightSpeed,
  droneType,
  terrainFollowBool,
  pitchAngle,
  altitudeOffsetObject,
  waypoints,
  intervalModeObject,
  waypointReductionBool,
  continuousOperationsEnabled,
  safeTakeoffAltitudeBool,
  safeTakeoffAltitude,
  continuousOperationStartTime,
  transitSpeed,
  transitSpeedBool,
}: CreateDJIFilesInput) {
  if (!polygon) {
    console.error('Must supply a valid polygon in order to generate a KML.');
    return;
  }

  let cameraHeading = flightAngle;
  if (flightMode == FlightModeType.Perimeter) {
    cameraHeading = 90;
  }

  try {
    const terrainElevations = await getElevations(map, waypoints);
    waypoints = generateTerrainFollowingAltitudes(terrainElevations, altitude);
    if (safeTakeoffAltitudeBool) {
      // terrain following overwrites the safe takeoff alt for the first wp, so we need to manually go in and change that
      waypoints.features[0].properties.altitude = safeTakeoffAltitude;
      if (waypoints.features[0].properties.name == 'Relative Altitude Point') {
        // if there exists a takeoff pt
        waypoints.features[1].properties.altitude = safeTakeoffAltitude;
      }
    } else {
      safeTakeoffAltitude = null;
    }
    if (waypointReductionBool) {
      waypoints = reduceWaypoints(waypoints, flightAngle);
    }
    if (continuousOperationsEnabled) {
      const takeoffBearing = bearing(
        waypoints.features[0].geometry.coordinates,
        waypoints.features[1].geometry.coordinates,
      );
      waypoints = generatePitch(
        waypoints,
        flightSpeed,
        continuousOperationStartTime,
        takeoffBearing,
      );
    }
    switch (filetype) {
      case 'KML': {
        const kml = generateDJITemplateKML({
          waypoints,
          altitude,
          cameraHeading,
          flightSpeed,
          pitchAngle,
          flightMode,
          droneType,
          terrainFollowBool,
          altitudeOffsetObject,
          intervalModeObject,
          continuousOperationsEnabled,
          takeOffSecurityHeight: safeTakeoffAltitude,
          transitSpeed,
          transitSpeedBool,
        });
        return kml;
      }
      case 'WPML': {
        const wpml = generateDJITemplateFlightFunctionalWPML({
          waypoints,
          altitude,
          cameraHeading,
          flightSpeed,
          pitchAngle,
          flightMode,
          droneType,
          terrainFollowBool,
          altitudeOffsetObject,
          intervalModeObject,
          continuousOperationsEnabled,
          takeOffSecurityHeight: safeTakeoffAltitude,
          transitSpeed,
          transitSpeedBool,
        });
        return wpml;
      }
      case 'LEGACY_WPML': {
        const wpml = generateDJITemplateWPML({
          droneType,
          safeTakeoffAltitude,
          transitSpeed,
          transitSpeedBool,
        });
        return wpml;
      }
      default:
        console.error('Invalid filetype for generateDJITemplate');
    }
  } catch (err) {
    console.error(err);
  }
}

export const createFlightPathKML = async (
  flightPathLine,
  altitude: number,
  solarFarmId: number,
  missionId: number,
  missionName: string,
  filename: string,
  polygon: Feature<Polygon>,
) => {
  try {
    const kml = generateForeflightKML(
      flightPathLine,
      altitude,
      solarFarmId,
      missionId,
      missionName,
      filename,
      polygon,
    );
    return kml;
  } catch (err) {
    console.error(err);
  }
};

interface DownloadJsonInput {
  missionName: string;
  polygon: Feature<Polygon>;
  altitude: number;
  altitudeOffsetObject: AltitudeOffsetObject;
  flightAngle: number;
  fieldOfViewHorizontal: number;
  fieldOfViewVertical: number;
  frontOverlap: number;
  markerLngLat: number[] | null;
  flightSpeed: number;
  droneType: DroneType;
  terrainFollowBool: boolean;
  pitchAngle: number;
  sideOverlap: number;
  zoom: number;
  center: PointType;
  currentFlightMode: CombinedFlightModeType;
  cameraType: CameraType;
  calculationMode: SpeedControlModeType;
  cameraInterval: number;
  currentSolarFarmId: number;
  currentSolarFarmName: string;
  unitOfMeasure: UnitType;
  cameraAngleMode: CameraAngleMode;
  waypoints: FeatureCollection;
  filename: string;
  frontSpacing;
  sideSpacing;
  safeTakeoffAltitude?: number;
  transitSpeed?: number;
}
// create and download the JSON containing all the flight plan data.
export const downloadJSON = ({
  missionName,
  polygon,
  altitude,
  altitudeOffsetObject,
  flightAngle,
  fieldOfViewHorizontal,
  fieldOfViewVertical,
  frontOverlap,
  markerLngLat,
  flightSpeed,
  droneType,
  terrainFollowBool,
  pitchAngle,
  sideOverlap,
  zoom,
  center,
  currentFlightMode,
  cameraType,
  calculationMode,
  cameraInterval,
  currentSolarFarmId,
  currentSolarFarmName,
  unitOfMeasure,
  cameraAngleMode,
  waypoints,
  filename,
  frontSpacing,
  sideSpacing,
  safeTakeoffAltitude,
  transitSpeed,
}: DownloadJsonInput) => {
  try {
    const roundedMapCenter = {
      lat: parseFloat(center.lat.toFixed(9)),
      lng: parseFloat(center.lng.toFixed(9)),
    };

    const roundedMapZoom = parseFloat(zoom.toFixed(4));

    const json: MissionFileType = {
      mission: {
        missionName,
        altitude: altitude,
        altitudeOffset: altitudeOffsetObject.offset,
        altitudeOffsetBool: altitudeOffsetObject.active,
        safeTakeoffAltitude: safeTakeoffAltitude,
        cameraAngle: pitchAngle,
        cameraInterval: cameraInterval,
        drone: droneType,
        flightAngle: flightAngle,
        flightMap: polygon,
        flightMode: currentFlightMode,
        flightSpeed: flightSpeed,
        transitSpeed: transitSpeed,
        frontOverlap: frontOverlap,
        frontSpacing: frontSpacing,
        mapCenter: roundedMapCenter,
        mapZoom: roundedMapZoom,
        overlapMode: OverlapType.Overlap,
        sensor: {
          id: null,
          fieldOfViewHeight: fieldOfViewVertical,
          fieldOfViewWidth: fieldOfViewHorizontal,
          name: cameraType,
        },
        sideOverlap: sideOverlap,
        sideSpacing: sideSpacing,
        solarFarm: {
          geom: null,
          id: currentSolarFarmId ? currentSolarFarmId : null,
          name: currentSolarFarmName,
        },
        speedControlMode: calculationMode,
        takeOffPointCenter: markerLngLat
          ? {
              lat: markerLngLat[1],
              lng: markerLngLat[0],
            }
          : null,
        terrainFollowBool: terrainFollowBool,
        userUnits: unitOfMeasure,
        cameraAngleMode: cameraAngleMode,
      },
      version: MISSION_JSON_VERSION,
      waypoints: waypoints as FeatureCollectionInput,
    };
    // convert JSON so it can be used in a blob
    const jsonExport = JSON.stringify(json);
    // Create a Blob with the JSON data
    const jsonBlob = new Blob([jsonExport], { type: 'application/json' });
    // Create a link to download the Blob as a file
    const jsonLink = document.createElement('a');
    jsonLink.href = URL.createObjectURL(jsonBlob);
    jsonLink.download = filename + '.json';
    // Append the link to the document and click it to download the file
    document.body.appendChild(jsonLink);
    jsonLink.click();
    // Clean up by removing the jsonLink and revoking the Blob URL
    document.body.removeChild(jsonLink);
    URL.revokeObjectURL(jsonLink.href);
  } catch (err) {
    console.error(err);
  }
  return waypoints;
};

export const isContinuousOperationsActive = (
  cameraAngleMode: CameraAngleMode,
) => {
  return cameraAngleMode === CameraAngleMode.FollowTracker;
};

interface DJIDownloadParams {
  map: mapboxgl.Map;
  missionInput: MissionInput;
  filename: string;
  solarFarm: SolarFarmResponse;
  intervalometer: IntervalometerObject;
  geospatial: GeospatialObject;
  continuousOperationStartTime: string;
  safeTakeoffAltitudeBool: boolean;
  terrainFollowBool: boolean;
  wpmlFileType: DJIFileType;
  transitSpeed?: number;
  transitSpeedBool?: boolean;
}

export const handleDJIDroneDownload = async ({
  map,
  missionInput,
  filename,
  solarFarm,
  geospatial,
  intervalometer,
  continuousOperationStartTime,
  safeTakeoffAltitudeBool,
  terrainFollowBool,
  wpmlFileType,
  transitSpeed,
  transitSpeedBool,
}: DJIDownloadParams) => {
  const { drone } = missionInput;
  const intervalModeObject = {
    active: intervalometer.intervalometerBool,
    actionTriggerType: 'multipleDistance' as ActionTriggerType,
    actionTriggerParam: intervalometer.actionTriggerParam,
  };

  const altitudeOffsetObject = {
    offset: missionInput.altitudeOffset,
    active: missionInput.altitudeOffsetBool,
  };

  const continuousOperationsEnabled = isContinuousOperationsActive(
    missionInput.cameraAngleMode,
  );

  if (missionInput.mode === FlightModeType.LinearCustom) {
    return; //TODO: SC-81341
  }

  // generate KML
  const kml = await createDJIFiles({
    filetype: DJI_FILE_TYPES.kml,
    map,
    polygon: geospatial.polygon,
    flightMode: missionInput.mode,
    altitude: missionInput.altitude,
    flightAngle: missionInput.flightAngle,
    flightSpeed: missionInput.flightSpeed,
    droneType: missionInput.drone,
    terrainFollowBool,
    pitchAngle: missionInput.cameraPitchAngle,
    altitudeOffsetObject,
    waypoints: geospatial.waypoints,
    intervalModeObject,
    waypointReductionBool: intervalometer.waypointReductionBool,
    continuousOperationsEnabled,
    safeTakeoffAltitudeBool,
    safeTakeoffAltitude: missionInput.safeTakeoffAltitude,
    continuousOperationStartTime,
    transitSpeed,
    transitSpeedBool,
  });
  if (kml === undefined) {
    console.error('KML is undefined. KML: ', kml);
    alert(
      `Flight files could not be generated, please try different parameters.`,
    );
    return;
  }
  if (drone === DroneType.M30tDock || drone === DroneType.M3tdDock) {
    const wpml = await createDJIFiles({
      filetype: wpmlFileType,
      map,
      polygon: geospatial.polygon,
      flightMode: missionInput.mode,
      altitude: missionInput.altitude,
      flightAngle: missionInput.flightAngle,
      flightSpeed: missionInput.flightSpeed,
      droneType: missionInput.drone,
      terrainFollowBool,
      pitchAngle: missionInput.cameraPitchAngle,
      altitudeOffsetObject,
      waypoints: geospatial.waypoints,
      intervalModeObject,
      waypointReductionBool: intervalometer.waypointReductionBool,
      continuousOperationsEnabled,
      safeTakeoffAltitudeBool,
      safeTakeoffAltitude: missionInput.safeTakeoffAltitude,
      continuousOperationStartTime,
      transitSpeed,
      transitSpeedBool,
    });

    if (wpml === undefined) {
      console.error('WPML undefined: ', wpml);
      alert(
        `Flight files could not be generated, please try different parameters.`,
      );
      return;
    }
    // Download the KML and WPML
    clickDownloadDjiWpml(kml, wpml, filename);
  } else {
    // Download just the KML if there is no WPML
    clickDownloadDjiKmz(kml, filename);
  }

  // Track various mission parms when mission is downloaded
  Mixpanel.track(MixpanelEvents.PlanDownloadMission, {
    drone: drone,
    terrain_follow_bool: terrainFollowBool,
    flight_mode: missionInput.mode,
    camera_type: missionInput.sensorName,
    speed_control_mode: missionInput.speedControl,
    altitude_offset_bool: missionInput.altitudeOffsetBool,
    intervalometer_bool: intervalometer.intervalometerBool,
    waypoint_reduction_bool: intervalometer.waypointReductionBool,
  });

  // download the JSON
  downloadJSON({
    missionName: missionInput.missionName,
    polygon: geospatial.polygon,
    altitude: missionInput.altitude,
    altitudeOffsetObject,
    flightAngle: missionInput.flightAngle,
    fieldOfViewHorizontal: missionInput.sensorFovWidth,
    fieldOfViewVertical: missionInput.sensorFovHeight,
    frontOverlap: missionInput.frontOverlap,
    markerLngLat: missionInput.takeoffPoint?.coordinates || null,
    flightSpeed: missionInput.flightSpeed,
    droneType: missionInput.drone,
    terrainFollowBool,
    pitchAngle: missionInput.cameraPitchAngle,
    sideOverlap: missionInput.sideOverlap,
    zoom: map?.getZoom(),
    center: map?.getCenter(),
    currentFlightMode: missionInput.mode,
    cameraType: missionInput.sensorName,
    calculationMode: missionInput.speedControl,
    cameraInterval: missionInput.cameraInterval,
    currentSolarFarmId: solarFarm?.id,
    currentSolarFarmName: solarFarm?.name,
    unitOfMeasure: missionInput.units,
    cameraAngleMode: missionInput.cameraAngleMode,
    waypoints: geospatial.waypoints,
    filename,
    frontSpacing: getFieldOfViewSpacing(
      missionInput.altitude,
      missionInput.sensorFovWidth,
      missionInput.frontOverlap,
    ),
    sideSpacing: getFieldOfViewSpacing(
      missionInput.altitude,
      missionInput.sensorFovHeight,
      missionInput.sideOverlap,
    ),
    safeTakeoffAltitude: missionInput.safeTakeoffAltitude,
    transitSpeed,
  });
};

interface MannedAirplaneDownloadParams {
  map: mapboxgl.Map;
  missionInput: MissionInput;
  missionId?: number;
  filename: string;
  solarFarm: SolarFarmResponse;
  geospatial: GeospatialObject;
}

export const handleMannedAirplaneDownload = async ({
  map,
  missionId,
  missionInput,
  filename,
  solarFarm,
  geospatial,
}: MannedAirplaneDownloadParams) => {
  const { drone } = missionInput;

  if (missionInput.mode === FlightModeType.LinearCustom) {
    return;
  }

  const altitudeOffsetObject = {
    offset: missionInput.altitudeOffset,
    active: missionInput.altitudeOffsetBool,
  };

  const kml = await createFlightPathKML(
    geospatial.flightPath,
    missionInput.altitude,
    solarFarm?.id,
    missionId,
    missionInput.missionName,
    filename,
    geospatial.polygon,
  );

  if (kml === undefined) {
    console.error('KML is undefined. KML: ', kml);
    alert(
      `Flight files could not be generated, please try different parameters.`,
    );
    return;
  }
  // Download the KMZ
  clickDownloadKML(kml, filename);

  // Track various mission parms when mission is downloaded
  Mixpanel.track(MixpanelEvents.PlanDownloadMission, {
    drone: drone,
    flight_mode: missionInput.mode,
    camera_type: missionInput.sensorName,
    speed_control_mode: missionInput.speedControl,
    altitude_offset_bool: missionInput.altitudeOffsetBool,
  });

  // download the JSON
  downloadJSON({
    missionName: missionInput.missionName,
    polygon: geospatial.polygon,
    altitude: missionInput.altitude,
    altitudeOffsetObject,
    flightAngle: missionInput.flightAngle,
    fieldOfViewHorizontal: missionInput.sensorFovWidth,
    fieldOfViewVertical: missionInput.sensorFovHeight,
    frontOverlap: missionInput.frontOverlap,
    markerLngLat: missionInput.takeoffPoint?.coordinates || null,
    flightSpeed: missionInput.flightSpeed,
    droneType: missionInput.drone,
    terrainFollowBool: false,
    pitchAngle: missionInput.cameraPitchAngle,
    sideOverlap: missionInput.sideOverlap,
    zoom: map?.getZoom(),
    center: map?.getCenter(),
    currentFlightMode: missionInput.mode,
    cameraType: missionInput.sensorName,
    calculationMode: missionInput.speedControl,
    cameraInterval: missionInput.cameraInterval,
    currentSolarFarmId: solarFarm?.id,
    currentSolarFarmName: solarFarm?.name,
    unitOfMeasure: missionInput.units,
    cameraAngleMode: missionInput.cameraAngleMode,
    waypoints: geospatial.waypoints,
    filename,
    frontSpacing: getFieldOfViewSpacing(
      missionInput.altitude,
      missionInput.sensorFovWidth,
      missionInput.frontOverlap,
    ),
    sideSpacing: getFieldOfViewSpacing(
      missionInput.altitude,
      missionInput.sensorFovHeight,
      missionInput.sideOverlap,
    ),
    safeTakeoffAltitude: missionInput.safeTakeoffAltitude,
  });
};

const roundBbox = (
  bbox: mapboxgl.BBox,
  precision: number = LAT_LONG_PRECISION,
) => {
  return bbox.map(coord => round(coord, precision)) as mapboxgl.BBox;
};
