import { Feature, FeatureCollection, LineString, Polygon } from '@turf/helpers';
import { XMLBuilder } from 'fast-xml-parser';

export function featureToCommaSeperatedCoords(
  lineFeature: Feature<LineString>,
  altitude: number,
) {
  const justCoordinates = lineFeature.geometry.coordinates;
  let commaSeparatedCoords = '';
  for (let i = 0; i < justCoordinates.length; i++) {
    const [x, y] = justCoordinates[i];
    commaSeparatedCoords += `${x},${y},${altitude} `;
  }
  return commaSeparatedCoords;
}

function convertToKML(kmlArray) {
  const builder = new XMLBuilder({
    format: true,
    ignoreAttributes: false,
  });
  return builder.build(kmlArray);
}

export function generateForeflightKML(
  flightPath: FeatureCollection<LineString>,
  altitude: number,
  solarFarmId: number,
  missionId: number,
  missionName: string,
  filename: string,
  polygon: Feature<Polygon>,
) {
  const transformPolygonToCoordinates = polygon.geometry.coordinates
    .map(mypolygon =>
      mypolygon.map(([lon, lat]) => `${lon},${lat},0`).join(' '),
    )
    .join(' ');
  const placemarks = [];
  flightPath.features.forEach(feature => {
    const commaSeparatedCoords = featureToCommaSeperatedCoords(
      feature,
      altitude,
    );
    placemarks.push({
      name: 'Flight Line',
      styleUrl: '#inline7',
      ExtendedData: {
        Data: [
          {
            '@_name': 'Solar Farm ID',
            value: solarFarmId,
          },
          ...(missionId !== null
            ? [
                {
                  '@_name': 'Mission ID',
                  value: missionId,
                },
                {
                  '@_name': 'Mission Name',
                  value: missionName,
                },
              ]
            : []),
          {
            '@_name': 'Filename',
            value: filename,
          },
        ],
      },
      LineString: {
        tessellate: 1,
        coordinates: commaSeparatedCoords,
      },
    });
  });

  placemarks.push({
    name: 'Inspection Area',
    styleUrl: '#inline2',
    Polygon: {
      tessellate: 1,
      outerBoundaryIs: {
        LinearRing: {
          coordinates: transformPolygonToCoordinates,
        },
      },
    },
  });

  const kmlContent = {
    '?xml': {
      '@_version': '1.0',
      '@_encoding': 'UTF-8',
    },
    kml: {
      '@_xmlns': 'http://www.opengis.net/kml/2.2',
      '@_xmlns:gx': 'http://www.google.com/kml/ext/2.2',
      '@_xmlns:kml': 'http://www.opengis.net/kml/2.2',
      '@_xmlns:atom': 'http://www.w3.org/2005/Atom',
      Document: {
        name: filename,
        open: 1,
        Style: [
          {
            '@_id': 'inline1',
            LineStyle: {
              color: 'ff0000ff',
              width: 5,
            },
            PolyStyle: { fill: 0 },
          },
          {
            '@_id': 'inline5',
            LineStyle: {
              color: 'ff0000ff',
              width: 5,
            },
            PolyStyle: { fill: 0 },
          },
          {
            '@_id': 'inline2',
            LineStyle: {
              color: 'ff1eff30',
              width: 3,
            },
            PolyStyle: { fill: 0 },
          },
        ],
        StyleMap: {
          '@_id': 'inline7',
          Pair: [
            { key: 'normal', styleUrl: '#inline5' },
            {
              key: 'highlight',
              styleUrl: '#inline1',
            },
          ],
        },
        Placemark: placemarks,
      },
    },
  };
  return convertToKML(kmlContent);
}
