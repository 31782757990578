// External Dependancies
// RaptorUI
import { Row } from '@raptormaps/layout';
import { DropdownMenu } from '@raptormaps/dropdown-menu';
import { IconButton } from '@raptormaps/button';

const RowMoreMenu = ({ record, setRecord, disableArchive = false }) => (
  <Row justify="end" className="row-more">
    {/* this prevents the dropdown activation from activating the parent */}
    <div onClick={e => e.stopPropagation()}>
      <DropdownMenu
        menuContentProps={{
          align: 'end',
          sideOffset: 8,
          side: 'bottom',
          style: { minWidth: '130px' },
        }}
        items={[
          {
            disabled: disableArchive,
            value: 'Archive',
            variant: 'option',
            onSelect: async () => {
              setRecord(record);
            },
            style: disableArchive
              ? {
                  fontSize: '14px',
                  cursor: 'not-allowed',
                }
              : {
                  color: 'red',
                  fontSize: '14px',
                },
          },
        ]}
        modal={false}
      >
        <IconButton
          aria-label="select menu options"
          icon="EllipsisVertical"
          variant="tertiary"
          size="small"
        />
      </DropdownMenu>
    </div>
  </Row>
);

export default RowMoreMenu;
