// External Dependencies
import { useEffect, Dispatch, useState } from 'react';
import {
  FeatureCollection,
  Polygon,
  Feature,
  featureCollection,
} from '@turf/helpers';
import bbox from '@turf/bbox';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { SolarFarmResponse, Point } from '@raptormaps/raptor-flight-client-ts';
import { Popover } from '@raptormaps/popover';

// Internal Dependencies
import { MAPBOX_ACCESS_TOKEN } from '@/shared/constants/mapConstants';
import { initMissionTaskLayers } from '@/shared/components/Map/layers';
import { initEvents } from '@/shared/components/Map/EventListeners/InitEvents';
import {
  initTerrainSource,
  initNavControl,
} from '@/shared/components/Map/utils';
import { initMissionTaskImages } from '@/shared/components/Map/images';
import {
  DEFAULT_MAP_CENTER,
  DEFAULT_ZOOM,
  MapboxStyles,
} from '@/shared/constants/mapConstants';
import {
  handleUpdateFlightSource,
  initMissionTaskSources,
} from '@/shared/components/Map/sources';
import { useRenderAsBuilts } from '@/shared/hooks/useAsBuilt';
import { useEquipmentSourcesOnMap } from '@/shared/hooks/useEquipment';
import { useGetDockLocations } from '@/shared/stores/TelemetryWebsocket/TelemetryWebsocketStore';
import {
  DOCK_SOURCE_ID,
  SELECTED_DOCK_SOURCE_ID,
} from '@/shared/components/Map/sources/DeviceSources';
import { MapDetails } from '@/shared/components/MapDetailsPopover/MapDetailsPopover';
import { MapDetailsPopoverAnchor } from '@/shared/components/MapDetailsPopover/MapDetailsPopover.styles';
import { isNumber } from '@/shared/utils/telemetryUtils';

mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;
interface MissionTaskMapProps {
  map?: mapboxgl.Map;
  solarFarm?: SolarFarmResponse;
  missionCenter?: Point;
  polygon?: Feature<Polygon>;
  waypoints?: FeatureCollection;
  flightPath?: Feature;
  setMap: Dispatch<mapboxgl.Map>;
  selectedDockId: number;
  previewDeviceId: number;
}

export const MissionTaskMap = ({
  map,
  solarFarm,
  missionCenter,
  polygon,
  waypoints,
  flightPath,
  setMap,
  selectedDockId,
  previewDeviceId,
}: MissionTaskMapProps) => {
  const [activeRowId, setActiveRowId] = useState<number>(null);
  useEquipmentSourcesOnMap(map, solarFarm, activeRowId);
  useRenderAsBuilts(map, solarFarm);

  const dockLocations = useGetDockLocations();

  const removeDockFromDockLocations = (dockId: number) => {
    const updatedDeviceLocations = dockLocations.filter(
      feature => feature?.properties?.deviceId !== dockId,
    );
    const updatedLocations = featureCollection(updatedDeviceLocations);
    handleUpdateFlightSource(map, DOCK_SOURCE_ID, updatedLocations);
  };

  const renderDockLocations = () => {
    const updatedLocations = featureCollection(dockLocations);
    handleUpdateFlightSource(map, DOCK_SOURCE_ID, updatedLocations);
  };

  useEffect(() => {
    if (map) return; // initialize map only once

    const center =
      (missionCenter && {
        lat: missionCenter.coordinates[1],
        lng: missionCenter.coordinates[0],
      }) ||
      (solarFarm && { lat: solarFarm.latitude, lng: solarFarm.longitude });

    const newMap = new mapboxgl.Map({
      container: 'map-container',
      style: MapboxStyles.StreetsV12,
      center: center || DEFAULT_MAP_CENTER,
      zoom: DEFAULT_ZOOM,
    });
    initNavControl(newMap);

    // Set up the draw tool
    const draw = new MapboxDraw({
      displayControlsDefault: false,
      simple_select: true,
    });
    newMap.addControl(draw);

    newMap.on('load', () => {
      initMissionTaskSources(newMap);
      initMissionTaskLayers(newMap);
      initMissionTaskImages(newMap);
      initEvents(newMap, setActiveRowId, draw);
      setMap(newMap);
    });

    newMap.on('style.load', () => {
      initTerrainSource(newMap);
    });

    return () => {
      newMap.remove();
      setMap(null);
    };
  }, []);
  // Center the map when the solar farm loads
  useEffect(() => {
    if (!map || !solarFarm) return;
    const { longitude, latitude } = solarFarm;
    if (isNumber(longitude) && isNumber(latitude)) {
      map.setCenter([longitude, latitude]);
    }
  }, [map, solarFarm]);

  // Manage the flight path and waypoints
  useEffect(() => {
    if (!map) return;
    handleUpdateFlightSource(map, 'flightPath', flightPath);
    handleUpdateFlightSource(map, 'waypoints', waypoints);
    handleUpdateFlightSource(map, 'polygon', polygon);
    if (polygon) {
      const bounds = bbox(polygon);
      map.fitBounds(bounds, {
        padding: {
          top: 20,
          bottom: 20,
          left: 620, // Offset for the sidebar
          right: 20,
        },
      });
    } else {
      map.setZoom(DEFAULT_ZOOM);
    }
  }, [waypoints, flightPath, polygon]);

  useEffect(() => {
    if (dockLocations.length && map) {
      renderDockLocations();
    }
  }, [dockLocations, map]);

  useEffect(() => {
    if (selectedDockId && map && dockLocations) {
      const selectedDockFeature = dockLocations.find(
        feature => feature.properties?.deviceId === selectedDockId,
      );

      if (selectedDockFeature) {
        const selectedDockFeatureCollection = featureCollection([
          selectedDockFeature,
        ]);
        handleUpdateFlightSource(
          map,
          SELECTED_DOCK_SOURCE_ID,
          selectedDockFeatureCollection,
        );

        // Remove selected dock from source
        removeDockFromDockLocations(selectedDockId);
      }
    }
  }, [selectedDockId, map, dockLocations]);

  useEffect(() => {
    if (previewDeviceId && map && dockLocations) {
      const devicePreviewFeature: Feature = dockLocations.find(
        (feature: Feature) => feature.properties?.deviceId === previewDeviceId,
      );
      if (devicePreviewFeature) {
        const geom = devicePreviewFeature?.geometry as Point;
        map.flyTo({
          center: geom.coordinates,
          offset: [300, 0],
          zoom: 18,
        });
      }
    }
  }, [previewDeviceId, map, dockLocations]);

  return (
    <div className="map">
      <div id="map-container" className="map-container" />
      <Popover>
        <MapDetailsPopoverAnchor />
        <MapDetails
          map={map}
          solarFarmId={solarFarm?.id}
          activeRowId={activeRowId}
        />
      </Popover>
    </div>
  );
};
