// External imports
import { useEffect, useState, useRef, Dispatch, SetStateAction } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import bbox from '@turf/bbox';
import { SolarFarmResponse } from '@raptormaps/raptor-flight-client-ts';
import { FeatureCollection, Feature, Point } from '@turf/helpers';
import { round } from 'lodash';

// Internal imports
import Mixpanel from '../../utils/mixpanel/utils';
import MixpanelEvents from '../../utils/mixpanel/events';
import { PointType, GeospatialObject } from '@/shared/types/missions.d';
import MapHeader from './MapHeader';
import { initTerrainSource, initDrawTool, updateDrawTool } from './utils';
import { initLayers } from './layers';
import { initImages } from './images';
import { initSources, handleUpdateFlightSource } from './sources';
import { initEvents } from './EventListeners/InitEvents';
import { useEquipmentSourcesOnMap } from '../../hooks/useEquipment';
import { useRenderAsBuilts } from '../../hooks/useAsBuilt';
import {
  calculateCenter,
  calculateZoom,
  addSolarFarmMarkers,
} from '../../../pages/SiteList/siteList.utils';
import {
  DEFAULT_MAP_CENTER,
  DEFAULT_ZOOM,
  DrawToolModes,
  MAPBOX_ACCESS_TOKEN,
  MapboxStyles,
} from '../../constants/mapConstants';
import {
  DEFAULT_GEOSPATIAL,
  LAT_LONG_PRECISION,
} from '@/pages/MissionPlanner/constants/missionConstants';
import './Map.css';
import { MapDetails } from '../MapDetailsPopover/MapDetailsPopover';
import { Popover } from '@raptormaps/popover';
import { MapDetailsPopoverAnchor } from '../MapDetailsPopover/MapDetailsPopover.styles';

mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

interface MapProps {
  map?: mapboxgl.Map;
  solarFarm?: SolarFarmResponse;
  zoom?: number;
  center?: PointType;
  geospatial?: GeospatialObject;
  nonCalibratedFlightPath?: Feature;
  markersGeoJson?: FeatureCollection<Point>;
  setMap: Dispatch<mapboxgl.Map>;
  setGeospatial?: Dispatch<GeospatialObject>;
  handleClickPopup?: (arg: number) => void;
  drawToolMode: DrawToolModes;
  resetMapOnTabChangeLoading?: boolean;
  setResetMapOnTabChangeLoading?: Dispatch<SetStateAction<boolean>>;
  showMapDetails?: boolean;
}

let globalGeospatialObject: GeospatialObject = DEFAULT_GEOSPATIAL;
const Map = ({
  map,
  solarFarm,
  center,
  zoom,
  geospatial,
  setGeospatial,
  nonCalibratedFlightPath,
  markersGeoJson,
  setMap,
  handleClickPopup,
  drawToolMode,
  showMapDetails = true,
}: MapProps) => {
  const marker = useRef<mapboxgl.Marker>(null);
  const [drawTool, setDrawTool] = useState(null);
  const [activeRowId, setActiveRowId] = useState<number | null>(null);
  const { polygon, waypoints, markerLngLat, flightPath } = geospatial || {};

  useEquipmentSourcesOnMap(map, solarFarm, activeRowId);
  useRenderAsBuilts(map, solarFarm);

  useEffect(() => {
    if (!map || !drawTool) return;
    const draw = updateDrawTool({
      map,
      drawToolMode,
      onDrawChange,
      onDrawDelete,
      prevDrawTool: drawTool,
    });
    setDrawTool(draw);
  }, [drawToolMode]);

  useEffect(() => {
    if (map) return; // initialize map only once
    const solarFarmCenter = solarFarm
      ? { lat: solarFarm.latitude, lng: solarFarm.longitude }
      : null;
    const initialCenter = center || solarFarmCenter || DEFAULT_MAP_CENTER;

    const newMap: mapboxgl.Map = new mapboxgl.Map({
      container: 'map-container',
      style: MapboxStyles.StreetsV12,
      center: [initialCenter.lng, initialCenter.lat],
      zoom: zoom || DEFAULT_ZOOM,
    });

    const nav = new mapboxgl.NavigationControl({
      visualizePitch: true,
    });
    newMap.addControl(nav, 'bottom-right');

    // If not on the homepage set up the draw tool
    const draw = drawToolMode
      ? initDrawTool(
          newMap,
          polygon ? DrawToolModes.SimpleSelect : drawToolMode,
          onDrawChange,
          onDrawDelete,
        )
      : null;

    setDrawTool(draw);

    newMap.on('load', () => {
      initSources(newMap);
      initLayers(newMap);
      initImages(newMap);
      initEvents(newMap, setActiveRowId, draw);
      setMap(newMap);
    });

    newMap.on('style.load', () => {
      initTerrainSource(newMap);
    });

    return () => {
      newMap.remove();
      setMap(null);
    };
  }, []);

  // Add markers and adjust zoom level for the site list page
  const markersRef = useRef<mapboxgl.Marker[]>([]);
  useEffect(() => {
    if (!map || !markersGeoJson) return;
    try {
      addSolarFarmMarkers(map, markersGeoJson, markersRef, handleClickPopup);
      map.fitBounds(bbox(markersGeoJson), { padding: 40 });
      drawTool.changeMode(DrawToolModes.SimpleSelect);
    } catch (err) {
      console.warn(err);
    }
  }, [map, markersGeoJson]);

  // Manage polygon
  useEffect(() => {
    if (!drawTool || !map) return;
    drawTool.deleteAll();
    if (polygon) {
      drawTool.changeMode(DrawToolModes.SimpleSelect);
      drawTool.add(polygon);
    } else {
      drawTool.changeMode(drawToolMode);
    }
  }, [polygon]);

  // Fly to the solar farm when it is loaded
  useEffect(() => {
    if (!map) return;
    if (center && zoom) {
      map.setZoom(zoom);
      map.setCenter([center.lng, center.lat]);
      return;
    } else if (markersGeoJson) {
      const boundingBox = bbox(markersGeoJson);
      map.setZoom(calculateZoom(boundingBox));
      map.setCenter(calculateCenter(boundingBox));
    } else if (solarFarm) {
      // centers on lat lng saved in uploaded mission
      map.setZoom(15);
      map.setCenter([solarFarm.longitude, solarFarm.latitude]);
    } else {
      map.setZoom(DEFAULT_ZOOM);
      map.setCenter(DEFAULT_MAP_CENTER);
    }
  }, [map, solarFarm, center, zoom, markersGeoJson]);

  // Manage the marker on the map
  useEffect(() => {
    if (!map) return;

    // Marker exists and we are updating the markerLngLat
    if (marker.current && markerLngLat) return;

    // Clear marker if markerLngLat is null
    if (marker.current && !markerLngLat) {
      marker.current.remove();
      marker.current = null;
    } else if (!marker.current && markerLngLat) {
      // Create marker if markerLngLat is not null and no marker exists
      const takeoffMarker = new mapboxgl.Marker({ draggable: true });
      takeoffMarker.setLngLat([markerLngLat.lng, markerLngLat.lat]);
      takeoffMarker.addTo(map);
      marker.current = takeoffMarker;

      const onDragEnd = () => {
        if (setGeospatial) {
          setGeospatial({
            ...globalGeospatialObject,
            markerLngLat: takeoffMarker.getLngLat(),
          });
        }
      };
      takeoffMarker.on('dragend', onDragEnd);
    }
  }, [markerLngLat]);

  // Manage the flight path and waypoints
  useEffect(() => {
    if (!map) return;
    handleUpdateFlightSource(map, 'flightPath', flightPath);
    handleUpdateFlightSource(map, 'waypoints', waypoints);
  }, [waypoints, flightPath]);

  // Manage the non-calibrated flight path
  useEffect(() => {
    if (!map) return;

    handleUpdateFlightSource(
      map,
      'nonCalibratedFlightPath',
      nonCalibratedFlightPath,
    );
  }, [map, nonCalibratedFlightPath]);

  useEffect(() => {
    globalGeospatialObject = geospatial;
  }, [geospatial]);

  const onDrawChange = ({ features }: MapboxDraw.DrawCreateEvent) => {
    if (features[0]?.geometry.type !== 'Polygon') {
      return; //TODO: sc-81296
    }

    Mixpanel.track(MixpanelEvents.PlanPolygonDrawChange);
    let coordinates = features[0].geometry.coordinates[0];
    if (setGeospatial) {
      coordinates = coordinates.map((coord: number[]) => [
        round(coord[0], LAT_LONG_PRECISION),
        round(coord[1], LAT_LONG_PRECISION),
      ]);
      features[0].geometry.coordinates[0] = coordinates;
      setGeospatial({ ...globalGeospatialObject, polygon: features[0] });
    }
  };

  const onDrawDelete = () => {
    Mixpanel.track(MixpanelEvents.PlanPolygonDrawDelete);
    if (setGeospatial) {
      setGeospatial(DEFAULT_GEOSPATIAL);
    }
  };

  return (
    <div className="map">
      {map && solarFarm && <MapHeader map={map} />}
      <div id="map-container" className="map-container" />
      {showMapDetails && (
        <Popover>
          <MapDetailsPopoverAnchor />
          <MapDetails
            map={map}
            solarFarmId={solarFarm?.id}
            activeRowId={activeRowId}
          />
        </Popover>
      )}
    </div>
  );
};

export default Map;
