import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import { Org, User } from '../types/missions.d';
import { ResponseError } from '@raptormaps/raptor-flight-client-ts';

interface EnterAppResponse {
  user: User;
  orgs: Org[];
  activeOrg: Org;
}

export const useEnterApp = () => {
  const { getAccessTokenSilently } = useAuth0();

  const enterApp = async () => {
    const accessToken = await getAccessTokenSilently();
    const response = await fetch(
      `${window.REACT_APP_PLANTLABELER_API_ENDPOINT}/api/v1/enter_app/`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: '/',
        },
      },
    );
    const result = await response.json();
    const activeOrg = result.user.orgs.find(
      org => org.id === result.user.latest_org_id,
    );

    sessionStorage.setItem('accessToken', accessToken);
    sessionStorage.setItem('tileServerUrl', result.tile_server_url);

    return {
      user: result.user,
      orgs: result.user.orgs,
      activeOrg: activeOrg,
    };
  };

  return useQuery<EnterAppResponse, ResponseError>({
    queryKey: ['enter-app'],
    queryFn: enterApp,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
};
