// Styled Components
import { Row } from '@raptormaps/layout';
import { Icon } from '@raptormaps/icon';
import { Text } from '@raptormaps/text';
import { ContentBlock } from '@raptormaps/content-block';
import { Button } from '@raptormaps/button';
import theme from '@raptormaps/theme';

// Components
import { IconTelemetry } from '../Shared/IconTelemetry';
import { DockCoverStateTelemetry } from '@/pages/Overview/Shared/DockCoverStateTelemetry';

// Telemetry
import { DockDevice } from '@/shared/types/devices';

// Growthbook
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { GrowthbookFlags } from '@/shared/utils/GrowthbookUtils';

interface DockSidebarTelemetryProps {
  selectedDockDevice: DockDevice;
  displayLiveStreamModal: boolean;
  toggleLiveStreamModal: () => void;
  openLivestreamIsDisabled: boolean;
  livestreamErrorMessage: string;
}

const DockSidebarTelemetry = ({
  selectedDockDevice,
  displayLiveStreamModal,
  toggleLiveStreamModal,
  openLivestreamIsDisabled,
  livestreamErrorMessage,
}: DockSidebarTelemetryProps) => {
  const deviceSn = selectedDockDevice.deviceSn;
  const livestreamModalVisible = useFeatureIsOn(
    GrowthbookFlags.LIVE_STREAM_MODAL_VISIBLE,
  );

  return (
    <>
      <ContentBlock borders columns={1} padding="large" variant="grey">
        <Row align="center" justify="space-between">
          <Row align="center" justify="start" gutter="xxs">
            <Icon icon="HouseDrone" />
            <Text variant="paragraph_medium" align="left">
              {selectedDockDevice.deviceName}
            </Text>
          </Row>
          <DockCoverStateTelemetry deviceSn={deviceSn} />
        </Row>
      </ContentBlock>
      <ContentBlock
        columns={2}
        padding="large"
        variant="white"
        style={{ boxShadow: 'unset' }}
      >
        <IconTelemetry
          telemetryKey="windspeed"
          deviceSn={selectedDockDevice.deviceSn}
        />
        <IconTelemetry
          telemetryKey="environmentTemperature"
          deviceSn={selectedDockDevice.deviceSn}
        />
        <IconTelemetry
          telemetryKey="rainfall"
          deviceSn={selectedDockDevice.deviceSn}
        />
        <IconTelemetry
          telemetryKey="internetSpeed"
          deviceSn={selectedDockDevice.deviceSn}
        />
      </ContentBlock>

      {livestreamModalVisible && (
        <Button
          icon="CameraWeb"
          size="large"
          variant="tertiary"
          style={{ marginTop: 0 }}
          iconPosition="right"
          onClick={() => toggleLiveStreamModal()}
          disabled={openLivestreamIsDisabled}
        >
          <Text
            variant="paragraph_large_bold"
            color={
              openLivestreamIsDisabled
                ? theme.colors.grey_500
                : theme.colors.black
            }
          >
            {displayLiveStreamModal
              ? 'Close Dock Livestream'
              : 'Open Dock Livestream'}
          </Text>
        </Button>
      )}
      {livestreamModalVisible && openLivestreamIsDisabled && (
        <Text
          variant="label_small"
          color={theme.colors.error_400}
          style={{ marginTop: -20, marginBottom: '16px' }}
        >
          {livestreamErrorMessage}
        </Text>
      )}
    </>
  );
};

export default DockSidebarTelemetry;
