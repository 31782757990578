import {
  UNAVAILABLE_VALUE,
  UNKOWN_BATTERY_PERCENT,
  UNKNOWN_VALUE,
} from '@/shared/constants/deviceConstants';
import { capitalize } from 'lodash';
import {
  DJI_MEMORY_CAPACITY_TYPE,
  DJILiveCapacity,
} from '../constants/djiTelemetryConstants';
import {
  DockCoverState,
  EmergencyStopState,
  LiveCapacityStatus,
} from '../types/devices.d';
import { DockDeviceTelemetry } from '@/shared/types/telemetry.d';
import { DockDevice } from '@/shared/types/devices.d';

export const toNAConverter = (value: string | null): string => {
  if (!value) {
    return UNAVAILABLE_VALUE;
  }

  return value
    .split('_')
    .map(word => capitalize(word))
    .join(' ');
};

export const toUnknownConverter = (value: string | null): string => {
  if (!value) {
    return capitalize(UNKNOWN_VALUE);
  }

  return value
    .split('_')
    .map(word => capitalize(word))
    .join(' ');
};

export const toNullConverter = () => null;

export const metersPerSecConverter = (value: number | null): string => {
  if (!isNumber(value)) {
    return UNAVAILABLE_VALUE;
  }

  return Number.isInteger(value) ? `${value} m/s` : `${value.toFixed(2)} m/s`;
};

export const convertToNAValue = (value: string | null): string =>
  value ?? UNAVAILABLE_VALUE;

export const convertSecondsToDisplay = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return minutes > 0
    ? `${minutes} minute${minutes > 1 ? 's' : ''} ${remainingSeconds} seconds`
    : `${remainingSeconds} seconds`;
};

export const degreesCelsiusConverter = (value: number | null): string => {
  if (!isNumber(value)) {
    return UNAVAILABLE_VALUE;
  }

  return Number.isInteger(value) ? `${value}°C` : `${value.toFixed(2)}°C`;
};

export const kbPerSecConverter = (value: number | null): string => {
  if (!isNumber(value)) {
    return UNAVAILABLE_VALUE;
  }

  return Number.isInteger(value) ? `${value} kb/s` : `${value.toFixed(2)} kb/s`;
};

export const metersConverter = (value: number | null): string => {
  if (!isNumber(value)) {
    return UNAVAILABLE_VALUE;
  }

  if (value < 0.01) {
    return '0 m';
  }

  return Number.isInteger(value) ? `${value} m` : `${value.toFixed(2)} m`;
};

export const metersPerSecondConverter = (value: number | null): string => {
  if (!isNumber(value)) {
    return UNAVAILABLE_VALUE;
  }

  return Number.isInteger(value) ? `${value} m/s` : `${value.toFixed(2)} m/s`;
};

export const degreesConverter = (value: number | null): string => {
  if (!isNumber(value)) {
    return UNAVAILABLE_VALUE;
  }

  return Number.isInteger(value) ? `${value}°` : `${value.toFixed(2)}°`;
};

export const coordinateConverter = (value: number | null): string => {
  if (!isNumber(value)) {
    return UNAVAILABLE_VALUE;
  }

  return value.toFixed(6);
};

export const percentConverter = (value: number | null) => {
  if (!isNumber(value)) {
    return UNAVAILABLE_VALUE;
  }

  return Number.isInteger(value) ? `${value}%` : `${value.toFixed(2)}%`;
};

export const batteryPercentConverter = (value: number | null) => {
  if (!isNumber(value)) {
    return UNKOWN_BATTERY_PERCENT;
  }

  return Number.isInteger(value) ? `${value}%` : `${value.toFixed(2)}%`;
};

export const secondsToMinutesAndSecondsConverter = (value: number | null) => {
  if (!isNumber(value)) {
    return UNAVAILABLE_VALUE;
  }

  const minutes = Math.floor(value / 60);
  const seconds = value % 60;

  if (minutes === 0) {
    return `${seconds}s`;
  }

  return `${minutes}m ${seconds}s`;
};

export const memoryStorageConverter = (
  value: DJI_MEMORY_CAPACITY_TYPE | null,
) => {
  if (!value) {
    return UNAVAILABLE_VALUE;
  }

  const usedMemory = isNumber(value?.used)
    ? convertKbToGb(value.used)
    : UNAVAILABLE_VALUE;
  const totalMemory = isNumber(value?.total)
    ? convertKbToGb(value.total)
    : UNAVAILABLE_VALUE;

  if (usedMemory === UNAVAILABLE_VALUE && totalMemory === UNAVAILABLE_VALUE) {
    return UNAVAILABLE_VALUE;
  }

  return `${usedMemory} / ${totalMemory} GB`;
};

export const countConverter = (value: number | null): string => {
  if (!isNumber(value)) {
    return UNAVAILABLE_VALUE;
  }

  return value.toString();
};

export const isNumber = (val: unknown): boolean => typeof val === 'number';

const convertKbToGb = (kb: number) => {
  const gb = kb / (1024 * 1024);

  if (gb < 0.01) {
    return '0';
  }

  return Number.isInteger(gb) ? String(gb) : String(gb.toFixed(1));
};

export const getDockCoverState = (
  emergencyStopState: string,
  dockCoverStateBaseValue: string,
) => {
  return emergencyStopState === EmergencyStopState.Enabled
    ? DockCoverState.EStop
    : dockCoverStateBaseValue ?? UNKNOWN_VALUE;
};

export const modifyStaleTelemetryState = (
  deviceTelemetry: DockDeviceTelemetry,
) => {
  const STALE_TELEMETRY_THRESHOLD_MS = 5000;

  Object.keys(deviceTelemetry).forEach((key: keyof DockDeviceTelemetry) => {
    if (
      deviceTelemetry[key].value === null ||
      deviceTelemetry[key].value === undefined ||
      !deviceTelemetry[key].timestamp
    ) {
      return;
    }
    const currentTime = Date.now();
    const lastMessageTime = deviceTelemetry[key].timestamp;
    const timeDelta = currentTime - lastMessageTime;
    deviceTelemetry[key].isStale = timeDelta >= STALE_TELEMETRY_THRESHOLD_MS;
    deviceTelemetry[key].lastMonitored = currentTime;
  });
};

export const getAllDevicesFromDocks = (docks?: DockDevice[]) => {
  return Object.values(
    (docks || []).reduce((acc, device) => {
      acc[device.deviceSn] = device;
      device.devices?.forEach(subDevice => {
        if (subDevice?.deviceSn) {
          acc[subDevice.deviceSn] = subDevice;
        }
      });
      return acc;
    }, {}),
  );
};

export const DJILiveCapacityConverter = (
  value: DJILiveCapacity | null,
): LiveCapacityStatus[] => {
  if (!value) {
    return null;
  }

  const availableDevices: LiveCapacityStatus[] = [];

  value.device_list.forEach(device => {
    const deviceLiveStatus: LiveCapacityStatus = { sn: null, cameras: [] };

    deviceLiveStatus.sn = device.sn;
    const cameras = device.camera_list.map(camera => ({
      index: camera.camera_index,
      videoList: camera.video_list.map(video => ({
        types: video.switchable_video_types,
        index: video.video_index,
        type: video.video_type,
      })),
    }));

    deviceLiveStatus.cameras = cameras;
    availableDevices.push(deviceLiveStatus);
  });

  return availableDevices;
};
