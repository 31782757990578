import styled from 'styled-components';

// RaptorUI
import { Text } from '@raptormaps/text';
import { IconButton } from '@raptormaps/button';

export const InputHelperText = styled(Text)`
  color: ${props => props.color};
  margin-top: -15px;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0.25px;
  font-weight: 500;
`;

export const IconButtonAdornment = styled(IconButton)`
  height: 32px;
  right: 1px;
  bottom: 1px;
  position: absolute;
  border-left: none;
  border-radius: 3px;
  display: flex;
}
`;
