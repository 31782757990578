// External Dependancies
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
// RaptorUI
import { Row } from '@raptormaps/layout';
import { Icon } from '@raptormaps/icon';

// All times are in UTC
dayjs.extend(utc);

export const StartAndEndTime = ({ missionTask }) => {
  const start = missionTask.startTsecs
    ? dayjs(missionTask.startTsecs * 1000).format('HH:mm')
    : null;
  const end = missionTask.endTsecs
    ? dayjs(missionTask.endTsecs * 1000).format('HH:mm')
    : null;

  return (
    <Row gutter="none">
      {start} <Icon icon="Minus" style={{ marginTop: '2px' }} /> {end}
    </Row>
  );
};
