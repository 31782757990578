// External Dependancies
import { useState, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import { useToast } from '@raptormaps/toast';

// Components
import Map from '@/shared/components/Map/Map';
import MissionPlanningSidebar from './components/MissionPlanningSidebar/MissionPlanningSidebar';

// Constants
import {
  DEFAULT_CALIBRATION,
  DEFAULT_GEOSPATIAL,
} from './constants/missionConstants';

// Types
import {
  CalibrationObject,
  GeospatialObject,
  PointType,
} from '@/shared/types/missions.d';
import { PageProps } from '@/shared/types/page';

// Utils
import { useSolarFarmById } from '@/shared/hooks/useSolarFarms';
import { useAppContext } from '@/shared/context/AppContext';
import { DrawToolModes } from '@/shared/constants/mapConstants';

const MissionPlanner = ({ solarFarmId }: PageProps) => {
  const toast = useToast();
  const { flightMission, clearMission } = useAppContext();
  const { data: solarFarm } = useSolarFarmById(solarFarmId, () => {
    toast.error(`Error fetching solar farm with ID ${solarFarmId}`);
  });

  // Mission state
  const [geospatial, setGeospatial] =
    useState<GeospatialObject>(DEFAULT_GEOSPATIAL);
  const [calibration, setCalibration] =
    useState<CalibrationObject>(DEFAULT_CALIBRATION);

  // Map state
  const [map, setMap] = useState<mapboxgl.Map>(null);
  const [initialZoom, setInitialZoom] = useState<number>(null);
  const [initialCenter, setInitialCenter] = useState<PointType>(null);
  const [drawToolMode, setDrawToolMode] = useState<DrawToolModes>(
    DrawToolModes.Polygon,
  );

  // Clear mission from context on unmount of the page
  useEffect(() => {
    return () => {
      if (flightMission && map) {
        clearMission();
      }
    };
  }, [map]);

  return (
    <>
      <MissionPlanningSidebar
        map={map}
        geospatial={geospatial}
        calibration={calibration}
        setInitialZoom={setInitialZoom}
        setInitialCenter={setInitialCenter}
        setGeospatial={setGeospatial}
        setCalibration={setCalibration}
        setDrawToolMode={setDrawToolMode}
        drawToolMode={drawToolMode}
      />
      <Map
        map={map}
        zoom={initialZoom}
        center={initialCenter}
        solarFarm={solarFarm}
        geospatial={geospatial}
        nonCalibratedFlightPath={calibration.nonCalibratedFlightPath}
        setMap={setMap}
        setGeospatial={setGeospatial}
        drawToolMode={drawToolMode}
      />
    </>
  );
};

export default MissionPlanner;
