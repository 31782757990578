import { GeoJSONSource } from '../map.d';
export const INVERTER_SOURCE_ID = 'raptor-inverters';

const InverterSource: GeoJSONSource = {
  id: INVERTER_SOURCE_ID,
  source: {
    type: 'geojson',
    data: { type: 'FeatureCollection', features: [] },
  },
};

const InverterSources: GeoJSONSource[] = [InverterSource];

export default InverterSources;
