// External Dependencies
import { Text } from '@raptormaps/text';
import { FormikErrors } from 'formik';

// Components
import {
  QuarterWidthTextInput,
  MinMax,
} from '@/pages/MissionPlanner/components/MissionPlanningSidebar/MissionPlanningSidebar.styles';

// Types
import { FormParameters } from '@/shared/types/missions.d';
import { UnitType } from '@raptormaps/raptor-flight-client-ts';

// Constants
import {
  METERS_TO_FEET,
  NUMBER_INPUT_STEP,
} from '@/pages/MissionPlanner/constants/missionConstants';

// Utils
import { handleInputScroll } from '@/pages/MissionPlanner/utils/utils';

interface AltitudeInputProps {
  units: UnitType;
  altitude: number;
  errors: FormikErrors<{ altitude: string }>;
  formParameters: FormParameters;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleEnterPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur: () => void;
}

const AltitudeInput = ({
  units,
  altitude,
  errors,
  formParameters,
  handleChange,
  handleEnterPress,
  onBlur,
}: AltitudeInputProps) => {
  return (
    <>
      <QuarterWidthTextInput
        gridColumn={3}
        style={{ alignItems: 'flex-start' }}
        label={
          units === UnitType.Metric ? 'Altitude (meters)' : 'Altitude (feet)'
        }
        inputSize="small"
        inputStyle="white-box"
        inputType="number"
        value={units === UnitType.Metric ? altitude : altitude * METERS_TO_FEET}
        disabled={units === UnitType.Imperial}
        onWheel={handleInputScroll}
        onBlur={onBlur}
        step={NUMBER_INPUT_STEP}
        min={formParameters.altitudeInput.min}
        max={formParameters.altitudeInput.max}
        onChange={handleChange}
        onKeyDownCapture={handleEnterPress}
        name="altitude"
        helperText={errors.altitude}
        // helperTextStyle={'left: -224px;'}
        className={`${errors.altitude ? 'is-invalid' : ''}`}
        data-testid="altitude-field"
      />
      <MinMax gridColumn={4}>
        <Text variant="paragraph_small">
          Min: {formParameters.altitudeInput.min}
        </Text>
        <Text variant="paragraph_small">
          Max: {formParameters.altitudeInput.max}
        </Text>
      </MinMax>
    </>
  );
};

export default AltitudeInput;
