// Styled Components
import { Text } from '@raptormaps/text';
import { Icon } from '@raptormaps/icon';
import { Row, Stack } from '@raptormaps/layout';
import theme from '@raptormaps/theme';

// Components
import { StaleTelemetryIcon } from './StaleTelemetryIcon';

// Telemetry
import { useDockDeviceTelemetryValue } from '@/shared/stores/TelemetryWebsocket/TelemetryWebsocketStore';
import { DockDeviceTelemetry } from '@/shared/types/telemetry';

// Constants
import { UNAVAILABLE_VALUE } from '@/shared/constants/deviceConstants';
import { TELEMETRY_KEY_TO_DISPLAY_CONFIG } from '@/shared/constants/telemetryConstants';
interface IconTelemetryProps {
  deviceSn: string;
}

export const RemainingImagesTelemetry = ({ deviceSn }: IconTelemetryProps) => {
  const uploadedFileCountKey: keyof DockDeviceTelemetry = 'uploadedFileCount';
  const expectedFileCountKey: keyof DockDeviceTelemetry = 'expectedFileCount';
  const { value: expectedFileCount } = useDockDeviceTelemetryValue({
    deviceSn,
    key: expectedFileCountKey,
    properties: { isStale: true, timestamp: true, lastMonitored: true },
  });

  const {
    value: uploadedFileCount,
    timestamp,
    isStale,
    lastMonitored,
  } = useDockDeviceTelemetryValue({
    deviceSn,
    key: uploadedFileCountKey,
    properties: { isStale: true, timestamp: true, lastMonitored: true },
  });

  const remainingImages =
    expectedFileCount === UNAVAILABLE_VALUE &&
    uploadedFileCount === UNAVAILABLE_VALUE
      ? UNAVAILABLE_VALUE
      : `${expectedFileCount}/${uploadedFileCount}`;

  const { toDisplay } = TELEMETRY_KEY_TO_DISPLAY_CONFIG[uploadedFileCountKey];

  return (
    <Row align="center" noWrap>
      {isStale ? (
        <StaleTelemetryIcon
          timestamp={timestamp}
          lastMonitored={lastMonitored}
        />
      ) : (
        <Icon icon="Image" size="large" color={theme.colors.black} />
      )}
      <Stack gutter="xxs">
        <Text variant="paragraph_small" align="left">
          Images Collected
        </Text>
        <Text variant="paragraph_large_bold" align="left">
          {remainingImages ? toDisplay(remainingImages) : UNAVAILABLE_VALUE}
        </Text>
      </Stack>
    </Row>
  );
};
