import { useAuth0 } from '@auth0/auth0-react';
import {
  BaseAPI,
  Configuration,
  ConfigurationParameters,
  FetchParams,
  RequestContext,
  Middleware,
  ResponseContext,
} from '@raptormaps/dji-dock-api-ts';

export interface AuthOptions {
  scope: string;
  audience: string;
  detailedResponse: boolean;
}

/* const DEFAULT_AUTH_OPTIONS: AuthOptions = {
  scope: '',
  audience: window.REACT_APP_AUTH0_CONFIG.audience,
  detailedResponse: false,
}; */

/**
 * useApi instantiates an API with middleware to add an Auth0
 * access token into the headers. API's must extend BaseAPI.
 * This version utilizes a newer typescript client generation
 * using an openapi tools action.
 */
export const useRoboticsHubApi = <T extends BaseAPI>(
  ctor: { new (params?: Configuration): T },
  configurationOptions: ConfigurationParameters = {},
  /* authOptions: AuthOptions = DEFAULT_AUTH_OPTIONS, */
): T => {
  // Expose the Auth0 SDK function for getting an access token
  const { getAccessTokenSilently } = useAuth0();

  // Access Token Middleware example
  class AccessTokenMiddleware implements Middleware {
    // Pre-request middleware
    async pre(context: RequestContext): Promise<FetchParams | void> {
      // Get an access token from the auth0 SDK - passes in options
      // provided in the useApi hook init
      const accessToken = await getAccessTokenSilently();

      // Returns the expected promise, embedding the JWT bearer token fetched properly before each call
      return Promise.resolve({
        url: context.url,
        init: {
          ...context.init,
          mode: 'cors',
          headers: new Headers({
            ...context.init.headers,
            authorization: `Bearer ${accessToken}`,
            'Access-Control-Allow-Origin': '*',
          }),
        },
      });
    }

    // This is the default post-request implementation
    async post(context: ResponseContext): Promise<Response | void> {
      return Promise.resolve(context.response);
    }
  }

  // Returns the api with the middleware configuration and input configuration
  // Configuration object is important and must always be created before creating an API -
  // this will define default headers, base URL, etc.
  const api: T = new ctor(
    new Configuration({
      ...configurationOptions,
      middleware: [new AccessTokenMiddleware()],
    }),
  );
  return api;
};
