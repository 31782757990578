// External Dependencies
import { useState } from 'react';
import { Text } from '@raptormaps/text';
import { Tabs } from '@raptormaps/tabs';
import { Row } from '@raptormaps/layout';
import { Button } from '@raptormaps/button';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { GrowthbookFlags } from '@/shared/utils/GrowthbookUtils';

// Components
import SpeedController from './components/SpeedController';
import CameraController from './components/CameraController/CameraController';
import { ParagraphWithTabs } from '@/pages/MissionPlanner/components/MissionPlanningSidebar/MissionPlanningSidebar.styles';

// Types
import { Settings } from '@/shared/types/missions.d';
import { FormParameters } from '@/shared/types/missions';

// Constants
import { SETTINGS_OPTIONS } from '@/pages/MissionPlanner/constants/missionConstants';
import { CAMERA_TYPE_TO_DISPLAY_NAME } from '@/shared/constants/missionLookups';

// Types
import {
  DroneType,
  CameraType,
  SpeedControlModeType,
} from '@raptormaps/raptor-flight-client-ts';
import { CombinedFlightModeType } from '@/shared/types/tempMissions';

interface SettingsSectionProps {
  sideOverlap: number;
  frontOverlap: number;
  device: DroneType;
  cameraType: CameraType;
  flightMode: CombinedFlightModeType;
  flightSpeed: number;
  transitSpeed?: number;
  cameraInterval: number;
  speedControlMode: SpeedControlModeType;
  formParameters: FormParameters;
  errors: {
    frontOverlap?: string;
    sideOverlap?: string;
    cameraInterval?: string;
    flightSpeed?: string;
    transitSpeed?: string;
  };
  setFieldValue: (field: string, value: unknown) => void;
  onBlur: () => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleEnterPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const SettingsSection = ({
  device,
  errors,
  sideOverlap,
  frontOverlap,
  flightMode,
  flightSpeed,
  speedControlMode,
  cameraInterval,
  cameraType,
  formParameters,
  handleChange,
  onBlur,
  handleEnterPress,
  transitSpeed,
  setFieldValue,
}: SettingsSectionProps) => {
  const [settings, setSetting] = useState<Settings>('Basic');

  const showCameraSelectWithZoom = useFeatureIsOn(
    GrowthbookFlags.CAMERA_SELECT_WITH_ZOOM,
  );
  return (
    <>
      <ParagraphWithTabs>
        <Text
          style={{ display: 'flex', gap: '1em' }}
          variant={'paragraph_small'}
        >
          Settings
        </Text>
        <Tabs
          handleClick={e => setSetting(SETTINGS_OPTIONS[e] as Settings)}
          items={SETTINGS_OPTIONS}
          activeIndex={SETTINGS_OPTIONS.indexOf(settings)}
        />
      </ParagraphWithTabs>
      {settings === SETTINGS_OPTIONS[0] && showCameraSelectWithZoom && (
        <>
          <Text style={{ marginBottom: '-10px' }} variant={'paragraph_small'}>
            Camera Type
          </Text>

          <Row align="center" justify="space-between" style={{ width: '100%' }}>
            {Object.values(CameraType).map(cameraTypeOption => (
              <Button
                key={cameraTypeOption}
                size="small"
                variant={
                  cameraType === cameraTypeOption ? 'primary' : 'secondary'
                }
                style={
                  cameraType === cameraTypeOption
                    ? { width: '45%', cursor: 'not-allowed' }
                    : { width: '45%' }
                }
                onClick={() => {
                  handleChange({
                    target: { name: 'cameraType', value: cameraTypeOption },
                  } as React.ChangeEvent<HTMLInputElement>);
                }}
              >
                {CAMERA_TYPE_TO_DISPLAY_NAME[cameraTypeOption]}
              </Button>
            ))}
          </Row>
        </>
      )}

      {settings === SETTINGS_OPTIONS[1] && (
        <>
          <CameraController
            device={device}
            errors={errors}
            sideOverlap={sideOverlap}
            frontOverlap={frontOverlap}
            cameraType={cameraType}
            formParameters={formParameters}
            handleChange={handleChange}
            onBlur={onBlur}
            handleEnterPress={handleEnterPress}
          />
          <SpeedController
            errors={errors}
            flightMode={flightMode}
            flightSpeed={flightSpeed}
            transitSpeed={transitSpeed}
            cameraInterval={cameraInterval}
            speedControlMode={speedControlMode}
            formParameters={formParameters}
            handleEnterPress={handleEnterPress}
            handleChange={handleChange}
            onBlur={onBlur}
            device={device}
            setFieldValue={setFieldValue}
          />
        </>
      )}
    </>
  );
};

export default SettingsSection;
