import {
  DOCK_SOURCE_ID,
  SELECTED_DOCK_SOURCE_ID,
  DRONE_SOURCE_ID,
  DRONE_DIRECTION_SOURCE_ID,
} from '../sources/DeviceSources';
import { LayerSpecification } from 'mapbox-gl';

const DOCK_LAYER_ID = 'dock-layer';
const SELECTED_DOCK_LAYER_ID = 'selected-dock-layer';
const DRONE_LAYER_ID = 'drone-layer';
const DRONE_DIRECTION_LAYER_ID = 'drone-direction-source';

const DroneLayer: LayerSpecification = {
  id: DRONE_LAYER_ID,
  type: 'symbol',
  source: DRONE_SOURCE_ID,
  layout: {
    'symbol-placement': 'point',
    'icon-image': 'drone-location',
    'icon-allow-overlap': true,
    'text-allow-overlap': true,
    visibility: 'visible',
  },
};

const DroneDirectionLayer: LayerSpecification = {
  id: DRONE_DIRECTION_LAYER_ID,
  type: 'symbol',
  source: DRONE_DIRECTION_SOURCE_ID,
  layout: {
    'symbol-placement': 'point',
    'icon-image': 'drone-direction-arrow',
    'icon-allow-overlap': true,
    'text-allow-overlap': true,
    'icon-offset': [0, -25],
    'icon-rotate': ['get', 'rotation'],
    visibility: 'visible',
  },
};

const SelectedDock: LayerSpecification = {
  id: SELECTED_DOCK_LAYER_ID,
  type: 'symbol',
  source: SELECTED_DOCK_SOURCE_ID,
  layout: {
    'symbol-placement': 'point',
    'icon-image': 'dock-location-selected',
    'icon-allow-overlap': true,
    'text-allow-overlap': true,
    visibility: 'visible',
  },
};

const Dock: LayerSpecification = {
  id: DOCK_LAYER_ID,
  type: 'symbol',
  source: DOCK_SOURCE_ID,
  layout: {
    'symbol-placement': 'point',
    'icon-image': 'dock-location',
    'icon-allow-overlap': true,
    'text-allow-overlap': true,
    visibility: 'visible',
  },
};

export default [DroneLayer, DroneDirectionLayer, SelectedDock, Dock];
