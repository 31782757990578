import { ThreeCircles } from 'react-loader-spinner';
import styled from 'styled-components';
import { Stack } from '@raptormaps/layout';
import { Text } from '@raptormaps/text';
import theme from '@raptormaps/theme';

interface SidebarLoaderProps {
  message: string;
}

const SidebarLoaderStack = styled(Stack)`
  margin-top: 30vh;
`;

export const SidebarLoader = ({ message }: SidebarLoaderProps) => {
  return (
    <SidebarLoaderStack align="center">
      <ThreeCircles
        height="80"
        width="80"
        color={theme.colors.primary_400}
        visible={true}
        ariaLabel="loading spinner"
      />
      <Text variant="paragraph_large_bold">{message}</Text>
    </SidebarLoaderStack>
  );
};
