import { Button, IconButton } from '@raptormaps/button';
import './ArchiveModal.css';

interface ArchiveModalProps {
  handleSubmit: () => void;
  handleClose: () => void;
}

const ArchiveModal = ({ handleSubmit, handleClose }: ArchiveModalProps) => {
  return (
    <>
      <div
        className="modal"
        onKeyDownCapture={e => {
          if (e.key === 'Enter') {
            handleSubmit();
          }
        }}
      >
        <div onClick={handleClose} className="overlay"></div>
        <div className="archive-modal-content">
          <div className="archive-modal-text">
            <div className="modal-heading">
              <h2>Archive Mission?</h2>
              <IconButton
                size="medium"
                onClick={handleClose}
                type={'button'}
                variant="tertiary"
                aria-label="close"
                icon="Xmark"
              />
            </div>
            <p>
              Archiving this mission will remove it from the mission library.
            </p>
          </div>
          <div className="modal-controls" style={{ marginTop: '0px' }}>
            <Button
              size="large"
              onClick={handleClose}
              type={'button'}
              variant="tertiary"
            >
              Cancel
            </Button>
            <Button
              icon="TrashCan"
              iconPosition="right"
              variant="error"
              size="large"
              onClick={handleSubmit}
            >
              Archive
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArchiveModal;
