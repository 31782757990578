// Styled Components
import { Tabs } from '@raptormaps/tabs';
import { FullRowTabs } from '@/pages/MissionPlanner/components/MissionPlanningSidebar/MissionPlanningSidebar.styles';
import {
  FormLabel,
  GridColumnOneSpanFour,
} from '@/pages/MissionPlanner/components/MissionPlanningSidebar/MissionPlanningSidebar.styles';

// Types
import { DroneType } from '@raptormaps/raptor-flight-client-ts';
import { DataCollectionType } from '@/shared/types/dataCollection';

// Constants
import {
  DATA_COLLECTION_TYPE_TO_DISPLAY,
  DISPLAY_NAME_TO_DATA_COLLECTION_TYPE,
} from '@/shared/constants/missionLookups';

interface DataCollctionProps {
  device: DroneType;
  collectionType: DataCollectionType;
  handleChangeCollectionType: (collectionType: DataCollectionType) => void;
}

const DataCollection = ({
  device,
  collectionType,
  handleChangeCollectionType,
}: DataCollctionProps) => {
  const dataCollectionOptions = Object.values(DataCollectionType)
    .filter(
      collectionType =>
        collectionType !== DataCollectionType.Object &&
        collectionType !== DataCollectionType.Waypoint,
    )
    .map(collectionType => {
      return DATA_COLLECTION_TYPE_TO_DISPLAY[collectionType];
    });

  if (device === DroneType.MannedAirplane) {
    return null;
  }

  return (
    <GridColumnOneSpanFour>
      <>
        <FormLabel variant="label_small">Data Collection Type</FormLabel>
        <FullRowTabs>
          <Tabs
            handleClick={(e: number) => {
              const displayedDataCollectionType = dataCollectionOptions[e];
              handleChangeCollectionType(
                DISPLAY_NAME_TO_DATA_COLLECTION_TYPE[
                  displayedDataCollectionType
                ],
              );
            }}
            items={[...dataCollectionOptions]}
            activeIndex={dataCollectionOptions.indexOf(
              DATA_COLLECTION_TYPE_TO_DISPLAY[collectionType],
            )}
          />
        </FullRowTabs>
      </>
    </GridColumnOneSpanFour>
  );
};

export default DataCollection;
