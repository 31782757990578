//external dependancies
import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  MissionsApi,
  MissionInput,
  MissionResponse,
  ResponseError,
  GetMissionsPaginatedResponse,
  MissionResponseLite,
} from '@raptormaps/raptor-flight-client-ts';

// local dependancies
import { useApi } from './useApi';
import { useAppContext } from '../context/AppContext';
import { DroneType, FlightModeType } from '@raptormaps/raptor-flight-client-ts';

export const GET_MISSIONS_PAGINATION_LIMIT = 20;

export const useGetMissions = (
  solarFarmId?: number,
  search = '',
  offset = 0,
  drone?: DroneType,
  mode?: FlightModeType,
  onError?: () => void,
) => {
  const { user } = useAppContext();
  const orgId = user.latest_org_id;
  const queryClient = useQueryClient();

  const api = useApi(MissionsApi, {
    basePath: window.REACT_APP_FLIGHT_API_ENDPOINT,
  });

  const queryKey = [
    'missions',
    orgId,
    solarFarmId,
    search,
    offset,
    drone,
    mode,
  ];
  const cachedData =
    queryClient.getQueryData<GetMissionsPaginatedResponse>(queryKey);

  const query = useQuery({
    queryKey,
    queryFn: async () => {
      if (!orgId || !solarFarmId) return null;
      return await api.getMissionsV1MissionsGet({
        solarFarmId,
        orgId,
        search,
        offset,
        order: 'desc',
        drone: drone ? [drone] : undefined,
        mode: mode ? [mode] : undefined,
        limit: GET_MISSIONS_PAGINATION_LIMIT,
      });
    },
    initialData: cachedData,
    enabled: !cachedData,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    onError: onError,
  });

  const cachedQueries =
    queryClient.getQueriesData<GetMissionsPaginatedResponse>([
      'missions',
      orgId,
      solarFarmId,
      search,
    ]);

  const missionSet = new Set<number>();
  const missions: MissionResponseLite[] = cachedQueries?.reduce(
    (acc, queryData) => {
      if (!queryData) return acc;
      const [key, data] = queryData;
      const offsetKey = key[4] as number;

      if (!data || offsetKey > offset) return acc;

      data.missions.forEach(mission => {
        if (!missionSet.has(mission.id)) {
          missionSet.add(mission.id);
          acc.push(mission);
        }
      });

      return [...acc];
    },
    [],
  );

  return {
    ...query,
    data: {
      missions,
      total: query?.data?.total,
    },
  };
};

export const useGetMission = (
  id?: number,
  solarFarmId?: number,
  onError?: () => void,
) => {
  const { user } = useAppContext();
  const orgId = user.latest_org_id;

  const api = useApi(MissionsApi, {
    basePath: window.REACT_APP_FLIGHT_API_ENDPOINT,
  });

  return useQuery({
    queryKey: ['mission', orgId, solarFarmId, id],
    queryFn: async () => {
      if (!orgId || !solarFarmId || !id) return null;
      const result = await api.getMissionByIdV1MissionsIdGet({
        id,
        solarFarmId,
        orgId,
      });
      return result;
    },
    retry(failureCount, error: ResponseError) {
      if ([403, 404].includes(error.response.status)) return false;
      return failureCount < 3;
    },
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    onError,
  });
};

export const useGetIsNameUnique = (
  missionName: string,
  solarFarmId: number,
) => {
  const { user } = useAppContext();
  const orgId = user.latest_org_id;

  const api = useApi(MissionsApi, {
    basePath: window.REACT_APP_FLIGHT_API_ENDPOINT,
  });

  return useQuery({
    queryKey: ['IsNameUnique', orgId, solarFarmId, missionName],
    queryFn: async () => {
      if (!orgId || !solarFarmId || !missionName) return { isUnique: true };
      const result = await api.getIsNameUniqueV1MissionsCheckUniqueGet({
        name: missionName,
        solarFarmId,
        orgId,
      });
      return result;
    },
    retry(failureCount, error: ResponseError) {
      if ([403, 404].includes(error.response.status)) return false;
      return failureCount < 3;
    },
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetUniqueName = (missionName: string, solarFarmId: number) => {
  const { user } = useAppContext();
  const orgId = user.latest_org_id;

  const api = useApi(MissionsApi, {
    basePath: window.REACT_APP_FLIGHT_API_ENDPOINT,
  });

  return useQuery({
    queryKey: ['UniqueName', orgId, solarFarmId, missionName],
    queryFn: async () => {
      if (!orgId || !solarFarmId || !missionName) return null;
      const result = await api.getUniqueNameV1MissionsUniqueNameGet({
        name: missionName,
        solarFarmId,
        orgId,
      });
      return result;
    },
    retry(failureCount, error: ResponseError) {
      if ([403, 404].includes(error.response.status)) return false;
      return failureCount < 3;
    },
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
};

export const useCreateMission = (solarFarmId: number) => {
  const { user } = useAppContext();
  const orgId = user.latest_org_id;
  const queryClient = useQueryClient();

  const api = useApi(MissionsApi, {
    basePath: window.REACT_APP_FLIGHT_API_ENDPOINT,
  });

  return useMutation<MissionResponse, ResponseError, MissionInput>({
    mutationFn: async (missionInput: MissionInput) => {
      return api.createMissionV1MissionsPost({
        orgId,
        solarFarmId,
        missionInput,
      });
    },
    onSuccess: result => {
      queryClient.invalidateQueries({
        queryKey: ['missions', orgId, solarFarmId],
      });
      queryClient.invalidateQueries({
        queryKey: ['UniqueName', orgId, solarFarmId, result.missionName],
      });
      queryClient.invalidateQueries({
        queryKey: ['IsNameUnique', orgId, solarFarmId, result.missionName],
      });
      queryClient.setQueryData(
        ['mission', orgId, solarFarmId, result.id],
        result,
      );
    },
  });
};

export const useUpdateMission = (missionId: number, solarFarmId: number) => {
  const { user } = useAppContext();
  const orgId = user.latest_org_id;
  const queryClient = useQueryClient();

  const api = useApi(MissionsApi, {
    basePath: window.REACT_APP_FLIGHT_API_ENDPOINT,
  });

  return useMutation<MissionResponse, ResponseError, MissionInput>({
    mutationFn: async (missionInput: MissionInput) => {
      return api.updateMissionV1MissionsIdPut({
        id: missionId,
        orgId,
        solarFarmId,
        missionInput,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['mission', orgId, solarFarmId, missionId],
      });
      queryClient.invalidateQueries({
        queryKey: ['missions', orgId, solarFarmId],
      });
      queryClient.invalidateQueries({
        queryKey: ['UniqueName', orgId, solarFarmId],
      });
      queryClient.invalidateQueries({
        queryKey: ['IsNameUnique', orgId, solarFarmId],
      });
    },
  });
};

export const useDeleteMission = (solarFarmId?: number) => {
  const { user } = useAppContext();
  const orgId = user.latest_org_id;
  const queryClient = useQueryClient();

  const api = useApi(MissionsApi, {
    basePath: window.REACT_APP_FLIGHT_API_ENDPOINT,
  });

  return useMutation<number, ResponseError, number>({
    mutationFn: async (missionId: number) => {
      return await api.deleteMissionByIdV1MissionsIdDelete({
        id: missionId,
        solarFarmId,
        orgId,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['mission', orgId, solarFarmId],
      });
      queryClient.invalidateQueries({
        queryKey: ['missions', orgId, solarFarmId],
      });
    },
  });
};
