// Types
import { DockDeviceTelemetry } from '../../types/telemetry';

export const DOCK_DEVICE_TELEMETRY_INITIAL_STATE: DockDeviceTelemetry = {
  bid: {
    value: null,
    timestamp: null,
    isStale: null,
    lastMonitored: null,
  },
  tid: {
    value: null,
    timestamp: null,
    isStale: null,
    lastMonitored: null,
  },
  aircraftBatteryPercent: {
    value: null,
    timestamp: null,
    isStale: null,
    lastMonitored: null,
  },
  aircraftChargeState: {
    value: null,
    timestamp: null,
    isStale: null,
    lastMonitored: null,
  },
  deviceSn: {
    value: null,
    timestamp: null,
    isStale: null,
    lastMonitored: null,
  },
  windspeed: {
    value: null,
    timestamp: null,
    isStale: null,
    lastMonitored: null,
  },
  rainfall: {
    value: null,
    timestamp: null,
    isStale: null,
    lastMonitored: null,
  },
  environmentTemperature: {
    value: null,
    timestamp: null,
    isStale: null,
    lastMonitored: null,
  },
  internetSpeed: {
    value: null,
    timestamp: null,
    isStale: null,
    lastMonitored: null,
  },
  aircraftDistanceFromDock: {
    value: null,
    timestamp: null,
    isStale: null,
    lastMonitored: null,
  },
  aircraftVerticalSpeed: {
    value: null,
    timestamp: null,
    isStale: null,
    lastMonitored: null,
  },
  aircraftHorizontalSpeed: {
    value: null,
    timestamp: null,
    isStale: null,
    lastMonitored: null,
  },
  aircraftState: {
    value: null,
    timestamp: null,
    isStale: null,
    lastMonitored: null,
  },
  altitudeRelativeTakeoff: {
    value: null,
    timestamp: null,
    isStale: null,
    lastMonitored: null,
  },
  altitudeRelativeTerrain: {
    value: null,
    timestamp: null,
    isStale: null,
    lastMonitored: null,
  },
  aircraftRoll: {
    value: null,
    timestamp: null,
    isStale: null,
    lastMonitored: null,
  },
  aircraftPitch: {
    value: null,
    timestamp: null,
    isStale: null,
    lastMonitored: null,
  },
  memoryCapacity: {
    value: null,
    timestamp: null,
    isStale: null,
    lastMonitored: null,
  },
  emergencyStopState: {
    value: null,
    timestamp: null,
    isStale: null,
    lastMonitored: null,
  },
  remainingFlightTime: {
    value: null,
    timestamp: null,
    isStale: null,
    lastMonitored: null,
  },
  cameraPayloadIndex: {
    value: null,
    timestamp: null,
    isStale: null,
    lastMonitored: null,
  },
  cameraGimbalPitch: {
    value: null,
    timestamp: null,
    isStale: null,
    lastMonitored: null,
  },
  cameraGimbalRoll: {
    value: null,
    timestamp: null,
    isStale: null,
    lastMonitored: null,
  },
  uploadedFileCount: {
    value: null,
    timestamp: null,
    isStale: null,
    lastMonitored: null,
  },
  expectedFileCount: {
    value: null,
    timestamp: null,
    isStale: null,
    lastMonitored: null,
  },
  longitude: {
    value: null,
    timestamp: null,
    isStale: null,
    lastMonitored: null,
  },
  latitude: {
    value: null,
    timestamp: null,
    isStale: null,
    lastMonitored: null,
  },
  coverState: {
    value: null,
    timestamp: null,
    isStale: null,
    lastMonitored: null,
  },
  liveCapacity: {
    value: null,
    timestamp: null,
    isStale: null,
    lastMonitored: null,
  },
  livestreamStatus: {
    value: null,
    timestamp: null,
    isStale: null,
    lastMonitored: null,
  },
  aircraftHeading: {
    value: null,
    timestamp: null,
    isStale: null,
    lastMonitored: null,
  },
};
