import styled from 'styled-components';
import { Stack, Row } from '@raptormaps/layout';
import { TextInput } from '@raptormaps/text-input';
import theme from '@raptormaps/theme';
import searchIcon from '../../assets/search.svg';

export const SiteListSidebar = styled(Stack)`
  padding: 30px 0px;
  height: 100vh;
  min-width: 496px;
  max-width: 496px;
  overflow-y: scroll;
  overflow-x: hidden;
  box-sizing: border-box;
  gap: 0;
  text-align: left;

  h2 {
    padding-left: 10px;
    margin: 0vh 5px 1.5vh 5px;
  }
`;

export const StyledSolarSiteSearch = styled(TextInput)`
  text-align: left;
  margin: 0vh 5px 1.5vh 5px;

  input {
    width: 384px;
    color: rgb(43, 43, 43);
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    letter-spacing: 0px;
    text-decoration: none;
    text-transform: none;
    border-radius: 4px;
    background: url(${searchIcon}) no-repeat 7px 10px;
    padding-left: 30px;
    background-color: #f8f8f8;
  }
`;

export const SolarSiteCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  min-height: 140px;
  max-height: 140px;
  border-bottom: 1px solid ${theme.colors.neutral_200};
  border-top: 1px solid ${theme.colors.neutral_200};
  position: relative;
  width: 100%;
  cursor: pointer;
`;

export const SolarSiteCardContent = styled(Stack)`
  height: 100%;
  size: 13px;
  weight: 400;
  line-height: 20px;
  color: ${theme.colors.neutral_800};
  width: calc(99% - 5vw);
  gap: 0px;

  > div {
    margin-left: 0.5vw;
  }
`;

export const HighlightText = styled.span<{
  $fillcolor?: string;
  $textColor?: string;
}>`
  background-color: ${props => props.$fillcolor};
  border-radius: 4px;
  padding: 0 4px 0 4px;
  color: ${props =>
    props.$textColor ? props.$textColor : theme.colors.neutral_800};
`;

export const SolarSiteCardHeadingRow = styled(Row)`
  justify-content: space-between;
  padding: 5px 10px 5px 10px;
  text-align: left;

  p,
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    flex: 1;
    max-width: 13%;
  }

  span {
    margin-bottom: 0;
    width: 90%;
    font-size: 1.17em;
  }
`;

export const SolarSiteCardRow = styled(Row)`
  display: inline;
  text-align: left;
  width: 90%;
  padding: 5px 10px 10px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SolarSiteCardStatsRow = styled(Row)`
  padding: 10px 5px;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  white-space: nowrap;

  span {
    display: inline-flex;
    margin: 0;

    b {
      margin-right: 0.25vw;
    }
  }
`;

export const SelectSolarSiteButton = styled.button`
  height: 100%;
  width: 70px !important;
  border: none;
  background-color: ${theme.colors.neutral_100};
  position: absolute;
  right: 0;
  cursor: pointer;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;
