// Styled Components
import styled from 'styled-components';

// RaptorUi
import { Text } from '@raptormaps/text';
import { Row } from '@raptormaps/layout';
import theme from '@raptormaps/theme';

export const StyledSliderRow = styled(Row)`
  ${props =>
    props.disabled &&
    `* {
      color: ${theme.colors.grey_400};
    }`};
`;

export const SliderLabelContainer = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 120px;
  text-align: left;
`;

export const SliderContainer = styled.div`
  width: 212px;
`;
