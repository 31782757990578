import { useQuery, useQueryClient } from 'react-query';

import {
  DolbyDevicesApi,
  DolbyDeviceResponse,
} from '@raptormaps/raptor-flight-client-ts';
import { useApi } from './useApi';
import { useAppContext } from '../context/AppContext';

export const useDolby = () => {
  const useSubscribeToDolbyStream = (
    videoRef: HTMLVideoElement,
    enabled,
    onError: () => void,
  ) => {
    return useQuery({
      queryKey: 'useSubscribeToDolbyStream',
      queryFn: async () => {
        // TODO: this is a placeholder function used in the Livestream Mock component
        // This will be refactored in SC-80264
        // return await connectToDolbyStream(videoRef, onError);
      },
      enabled,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onError,
    });
  };

  return {
    useSubscribeToDolbyStream,
  };
};

interface GetDolbyCredentialsParams {
  solarFarmId: number;
  deviceId: number;
  enabled?: boolean;
}

export const useGetDolbyCredentials = ({
  solarFarmId,
  deviceId,
  enabled = true,
}: GetDolbyCredentialsParams) => {
  const { user } = useAppContext();
  const orgId = user.latest_org_id;
  const queryClient = useQueryClient();

  const api = useApi(DolbyDevicesApi, {
    basePath: window.REACT_APP_FLIGHT_API_ENDPOINT,
  });

  const cachedData = queryClient.getQueryData<DolbyDeviceResponse>([
    'dolbyDeviceCredentials',
    orgId,
    solarFarmId,
    deviceId,
  ]);

  return useQuery({
    queryKey: ['dolbyDeviceCredentials', orgId, solarFarmId, deviceId],
    queryFn: async () => {
      if (!orgId || !solarFarmId) return null;
      return await api.getDolbyDeviceCredentialsV1DolbyDevicesDeviceIdGet({
        solarFarmId,
        orgId,
        deviceId,
      });
    },
    initialData: cachedData,
    enabled,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
};
