import { Layers } from 'mapbox-gl';
import theme from '@raptormaps/theme';
import { STRING_SOURCE_ID } from '../sources/StringSources';

export const STRING_LAYER_ID = 'string-layer';

const StringLayer: Layers = [
  {
    id: STRING_LAYER_ID,
    type: 'line',
    source: STRING_SOURCE_ID,
    minzoom: 18,
    paint: {
      'line-color': theme.colors.blue_500,
      'line-width': 1.5,
      'line-opacity': 1,
    },
  },
];

const StringLayers: Layers = [...StringLayer];

export default StringLayers;
