import { FormParameters } from '../../../shared/types/missions';

export const droneFormParameters: FormParameters = {
  altitudeInput: {
    min: 0.5,
    max: 400,
  },
  safeTakeoffAltitudeInput: {
    min: 0,
    max: 400,
  },
  altitudeOffset: {
    min: -50,
    max: 400,
  },
  frontOverlapInput: {
    min: -10,
    max: 0.9,
  },
  sideOverlapInput: {
    min: -10,
    max: 0.9,
  },
  pitchAngleInput: {
    min: -90,
    max: 23,
  },
  flightAngleInput: {
    min: 0,
    max: 360,
  },
  flightSpeedInput: {
    min: 0,
    max: 50,
  },
  cameraIntervalInput: {
    min: 0.1,
    max: 50,
  },
};

export const mannedFormParameters: FormParameters = {
  altitudeInput: {
    min: 0.5,
    max: 3048,
  },
  safeTakeoffAltitudeInput: {
    min: 0,
    max: 400,
  },
  altitudeOffset: {
    min: 0,
    max: 400,
  },
  frontOverlapInput: {
    min: -10,
    max: 0.9,
  },
  sideOverlapInput: {
    min: -10,
    max: 0.9,
  },
  pitchAngleInput: {
    min: -90,
    max: 23,
  },
  flightAngleInput: {
    min: 0,
    max: 360,
  },
  flightSpeedInput: {
    min: 0,
    max: 152.4,
  },
  cameraIntervalInput: {
    min: 0.1,
    max: 50,
  },
};
