// Types
import { DockDeviceTelemetry } from '@/shared/types/telemetry';
import { DJI_MEMORY_CAPACITY_TYPE } from '@/shared/constants/djiTelemetryConstants';
import { IconProps } from '@raptormaps/icon';

// Utils
import {
  coordinateConverter,
  degreesCelsiusConverter,
  kbPerSecConverter,
  metersConverter,
  metersPerSecConverter,
  batteryPercentConverter,
  toNAConverter,
  toUnknownConverter,
  memoryStorageConverter,
  secondsToMinutesAndSecondsConverter,
  degreesConverter,
  countConverter,
  toNullConverter,
} from '@/shared/utils/telemetryUtils';

interface TelemetryDisplayConfiguration {
  toDisplay: (
    value: number | string | null | DJI_MEMORY_CAPACITY_TYPE,
  ) => string;
  title: string;
  iconName?: IconProps['icon'];
  iconTextName?: string;
}

export const TELEMETRY_KEY_TO_DISPLAY_CONFIG: {
  [key in keyof DockDeviceTelemetry]: TelemetryDisplayConfiguration;
} = {
  bid: {
    title: '',
    toDisplay: toNAConverter,
  },
  tid: {
    title: '',
    toDisplay: toNAConverter,
  },
  aircraftBatteryPercent: {
    title: 'Battery Level',
    toDisplay: batteryPercentConverter,
    iconName: 'BatteryThreeQuarters',
  },
  aircraftChargeState: {
    title: '',
    toDisplay: toUnknownConverter,
  },
  deviceSn: {
    title: '',
    toDisplay: toNAConverter,
  },
  windspeed: {
    title: 'Wind Speed',
    toDisplay: metersPerSecConverter,
    iconName: 'Wind',
  },
  rainfall: {
    title: 'Rainfall',
    toDisplay: toNAConverter,
    iconName: 'SunCloud',
  },
  environmentTemperature: {
    title: 'Temperature',
    toDisplay: degreesCelsiusConverter,
    iconName: 'TemperatureHigh',
  },
  internetSpeed: {
    title: 'Internet Speed',
    toDisplay: kbPerSecConverter,
    iconName: 'Wifi',
  },
  aircraftDistanceFromDock: {
    title: 'Distance to Dock',
    toDisplay: metersConverter,
    iconName: 'House',
  },
  aircraftVerticalSpeed: {
    title: 'Vertical Speed',
    toDisplay: metersPerSecConverter,
    iconTextName: 'V.S',
  },
  aircraftHorizontalSpeed: {
    title: 'Horizontal Speed',
    toDisplay: metersPerSecConverter,
    iconTextName: 'H.S',
  },
  aircraftState: {
    title: '',
    toDisplay: toNAConverter,
  },
  altitudeRelativeTakeoff: {
    title: 'Altitude Relative to Takeoff',
    toDisplay: metersConverter,
    iconTextName: 'ALT',
  },
  altitudeRelativeTerrain: {
    title: 'Altitude Relative to Terrain',
    toDisplay: metersConverter,
    iconTextName: 'T.ALT',
  },
  aircraftRoll: {
    title: 'Aircraft Roll',
    toDisplay: degreesConverter,
    iconTextName: 'A.R',
  },
  aircraftPitch: {
    title: 'Aircraft Pitch',
    toDisplay: degreesConverter,
    iconTextName: 'A.P',
  },
  memoryCapacity: {
    title: 'Memory Useage',
    toDisplay: memoryStorageConverter,
    iconName: 'SdCard',
  },
  emergencyStopState: {
    title: '',
    toDisplay: toUnknownConverter,
  },
  remainingFlightTime: {
    title: 'Time Remaining',
    toDisplay: secondsToMinutesAndSecondsConverter,
    iconName: 'Clock',
  },
  cameraPayloadIndex: {
    title: '',
    toDisplay: toNAConverter,
  },
  cameraGimbalPitch: {
    title: 'Camera Pitch',
    toDisplay: degreesConverter,
    iconTextName: 'C.P',
  },
  cameraGimbalRoll: {
    title: 'Camera Roll',
    toDisplay: degreesConverter,
    iconTextName: 'C.R',
  },
  uploadedFileCount: {
    title: '',
    toDisplay: countConverter,
  },
  expectedFileCount: {
    title: '',
    toDisplay: countConverter,
  },
  longitude: {
    title: '',
    toDisplay: coordinateConverter,
  },
  latitude: {
    title: '',
    toDisplay: coordinateConverter,
  },
  coverState: {
    title: '',
    toDisplay: toUnknownConverter,
  },
  liveCapacity: {
    title: '',
    toDisplay: toNullConverter,
  },
  livestreamStatus: {
    title: '',
    toDisplay: toNullConverter,
  },
  aircraftHeading: {
    title: '',
    toDisplay: degreesConverter,
  },
};
