import mixpanel from 'mixpanel-browser';

const hasApiKey = Boolean(window.REACT_APP_MIXPANEL_API_KEY);

// Initialize Mixpanel if we have an API key
if (hasApiKey) {
  mixpanel.init(window.REACT_APP_MIXPANEL_API_KEY);
}

const Mixpanel = {
  identify: (id: string) => {
    if (hasApiKey) mixpanel.identify(id);
  },
  alias: (id: string) => {
    if (hasApiKey) mixpanel.alias(id);
  },
  track: (name: string, props?: object) => {
    if (hasApiKey) mixpanel.track(name, props);
  },
  people: {
    set: (props: object) => {
      if (hasApiKey) mixpanel.people.set(props);
    },
  },
};

export default Mixpanel;
