// RaptorUI
import {
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalTitle,
} from '@raptormaps/modal';
import { Button } from '@raptormaps/button';
import { Stack } from '@raptormaps/layout';
import { Text } from '@raptormaps/text';
// Internal Dependancies
import { ModalButtonContainer } from './ArchiveModal.styles';
import { ModalProps } from '@/shared/types/page.d';

export const ArchiveModal = ({
  open,
  onClose,
  handleCancel,
  handleConfirm,
  title,
  description,
  confirmText,
  closeText,
}: ModalProps) => {
  return (
    <Modal open={open} onClose={onClose}>
      <ModalContent>
        <ModalCloseButton />
        <Stack gutter="l">
          <ModalTitle>{title}</ModalTitle>
          <Text>{description}</Text>
          <ModalButtonContainer justify="end" gutter="xxl">
            <Button
              size="large"
              onClick={handleCancel}
              type={'button'}
              variant="tertiary"
            >
              {closeText}
            </Button>
            <Button
              icon="TrashCan"
              iconPosition="right"
              variant="error"
              size="large"
              onClick={handleConfirm}
            >
              {confirmText}
            </Button>
          </ModalButtonContainer>
        </Stack>
      </ModalContent>
    </Modal>
  );
};
