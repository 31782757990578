import { Dispatch, SetStateAction, ReactNode } from 'react';
import { Feature, Polygon, FeatureCollection, Point } from '@turf/helpers';
import mapboxgl from 'mapbox-gl';
import {
  DroneType,
  CameraAngleMode,
  CameraType,
  FeatureCollectionInput,
  OverlapType,
  SpeedControlModeType,
  UnitType,
  MissionResponse,
  MissionInput,
  SolarFarmResponse,
} from '@raptormaps/raptor-flight-client-ts';
import { CombinedFlightModeType } from './tempMissions';
import { DataCollectionType } from './dataCollection';

export interface Org {
  api_phone: string | null;
  class_name: string | null;
  created_tsecs: number | null;
  id: number;
  logo_file_id: number | null;
  master: object;
  master_id: number;
  name: string;
  paid: boolean;
  permittance: object;
  type: string;
  updated_tsecs: number | null;
  users: [object];
  uuid: string | null;
}

export type WaypointTurnModes =
  | 'coordinateTurn'
  | 'toPointAndStopWithDiscontinuityCurvature'
  | 'toPointAndStopWithContinuityCurvature'
  | 'toPointAndPassWithContinuityCurvature';

export type WaypointHeadingMode = 'manually' | 'followWayline';

export type ActionTriggerType = 'multipleTiming' | 'multipleDistance';

export type User = {
  id: number;
  email: string;
  orgs: Org[];
  active: boolean;
  latest_org_id: number;
  name: string;
  role: string;
  roles: [string];
  token: string;
};

export type FileType = 'KMZ' | 'KML';

export interface MapContextValue {
  center: PointType;
  currentLayers: [];
  zoom: number;
  initialized: boolean;
}

export interface MapContextProviderProps {
  children: ReactNode;
}

export interface MissionFileType {
  mission: MissionType;
  version: string;
  waypoints: FeatureCollectionInput;
}

export type DroneCameraTypeDefaults = {
  [key in DroneType]: CameraTypeDefaults;
};

export type DroneTransitSpeedDefaults = {
  [DroneType.M30t]: TransitSpeedDefaults;
  [DroneType.M3t]: TransitSpeedDefaults;
  [DroneType.M30tDock]: TransitSpeedDefaults;
  [DroneType.M3tdDock]: TransitSpeedDefaults;
};

interface DroneCameraDefaultFOV {
  fieldOfViewHorizontal: number;
  fieldOfViewVertical: number;
}
type CameraTypeDefaults = {
  [key in CameraType]: Partial<DroneCameraDefaultFOV>;
};

type TransitSpeedDefaults = {
  default: number;
  min: number;
  max: number;
};

export interface MissionType {
  // primarily the form
  // all units saved in metric / SI
  missionName?: string;
  altitude: number; // meters
  safeTakeoffAltitude?: number; // meters
  altitudeOffset?: number; // meters - added v2.1.0 optional
  altitudeOffsetBool?: boolean; // added v2.1.0 optional
  cameraAngle: number; // -180 to 180 deg (with 0 being horizon, -90 being nadir)
  cameraInterval: number; // seconds
  cameraAngleMode: CameraAngleMode; // manual or follow_tracker
  drone: DroneType;
  flightAngle: number; // 0 to 360 deg (heading)
  flightMap: Feature<Polygon>; // polygon or perimeter
  flightMode: CombinedFlightModeType;
  flightSpeed: number; // meters/second
  frontOverlap: number;
  frontSpacing: number;
  mapCenter: PointType;
  mapZoom: number; // 0 to 23?
  overlapMode: OverlapType;
  sensor: SensorType; // currently CameraType
  sideOverlap: number;
  sideSpacing: number;
  solarFarm: SolarFarmType;
  speedControlMode: SpeedControlModeType;
  takeOffPointCenter: PointType | null; //
  terrainFollowBool: boolean;
  userUnits: UnitType; //imperial or metric
  createdTsecs?: number;
  updatedTsecs?: number;
  updatorId?: number;
  orgId?: number;
  transitSpeed?: number;
}

export interface PointType {
  lat: number;
  lng: number;
}

export interface SensorType {
  fieldOfViewWidth: number; // currently fov horizontal
  fieldOfViewHeight: number; // currently fov vertical
  id?: number; // from database
  name: CameraType; // 'M3T RGB Wide'
}

export interface SolarFarmType {
  // look similar to plant-labeler solar_farm table
  geom?: PointType;
  id?: number;
  name: string;
}

export interface CalibrationObject {
  active: boolean;
  rhumbBearing: number;
  rhumbDistance: number;
  nonCalibratedFlightPath?: Feature;
}

export interface IntervalometerObject {
  intervalometerBool: boolean;
  waypointReductionBool: boolean;
  actionTriggerParam: number;
}

export interface AltitudeOffsetObject {
  offset: number;
  active: boolean;
}

export interface IntervalModeObject {
  active: boolean;
  actionTriggerType: ActionTriggerType;
  actionTriggerParam: number; // seconds or meters depending on trigger type
}

// anything not defined in SolarFarm.ts is given a type of undefined
export interface SolarFarm {
  accessCode?: string;
  address?: string;
  clientOrgId?: number;
  commissioningTsecs?: number;
  createdTsecs?: number;
  creatorId?: number;
  features?: undefined;
  id: number;
  inspections?: undefined;
  latitude: number;
  longitude: number;
  moduleLayout?: string;
  moduleMount?: string;
  name: string;
  orgId?: number;
  permittance?: undefined;
  rowChildProcessStatus?: undefined;
  siteLayout?: string;
  size?: number;
  status?: number;
  tileMaps?: undefined;
  updatedTsecs?: number;
  uuid?: string;
  version?: undefined;
}

export interface AreaMissionFormikValues {
  cameraType: CameraType;
  userUnits: UnitType;
  altitude: number;
  altitudeOffset: number;
  altitudeOffsetBool: boolean;
  safeTakeoffAltitude?: number;
  safeTakeoffAltitudeBool: boolean;
  frontOverlap: number;
  sideOverlap: number;
  cameraAngle: number;
  flightAngle: number;
  speedControlMode: SpeedControlModeType;
  cameraInterval: number;
  flightSpeed: number;
  transitSpeed: number;
  terrainFollowBool: boolean;
  takeOffPointCenter?: number[];
  flightMode: CombinedFlightModeType;
  cameraAngleMode: CameraAngleMode;
  startTime: string;
}

export interface LinearMissionFormikValues {
  cameraType: CameraType;
  userUnits: UnitType;
  altitude: number;
  altitudeOffset: number;
  altitudeOffsetBool: boolean;
  safeTakeoffAltitude?: number;
  safeTakeoffAltitudeBool: boolean;
  frontOverlap: number;
  sideOverlap: number;
  cameraAngle: number;
  flightAngle: number;
  speedControlMode: SpeedControlModeType;
  cameraInterval: number;
  flightSpeed: number;
  transitSpeed: number;
  terrainFollowBool: boolean;
  takeOffPointCenter?: number[];
  flightMode: CombinedFlightModeType;
  cameraAngleMode: CameraAngleMode;
  startTime: string;
}

export interface MannedAirplaneFormikValues {
  cameraType: CameraType;
  userUnits: UnitType;
  altitude: number;
  frontOverlap: number;
  sideOverlap: number;
  cameraAngle: number;
  flightAngle: number;
  speedControlMode: SpeedControlModeType;
  cameraInterval: number;
  flightSpeed: number;
  flightMode: CombinedFlightModeType;
  cameraAngleMode: CameraAngleMode;
}

export interface FormParameters {
  altitudeInput: {
    min: number;
    max: number;
  };
  safeTakeoffAltitudeInput: {
    min: number;
    max: number;
  };
  altitudeOffset: {
    min: number;
    max: number;
  };
  frontOverlapInput: {
    min: number;
    max: number;
  };
  sideOverlapInput: {
    min: number;
    max: number;
  };
  pitchAngleInput: {
    min: number;
    max: number;
  };
  flightAngleInput: {
    min: number;
    max: number;
  };
  flightSpeedInput: {
    min: number;
    max: number;
  };
  cameraIntervalInput: {
    min: number;
    max: number;
  };
}

type FlightModeDefaults = {
  [key in CombinedFlightModeType]: {
    altitude?: number;
    cameraAngle?: number;
    cameraAngleMode: CameraAngleMode;
    cameraInterval?: number;
    cameraType?: CameraType;
    flightSpeed?: number;
    frontOverlap?: number;
    sideOverlap?: number;
    speedControlMode?: SpeedControlModeType;
  };
};

type FlightModeToWaypointTurnMode = {
  [key in CombinedFlightModeType]: WaypointTurnModes;
};

export type Settings = 'Basic' | 'Advanced';

export type DataCollectionTypeToFlightMode = {
  [key in DataCollectionType]: CombinedFlightModeType[];
};

export interface GeospatialObject {
  waypoints: FeatureCollection<Point>;
  markerLngLat: PointType;
  flightPath: Feature;
  polygon: Feature<Polygon>;
}

export interface MissionPlanningFormProps {
  ref: React.RefObject<HTMLDivElement>;
  map: mapboxgl.Map;
  device: DroneType;
  initialMission: MissionFileType;
  geospatial: GeospatialObject;
  calibration: CalibrationObject;
  collectionType: DataCollectionType;
  setCalibration: Dispatch<SetStateAction<CalibrationObject>>;
  setGeospatial: Dispatch<SetStateAction<GeospatialObject>>;
  handleAddTakeoffPoint: (point?: PointType) => void;
  handleRemoveTakeoffPoint: () => void;
  setFormModified: Dispatch<SetStateAction<boolean>>;
  setSaveDisabled: Dispatch<SetStateAction<boolean>>;
  setDownloadDisabled: Dispatch<SetStateAction<boolean>>;
  handleCreateMission: (mission: MissionInput) => Promise<MissionResponse>;
  handleUpdateMission: (mission: MissionInput) => Promise<MissionResponse>;
}

export type MissionPlanningFormElement = React.ForwardRefExoticComponent<
  React.PropsWithoutRef<MissionPlanningFormProps> &
    React.RefAttributes<MissionPlanningFormHandle>
>;

export interface MissionPlanningFormHandle {
  generateMissionInput: (missionName: string) => MissionInput;
  handleDownload: (
    filename: string,
    input: MissionInput,
    solarFarm: SolarFarmResponse,
  ) => void;
}

export enum WaypointNames {
  safeTakeoffAltitude = 'Safe Takeoff Altitude',
  relativeAltitudePoint = 'Relative Altitude Point',
}
