// RaptorUI
import { Checkbox } from '@raptormaps/checkbox';
import { CheckboxVariant } from '@raptormaps/checkbox/build/Checkbox.interface';
import { Icon } from '@raptormaps/icon';
import { Text } from '@raptormaps/text';
import { InlineIconToggle } from '@/pages/MissionPlanner/components/MissionPlanningSidebar/MissionPlanningSidebar.styles';
import { Tooltip } from '@raptormaps/tooltip';
import { Stack, Row } from '@raptormaps/layout';
import theme from '@raptormaps/theme';

// Internal dependancies
import { FullWidthRow } from '@/shared/styles/sidebar.styles';
import { WarningBannerStack } from '../MissionParameters.styles';
import {
  GeospatialObject,
  IntervalometerObject,
} from '@/shared/types/missions.d';
import './WaypointReduction.css';

interface WaypointReductionSectionProps {
  geospatial: GeospatialObject;
  intervalometer: IntervalometerObject;
  setIntervalometer: React.Dispatch<React.SetStateAction<IntervalometerObject>>;
}

const WAYPOINT_WARNING_THRESHOLD = 300;

const WaypointReductionSection = ({
  geospatial,
  intervalometer,
  setIntervalometer,
}: WaypointReductionSectionProps) => {
  return (
    <>
      <InlineIconToggle>
        <Text variant={'paragraph_medium_bold'}>
          <Icon icon="CameraRotate" size={'large'} />
          Intervalometer
          <Tooltip
            colorVariant="dark"
            delay={100}
            placement={{
              align: 'center',
              side: 'right',
            }}
            tip="Intervalometer changes the camera trigger from “at each waypoint” to “at a set distance”."
            shouldWrapChildren={true}
          >
            <Icon
              aria-label="Altitude Offset Information"
              icon="CircleInfo"
              size={'medium'}
            />
          </Tooltip>
        </Text>
        <Checkbox
          data-testid="intervalometerBool"
          name="intervalometerBool"
          checked={intervalometer.intervalometerBool}
          onChange={e => {
            const intervalometerBool = e.target.checked;
            if (intervalometerBool) {
              setIntervalometer({
                ...intervalometer,
                intervalometerBool: true,
              });
            } else {
              setIntervalometer({
                ...intervalometer,
                intervalometerBool: false,
                waypointReductionBool: false,
              });
            }
          }}
          variant={CheckboxVariant.TOGGLE}
        />
      </InlineIconToggle>

      <InlineIconToggle disabled={!intervalometer.intervalometerBool}>
        <Text variant={'paragraph_medium_bold'}>
          <Icon icon="RectangleTerminal" size={'large'} />
          Waypoint Optimization
          <Tooltip
            colorVariant="dark"
            delay={100}
            placement={{
              align: 'center',
              side: 'right',
            }}
            tip={
              <Stack gutter={'none'}>
                <Text variant={'paragraph_small'} color={theme.colors.white}>
                  Waypoint optimization reduces the total amount of waypoints
                  exported when downloading a mission.
                </Text>
                <Text variant={'paragraph_small'} color={theme.colors.white}>
                  ATTN (1): Requires Intervalometer to be enabled.
                </Text>
                <Text variant={'paragraph_small'} color={theme.colors.white}>
                  ATTN (2): This feature may reduce the precision of terrain
                  follow.
                </Text>
              </Stack>
            }
            shouldWrapChildren={true}
          >
            <Icon
              aria-label="Altitude Offset Information"
              icon="CircleInfo"
              size={'medium'}
            />
          </Tooltip>
        </Text>
        <Checkbox
          data-testid="waypointReductionBool"
          name="waypointReductionBool"
          disabled={!intervalometer.intervalometerBool}
          checked={intervalometer.waypointReductionBool}
          onChange={e => {
            setIntervalometer({
              ...intervalometer,
              waypointReductionBool: e.target.checked,
            });
          }}
          variant={CheckboxVariant.TOGGLE}
        />
      </InlineIconToggle>

      <FullWidthRow justify="space-between">
        <Text>Waypoint count </Text>
        <Text>{geospatial.waypoints?.features.length || 0}</Text>
      </FullWidthRow>

      {geospatial.waypoints?.features.length > WAYPOINT_WARNING_THRESHOLD && (
        <WarningBannerStack align="start">
          <Row gutter="xs" style={{ marginBottom: '-15px' }}>
            <Icon
              className="icon-label-icon"
              icon="TriangleExclamation"
              size="large"
              color={theme.colors.yellow_500}
            />
            <Text variant="paragraph_small_bold">Elevated Waypoint Count!</Text>
          </Row>
          <Text
            align="left"
            variant="paragraph_small"
            style={{ padding: '0 10px 5px 35px' }}
          >
            This may result in reduced performance in other flight software,
            consider optimizing the waypoint count to ensure smooth operation.
          </Text>
        </WarningBannerStack>
      )}
    </>
  );
};

export default WaypointReductionSection;
