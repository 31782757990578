import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react';
import { MissionFileType, Org } from '../types/missions';
import { User } from '../types/missions';
import { datadogRum } from '@datadog/browser-rum';
import { useEnterApp } from '../hooks/useEnterApp';
import { ResponseError } from '@raptormaps/raptor-flight-client-ts';
import { appcuesInitializeUser } from '../../appcues';

export const AppContext = createContext<AppContextValue | null>(null);
AppContext.displayName = 'AppContext';

interface AppContextValue {
  flightMission?: MissionFileType;
  user: User;
  orgs: Org[];
  error: ResponseError;
  activeOrg?: Org;
  setFlightMission: (flightMission: MissionFileType) => void;
  clearMission: () => void;
}

export const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const [flightMission, setFlightMission] = useState<MissionFileType>(null);

  const { data, error } = useEnterApp();
  const { user, orgs, activeOrg } = data || {};

  useEffect(() => {
    if (!user) return;
    datadogRum.setGlobalContext({
      user_email: user.email,
      user_id: user.id,
      roles: user.roles,
      username: user.name,
      latest_org_id: user.latest_org_id,
    });
    // Passes user ID to Appcues
    appcuesInitializeUser(user.id);
  }, [user]);

  const clearMission = () => {
    setFlightMission(null);
  };

  return (
    <AppContext.Provider
      value={{
        flightMission,
        user,
        activeOrg,
        orgs,
        error,
        setFlightMission,
        clearMission,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = (): AppContextValue => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('Cannot access AppContext outside of AppContext.Provider');
  }
  return context;
};

export default AppContextProvider;
