// External dependancies
import { ThreeCircles } from 'react-loader-spinner';
import { Row, Gutter } from '@raptormaps/layout';
import theme from '@raptormaps/theme';

// Components
import { InputHelperText } from './MissionName/missionNameInput.styles';

interface UniqueNameValidationMessageProps {
  isFetching: boolean;
  errorText: string;
}
const UniqueNameValidationMessage = ({
  isFetching,
  errorText,
}: UniqueNameValidationMessageProps) => {
  return isFetching ? (
    <Row gutter={Gutter.S}>
      <div style={{ marginTop: '-13px' }}>
        <ThreeCircles
          height="15"
          width="15"
          color={theme.colors.primary_400}
          visible={true}
          ariaLabel="loading spinner"
        />
      </div>
      <InputHelperText>Validating mission name...</InputHelperText>
    </Row>
  ) : (
    <InputHelperText color={theme.colors.red_400}>{errorText}</InputHelperText>
  );
};

export default UniqueNameValidationMessage;
