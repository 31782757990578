import { Fragment } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import logo from '../../../assets/rm_logo_sm.png';
import { Icon } from '@raptormaps/icon';
import { Button } from '@raptormaps/button';
import { useAppContext } from '../../context/AppContext';
import { useUpdateLatestOrg } from '../../hooks/useOrgs';
import { AccountOrgSwitcher } from './components/AccountOrgSwitcher/AccountOrgSwitcher';
import { useLocation } from 'react-router-dom';
import { useToast } from '@raptormaps/toast';
import './SideNav.css';
import { useUrlSearchParams } from '../../hooks/useUrlParams';
import { useRoutes } from '../../hooks/useRoutes';
function SideNav() {
  const toast = useToast();
  const { logout } = useAuth0();
  const { user, orgs } = useAppContext();
  const location = useLocation();
  const navigate = useNavigate();
  const solarFarmId = useUrlSearchParams<number>('solar_farm_id', 'number');
  const updateLatestOrg = useUpdateLatestOrg();
  const routes = useRoutes({ solarFarmId });

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };
  const currentBasePathname = location.pathname.split('/')[1];
  const navLinks = routes.filter(route => route.tabDisplay);

  // Don't display Nav sidebar when viewing live stream
  if (location.pathname.includes('live-stream')) {
    return null;
  }

  return (
    <div className="SideNav">
      <div className="side-nav-headers">
        <div className="side-nav-space">
          <img className="rm-logo" src={logo} alt="Raptor Maps Logo" />
        </div>
        {navLinks.map(({ tabDisplay }) => (
          <Fragment key={tabDisplay.basePath}>
            <NavLink to={`/${tabDisplay.basePath}`}>
              <Button
                disabled={tabDisplay.basePath.includes(currentBasePathname)}
              >
                <Icon icon={tabDisplay.icon} size="medium" />
              </Button>
            </NavLink>
            {tabDisplay.label === 'Exit' ? (
              <div className="side-nav-space"></div>
            ) : null}
          </Fragment>
        ))}
      </div>
      <div className="SideNav-controls">
        {(!currentBasePathname || currentBasePathname === 'denied') && (
          <AccountOrgSwitcher
            user={user}
            changeOrg={id => {
              updateLatestOrg.mutate(id, {
                onSuccess: () => {
                  navigate('/', { state: { orgId: id } });
                  toast.success(
                    'Organization successfully changed to ' +
                      orgs.find(org => org.id === id)?.name,
                  );
                },
                onError: () => {
                  toast.error(
                    'Error changing organization to ' +
                      orgs.find(org => org.id === id)?.name,
                  );
                },
              });
            }}
          />
        )}
        <Button onClick={handleLogout}>
          <Icon icon="SignOut" size="medium" />
        </Button>
      </div>
    </div>
  );
}

export default SideNav;
