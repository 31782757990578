import { DockDeviceTelemetry } from '../types/telemetry.d';
import {
  DJI_RAINFALL_TO_RM_RAINFALL,
  DJI_AIRCRAFT_STATE_TO_RM_STATE,
  DJI_DOCK_COVER_TO_RM_DOCK_COVER,
  DJI_AIRCRAFT_CHARGE_TO_RM_CHARGE,
  DJI_EMERGENCY_STOP_STATE_TO_RM_EMERGENCY_STOP_STATE,
  DJILiveStatus,
} from '../constants/djiTelemetryConstants';
import jsonata from 'jsonata';
import { point } from '@turf/helpers';
import { produce } from 'immer';
import {
  TelemetryWebsocketInitialState,
  TelemetryWebsocketState,
} from '../stores/TelemetryWebsocket/TelemetryWebsocketStore';
import {
  DeviceResponse,
  DeviceType,
} from '@raptormaps/raptor-flight-client-ts';
import { getAllDevicesFromDocks } from './telemetryUtils';
import { isNumber } from '../utils/telemetryUtils';
import { DockDevice } from '../types/devices';
// Events
import {
  TelemetryEmitter,
  TELEMETRY_EVENTS,
  DJI_METHOD_TO_EMITTER_EVENT,
} from '../stores/TelemetryWebsocket/TelemetryEmitter';

/**
 * Uses JSONata to extract telemetry data from a message
 * Will ignore fields that are not present in the message
 */
export const extractTelemetryFromMessage = async (
  message: unknown,
  cameraPayloadIndex?: string,
) => {
  const expression = `
  {
    "bid": bid,
    "tid": tid,
    "longitude": $round(data.longitude, 6),
    "latitude": $round(data.latitude, 6),
    "aircraftChargeState": $lookup($DJI_AIRCRAFT_CHARGE_TO_RM_CHARGE, $string(data.drone_charge_state.state)),
    "deviceSn": $split(topic, "/")[4],
    "windspeed": data.wind_speed,
    "rainfall": $lookup($DJI_RAINFALL_TO_RM_RAINFALL, $string(data.rainfall)),
    "environmentTemperature": data.environment_temperature,
    "internetSpeed": data.network_state.rate,
    "timestamp": timestamp,
    "aircraftDistanceFromDock": data.home_distance,
    "aircraftVerticalSpeed": data.vertical_speed,
    "aircraftHorizontalSpeed": data.horizontal_speed,
    "altitudeRelativeTakeoff": data.elevation,
    "aircraftRoll": data.attitude_roll,
    "aircraftPitch": data.attitude_pitch,
    "coverState": $lookup($DJI_DOCK_COVER_TO_RM_DOCK_COVER, $string(data.cover_state)),
    "aircraftState": $split(topic, "/")[4] != gateway ? $lookup($DJI_AIRCRAFT_STATE_TO_RM_STATE, $string(data.mode_code)) : null,
    "aircraftBatteryPercent": ($split(topic, "/")[4] = gateway ? data.drone_battery_maintenance_info.batteries[0].capacity_percent : data.battery.capacity_percent),
    "memoryCapacity": data.storage,
    "emergencyStopState": $lookup($DJI_EMERGENCY_STOP_STATE_TO_RM_EMERGENCY_STOP_STATE, $string(data.emergency_stop_state)),
    "remainingFlightTime": data.battery.remain_flight_time,
    "cameraPayloadIndex": data.cameras[0].payload_index,
    "cameraGimbalPitch": ($cameraPayloadIndex != null ? $lookup(data, $cameraPayloadIndex).gimbal_roll : null),
    "cameraGimbalRoll": ($cameraPayloadIndex != null ? $lookup(data, $cameraPayloadIndex).gimbal_pitch : null),
    "uploadedFileCount": data.flight_task.uploaded_file_count,
    "expectedFileCount": data.flight_task.expected_file_count,
    "liveCapacity": data.live_capacity,
    "livestreamStatus": data.live_status,
    "aircraftHeading": data.attitude_head
  }
  `;

  const parsedTelemetry = await jsonata(expression).evaluate(message, {
    DJI_RAINFALL_TO_RM_RAINFALL,
    DJI_AIRCRAFT_STATE_TO_RM_STATE,
    DJI_DOCK_COVER_TO_RM_DOCK_COVER,
    DJI_AIRCRAFT_CHARGE_TO_RM_CHARGE,
    DJI_EMERGENCY_STOP_STATE_TO_RM_EMERGENCY_STOP_STATE,
    cameraPayloadIndex,
  });

  return parsedTelemetry;
};

export const stitchParsedTelemetryData = (
  parsedTelemetry,
  dockDeviceTelemetry,
): DockDeviceTelemetry => {
  const updatedDockDeviceTelemetry = { ...dockDeviceTelemetry };

  for (const key in parsedTelemetry) {
    if (key === 'timestamp') {
      continue;
    }

    if (parsedTelemetry[key] === null) {
      continue;
    }

    // Ignore parsed messages that are older than the current telemetry
    if (dockDeviceTelemetry[key]?.timestamp >= parsedTelemetry.timestamp) {
      continue;
    }

    updatedDockDeviceTelemetry[key] = {
      value: parsedTelemetry[key],
      timestamp: parsedTelemetry.timestamp,
      isStale: false,
    };
  }

  return updatedDockDeviceTelemetry;
};

export const handleDeviceLocation = (getState, setState, parsedTelemetry) => {
  if (
    !parsedTelemetry ||
    (!parsedTelemetry?.latitude && !parsedTelemetry?.longitude)
  ) {
    return;
  }
  const deviceLocations = getState().deviceLocations;
  const subscribedDevices = getState().subscribedDevices;
  const allDevices = getAllDevicesFromDocks(subscribedDevices) as DockDevice[];
  const existingLocationFeature = deviceLocations.find(
    feature => feature.properties?.deviceSn === parsedTelemetry.deviceSn,
  );
  const subscribedDevice = allDevices.find(
    (device: DeviceResponse) => device.deviceSn === parsedTelemetry.deviceSn,
  );
  if (!subscribedDevice) {
    return;
  }
  if (!existingLocationFeature) {
    const deviceLocationFeature = createNewDeviceLocation(
      parsedTelemetry,
      subscribedDevice,
    );
    setState(
      produce((initialState: TelemetryWebsocketInitialState) => {
        initialState.deviceLocations.push(deviceLocationFeature);
      }),
    );
  } else if (
    existingLocationFeature &&
    subscribedDevice.deviceType === DeviceType.AerialDrone
  ) {
    const updatedDeviceLocations = updateAircraftFeature(
      deviceLocations,
      parsedTelemetry,
      existingLocationFeature,
    );
    setState(
      (state: TelemetryWebsocketState) =>
        (state.deviceLocations = updatedDeviceLocations),
    );
  }
};

/**
 * Create a point feature from telemetry location data
 */
const createNewDeviceLocation = (parsedTelemetry, subscribedDevice) => {
  const locationFeature = point([
    parsedTelemetry.longitude,
    parsedTelemetry.latitude,
  ]);
  locationFeature.properties = {
    deviceSn: parsedTelemetry.deviceSn,
  };
  if (subscribedDevice) {
    locationFeature.properties.deviceName = subscribedDevice.deviceName;
    locationFeature.properties.deviceType = subscribedDevice.deviceType;
    locationFeature.properties.deviceId = subscribedDevice.id;
    locationFeature.properties.testChange = 0;
  }
  if (subscribedDevice.deviceType === DeviceType.AerialDrone) {
    // Aircraft pitch received in same message as aircraft latitude / longitude
    locationFeature.properties.rotation = isNumber(
      parsedTelemetry?.aircraftHeading,
    )
      ? parsedTelemetry.aircraftHeading
      : 0;
  }
  return locationFeature;
};

/**
 * Update existing device feature with new telemetry data
 */
const updateAircraftFeature = (
  deviceLocations,
  parsedTelemetry,
  existingLocationFeature,
) => {
  const updatedAircraftFeature = point([
    parsedTelemetry.longitude,
    parsedTelemetry.latitude,
  ]);

  const rotation = isNumber(parsedTelemetry?.aircraftHeading)
    ? parsedTelemetry.aircraftHeading
    : existingLocationFeature.rotation;

  updatedAircraftFeature.properties = {
    ...existingLocationFeature.properties,
    rotation,
  };

  const updatedDeviceLocations = deviceLocations.filter(
    feature => feature.properties?.deviceSn !== parsedTelemetry.deviceSn,
  );
  updatedDeviceLocations.push(updatedAircraftFeature);
  return updatedDeviceLocations;
};

export const handleLivestreamEvents = (getState, parsedTelemetry) => {
  const deviceSn = parsedTelemetry?.deviceSn;

  if (!parsedTelemetry.livestreamStatus || !deviceSn) {
    return;
  }

  const prevLivestreamStatus: DJILiveStatus[] =
    getState().devicesTelemetry?.[deviceSn]?.livestreamStatus.value;

  const currentLivestreamStatus = parsedTelemetry.livestreamStatus;

  const prevStreamWasInactive =
    !prevLivestreamStatus || !prevLivestreamStatus.length;
  const prevStreamWasActive = prevLivestreamStatus?.length;
  const currentStreamIsActive = currentLivestreamStatus?.length;
  const currentStreamIsInactive =
    !currentLivestreamStatus || !currentStreamIsActive;

  if (prevStreamWasInactive && currentStreamIsActive) {
    TelemetryEmitter.emit(
      TELEMETRY_EVENTS.LIVESTREAM_BECAME_AVAILABLE,
      deviceSn,
    );
  } else if (currentStreamIsInactive && prevStreamWasActive) {
    TelemetryEmitter.emit(
      TELEMETRY_EVENTS.LIVESTREAM_BECAME_UNAVAILABLE,
      deviceSn,
    );
  }
};

export const emitTelemetryEvents = (topic, data) => {
  const isEventTopic = topic?.split('/')[5];
  if (isEventTopic !== 'events') return;

  const event = DJI_METHOD_TO_EMITTER_EVENT[data?.method];
  if (event) {
    TelemetryEmitter.emit(event, data);
  }
};
