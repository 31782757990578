// External Dependencies
import { useEffect, forwardRef, useImperativeHandle } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Stack } from '@raptormaps/layout';

// Components
import AltitudeInput from './shared/MissionParameters/components/AltitudeInput';
import CourseAndCameraAngleSection from './shared/MissionParameters/CourseAndCameraAngleSection';
import FlightModeDropdown from './shared/MissionParameters/components/FlightModeDropdown';
import SettingsSection from './shared/MissionParameters/SettingsSection';
import CalibrationSection from './shared/Calibration/CalibrationSection';
import MissionStatsSection from './shared/MissionStats';

import {
  GridColumnOneSpanTwo,
  FullWidthGridSection,
} from './MissionPlanningSidebar/MissionPlanningSidebar.styles';

// types
import {
  MannedAirplaneFormikValues,
  MissionPlanningFormProps,
  MissionPlanningFormHandle,
} from '@/shared/types/missions.d';
import { CombinedFlightModeType } from '@/shared/types/tempMissions';
import {
  FlightModeType,
  MissionInput,
  SpeedControlModeType,
  SolarFarmResponse,
} from '@raptormaps/raptor-flight-client-ts';

// Constants
import { MannedFlightPlanSchema } from '@/pages/MissionPlanner/schemas/FlightPlanSchema';
import { mannedFormParameters } from '@/pages/MissionPlanner/constants/FormParameters';
import {
  ACCEPTED_ERRORS,
  DRONE_CAMERA_DEFAULTS,
  FLIGHT_MODE_DEFAULTS,
} from '@/pages/MissionPlanner/constants/missionConstants';

// Utils
import {
  calculateWaypointData,
  handleMannedAirplaneDownload,
} from '@/pages/MissionPlanner/utils/flightPlanningUtils';
import { generateMissionInputFromMannedAirplane } from '@/pages/MissionPlanner/utils/missionApiUtils';
import {
  calculateFlightSpeed,
  calculateCameraInterval,
} from '@/pages/MissionPlanner/utils/utils';

const MannedAirplaneForm = forwardRef<
  MissionPlanningFormHandle,
  MissionPlanningFormProps
>(
  (
    {
      map,
      device,
      initialMission,
      geospatial,
      calibration,
      collectionType,
      setFormModified,
      setCalibration,
      setGeospatial,
      setSaveDisabled,
      setDownloadDisabled,
    }: MissionPlanningFormProps,
    ref,
  ) => {
    const formik = useFormik<MannedAirplaneFormikValues>({
      initialValues: {
        cameraType: initialMission.mission.sensor.name,
        userUnits: initialMission.mission.userUnits,
        altitude: initialMission.mission.altitude,
        frontOverlap: initialMission.mission.frontOverlap,
        sideOverlap: initialMission.mission.sideOverlap,
        cameraAngle: initialMission.mission.cameraAngle,
        flightAngle: initialMission.mission.flightAngle,
        speedControlMode: initialMission.mission.speedControlMode,
        cameraInterval: initialMission.mission.cameraInterval,
        flightSpeed: initialMission.mission.flightSpeed,
        cameraAngleMode: initialMission.mission.cameraAngleMode,
        flightMode: initialMission.mission.flightMode,
      },
      enableReinitialize: true,
      onSubmit: (values, { setSubmitting }) => {
        setTimeout(() => {
          alert(JSON.stringify(values, null, 2));
          setSubmitting(false);
        }, 400);
      },
      validationSchema: MannedFlightPlanSchema,
    });

    const { values, handleChange, validateForm, errors, setFieldValue } =
      formik;

    const { fieldOfViewHorizontal, fieldOfViewVertical } =
      DRONE_CAMERA_DEFAULTS[device][values.cameraType];

    const handleCalculateWaypoints = async () => {
      const formErrors = await validateForm(values);
      const errors = Object.keys(formErrors);
      if (
        !map ||
        !errors.every(error => ACCEPTED_ERRORS.includes(error)) ||
        !geospatial.polygon
      ) {
        return;
      }

      const { waypoints, flightPath, nonCalibratedFlightPath } =
        await calculateWaypointData({
          map,
          fieldOfViewHorizontal,
          fieldOfViewVertical,
          flightMode: values.flightMode,
          polygon: geospatial.polygon,
          altitude: values.altitude,
          pitchAngle: values.cameraAngle,
          flightAngle: values.flightAngle,
          sideOverlap: values.sideOverlap,
          frontOverlap: values.frontOverlap,
          safeTakeoffAltitude: 0,
          safeTakeoffAltitudeBool: false,
          markerLngLat: null,
          calibrationBool: calibration.active,
          calibrationBearing: calibration.rhumbBearing,
          calibrationDistance: calibration.rhumbDistance,
          continuousOperationsEnabled: false,
          cameraInterval: values.cameraInterval,
          flightSpeed: values.flightSpeed,
        });

      setGeospatial({ ...geospatial, waypoints, flightPath });
      setCalibration({
        ...calibration,
        nonCalibratedFlightPath,
      });
    };

    // Update waypoints on values changing
    useEffect(() => {
      if (!map) return;
      handleCalculateWaypoints();
    }, [
      map,
      geospatial.polygon,
      geospatial.markerLngLat,
      values.flightMode,
      calibration.active,
      fieldOfViewHorizontal,
      fieldOfViewVertical,
      values.cameraAngleMode,
    ]);

    const handleEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        handleChange(e);
        handleCalculateWaypoints();
      }
    };

    useEffect(() => {
      setFormModified(formik.dirty);
    }, [formik.dirty]);

    // Disable save and download buttons based on errors and flight mode
    useEffect(() => {
      const hasErrors = Object.keys(errors).length > 0;
      setSaveDisabled(hasErrors);
      setDownloadDisabled(hasErrors);
    }, [errors, values.flightMode]);

    useEffect(() => {
      setGeospatial({ ...geospatial, markerLngLat: null });
    }, []);

    // Calculate controlled input based on speed control mode
    useEffect(() => {
      if (values.speedControlMode === SpeedControlModeType.Flight) {
        const calculatedCameraInterval = calculateCameraInterval({
          altitude: values.altitude,
          frontOverlap: values.frontOverlap,
          fieldOfViewHorizontal,
          flightSpeed: values.flightSpeed,
        });
        setFieldValue('cameraInterval', calculatedCameraInterval);
      } else if (values.speedControlMode === SpeedControlModeType.Shutter) {
        const calculatedFlightSpeed = calculateFlightSpeed({
          altitude: values.altitude,
          frontOverlap: values.frontOverlap,
          fieldOfViewHorizontal,
          cameraInterval: values.cameraInterval,
        });
        setFieldValue('flightSpeed', calculatedFlightSpeed);
      }
    }, [
      values.altitude,
      fieldOfViewHorizontal,
      values.frontOverlap,
      values.flightSpeed,
      values.cameraInterval,
      values.speedControlMode,
    ]);

    useImperativeHandle(ref, () => ({
      generateMissionInput: (missionName: string) => {
        if (!map) return;
        return generateMissionInputFromMannedAirplane({
          missionName,
          values,
          device,
          geospatial,
          center: map.getCenter(),
          zoom: map.getZoom(),
        });
      },
      handleDownload: (
        filename: string,
        input: MissionInput,
        solarFarm: SolarFarmResponse,
      ) => {
        handleMannedAirplaneDownload({
          map,
          missionInput: input,
          filename,
          solarFarm,
          geospatial,
        });
      },
    }));

    return (
      <FormikProvider value={formik}>
        <Stack>
          <FullWidthGridSection>
            <GridColumnOneSpanTwo>
              <FlightModeDropdown
                mode={values.flightMode}
                collectionType={collectionType}
                flightModes={[FlightModeType.Airplane]}
                handleChange={(flightMode: CombinedFlightModeType) => {
                  setFieldValue('flightMode', flightMode);
                  Object.keys(FLIGHT_MODE_DEFAULTS[flightMode]).forEach(key => {
                    {
                      setFieldValue(key, FLIGHT_MODE_DEFAULTS[flightMode][key]);
                    }
                  });
                }}
              />
            </GridColumnOneSpanTwo>
            <AltitudeInput
              errors={errors}
              units={values.userUnits}
              altitude={values.altitude}
              formParameters={mannedFormParameters}
              handleChange={handleChange}
              handleEnterPress={handleEnterPress}
              onBlur={handleCalculateWaypoints}
            />
          </FullWidthGridSection>
          <CourseAndCameraAngleSection
            errors={errors}
            formParameters={mannedFormParameters}
            flightMode={values.flightMode}
            cameraAngleMode={values.cameraAngleMode}
            flightAngle={values.flightAngle}
            cameraAngle={values.cameraAngle}
            startTime={null}
            handleChange={handleChange}
            onBlur={handleCalculateWaypoints}
            handleEnterPress={handleEnterPress}
          />
          <SettingsSection
            device={device}
            errors={errors}
            sideOverlap={values.sideOverlap}
            frontOverlap={values.frontOverlap}
            cameraType={values.cameraType}
            flightSpeed={values.flightSpeed}
            flightMode={values.flightMode}
            cameraInterval={values.cameraInterval}
            speedControlMode={values.speedControlMode}
            formParameters={mannedFormParameters}
            handleChange={handleChange}
            onBlur={handleCalculateWaypoints}
            handleEnterPress={handleEnterPress}
            setFieldValue={setFieldValue}
          />
          <CalibrationSection
            calibration={calibration}
            setCalibration={setCalibration}
            onBlur={handleCalculateWaypoints}
          />
          <MissionStatsSection
            flightSpeed={values.flightSpeed}
            altitude={values.altitude}
            fieldOfViewVertical={fieldOfViewVertical}
            fieldOfViewHorizontal={fieldOfViewHorizontal}
            frontOverlap={values.frontOverlap}
            sideOverlap={values.sideOverlap}
            flightMode={values.flightMode}
            flightPath={geospatial.flightPath}
          />
        </Stack>
      </FormikProvider>
    );
  },
);

export default MannedAirplaneForm;
