import { useMutation, useQuery } from 'react-query';
import {
  CameraLens,
  ChangeVideoQualityRequest,
  LiveStreamStatus,
  MOCK_LIVE_STREAM_RESPONSE,
  MOCK_STREAMING_LENSES,
  StartLiveStreamResponse,
} from '../constants/mockDeviceData';

/**
 * Mock a successful live stream start
 * @param liveStreamStatus mock status of live stream that determines if route is successful or rejected
 * @returns
 */
export const useStartLiveStream = (
  liveStreamStatus: LiveStreamStatus,
  onSuccess: (StartLiveStreamResponse) => void,
  onError: () => void,
) => {
  const mockStartLiveStream = async (): Promise<StartLiveStreamResponse> => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (liveStreamStatus === LiveStreamStatus.Error) {
          reject(liveStreamStatus);
        } else if (liveStreamStatus === LiveStreamStatus.Success) {
          resolve(MOCK_LIVE_STREAM_RESPONSE);
        }
      }, 2000);
    });
  };

  return useQuery({
    queryKey: 'startLiveStream',
    queryFn: async () => {
      return await mockStartLiveStream();
    },
    onSuccess,
    onError,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });
};

/**
 * Mock function to simulate live stream
 * @param liveStreamStatus - mock status of live stream that determines if route is successful or rejected
 * @returns
 */
export const useStopLiveStream = () => {
  const mockStopLiveStream = async (
    liveStreamStatus: LiveStreamStatus,
  ): Promise<LiveStreamStatus> => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        liveStreamStatus === LiveStreamStatus.Success
          ? resolve(liveStreamStatus)
          : reject(liveStreamStatus);
      }, 3000);
    });
  };

  return useMutation({
    mutationKey: 'stopLiveStream',
    mutationFn: async (liveStreamStatus: LiveStreamStatus) => {
      return await mockStopLiveStream(liveStreamStatus);
    },
  });
};

/**
 * Mock function to simulate fetching lenses available for the camera to stream
 * @param liveStreamStatus - mock status of live stream that determines if route is successful or rejected
 * @param enabled - boolean to determine if the query should be enabled
 * @returns
 */
export const useGetStreamingLenses = (
  liveStreamStatus: LiveStreamStatus,
  enabled,
) => {
  const mockGetStreamingLens = async (): Promise<CameraLens[]> => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        liveStreamStatus === LiveStreamStatus.Success
          ? resolve(MOCK_STREAMING_LENSES)
          : reject(liveStreamStatus);
      }, 2000);
    });
  };

  return useQuery({
    queryKey: 'streamingLenses',
    queryFn: async () => {
      return await mockGetStreamingLens();
    },
    enabled,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: 30000,
  });
};

export const useChangeLens = (liveStreamStatus: LiveStreamStatus) => {
  const mockChangeLens = async (
    changeLensRequest: CameraLens,
  ): Promise<CameraLens> => {
    const updatedLens = MOCK_STREAMING_LENSES.find(
      lens => lens.videoId === changeLensRequest.videoId,
    );
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        liveStreamStatus === LiveStreamStatus.Success
          ? resolve(updatedLens)
          : reject(changeLensRequest);
      }, 2000);
    });
  };

  return useMutation({
    mutationKey: 'changeLens',
    mutationFn: async (changeLensRequest: CameraLens) => {
      return await mockChangeLens(changeLensRequest);
    },
  });
};

export const useChangeVideoQuality = (liveStreamStatus: LiveStreamStatus) => {
  const mockVideoQuality = async (
    changeVideoQualityRequest: ChangeVideoQualityRequest,
  ): Promise<LiveStreamStatus> => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        liveStreamStatus === LiveStreamStatus.Success
          ? resolve(liveStreamStatus)
          : reject(changeVideoQualityRequest);
      }, 2000);
    });
  };

  return useMutation({
    mutationKey: 'changeVideoQuality',
    mutationFn: async (
      changeVideoQualityRequest: ChangeVideoQualityRequest,
    ) => {
      return await mockVideoQuality(changeVideoQualityRequest);
    },
  });
};
