// External Dependancies
import mapboxgl from 'mapbox-gl';
import theme from '@raptormaps/theme';
import {
  useCallback,
  useEffect,
  useState,
  useMemo,
  Dispatch,
  SetStateAction,
  useRef,
} from 'react';
import { useNavigate, createSearchParams, useBlocker } from 'react-router-dom';
import { useToast } from '@raptormaps/toast';
import { ThreeCircles } from 'react-loader-spinner';
import { Stack } from '@raptormaps/layout';
import './MissionPlanningSidebar.css';

// Components
import AreaMissionForm from '../AreaMissionForm';
import LinearMissionForm from '../LinearMissionForm';
import LinearMissionFormV2 from '../LinearMissionFormV2';
import MannedAirplaneForm from '../MannedAirplaneForm';
import MissionPlanningHeader from '../MissionPlanningHeader';
import SaveSection from '../shared/SaveSection';
import { ConfirmationModal } from 'shared/components/ConfirmationModal/ConfirmationModal';
import { MissionPlanningSidebarContainer } from './MissionPlanningSidebar.styles';

// Constants
import {
  DEFAULT_FLIGHT_MISSION_FILE,
  DEFAULT_GEOSPATIAL,
  DEFAULT_CALIBRATION,
} from '../../constants/missionConstants';
import { FLIGHT_MODE_TO_COLLECTION_TYPE } from '@/shared/constants/missionLookups';

// types
import {
  PointType,
  MissionFileType,
  GeospatialObject,
  CalibrationObject,
  MissionPlanningFormElement,
  MissionPlanningFormHandle,
} from '@/shared/types/missions.d';
import { DataCollectionType } from '@/shared/types/dataCollection';
import {
  DroneType,
  MissionResponse,
  MissionInput,
} from '@raptormaps/raptor-flight-client-ts';

// Utils
import Mixpanel from '@/shared/utils/mixpanel/utils';
import MixpanelEvents from '@/shared/utils/mixpanel/events';
import { parseToMissionFileType } from '../../utils/missionApiUtils';
import { parseFlightApiErrorDetails } from '@/shared/utils/utils';
import { useUrlParams, useUrlSearchParams } from '@/shared/hooks/useUrlParams';
import { useGetMission } from '@/shared/hooks/useMissions';
import { useSolarFarmById } from '@/shared/hooks/useSolarFarms';
import { useAppContext } from '@/shared/context/AppContext';
import { useCreateMission, useUpdateMission } from '@/shared/hooks/useMissions';
import { shouldBlockMissionBuilderNavigation } from '@/pages/MissionPlanner/utils/utils';

// Growthbook
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { GrowthbookFlags } from '@/shared/utils/GrowthbookUtils';
import { DrawToolModes } from '@/shared/constants/mapConstants';

interface MissionPlanningSidebarProps {
  map: mapboxgl.Map;
  geospatial: GeospatialObject;
  calibration: CalibrationObject;
  setInitialZoom: Dispatch<SetStateAction<number>>;
  setInitialCenter: Dispatch<SetStateAction<PointType>>;
  setGeospatial: Dispatch<SetStateAction<GeospatialObject>>;
  setCalibration: Dispatch<SetStateAction<CalibrationObject>>;
  setDrawToolMode: Dispatch<SetStateAction<DrawToolModes>>;
  drawToolMode: DrawToolModes;
}

const MissionPlanningSidebar = ({
  map,
  geospatial,
  calibration,
  setInitialZoom,
  setInitialCenter,
  setGeospatial,
  setCalibration,
  setDrawToolMode,
  drawToolMode,
}: MissionPlanningSidebarProps) => {
  const navigate = useNavigate();
  const toast = useToast();
  const formRef = useRef<MissionPlanningFormHandle>(null);
  const { flightMission, clearMission } = useAppContext();
  const [formModified, setFormModified] = useState<boolean>(false);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);
  const [downloadDisabled, setDownloadDisabled] = useState<boolean>(false);
  const [displayLinearWarningModal, setDisplayLinearWarningModal] =
    useState<boolean>(false);

  const solarFarmId = useUrlSearchParams<number>('solar_farm_id', 'number');
  const missionId = useUrlParams<number>('missionId', 'number');

  const displayLinearMissionsV2 = useFeatureIsOn(
    GrowthbookFlags.LINEAR_MISSIONS_V2,
  );

  const { data: apiMission } = useGetMission(missionId, solarFarmId, () => {
    toast.error(`Error fetching mission with ID ${missionId}`);
    navigate({
      pathname: `/plan`,
      search: createSearchParams({
        solar_farm_id: solarFarmId.toString(),
      }).toString(),
    });
  });
  const { data: solarFarm } = useSolarFarmById(solarFarmId);

  const createMission = useCreateMission(solarFarm?.id);
  const updateMission = useUpdateMission(missionId, solarFarm?.id);

  // Set initial mission
  const initialMission = useMemo<MissionFileType>(
    () =>
      flightMission ||
      parseToMissionFileType(apiMission) ||
      DEFAULT_FLIGHT_MISSION_FILE,
    [apiMission],
  );

  const [missionName, setMissionName] = useState<string>(
    initialMission.mission.missionName || '',
  );
  const [device, setDevice] = useState<DroneType>(
    initialMission.mission.drone || DroneType.M3t,
  );

  const [collectionType, setCollectionType] = useState<DataCollectionType>(
    FLIGHT_MODE_TO_COLLECTION_TYPE[initialMission.mission.flightMode],
  );

  useEffect(() => {
    if (
      displayLinearMissionsV2 &&
      collectionType === DataCollectionType.Linear &&
      drawToolMode !== DrawToolModes.Line
    ) {
      setDrawToolMode(DrawToolModes.Line);
    } else if (drawToolMode !== DrawToolModes.Polygon) {
      setDrawToolMode(DrawToolModes.Polygon);
    }
  }, [collectionType]);

  const handleChangeCollectionType = (
    newCollectionType: DataCollectionType,
  ) => {
    if (displayLinearMissionsV2) {
      setDisplayLinearWarningModal(true);
    } else {
      setCollectionType(newCollectionType);
    }
  };

  const handleCreateMission = async (
    mission: MissionInput,
  ): Promise<MissionResponse> => {
    Mixpanel.track(MixpanelEvents.PlanSaveMission);

    try {
      return await createMission.mutateAsync(mission, {
        onSuccess: result => {
          navigate({
            pathname: `/plan/${result.id}`,
            search: createSearchParams({
              solar_farm_id: result.solarFarmId.toString(),
            }).toString(),
          });
          toast.success('Mission Sucessfully Saved', {
            duration: 10000,
          });
        },
      });
    } catch (err) {
      const apiError = await parseFlightApiErrorDetails(
        err,
        'Error saving mission. Please try again.',
      );
      const message = apiError[0]?.msg || apiError;
      toast.error(message, {
        duration: 10000,
      });
      console.error(err);
    }
  };

  const handleUpdateMission = async (
    mission: MissionInput,
  ): Promise<MissionResponse> => {
    Mixpanel.track(MixpanelEvents.PlanSaveMission);

    try {
      return await updateMission.mutateAsync(mission, {
        onSuccess: () => {
          toast.success('Mission Sucessfully Saved', {
            duration: 10000,
          });
        },
      });
    } catch (err) {
      toast.error('Error saving mission progress. Please try again.', {
        duration: 10000,
      });
      console.error(err);
    }
  };

  // Initialize polygon and markerLngLat with mission data
  useEffect(() => {
    if (!map) return;
    const { mission } = initialMission;

    const updatedPolygon =
      mission.flightMap && geospatial.polygon !== mission.flightMap
        ? mission.flightMap
        : geospatial.polygon;

    const updatedMarkerLngLat =
      mission.takeOffPointCenter || geospatial.markerLngLat;

    setGeospatial({
      ...geospatial,
      polygon: updatedPolygon,
      markerLngLat: updatedMarkerLngLat,
    });

    setMissionName(mission.missionName);
    setDevice(mission.drone);
    setCollectionType(FLIGHT_MODE_TO_COLLECTION_TYPE[mission.flightMode]);

    if (mission?.mapZoom) {
      setInitialZoom(mission.mapZoom);
    }
    if (mission?.mapCenter) {
      setInitialCenter(mission.mapCenter);
    }
  }, [solarFarm, initialMission, map]);

  const { takeOffPointCenter } = initialMission.mission;
  const { markerLngLat } = geospatial;
  const dirtyState =
    formModified ||
    geospatial.polygon !== initialMission.mission.flightMap ||
    (!markerLngLat && !!takeOffPointCenter) ||
    (!takeOffPointCenter && !!markerLngLat) ||
    markerLngLat?.lat !== takeOffPointCenter?.lat ||
    markerLngLat?.lng !== takeOffPointCenter?.lng ||
    initialMission.mission.missionName !== missionName ||
    initialMission.mission.drone !== device;

  // Determine if user should be blocked from navigating away from the mission builder
  const shouldBlock = useCallback(
    ({ currentLocation, nextLocation }) => {
      return shouldBlockMissionBuilderNavigation({
        currentLocation,
        nextLocation,
        dirtyState,
      });
    },
    [formModified, geospatial],
  );

  const blocker = useBlocker(shouldBlock);
  let PlanningForm: MissionPlanningFormElement = null;
  if (device === DroneType.MannedAirplane) {
    PlanningForm = MannedAirplaneForm;
  } else if (collectionType === DataCollectionType.Area) {
    PlanningForm = AreaMissionForm;
  } else if (
    displayLinearMissionsV2 &&
    collectionType === DataCollectionType.Linear
  ) {
    PlanningForm = LinearMissionFormV2;
  } else if (collectionType === DataCollectionType.Linear) {
    PlanningForm = LinearMissionForm;
  }

  if (missionId && !apiMission) {
    return (
      <MissionPlanningSidebarContainer>
        <Stack align="center" justify="center" style={{ height: '100%' }}>
          <ThreeCircles
            height="100"
            width="100"
            color={theme.colors.primary_400}
            visible={true}
            ariaLabel="loading spinner"
          />
        </Stack>
      </MissionPlanningSidebarContainer>
    );
  }

  const handleConfirmSwitchDataCollection = () => {
    if (collectionType === DataCollectionType.Linear) {
      setCollectionType(DataCollectionType.Area);
      setGeospatial(DEFAULT_GEOSPATIAL);
    } else {
      setCollectionType(DataCollectionType.Linear);
      setGeospatial(DEFAULT_GEOSPATIAL);
    }
    setDisplayLinearWarningModal(false);
  };

  return (
    <MissionPlanningSidebarContainer className="flight-planning-sidebar">
      <MissionPlanningHeader
        solarFarmId={solarFarm?.id}
        missionName={missionName}
        device={device}
        collectionType={collectionType}
        setDevice={setDevice}
        setMissionName={setMissionName}
        handleChangeCollectionType={handleChangeCollectionType}
      />
      <PlanningForm
        ref={formRef}
        map={map}
        device={device}
        initialMission={initialMission}
        calibration={calibration}
        geospatial={geospatial}
        collectionType={collectionType}
        setDownloadDisabled={setDownloadDisabled}
        setSaveDisabled={setSaveDisabled}
        setCalibration={setCalibration}
        setGeospatial={setGeospatial}
        handleAddTakeoffPoint={(point?: PointType) => {
          Mixpanel.track(MixpanelEvents.PlanCreateTakeoffPoint);
          setGeospatial({
            ...geospatial,
            markerLngLat: point || map?.getCenter(),
          });
        }}
        handleRemoveTakeoffPoint={() => {
          setGeospatial({ ...geospatial, markerLngLat: null });
        }}
        setFormModified={setFormModified}
        handleCreateMission={handleCreateMission}
        handleUpdateMission={handleUpdateMission}
      />
      <SaveSection
        missionName={missionName}
        isLoading={createMission.isLoading || updateMission.isLoading}
        saveDisabled={
          createMission.isLoading ||
          updateMission.isLoading ||
          saveDisabled ||
          !solarFarm ||
          !geospatial.polygon ||
          !missionName ||
          !dirtyState ||
          (displayLinearMissionsV2 &&
            collectionType === DataCollectionType.Linear)
        }
        downloadDisabled={
          createMission.isLoading ||
          updateMission.isLoading ||
          downloadDisabled ||
          !solarFarm ||
          !geospatial.polygon ||
          !missionName ||
          (displayLinearMissionsV2 &&
            collectionType === DataCollectionType.Linear)
        }
        saveAsDisabled={
          createMission.isLoading ||
          updateMission.isLoading ||
          saveDisabled ||
          !solarFarm ||
          !geospatial.polygon ||
          !missionName ||
          !missionId ||
          (displayLinearMissionsV2 &&
            collectionType === DataCollectionType.Linear)
        }
        downloadHelperText={
          device === DroneType.MannedAirplane ? '.KML' : '.KMZ'
        }
        buttonText={missionId ? 'Save Progress' : 'Save To Library'}
        handleSave={() => {
          const missionInput =
            formRef.current.generateMissionInput(missionName);
          missionId
            ? handleUpdateMission(missionInput)
            : handleCreateMission(missionInput);
        }}
        handleSaveAs={(newMissionName: string) => {
          const missionInput =
            formRef.current.generateMissionInput(newMissionName);
          handleCreateMission(missionInput);
        }}
        handleDownload={(filename: string) => {
          const missionInput =
            formRef.current.generateMissionInput(missionName);

          if (!saveDisabled) {
            missionId
              ? handleUpdateMission(missionInput)
              : handleCreateMission(missionInput);
          }

          formRef.current.handleDownload(filename, missionInput, solarFarm);
        }}
      />
      <ConfirmationModal
        open={blocker.state === 'blocked'}
        onClose={() => blocker.reset()}
        handleCancel={() => blocker.reset()}
        handleConfirm={() => {
          clearMission();
          setGeospatial(DEFAULT_GEOSPATIAL);
          setCalibration(DEFAULT_CALIBRATION);
          blocker.proceed();
        }}
        title="Leave Flight Plan?"
        description="Changes that you made may not be saved."
        confirmText="Leave"
        closeText="Cancel"
      />
      <ConfirmationModal
        open={displayLinearWarningModal}
        onClose={() => setDisplayLinearWarningModal(false)}
        handleCancel={() => {
          setDisplayLinearWarningModal(false);
        }}
        handleConfirm={handleConfirmSwitchDataCollection}
        title="Change Collection Type?"
        description="Some changes that you made will not be saved."
        confirmText="Discard Changes"
        closeText="Cancel"
      />
    </MissionPlanningSidebarContainer>
  );
};

export default MissionPlanningSidebar;
