import styled from 'styled-components';

export const SolarFarmPopupContainer = styled.div`
  height: 1vh;
  width: 100%;
  padding-right: 2vw;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    margin-top: 1vh;
  }
`;

export const SolarFarmPopupButton = styled.button`
  height: 100%;
  width: 2vw;
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  cursor: pointer;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;

  :focus {
    border: none;
    outline: none;
  }
`;
