import { Layers } from 'mapbox-gl';
import theme from '@raptormaps/theme';
import { INVERTER_SOURCE_ID } from '../sources/InverterSources';

export const INVERTER_LAYER_ID = 'inverter-layer';

const InverterBlockLayer: Layers = [
  {
    id: INVERTER_LAYER_ID,
    type: 'symbol',
    source: INVERTER_SOURCE_ID,
    minzoom: 16,
    layout: {
      'symbol-placement': 'point',
      'text-field': ['get', 'object_name'],
      'text-size': 24,
      'text-offset': [-2, -1],
      'text-font': ['Roboto Light'],
      'text-anchor': 'left',
      'icon-image': 'inverter-off',
      visibility: 'visible',
    },
    paint: {
      'text-color': theme.colors.neutral_400,
    },
  },
];

const InverterLayers: Layers = [...InverterBlockLayer];

export default InverterLayers;
