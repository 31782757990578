import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';
import { useAppContext } from '../context/AppContext';

export const useFeatureFlags = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { user } = useAppContext();

  const getFeatures = async org_id => {
    const response = await fetch(
      `${window.REACT_APP_PLANTLABELER_API_ENDPOINT}/api/v2/orgs/${org_id}/features?org_id=${org_id}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    const result = await response?.json();
    const features = {};
    result.org_features.forEach(
      feature => (features[feature.feature_keycode] = feature),
    );
    return features;
  };
  const {
    isLoading: isLoadingFeatures,
    error,
    data: features,
  } = useQuery({
    queryKey: ['features', user?.latest_org_id],
    queryFn: () => getFeatures(user?.latest_org_id),
  });

  return {
    isLoadingFeatures,
    error,
    features,
  };
};
