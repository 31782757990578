// External Dependencies
import { Dispatch, SetStateAction } from 'react';
import { Stack } from '@raptormaps/layout';
import { ThreeCircles } from 'react-loader-spinner';
import { Text } from '@raptormaps/text';
import { Button } from '@raptormaps/button';
import theme from '@raptormaps/theme';

// Components
import MissionNameInput from './shared/MissionName/MissionNameInput';
import { FormLabel } from './MissionPlanningSidebar/MissionPlanningSidebar.styles';
import { DropdownMenu, DropdownOption } from '@raptormaps/dropdown-menu';
import DataCollection from './shared/MissionParameters/components/DataCollection/DataCollection';

// Types
import { DroneType } from '@raptormaps/raptor-flight-client-ts';
import { DataCollectionType } from '@/shared/types/dataCollection';

// Constants
import { DRONE_TYPE_TO_DISPLAY_NAME } from '@/shared/constants/missionLookups';

// Utils
import { useSolarFarmById } from '@/shared/hooks/useSolarFarms';

interface MissionPlanningHeaderProps {
  device: DroneType;
  solarFarmId: number;
  missionName: string;
  collectionType: DataCollectionType;
  setMissionName: Dispatch<SetStateAction<string>>;
  setDevice: Dispatch<SetStateAction<DroneType>>;
  handleChangeCollectionType: (collectionType: DataCollectionType) => void;
}
const MissionPlanningHeader = ({
  device,
  solarFarmId,
  missionName,
  collectionType,
  setDevice,
  setMissionName,
  handleChangeCollectionType,
}: MissionPlanningHeaderProps) => {
  const { data: solarFarm, isLoading: isLoadingSolarFarm } =
    useSolarFarmById(solarFarmId);

  const droneTypeOptions: DropdownOption[] = Object.values(DroneType)
    .filter(droneType => droneType != DroneType.Custom)
    .map(drone => ({
      onSelect: () => setDevice(drone),
      value: DRONE_TYPE_TO_DISPLAY_NAME[drone],
      variant: 'option',
      style: { fontSize: '14px', justifyContent: 'start' },
    }));

  return (
    <Stack>
      {isLoadingSolarFarm || !solarFarm ? (
        <ThreeCircles
          height="40"
          width="40"
          color={theme.colors.primary_400}
          visible={true}
          ariaLabel="loading spinner"
        />
      ) : (
        <Text variant="h3" align="left">
          Mission Builder: {solarFarm?.name}
        </Text>
      )}
      <MissionNameInput
        solarFarmId={solarFarmId}
        missionName={missionName}
        setMissionName={setMissionName}
      />
      <Stack>
        <Stack gutter="xxs">
          <FormLabel variant="label_small">Device</FormLabel>
          <DropdownMenu
            menuContentProps={{
              align: 'start',
              sideOffset: 0,
              side: 'bottom',
              style: {
                maxHeight: '70vh',
                width: '450px',
                minWidth: '100px',
                marginTop: 0,
              },
            }}
            items={droneTypeOptions}
            modal={false}
          >
            <Button
              data-testid="drone-type-dropdown"
              size="small"
              variant="secondary"
              id="drone-type-dropdown"
              style={{ justifyContent: 'space-between' }}
            >
              {DRONE_TYPE_TO_DISPLAY_NAME[device as DroneType]}
            </Button>
          </DropdownMenu>
        </Stack>
        <DataCollection
          device={device}
          collectionType={collectionType}
          handleChangeCollectionType={handleChangeCollectionType}
        />
      </Stack>
    </Stack>
  );
};

export default MissionPlanningHeader;
