//External Depenancies
import theme from '@raptormaps/theme';

// Compoents
import { Checkbox } from '@raptormaps/checkbox';
import { CheckboxVariant } from '@raptormaps/checkbox/build/Checkbox.interface';
import { Icon } from '@raptormaps/icon';
import { Text } from '@raptormaps/text';
import { Tooltip } from '@raptormaps/tooltip';
import { Button } from '@raptormaps/button';
import { Row, Stack } from '@raptormaps/layout';
import {
  QuarterWidthTextInput,
  MinMax,
  FullWidthGridSection,
  InlineIconToggle,
} from '@/pages/MissionPlanner/components/MissionPlanningSidebar/MissionPlanningSidebar.styles';
import { InstructionsBubble } from './SpecialFeaturesSection.styles';
import { InputHelperText } from '@/shared/styles/text.styles';

// Constants / Types
import {
  METERS_TO_FEET,
  NUMBER_INPUT_STEP,
} from '@/pages/MissionPlanner/constants/missionConstants';
import { FormParameters, PointType } from '@/shared/types/missions.d';
import { UnitType } from '@raptormaps/raptor-flight-client-ts';

// Utils
import { handleInputScroll } from '@/pages/MissionPlanner/utils/utils';

interface InlineCheckboxSectionProps {
  userUnits: UnitType;
  safeTakeoffAltitudeBool: boolean;
  safeTakeoffAltitude?: number;
  altitudeOffsetBool: boolean;
  altitudeOffset: number;
  terrainFollowBool: boolean;
  formParameters: FormParameters;
  markerLngLat: PointType;
  errors: {
    userUnits?: string;
    safeTakeoffAltitudeBool?: string;
    safeTakeoffAltitude?: string;
    altitudeOffsetBool?: string;
    altitudeOffset?: string;
    terrainFollowBool?: string;
  };
  handleAddTakeoffpoint: () => void;
  handleRemoveTakeoffpoint: () => void;
  onBlur: () => void;
  handleEnterPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SpecialFeaturesSection = ({
  userUnits,
  safeTakeoffAltitudeBool,
  safeTakeoffAltitude,
  altitudeOffsetBool,
  altitudeOffset,
  terrainFollowBool,
  formParameters,
  markerLngLat,
  errors,
  onBlur,
  handleEnterPress,
  handleAddTakeoffpoint,
  handleRemoveTakeoffpoint,
  handleChange,
}: InlineCheckboxSectionProps) => {
  const markerCoordinates = () => {
    if (markerLngLat) {
      let lat = String(markerLngLat?.lat?.toFixed(5));
      let lng = String(markerLngLat?.lng?.toFixed(5));
      if (lat[0] != '-') lat = `+${lat}`;
      if (lng[0] != '-') lng = `+${lng}`;
      return lat + ', ' + lng;
    } else return null;
  };

  return (
    <Stack>
      <>
        <InlineIconToggle>
          <Text variant={'paragraph_medium_bold'}>
            <Icon icon="HouseDrone" size={'large'} />
            Safe Takeoff Altitude
            <Tooltip
              colorVariant="dark"
              delay={100}
              placement={{
                align: 'center',
                side: 'right',
              }}
              tip={
                <Stack gutter={'none'}>
                  <Text variant={'paragraph_small'} color={theme.colors.white}>
                    Safe Takeoff Altitude ensures the drone ascends to a minimum
                    set height, but if the inspection altitude is higher, it
                    will continue to ascend to the inspection altitude.
                  </Text>
                  <Text variant={'paragraph_small'} color={theme.colors.white}>
                    ATTN: Do not use this feature in conjunction with Terrain
                    follow
                  </Text>
                </Stack>
              }
              shouldWrapChildren={true}
            >
              <Icon
                aria-label="Safe takeoff altitude information"
                icon="CircleInfo"
                size={'medium'}
              />
            </Tooltip>
          </Text>
          <Checkbox
            data-testid="safeTakeoffAltitudeBool"
            name="safeTakeoffAltitudeBool"
            checked={safeTakeoffAltitudeBool}
            onChange={handleChange}
            variant={CheckboxVariant.TOGGLE}
          />
        </InlineIconToggle>
        {safeTakeoffAltitudeBool && (
          <FullWidthGridSection>
            <QuarterWidthTextInput
              label={'Safe takeoff altitude (meters)'}
              inputSize="small"
              inputStyle="white-box"
              inputType="number"
              name="safeTakeoffAltitude"
              step={NUMBER_INPUT_STEP}
              value={
                userUnits === UnitType.Metric
                  ? safeTakeoffAltitude
                  : safeTakeoffAltitude * METERS_TO_FEET
              }
              disabled={
                userUnits === UnitType.Imperial || !safeTakeoffAltitudeBool
              }
              onWheel={handleInputScroll}
              min={formParameters.safeTakeoffAltitudeInput.min}
              max={formParameters.safeTakeoffAltitudeInput.max}
              onChange={handleChange}
              onBlur={onBlur}
              onKeyDownCapture={handleEnterPress}
              helperText={errors.safeTakeoffAltitude}
              className={`${errors.safeTakeoffAltitude ? 'is-invalid' : ''}`}
            />
            <MinMax gridColumn={2}>
              <Text variant="paragraph_small">
                Min: {formParameters.safeTakeoffAltitudeInput.min}
              </Text>
              <Text variant="paragraph_small">
                Max: {formParameters.safeTakeoffAltitudeInput.max}
              </Text>
            </MinMax>
          </FullWidthGridSection>
        )}
      </>
      <InlineIconToggle>
        <Text variant={'paragraph_medium_bold'}>
          <Icon icon="ArrowUpToDottedLine" size={'large'} />
          Altitude Offset
          <Tooltip
            colorVariant="dark"
            delay={100}
            placement={{
              align: 'center',
              side: 'right',
            }}
            shouldWrapChildren={true}
            tip="Altitude Offset increases the flight height without changing the image spacing and flight line spacing."
          >
            <Icon
              aria-label="Altitude Offset Information"
              icon="CircleInfo"
              size={'medium'}
            />
          </Tooltip>
        </Text>
        <Checkbox
          data-testid="altitudeOffsetBool"
          name="altitudeOffsetBool"
          checked={altitudeOffsetBool}
          onChange={handleChange}
          variant={CheckboxVariant.TOGGLE}
        />
      </InlineIconToggle>

      {altitudeOffsetBool && (
        <FullWidthGridSection>
          <QuarterWidthTextInput
            label={'Altitude offset (meters)'}
            name={'altitudeOffset'}
            inputSize="small"
            inputStyle="white-box"
            inputType="number"
            step={NUMBER_INPUT_STEP}
            value={altitudeOffset}
            disabled={!altitudeOffsetBool || userUnits === UnitType.Imperial}
            onChange={handleChange}
            onBlur={onBlur}
            onKeyDownCapture={handleEnterPress}
            onWheel={handleInputScroll}
            helperText={errors.altitudeOffset}
            className={`${errors.altitudeOffset ? 'is-invalid' : ''}`}
          />
          <MinMax gridColumn={2}>
            <Text variant="paragraph_small">
              Min: {formParameters.altitudeOffset.min}
            </Text>
            <Text variant="paragraph_small">
              Max: {formParameters.altitudeOffset.max}
            </Text>
          </MinMax>
        </FullWidthGridSection>
      )}

      <InlineIconToggle>
        <Text variant={'paragraph_medium_bold'}>
          <Icon icon="Mountains" size={'large'} />
          Terrain Follow
          <Tooltip
            colorVariant="dark"
            delay={100}
            placement={{
              align: 'center',
              side: 'right',
            }}
            tip="Terrain Follow ensures your drone adjusts its altitude based on the terrain model, maintaining a consistent height above the ground."
            shouldWrapChildren={true}
          >
            <Icon
              aria-label="Terrain Follow Information"
              icon="CircleInfo"
              size={'medium'}
            />
          </Tooltip>
        </Text>
        <Checkbox
          data-testid="terrainFollowBool"
          name="terrainFollowBool"
          checked={terrainFollowBool}
          onChange={e => {
            handleChange(e);
            if (!e.target.checked) {
              handleRemoveTakeoffpoint();
            }
          }}
          variant={CheckboxVariant.TOGGLE}
        />
      </InlineIconToggle>
      {terrainFollowBool && (
        <>
          <Row justify="space-between" align="center" style={{ width: '100%' }}>
            <InstructionsBubble align="start" gutter="none">
              <Text
                style={{ display: 'flex', alignItems: 'center' }}
                variant="paragraph_small_bold"
              >
                <Icon
                  aria-label="Relative Altitute Point Information"
                  icon="CircleInfo"
                  size={'medium'}
                  style={{ color: theme.colors.blue_500, marginRight: '5px' }}
                />{' '}
                Set Your Relative Altitude Point!
              </Text>
              <Text
                variant="paragraph_small"
                align="left"
                style={{ marginLeft: '25px' }}
              >
                For precise height calculations, set the relative altitude point
                to your takeoff location or to the same elevation as your
                takeoff location.
              </Text>
            </InstructionsBubble>
            <Text>* Relative Altitude Point</Text>
            <Button
              style={{ marginLeft: '-15px' }}
              size="small"
              variant="tertiary"
              onClick={
                markerCoordinates()
                  ? () => handleRemoveTakeoffpoint()
                  : () => handleAddTakeoffpoint()
              }
              icon={markerLngLat ? 'Xmark' : 'ChevronRight'}
              iconPosition="right"
            >
              <Text variant="paragraph_medium_bold">
                {markerCoordinates() ? markerCoordinates() : 'Create'}
              </Text>
            </Button>
          </Row>
          {errors.terrainFollowBool && (
            <InputHelperText
              style={{ display: 'flex', alignItems: 'center' }}
              color={theme.colors.red_400}
            >
              <Icon
                icon="TriangleExclamation"
                size={'small'}
                style={{ color: theme.colors.red_400, marginRight: '5px' }}
              />
              Required field! Please set a relative altitude point or disable
              Terrain Following.
            </InputHelperText>
          )}
        </>
      )}
    </Stack>
  );
};

export default SpecialFeaturesSection;
