import { ThreeCircles } from 'react-loader-spinner';
import './PageLoader.css';

export const PageLoader = () => (
  <div className="app-container">
    <div className="loader">
      <ThreeCircles
        height="100"
        width="100"
        color="#f68b37"
        visible={true}
        ariaLabel="loading spinner"
      />
    </div>
  </div>
);
