import errorMessageImage from '../../assets/error-message.svg';
import { Button } from '@raptormaps/button';
import './Fallback.css';

interface FallbackComponentProps {
  resetErrorBoundary?: () => void;
}
const FallbackComponent = ({ resetErrorBoundary }: FallbackComponentProps) => {
  return (
    <div role="alert" className="fallback-container">
      <img
        src={errorMessageImage}
        style={{ marginBottom: '1rem', maxWidth: '100%' }}
        alt=""
      />
      <p>
        <strong>Something went wrong...</strong>
      </p>
      <p>
        <strong>Please try again.</strong>
      </p>
      {resetErrorBoundary && (
        <Button onClick={resetErrorBoundary}>Try Again</Button>
      )}
    </div>
  );
};

export default FallbackComponent;
