import {
  CameraAngleMode,
  DroneType,
  FlightModeType,
  CameraType,
  SpeedControlModeType,
} from '@raptormaps/raptor-flight-client-ts';
import { CombinedFlightModeType } from '@/shared/types/tempMissions';
import { DataCollectionType } from '@/shared/types/dataCollection';
import {
  FlightModeToWaypointTurnMode,
  DataCollectionTypeToFlightMode,
  WaypointTurnModes,
  WaypointHeadingMode,
} from '../types/missions';

export const DRONE_TYPE_TO_DISPLAY_NAME: {
  [key in DroneType]: string;
} = {
  [DroneType.M3t]: 'DJI - M3T',
  [DroneType.M30t]: 'DJI - M30T',
  [DroneType.Custom]: 'Custom',
  [DroneType.MannedAirplane]: 'Manned Airplane',
  [DroneType.M30tDock]: 'DJI - M30T Dock',
  [DroneType.M3tdDock]: 'DJI - M3TD Dock',
};

export const CAMERA_ANGLE_MODE_TO_DISPLAY_NAME: {
  [key in CameraAngleMode]: string;
} = {
  [CameraAngleMode.Manual]: 'Manual',
  [CameraAngleMode.FollowTracker]: 'Follow Tracker',
};

export const DISPLAY_NAME_TO_CAMERA_ANGLE_MODE: {
  [key: string]: CameraAngleMode;
} = {
  Manual: CameraAngleMode.Manual,
  'Follow Tracker': CameraAngleMode.FollowTracker,
};

export const FLIGHT_MODE_TO_DISPLAY_NAME: {
  [key in CombinedFlightModeType]: string;
} = {
  [FlightModeType.Standard]: 'Standard',
  [FlightModeType.Comprehensive]: 'Comprehensive',
  [FlightModeType.Oblique]: 'Oblique',
  [FlightModeType.Perimeter]: 'Perimeter',
  [FlightModeType.Wiring]: 'Wiring',
  [FlightModeType.Cablines]: 'CABLines',
  [FlightModeType.Iimode]: 'Instant',
  [FlightModeType.Custom]: 'Custom',
  [FlightModeType.Airplane]: 'Airplane',
  [FlightModeType.Overview]: 'Overview',
  [FlightModeType.Orthomosaic]: 'Ortho',
  [FlightModeType.SquareOrbital]: 'Square Orbital',
  [FlightModeType.LinearCustom]: 'Custom',
};

export const DISPLAY_NAME_TO_FLIGHT_MODE: {
  [key: string]: CombinedFlightModeType;
} = {
  Standard: FlightModeType.Standard,
  Comprehensive: FlightModeType.Comprehensive,
  Oblique: FlightModeType.Oblique,
  Perimeter: FlightModeType.Perimeter,
  Wiring: FlightModeType.Wiring,
  CABLines: FlightModeType.Cablines,
  Instant: FlightModeType.Iimode,
  Custom: FlightModeType.Custom,
  Airplane: FlightModeType.Airplane,
  Overview: FlightModeType.Overview,
  Ortho: FlightModeType.Orthomosaic,
  SquareOrbital: FlightModeType.SquareOrbital,
};

export const CAMERA_TYPE_TO_DISPLAY_NAME: {
  [key in CameraType]: string;
} = {
  [CameraType.Rgb]: 'RGB Wide',
  [CameraType.Thermal]: 'IR',
};

export const SPEED_CONTROL_TO_DISPLAY_NAME: {
  [key in SpeedControlModeType]: string;
} = {
  [SpeedControlModeType.Shutter]: 'Camera Interval',
  [SpeedControlModeType.Flight]: 'Flight Speed',
};

export const DISPLAY_NAME_TO_SPEED_CONTROL: {
  [key: string]: SpeedControlModeType;
} = {
  'Camera Interval': SpeedControlModeType.Shutter,
  'Flight Speed': SpeedControlModeType.Flight,
};

export const FLIGHT_MODE_TO_COLLECTION_TYPE: {
  [key in CombinedFlightModeType]: DataCollectionType;
} = {
  [FlightModeType.Standard]: DataCollectionType.Area,
  [FlightModeType.Comprehensive]: DataCollectionType.Area,
  [FlightModeType.Oblique]: DataCollectionType.Area,
  [FlightModeType.Perimeter]: DataCollectionType.Area,
  [FlightModeType.Wiring]: DataCollectionType.Area,
  [FlightModeType.Iimode]: DataCollectionType.Area,
  [FlightModeType.Custom]: DataCollectionType.Area,
  [FlightModeType.Cablines]: DataCollectionType.Linear,
  [FlightModeType.Airplane]: DataCollectionType.Area,
  [FlightModeType.Overview]: DataCollectionType.Area,
  [FlightModeType.Orthomosaic]: DataCollectionType.Area,
  [FlightModeType.SquareOrbital]: DataCollectionType.Area,
  [FlightModeType.LinearCustom]: DataCollectionType.Linear,
};

export const DATA_COLLECTION_TYPE_TO_DISPLAY: {
  [key in DataCollectionType]: string;
} = {
  area: 'Area',
  linear: 'Linear',
  object: 'Object',
  waypoint: 'Waypoint',
};

export const DISPLAY_NAME_TO_DATA_COLLECTION_TYPE: {
  [key: string]: DataCollectionType;
} = {
  Area: DataCollectionType.Area,
  Linear: DataCollectionType.Linear,
  Object: DataCollectionType.Object,
  Waypoint: DataCollectionType.Waypoint,
};

export const COLLECTION_TYPE_FLIGHT_MODE: DataCollectionTypeToFlightMode = {
  [DataCollectionType.Area]: [
    FlightModeType.Standard,
    FlightModeType.Comprehensive,
    FlightModeType.Oblique,
    FlightModeType.Perimeter,
    FlightModeType.Wiring,
    FlightModeType.Iimode,
    FlightModeType.Custom,
    FlightModeType.Orthomosaic,
    FlightModeType.SquareOrbital,
  ],
  [DataCollectionType.Linear]: [FlightModeType.Cablines],
  [DataCollectionType.Object]: [],
  [DataCollectionType.Waypoint]: [],
};

export const FLIGHT_MODE_TO_WAYPOINT_TURN_MODE: FlightModeToWaypointTurnMode = {
  [FlightModeType.Standard]: 'coordinateTurn',
  [FlightModeType.Comprehensive]: 'coordinateTurn',
  [FlightModeType.Overview]: 'coordinateTurn',
  [FlightModeType.Oblique]: 'coordinateTurn',
  [FlightModeType.Perimeter]: 'coordinateTurn',
  [FlightModeType.Wiring]: 'coordinateTurn',
  [FlightModeType.Cablines]: 'coordinateTurn',
  [FlightModeType.Iimode]: 'toPointAndStopWithContinuityCurvature',
  [FlightModeType.Custom]: 'coordinateTurn',
  [FlightModeType.Airplane]: 'coordinateTurn',
  [FlightModeType.Orthomosaic]: 'coordinateTurn',
  [FlightModeType.SquareOrbital]: 'toPointAndPassWithContinuityCurvature',
  [FlightModeType.LinearCustom]: 'coordinateTurn',
};

interface DJIFlightModeConfiguration {
  waypointTurnMode: WaypointTurnModes;
  waypointHeadingMode: WaypointHeadingMode;
}

export const DJI_FLIGHT_MODE_VALUES: {
  [key in CombinedFlightModeType]?: DJIFlightModeConfiguration;
} = {
  [FlightModeType.Standard]: {
    waypointTurnMode: 'coordinateTurn',
    waypointHeadingMode: 'manually',
  },
  [FlightModeType.Comprehensive]: {
    waypointTurnMode: 'coordinateTurn',
    waypointHeadingMode: 'manually',
  },
  [FlightModeType.Overview]: {
    waypointTurnMode: 'coordinateTurn',
    waypointHeadingMode: 'manually',
  },
  [FlightModeType.Oblique]: {
    waypointTurnMode: 'coordinateTurn',
    waypointHeadingMode: 'manually',
  },
  [FlightModeType.Perimeter]: {
    waypointTurnMode: 'coordinateTurn',
    waypointHeadingMode: 'manually',
  },
  [FlightModeType.Wiring]: {
    waypointTurnMode: 'coordinateTurn',
    waypointHeadingMode: 'manually',
  },
  [FlightModeType.Cablines]: {
    waypointTurnMode: 'coordinateTurn',
    waypointHeadingMode: 'manually',
  },
  [FlightModeType.Iimode]: {
    waypointTurnMode: 'toPointAndStopWithContinuityCurvature',
    waypointHeadingMode: 'manually',
  },
  [FlightModeType.Custom]: {
    waypointTurnMode: 'coordinateTurn',
    waypointHeadingMode: 'manually',
  },
  [FlightModeType.Airplane]: {
    waypointTurnMode: 'coordinateTurn',
    waypointHeadingMode: 'manually',
  },
  [FlightModeType.Orthomosaic]: {
    waypointTurnMode: 'coordinateTurn',
    waypointHeadingMode: 'followWayline',
  },
  [FlightModeType.SquareOrbital]: {
    waypointTurnMode: 'toPointAndPassWithContinuityCurvature',
    waypointHeadingMode: 'manually',
  },
};

export enum ActionTrigger {
  multipleTiming = 'multipleTiming',
  multipleDistance = 'multipleDistance',
}
