import { Button } from '@raptormaps/button';
import { Icon } from '@raptormaps/icon';
import './Breadcrumbs.css';
import { Link } from 'react-router-dom';

export type BreadCrumb = [string, string];

interface BreadcrumbProps {
  crumbs: BreadCrumb[];
}

const BreadcrumbNavigation = ({ crumbs }: BreadcrumbProps) => {
  const breadcrumbs = crumbs.map(([title, path], index) => (
    <span key={path}>
      <li className="breadcrumb-listItem">
        <Link to={path}>
          <Button
            className="breadcrumb-link"
            size="small"
            variant="tertiary"
            role="button"
            disabled={index === crumbs.length - 1}
          >
            {title}
          </Button>
        </Link>
      </li>
      {index !== crumbs.length - 1 && (
        <Icon className="breadcrumb-chevron" icon="ChevronRight" />
      )}
    </span>
  ));
  return (
    <nav aria-label="Breadcrumb" className="breadcrumb">
      <ul className="breadcrumb-list">{breadcrumbs}</ul>
    </nav>
  );
};

export default BreadcrumbNavigation;
