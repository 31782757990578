import { GeoJSONSource } from '../map.d';
export const PYRANOMETER_SOURCE_ID = 'raptor-pyranometer';

const PyranometerSource: GeoJSONSource = {
  id: PYRANOMETER_SOURCE_ID,
  source: {
    type: 'geojson',
    data: { type: 'FeatureCollection', features: [] },
  },
};

const PyranometerSources: GeoJSONSource[] = [PyranometerSource];

export default PyranometerSources;
