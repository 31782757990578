// external Dependancies
import { Feature } from '@turf/helpers';

// RaptorUI
import { Row, Stack } from '@raptormaps/layout';
import { Text } from '@raptormaps/text';

// Utilities
import {
  calculateFlightTimeFromGridline,
  getFieldOfViewSpacing,
} from '../../utils/utils';

// Styled Components
import { SectionCollapsible } from '@/pages/MissionPlanner/components/MissionPlanningSidebar/MissionPlanningSidebar.styles';

// Types
import { CombinedFlightModeType } from '@/shared/types/tempMissions';

interface MissionStatsSectionProps {
  flightSpeed: number;
  altitude: number;
  fieldOfViewVertical: number;
  fieldOfViewHorizontal: number;
  frontOverlap: number;
  sideOverlap: number;
  flightPath: Feature;
  flightMode: CombinedFlightModeType;
}

const MissionStatsSection = ({
  flightSpeed,
  altitude,
  fieldOfViewVertical,
  fieldOfViewHorizontal,
  frontOverlap,
  sideOverlap,
  flightPath,
  flightMode,
}: MissionStatsSectionProps) => {
  return (
    <SectionCollapsible
      iconColor="neutral_900"
      title="Mission Stats"
      variant="ghost"
    >
      <Stack justify="start">
        <Row justify="space-between">
          <Text variant="paragraph_medium">
            Data Collection Time (minutes){' '}
          </Text>
          <Text>
            {(
              calculateFlightTimeFromGridline(
                flightPath,
                flightSpeed,
                flightMode,
              ) / 60
            ).toFixed(1)}
          </Text>
        </Row>
        <Row justify="space-between">
          <Text variant="paragraph_medium">Side Spacing (meters) </Text>
          <Text>
            {getFieldOfViewSpacing(
              altitude,
              fieldOfViewVertical,
              sideOverlap,
            ).toFixed(1)}
          </Text>
        </Row>
        <Row justify="space-between">
          <Text variant="paragraph_medium">Front Spacing (meters) </Text>
          <Text>
            {getFieldOfViewSpacing(
              altitude,
              fieldOfViewHorizontal,
              frontOverlap,
            ).toFixed(1)}
          </Text>
        </Row>
      </Stack>
    </SectionCollapsible>
  );
};

export default MissionStatsSection;
