// External Dependencies
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import {
  FarmObjectsApi,
  ObjectType,
} from '@raptormaps/customer-farm-api-client-ts';
import { SolarFarmResponse } from '@raptormaps/raptor-flight-client-ts';
import { FeatureCollection, Geometries } from '@turf/helpers';
import mapboxgl from 'mapbox-gl';
import Pbf from 'pbf';
import { decode } from 'geobuf';
import { useToast } from '@raptormaps/toast';

// Internal Dependencies
import { useAppContext } from '../context/AppContext';
import { useApiLegacy } from './useApiLegacy';
import { updateEquipmentSource } from '../components/Map/sources';
import { INVERTER_BLOCK_SOURCE_ID } from '../components/Map/sources/InverterBlockSources';
import { INVERTER_SOURCE_ID } from '../components/Map/sources/InverterSources';
import { COMBINER_SOURCE_ID } from '../components/Map/sources/CombinerSources';
import { PYRANOMETER_SOURCE_ID } from '../components/Map/sources/PyranometerSources';
import { MODULE_SOURCE_ID } from '../components/Map/sources/ModuleSources';
import {
  GEOSPATIAL_ROW_SOURCE_ID,
  GEOSPATIAL_ROW_LABEL_SOURCE_ID,
} from '../components/Map/sources/GeospatialRowSources';
// Mapbox Layers
import InverterBlockLayers, {
  SECTION_ZONE_CLUSTER_FILL_OPACITY,
} from '@/shared/components/Map/layers/InverterBlockLayers';
import RowLayers from '@/shared/components/Map/layers/GeospatialRowLayers';
import CombinerLayers from '@/shared/components/Map/layers/CombinerLayers';
import InverterLayers from '@/shared/components/Map/layers/InverterLayers';
import ModuleLayers from '@/shared/components/Map/layers/ModuleLayers';
import PyranometerLayers from '@/shared/components/Map/layers/PyranometerLayers';
import StringLayers from '@/shared/components/Map/layers/StringLayers';
import { GeoJSONPolygonToPoint } from '../components/Map/utils';
import { STRING_SOURCE_ID } from '../components/Map/sources/StringSources';
import { EquipmentDetailLayers } from '../components/MapDetailsPopover/mapDetailsConstants';
// Types
import { EquipmentLayerData } from '@/shared/components/MapDetailsPopover/mapDetails';

export const useGetInverterBlocks = (solarFarmId: number) => {
  const { getAccessTokenSilently } = useAuth0();
  const { user } = useAppContext();
  const orgId = user.latest_org_id;

  return useQuery({
    queryKey: ['inverterBlocks', orgId, solarFarmId],
    queryFn: async () => {
      if (!orgId || !solarFarmId) return null;
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(
        `${window.REACT_APP_FARMBUILDER_API_ENDPOINT}/solar_farms/${solarFarmId}/row_bounding_boxes?org_id=${orgId}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: '/',
          },
        },
      );

      if (response.status !== 200) {
        throw new Error('Failed to fetch inverter blocks');
      }
      return response.json();
    },
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetEquipmentRows = (solarFarmId: number) => {
  const { user } = useAppContext();
  const { getAccessTokenSilently } = useAuth0();
  const orgId = user.latest_org_id;

  const fetchGeospatialRows = async () => {
    const accessToken = await getAccessTokenSilently();
    if (!accessToken || !solarFarmId) {
      return;
    }

    const response = await fetch(
      `${window.REACT_APP_CUSTOMER_API_ENDPOINT}/v2/tile/solar_farms/${solarFarmId}/rows/geobuf`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: '/',
        },
      },
    );

    const data = await response.arrayBuffer();
    if (data.byteLength === 0) {
      return {
        type: 'FeatureCollection',
        features: [],
      } as FeatureCollection<Geometries>;
    }

    // convert formater for geo buff
    const pbf = new Pbf(data);
    // decoding the serialized data into a human readable JSON
    const rows = decode(pbf).features.map(f => ({
      ...f,
      id: f.properties.id,
      properties: {
        ...f.properties,
        // geobuf doesn't support booleans, instead it passes string f and string t so here we are converting those values to boolean
        is_shoulder_at_start: f.properties.is_shoulder_at_start === 't',
      },
    }));
    return {
      type: 'FeatureCollection',
      features: rows,
    } as FeatureCollection<Geometries>;
  };

  return useQuery({
    queryKey: ['rows', orgId, solarFarmId],
    queryFn: () => fetchGeospatialRows(),
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetEquipmentCombiners = (solarFarmId: number) => {
  const { user } = useAppContext();
  const orgId = user.latest_org_id;
  const FarmsObjectApi = useApiLegacy(FarmObjectsApi, {
    basePath: window.REACT_APP_FARMBUILDER_API_ENDPOINT,
  });
  return useQuery({
    queryKey: ['combiners', orgId, solarFarmId],
    queryFn: async () => {
      if (!orgId || !solarFarmId) return null;
      const response = await FarmsObjectApi.getFarmObjectGeojson({
        solarFarmId: solarFarmId,
        orgId: orgId,
        objectType: ObjectType.Combiner,
      });
      return response as FeatureCollection<Geometries>;
    },
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetEquipmentInverters = (solarFarmId: number) => {
  const { user } = useAppContext();
  const orgId = user.latest_org_id;
  const FarmsObjectApi = useApiLegacy(FarmObjectsApi, {
    basePath: window.REACT_APP_FARMBUILDER_API_ENDPOINT,
  });
  return useQuery({
    queryKey: ['inverters', orgId, solarFarmId],
    queryFn: async () => {
      if (!orgId || !solarFarmId) return null;
      const response = await FarmsObjectApi.getFarmObjectGeojson({
        solarFarmId: solarFarmId,
        orgId: orgId,
        objectType: ObjectType.Inverter,
      });
      return response as FeatureCollection<Geometries>;
    },
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetEquipmentPyranometers = (solarFarmId: number) => {
  const { user } = useAppContext();
  const orgId = user.latest_org_id;
  const FarmsObjectApi = useApiLegacy(FarmObjectsApi, {
    basePath: window.REACT_APP_FARMBUILDER_API_ENDPOINT,
  });
  return useQuery({
    queryKey: ['pyranometers', orgId, solarFarmId],
    queryFn: async () => {
      if (!orgId || !solarFarmId) return null;
      const response = await FarmsObjectApi.getFarmObjectGeojson({
        solarFarmId: solarFarmId,
        orgId: orgId,
        objectType: ObjectType.Pyranometer,
      });
      return response as FeatureCollection<Geometries>;
    },
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetEquipmentModulesAndStrings = (
  solarFarmId: number,
  rowId: number,
) => {
  const { user } = useAppContext();
  const orgId = user.latest_org_id;
  const FarmsObjectApi = useApiLegacy(FarmObjectsApi, {
    basePath: window.REACT_APP_FARMBUILDER_API_ENDPOINT,
  });

  return useQuery({
    queryKey: ['modules', rowId],
    queryFn: async () => {
      if (!orgId || !solarFarmId || !rowId) return null;
      const response = await FarmsObjectApi.getGeospatialRowChildGeojson({
        solarFarmId: solarFarmId,
        rowId: rowId,
      });
      return {
        modules: response.modules as FeatureCollection<Geometries>,
        strings: response.strings as FeatureCollection<Geometries>,
      };
    },
  });
};

export const useEquipmentSourcesOnMap = (
  map: mapboxgl.Map,
  solarFarm: SolarFarmResponse,
  rowId?: number,
) => {
  const toast = useToast();

  const { data: inverterBlocks, error: inverterBlocksError } =
    useGetInverterBlocks(solarFarm?.id);

  const { data: geospatialRows, error: rowsError } = useGetEquipmentRows(
    solarFarm?.id,
  );

  const { data: inverters, error: inverterError } = useGetEquipmentInverters(
    solarFarm?.id,
  );

  const { data: combiners, error: combinerError } = useGetEquipmentCombiners(
    solarFarm?.id,
  );

  const { data: pyranometers, error: pyranometerError } =
    useGetEquipmentPyranometers(solarFarm?.id);

  const { data: modulesAndStrings, error: moduleAndStringError } =
    useGetEquipmentModulesAndStrings(solarFarm?.id, rowId);

  useEffect(() => {
    if (!map) return;

    updateEquipmentSource(map, INVERTER_BLOCK_SOURCE_ID, inverterBlocks);

    if (inverterBlocksError) {
      toast.error(`Error fetching inverter blocks ${solarFarm?.name}`, {
        duration: 5000,
      });
    }
  }, [inverterBlocks, inverterBlocksError, map]);

  useEffect(() => {
    if (!map) return;

    const geospatialRowLabels = GeoJSONPolygonToPoint(geospatialRows);
    // add rows
    updateEquipmentSource(map, GEOSPATIAL_ROW_SOURCE_ID, geospatialRows);
    // add row labels
    updateEquipmentSource(
      map,
      GEOSPATIAL_ROW_LABEL_SOURCE_ID,
      geospatialRowLabels as FeatureCollection<Geometries>,
    );
    if (rowsError) {
      toast.error(`Error fetching geospatial rows for ${solarFarm?.name}`, {
        duration: 5000,
      });
    }
  }, [geospatialRows, rowsError, map]);

  useEffect(() => {
    if (!map) return;

    updateEquipmentSource(map, INVERTER_SOURCE_ID, inverters);
    if (inverterError) {
      toast.error(`Error inverters for ${solarFarm?.name}`, { duration: 5000 });
    }
  }, [inverters, inverterError, map]);

  useEffect(() => {
    if (!map) return;

    updateEquipmentSource(map, COMBINER_SOURCE_ID, combiners);
    if (combinerError) {
      toast.error(`Error fetching combiners for ${solarFarm?.name}`, {
        duration: 5000,
      });
    }
  }, [combiners, combinerError, map]);

  useEffect(() => {
    if (!map) return;

    updateEquipmentSource(map, PYRANOMETER_SOURCE_ID, pyranometers);
    if (pyranometerError) {
      toast.error(`Error fetching pyranometers for ${solarFarm?.name}`, {
        duration: 5000,
      });
    }
  }, [pyranometers, pyranometerError, map]);

  useEffect(() => {
    if (!map) return;

    updateEquipmentSource(map, MODULE_SOURCE_ID, modulesAndStrings?.modules);
    updateEquipmentSource(map, STRING_SOURCE_ID, modulesAndStrings?.strings);
    if (moduleAndStringError) {
      toast.error(`Error fetching modules and strings for ${solarFarm?.name}`, {
        duration: 5000,
      });
    }
  }, [modulesAndStrings, moduleAndStringError, rowId, map]);
};

export const useEquipmentForSliders = (solarFarmId: number, rowId?: number) => {
  /* 
    args: solarFarmId: number, rowId?: number
    rerturns: sliders: EquipmentLayerData[]
  */
  const { data: modulesAndStrings } = useGetEquipmentModulesAndStrings(
    solarFarmId,
    rowId,
  );
  const { data: inverterBlocks } = useGetInverterBlocks(solarFarmId);
  const { data: geospatialRows } = useGetEquipmentRows(solarFarmId);
  const { data: combiners } = useGetEquipmentCombiners(solarFarmId);
  const { data: inverters } = useGetEquipmentInverters(solarFarmId);
  const { data: pyranometers } = useGetEquipmentPyranometers(solarFarmId);

  const sliders: EquipmentLayerData[] = [
    {
      layers: ModuleLayers,
      name: EquipmentDetailLayers.module,
      data: modulesAndStrings?.modules,
    },
    {
      layers: StringLayers,
      name: EquipmentDetailLayers.string,
      data: modulesAndStrings?.strings,
    },
    {
      layers: InverterBlockLayers,
      name: EquipmentDetailLayers.inverterBlock,
      data: inverterBlocks,
      defaultOpacity: SECTION_ZONE_CLUSTER_FILL_OPACITY * 100,
    },
    {
      layers: RowLayers,
      name: EquipmentDetailLayers.row,
      data: geospatialRows,
    },
    {
      layers: CombinerLayers,
      name: EquipmentDetailLayers.combiner,
      data: combiners,
    },
    {
      layers: InverterLayers,
      name: EquipmentDetailLayers.inverter,
      data: inverters,
    },
    {
      layers: PyranometerLayers,
      name: EquipmentDetailLayers.pyranometer,
      data: pyranometers,
    },
  ];

  return sliders;
};
