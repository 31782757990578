// External Dependencies
import { useState, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import { TileMap } from '@raptormaps/customer-farm-api-client-ts';

// Raptor UI
import { PopoverTrigger, PopoverClose } from '@raptormaps/popover';
import { Text } from '@raptormaps/text';
import { Row, Stack } from '@raptormaps/layout';

// Components
import {
  MapDetailsPopover,
  MapDetailsTrigger,
} from './MapDetailsPopover.styles';
import { PopoverCloseButton } from '@/shared/styles/sidebar.styles';
import { EquipmentSlider } from './components/EquipmentSlider';

//Hooks
import { useGetAsBuilt } from '../../hooks/useAsBuilt';
import { useEquipmentForSliders } from '@/shared/hooks/useEquipment';
import { Waypoints, FlightPath } from '../Map/layers/FlightLayers';
// Types
import { EquipmentLayerData, Visibility } from './mapDetails';
import {
  EquipmentDetailLayers,
  FlightDetailLayers,
} from './mapDetailsConstants';

interface MapLayerModalProps {
  map: mapboxgl.Map;
  solarFarmId: number;
  activeRowId: number;
  useFlightDetails?: boolean;
}

export const MapDetails = ({
  map,
  solarFarmId,
  activeRowId,
  useFlightDetails = true,
}: MapLayerModalProps) => {
  // Get all the layers
  const equipmentSliders = useEquipmentForSliders(solarFarmId, activeRowId);
  const { data: asBuilts } = useGetAsBuilt(solarFarmId);
  const flightLayers = [
    { layers: [Waypoints], name: FlightDetailLayers.waypoints, data: null },
    { layers: [FlightPath], name: FlightDetailLayers.flightPath, data: null },
  ];

  // Set Layer Visibility States
  let initialAsBuiltVisibilityStates = {};
  useEffect(() => {
    initialAsBuiltVisibilityStates =
      asBuilts?.reduce((acc: Visibility, asBuilt: TileMap) => {
        acc[asBuilt.id] = { opacity: 100, visible: true };
        return acc;
      }, {} as Visibility) || {};

    setAsBuiltVisibility(initialAsBuiltVisibilityStates);
  }, [asBuilts]);

  const initialEquipmentVisibilityStates =
    equipmentSliders?.reduce(
      (acc: Visibility, equipment: EquipmentLayerData) => {
        const opacity = equipment?.defaultOpacity || 100;
        acc[equipment.name] = { opacity: opacity, visible: true };
        return acc;
      },
      {} as Visibility,
    ) || {};

  const initialFlightVisibilityStates =
    flightLayers?.reduce((acc: Visibility, layer: EquipmentLayerData) => {
      acc[layer.name] = { opacity: 100, visible: true };
      return acc;
    }, {} as Visibility) || {};

  // State controlling layer visibility
  const [flightVisibility, setFlightVisibility] = useState<{
    [key: string]: Visibility;
  }>(initialFlightVisibilityStates);
  const [equipmentVisibility, setEquipmentVisibility] = useState<{
    [key: string]: Visibility;
  }>(initialEquipmentVisibilityStates);
  const [asBuiltVisibility, setAsBuiltVisibility] = useState<{
    [key: string]: Visibility;
  }>(initialAsBuiltVisibilityStates);

  return (
    <>
      <PopoverTrigger>
        <MapDetailsTrigger
          data-testid="map-details-trigger"
          icon="LayerGroup"
          aria-label="Map Details Popover"
          variant="secondary"
          size="small"
        />
      </PopoverTrigger>
      <MapDetailsPopover>
        <Row justify="space-between">
          <Text variant="h4"> Map Details</Text>
          <PopoverClose>
            <PopoverCloseButton
              variant="tertiary"
              size="small"
              icon="Xmark"
              aria-label="Close Map Details Popover"
            />
          </PopoverClose>
        </Row>
        {useFlightDetails && (
          <Stack gutter="xs" style={{ marginTop: '15px' }}>
            <Text
              variant="paragraph_large_bold"
              style={{ marginBottom: '-5px' }}
            >
              Flight
            </Text>
            {flightLayers.map(layer => (
              <EquipmentSlider
                map={map}
                key={layer.layers[0].id}
                equipmentLayerData={layer}
                visibility={flightVisibility[layer.name]}
                setVisibility={newState => {
                  setFlightVisibility(prevState => ({
                    ...prevState,
                    [layer.name]: { ...prevState[layer.name], ...newState },
                  }));
                }}
              />
            ))}
          </Stack>
        )}
        <Stack gutter="xs" style={{ marginTop: '15px' }}>
          <Text variant="paragraph_large_bold" style={{ marginBottom: '-5px' }}>
            {' '}
            Equipment Opacity
          </Text>
          {equipmentSliders.map((equipmentLayer: EquipmentLayerData) => (
            <EquipmentSlider
              map={map}
              key={equipmentLayer.layers[0].id}
              equipmentLayerData={equipmentLayer}
              disabled={
                !equipmentLayer.data ||
                equipmentLayer.data?.features?.length <= 0
              }
              disabledTooltipMessage={
                equipmentLayer.name === EquipmentDetailLayers.string ||
                (equipmentLayer.name === EquipmentDetailLayers.module &&
                  activeRowId === null)
                  ? 'Select a row to enable string and module visibility controls.'
                  : `No ${equipmentLayer.name.toLowerCase()} data found for this solar site.`
              }
              visibility={equipmentVisibility[equipmentLayer.name]}
              setVisibility={newState => {
                setEquipmentVisibility(prevState => ({
                  ...prevState,
                  [equipmentLayer.name]: {
                    ...prevState[equipmentLayer.name],
                    ...newState,
                  },
                }));
              }}
            />
          ))}
        </Stack>
        <Stack style={{ margin: '15px 0 20px 0' }}>
          <Text variant="paragraph_large_bold" style={{ marginBottom: '-5px' }}>
            Background Layers
          </Text>
          {asBuilts?.length > 0 ? (
            asBuilts.map((asBuilt: TileMap) => (
              <EquipmentSlider
                map={map}
                key={asBuilt.id}
                equipmentLayerData={{
                  name: asBuilt.filename,
                  layers: [
                    {
                      type: 'raster',
                      id: 'raptor-as-built-tiles-' + asBuilt.id,
                    },
                  ],
                  data: null,
                }}
                visibility={asBuiltVisibility[asBuilt.id]}
                setVisibility={newState => {
                  setAsBuiltVisibility(prevState => ({
                    ...prevState,
                    [asBuilt.id]: { ...prevState[asBuilt.id], ...newState },
                  }));
                }}
              />
            ))
          ) : (
            <EquipmentSlider
              map={map}
              visibility={null}
              setVisibility={null}
              disabled={true}
              disabledTooltipMessage={'No background layers available'}
            />
          )}
        </Stack>
      </MapDetailsPopover>
    </>
  );
};
