import styled, { css } from 'styled-components';
import theme, { mapTypographyToStyles } from '@raptormaps/theme';
import {
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from '@radix-ui/react-dropdown-menu';

export const MenuDivider = styled(DropdownMenuSeparator)`
  height: 1px;
  background-color: ${theme.colors.neutral_800};
  border-bottom: 2px solid ${theme.colors.neutral_800};
`;

export const BaseNavItem = styled.button`
  display: flex;
  align-items: center;

  border: none;
  background-color: transparent;
  color: inherit;
  border-radius: 4px;

  transition: background-color linear 100ms;

  :hover {
    color: inherit;
    background-color: ${theme.colors.neutral_800};
  }
`;

export const textEllipsisStyles = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const EllipsisText = styled(Text)`
  ${textEllipsisStyles};
`;

const BaseMenuItem = styled(BaseNavItem)`
  ${mapTypographyToStyles('paragraph_medium')}
  padding: 8px;
  color: inherit;

  &[data-highlighted] {
    outline: none;
    background-color: ${theme.colors.neutral_800};
  }
`;

export const OrgOption = styled(BaseMenuItem)`
  flex: 1;
  text-align: left;
  ${textEllipsisStyles};
  // needed for text ellipsis to work
  display: block;
  cursor: pointer;
`;

export const EditOrgLink = styled(BaseMenuItem)`
  padding: 8px 10px;
`;

export const MenuLabel = styled(DropdownMenuLabel)`
  padding: 4px 8px;
  color: inherit;
  ${textEllipsisStyles}
`;

/* z-index 501 is so this container appears above the dropdown inputs in the mission planning form */
export const DropdownContentContainer = styled(DropdownMenuContent)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: ${theme.colors.neutral_400};
  background-color: ${theme.colors.black};
  padding: 8px;

  max-height: var(--radix-dropdown-menu-content-available-height);
  overflow-y: auto;

  z-index: 501;
`;

export const MenuButton = styled(BaseMenuItem)``;
