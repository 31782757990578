import { useEffect, useState } from 'react';
import { PointType } from '../../types/missions.d';
import { Map } from 'mapbox-gl';

const MapHeader = ({ map }: { map: Map }) => {
  const [zoom, setZoom] = useState<number>(map.getZoom());
  const [center, setCenter] = useState<PointType>(map.getCenter());
  const [initialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (initialized || !map) return;
    map.on('move', () => {
      setZoom(map.getZoom());
      setCenter(map.getCenter());
    });
    setInitialized(true);
  }, [map]);

  return (
    <div className="sidebar">
      Longitude: {center?.lng?.toFixed(8)} | Latitude: {center?.lat?.toFixed(8)}{' '}
      | Zoom: {zoom?.toFixed(2)}
    </div>
  );
};
export default MapHeader;
