// Styled components
import { Row, Stack } from '@raptormaps/layout';
import {
  CameraControlsContainer,
  CameraControlIcon,
} from './LiveStream.styled';
import theme from '@raptormaps/theme';
import { ThreeCircles } from 'react-loader-spinner';
import { DropdownMenu } from '@raptormaps/dropdown-menu';
import { Button } from '@raptormaps/button';

// Mock Data
import {
  CameraLens,
  ChangeVideoQualityRequest,
  VIDEO_QUALITY_OPTIONS,
  VideoLensType,
  VideoQuality,
} from '../../shared/constants/mockDeviceData';

interface CameraControlsProps {
  lensesLoading: boolean;
  availableLenses: CameraLens[];
  onLensChange: (lens: CameraLens) => void;
  currentLens: VideoLensType | null;
  videoId: string;
  onVideoQualityChange: (
    changeVideoQualityRequest: ChangeVideoQualityRequest,
  ) => void;
  currentVideoQuality: VideoQuality | null;
  lensIsChanging: boolean;
  videoQualityIsChanging: boolean;
}

const CameraControls = ({
  lensesLoading,
  availableLenses,
  onLensChange,
  currentLens,
  videoId,
  onVideoQualityChange,
  currentVideoQuality,
  lensIsChanging,
  videoQualityIsChanging,
}: CameraControlsProps) => {
  return (
    <CameraControlsContainer data-testid="live-stream-camera-controls">
      <Stack justify="center" align="center">
        <Row justify="center" align="center">
          <CameraControlIcon icon="CameraRetro" size="medium" />
          {lensesLoading || lensIsChanging ? (
            <ThreeCircles
              height="40"
              width="40"
              color={theme.colors.primary_400}
              visible={true}
              ariaLabel="lens-loading-spinner"
            />
          ) : (
            <DropdownMenu
              items={availableLenses.map(lens => ({
                onSelect: () => {
                  onLensChange(lens);
                },
                value: lens.videoLensType,
                variant: 'option',
              }))}
              modal={false}
              menuContentProps={{
                style: {
                  minWidth: '140px',
                },
              }}
            >
              <Button size="small" variant="secondary" style={{ width: 140 }}>
                {currentLens ?? 'Select Lens'}
              </Button>
            </DropdownMenu>
          )}
        </Row>
        <Row justify="center" align="center">
          <CameraControlIcon icon="HighDefinition" size="medium" />
          {videoQualityIsChanging ? (
            <ThreeCircles
              height="40"
              width="40"
              color={theme.colors.primary_400}
              visible={true}
              ariaLabel="loading spinner"
            />
          ) : (
            <DropdownMenu
              items={VIDEO_QUALITY_OPTIONS.map(quality => ({
                onSelect: () => {
                  const changeVideoQualityRequest: ChangeVideoQualityRequest = {
                    videoId,
                    videoQuality: quality,
                  };
                  onVideoQualityChange(changeVideoQualityRequest);
                },
                value: quality,
                variant: 'option',
              }))}
              modal={false}
              menuContentProps={{
                style: {
                  minWidth: '140px',
                },
              }}
            >
              <Button size="small" variant="secondary" style={{ width: 140 }}>
                {currentVideoQuality ?? 'Select Quality'}
              </Button>
            </DropdownMenu>
          )}
        </Row>
      </Stack>
    </CameraControlsContainer>
  );
};

export default CameraControls;
