import { Pill } from '@raptormaps/pill';
import { Card } from '@raptormaps/card';
import { Text } from '@raptormaps/text';
import { Stack } from '@raptormaps/layout';
import { Tooltip } from '@raptormaps/tooltip';
import { IssueMissionResponse } from '@raptormaps/dji-dock-api-ts';

// Internal Dependencies
import { LongText } from '../ExecuteFlight.styles';
import { FullWidthRow } from '@/shared/styles/sidebar.styles';

export const IssuedMissionCard = ({
  response,
}: {
  response: IssueMissionResponse;
}) => {
  return (
    <Card style={{ width: '100%' }}>
      <Stack>
        <FullWidthRow
          justify="space-between"
          align="center"
          style={{ paddingRight: '10px' }}
        >
          <Text variant="paragraph_medium_bold">Status</Text>
          <Pill variant={response.status === 'ok' ? 'green' : 'red'}>
            {' '}
            {response.status}{' '}
          </Pill>
        </FullWidthRow>
        <FullWidthRow
          justify="space-between"
          align="center"
          style={{ paddingRight: '10px' }}
        >
          <Text variant="paragraph_medium_bold">Url </Text>
          <Tooltip
            colorVariant="dark"
            delay={100}
            placement={{
              align: 'center',
              side: 'right',
            }}
            tip={`${response.data['data']['file']['url']}`}
            shouldWrapChildren={true}
          >
            <LongText variant="paragraph_medium" align="right">
              {response.data['data']['file']['url']}
            </LongText>
          </Tooltip>
        </FullWidthRow>
        <FullWidthRow
          justify="space-between"
          align="center"
          style={{ paddingRight: '10px' }}
        >
          <Text variant="paragraph_medium_bold">MD5 Signature </Text>
          <Tooltip
            colorVariant="dark"
            delay={100}
            placement={{
              align: 'center',
              side: 'right',
            }}
            tip={`${response.data['data']['file']['fingerprint']}`}
            shouldWrapChildren={true}
          >
            <LongText variant="paragraph_medium" align="right">
              {response.data['data']['file']['fingerprint']}
            </LongText>
          </Tooltip>
        </FullWidthRow>
      </Stack>
    </Card>
  );
};
