// Raptor UI
import { Button } from '@raptormaps/button';
import { Row } from '@raptormaps/layout';
import { Text } from '@raptormaps/text';
import theme from '@raptormaps/theme';

// Telemetry
import { useGetDroneLocation } from '@/shared/stores/TelemetryWebsocket/TelemetryWebsocketStore';

// Map
import { Feature, Point } from '@turf/helpers';

interface DroneLocationButtonProps {
  droneSn: string;
  navigateToDroneLocation: (droneLocation: Feature) => void;
}

export const DroneLocationButton = ({
  droneSn,
  navigateToDroneLocation,
}: DroneLocationButtonProps) => {
  const droneLocation = useGetDroneLocation(droneSn);
  const droneLocationIsAvaiable = !!droneLocation;

  return (
    <Row
      justify="center"
      style={{
        boxShadow:
          'rgba(16, 24, 40, 0.1) 0px 1px 2px -1px, rgba(16, 24, 40, 0.1) 0px 1px 3px 0px',
      }}
    >
      <Button
        icon="Compass"
        size="large"
        variant="tertiary"
        iconPosition="right"
        disabled={!droneLocationIsAvaiable}
        onClick={() => navigateToDroneLocation(droneLocation as Feature<Point>)}
      >
        <Text
          variant="paragraph_large_bold"
          color={
            !droneLocationIsAvaiable
              ? theme.colors.neutral_400
              : theme.colors.black
          }
        >
          Go to Location
        </Text>
      </Button>
    </Row>
  );
};
