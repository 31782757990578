//React
import { useMemo } from 'react';

// Feature Flags
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { GrowthbookFlags } from '@/shared/utils/GrowthbookUtils';

// RaptorUI
import { DropdownMenu, DropdownOption } from '@raptormaps/dropdown-menu';
import { Icon } from '@raptormaps/icon';
import { Row } from '@raptormaps/layout';
import { CheckboxVariant } from '@raptormaps/checkbox/build/Checkbox.interface';
import { Tooltip } from '@raptormaps/tooltip';

// Styled Components
import {
  GridColumnOneSpanTwo,
  QuarterWidthTextInput,
  InlineQuartWidthTextInput,
  FullWidthGridSection,
  GridTextWithElipsis,
  FormLabel,
  InlineCheckboxText,
  LargeCheckbox,
} from '@/pages/MissionPlanner/components/MissionPlanningSidebar/MissionPlanningSidebar.styles';

import { DropdownButton } from '@/shared/styles/input.styles';

// Constants
import {
  NUMBER_INPUT_STEP,
  PERCENT_INPUT_STEP,
  DRONE_CAMERA_DEFAULTS,
} from '@/pages/MissionPlanner/constants/missionConstants';

// Types
import { CAMERA_TYPE_TO_DISPLAY_NAME } from '@/shared/constants/missionLookups';
import { FormParameters } from '@/shared/types/missions';
import { DroneType, CameraType } from '@raptormaps/raptor-flight-client-ts';

// Utils
import { calculateControlledOverlap } from './cameraController.utils';
import { handleInputScroll } from '@/pages/MissionPlanner/utils/utils';

interface CameraControllerProps {
  sideOverlap: number;
  frontOverlap: number;
  device: DroneType;
  cameraType: CameraType;
  formParameters: FormParameters;
  errors: { frontOverlap?: string; sideOverlap?: string };
  onBlur: () => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleEnterPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const CameraController = ({
  device,
  errors,
  sideOverlap,
  frontOverlap,
  cameraType,
  formParameters,
  handleChange,
  onBlur,
  handleEnterPress,
}: CameraControllerProps) => {
  const showCameraSelectWithZoom = useFeatureIsOn(
    GrowthbookFlags.CAMERA_SELECT_WITH_ZOOM,
  );

  // Because we pass the values from the input to state so they can be converted to decimals before being passed to formik we need to handle the enter key press in the same manner.
  const handleOverlapEnterPress = (
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (e.key === 'Enter') {
      onBlur();
    }
  };
  const controlledOverlap = useMemo(() => {
    const { controlledSideOverlap, controlledFrontOverlap } =
      calculateControlledOverlap(cameraType, device, sideOverlap, frontOverlap);
    return { side: controlledSideOverlap, front: controlledFrontOverlap };
  }, [cameraType, frontOverlap, sideOverlap]);

  const cameraTypeOptions: DropdownOption[] = Object.values(CameraType).map(
    (cameraTypeOption: CameraType) => ({
      onSelect: () =>
        handleChange({
          target: { name: 'cameraType', value: cameraTypeOption },
        } as React.ChangeEvent<HTMLInputElement>),
      value: CAMERA_TYPE_TO_DISPLAY_NAME[cameraTypeOption],
      variant: 'option',
      style: { fontSize: '14px', justifyContent: 'start' },
    }),
  );

  return (
    <>
      {showCameraSelectWithZoom && (
        <>
          <FormLabel variant="label_small" style={{ marginBottom: '-15px' }}>
            Camera Type{' '}
          </FormLabel>
          {Object.values(CameraType).map((cameraTypeOption: CameraType, i) => (
            <FullWidthGridSection style={{ marginBottom: '-10px' }}>
              <Row style={{ gridColumn: '1 / span 2' }}>
                <LargeCheckbox
                  name=""
                  checked={cameraType === cameraTypeOption}
                  onChange={handleChange}
                  variant={CheckboxVariant.CHECKBOX}
                />
                <InlineCheckboxText variant={'paragraph_medium'}>
                  {cameraTypeOption}
                </InlineCheckboxText>
              </Row>
              <InlineQuartWidthTextInput
                label={i === 0 ? 'Zoom' : ''}
                style={{ gridColumn: '3 / span 2' }}
                inputSize="small"
                inputStyle="white-box"
                inputType="text"
                step={NUMBER_INPUT_STEP}
                value={'1x'}
                disabled={cameraType !== cameraTypeOption}
                onWheel={handleInputScroll}
                onChange={handleChange}
                onBlur={onBlur}
                onKeyDownCapture={handleEnterPress}
                name="safeTakeoffAltitude"
              />
            </FullWidthGridSection>
          ))}
        </>
      )}

      <FullWidthGridSection
        style={
          errors.frontOverlap && errors.sideOverlap
            ? { marginBottom: '20px' }
            : null
        }
      >
        <GridColumnOneSpanTwo>
          <FormLabel
            title={
              'This input determines which camera FOV will be used to calculate the spacing between images to achieve the desired overlap.'
            }
            variant={'paragraph_small'}
            style={{ marginBottom: '5px' }}
          >
            {' '}
            Overlap Calculation
            <Tooltip
              colorVariant="dark"
              delay={100}
              placement={{
                align: 'center',
                side: 'right',
              }}
              tip="Select the active overlap calculation camera"
              shouldWrapChildren={true}
            >
              <Icon
                aria-label="Overlap Calculation Camera"
                icon="CircleInfo"
                size={'medium'}
                style={{ paddingBottom: '15px' }}
              />
            </Tooltip>
          </FormLabel>
          <DropdownMenu
            menuContentProps={{
              align: 'start',
              sideOffset: 0,
              side: 'bottom',
              style: {
                maxHeight: '70vh',
                width: '219px',
                minWidth: '120px',
                marginTop: '0px',
              },
            }}
            items={cameraTypeOptions}
            modal={false}
          >
            <DropdownButton
              style={{ width: '100%' }}
              disabled={false}
              size="small"
              variant="secondary"
            >
              {CAMERA_TYPE_TO_DISPLAY_NAME[cameraType]}
            </DropdownButton>
          </DropdownMenu>
        </GridColumnOneSpanTwo>
        <QuarterWidthTextInput
          style={{ marginTop: '1.5px' }}
          gridColumn={3}
          label={'Front (%)'}
          name="frontOverlap"
          inputSize="small"
          inputStyle="white-box"
          inputType="number"
          value={(frontOverlap * 100).toFixed(0)}
          onWheel={handleInputScroll}
          onBlur={onBlur}
          step={PERCENT_INPUT_STEP}
          min={formParameters.frontOverlapInput.min * 100}
          max={formParameters.frontOverlapInput.max * 100}
          onChange={e => {
            handleChange({
              target: {
                name: 'frontOverlap',
                value: (parseFloat(e.target.value) / 100).toString(),
              },
            } as React.ChangeEvent<HTMLInputElement>);
          }}
          onKeyDownCapture={handleOverlapEnterPress}
          helperText={
            frontOverlap > 0
              ? errors.frontOverlap
              : 'Front Overlap is required.'
          }
          helperTextStyle={`left: -222px; top: 53px;`}
          className={`${errors.frontOverlap ? 'is-invalid' : ''}`}
        />
        <QuarterWidthTextInput
          style={{ marginTop: '1.5px' }}
          gridColumn={4}
          name="sideOverlap"
          label="Side (%)"
          inputSize="small"
          inputStyle="white-box"
          inputType="number"
          value={(sideOverlap * 100).toFixed(0)}
          onWheel={handleInputScroll}
          onBlur={onBlur}
          step={PERCENT_INPUT_STEP}
          min={formParameters.sideOverlapInput.min * 100}
          max={formParameters.sideOverlapInput.max * 100}
          onChange={e => {
            handleChange({
              target: {
                name: 'sideOverlap',
                value: (parseFloat(e.target.value) / 100).toString(),
              },
            } as React.ChangeEvent<HTMLInputElement>);
          }}
          onKeyDownCapture={handleOverlapEnterPress}
          helperText={
            sideOverlap > 0 ? errors.sideOverlap : 'Side Overlap is required.'
          }
          helperTextStyle={`left: -334px; ${
            errors.frontOverlap && errors.sideOverlap
              ? 'top: 70px;'
              : 'top: 58px;'
          }`}
          className={`${errors.sideOverlap ? 'is-invalid' : ''}`}
        />
      </FullWidthGridSection>
      {Object.keys(DRONE_CAMERA_DEFAULTS[device]).map(
        (cameraTypeOption: CameraType) => (
          <FullWidthGridSection
            style={{ marginTop: '-30px' }}
            key={cameraTypeOption}
          >
            <GridColumnOneSpanTwo>
              <GridTextWithElipsis>
                {' '}
                {CAMERA_TYPE_TO_DISPLAY_NAME[cameraTypeOption]}{' '}
              </GridTextWithElipsis>
            </GridColumnOneSpanTwo>
            <GridTextWithElipsis>
              {' '}
              {cameraType === cameraTypeOption
                ? Math.round(frontOverlap * 100)
                : Math.round(controlledOverlap.front * 100)}
              %{' '}
            </GridTextWithElipsis>
            <GridTextWithElipsis>
              {' '}
              {cameraType === cameraTypeOption
                ? Math.round(sideOverlap * 100)
                : Math.round(controlledOverlap.side * 100)}
              %{' '}
            </GridTextWithElipsis>
          </FullWidthGridSection>
        ),
      )}
    </>
  );
};

export default CameraController;
