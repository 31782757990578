import styled from 'styled-components';
import { Text } from '@raptormaps/text';
import { Icon } from '@raptormaps/icon';
import theme from '@raptormaps/theme';

import { FullWidthRow } from '@/shared/styles/sidebar.styles';

const StyledDeviceIncompatibleError = styled(FullWidthRow)`
  border: 2px solid ${theme.colors.error_400};
  border-radius: 5px;
  background-color: ${theme.colors.error_50};
  padding: 15px;
  svg {
    color: ${theme.colors.error_400};
  }
`;

const IndentedTextBlock = styled(Text)`
  margin-left: 32px;
  margin-top: -8px;
`;

export const DeviceIncompatibleError = () => {
  return (
    <StyledDeviceIncompatibleError gutter="sm">
      <Icon icon={'CircleXmark'} size={'medium'} />
      <Text align="left" variant="paragraph_medium_bold">
        Device incompatible with current mission!
      </Text>
      <IndentedTextBlock align="left" variant="paragraph_medium">
        The selected device cannot be used with the current mission route.
        Please choose a compatible device to proceed.
      </IndentedTextBlock>
    </StyledDeviceIncompatibleError>
  );
};
