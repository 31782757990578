import styled from 'styled-components';
import { Stack, Row } from '@raptormaps/layout';
import theme from '@raptormaps/theme';
import { FloatingSidebarContainer } from '@raptormaps/sidebar';
import { Pill } from '@raptormaps/pill';
import { IconButton } from '@raptormaps/button';

export const SiteOverviewContainer = styled(Stack)`
  overflow: hidden;
`;

export const Divider = styled(Row)`
  height: 1px;
  background-color: ${theme.colors.grey_300};
  border-bottom: 2px solid ${theme.colors.grey_300};
`;

export const CardPadding = styled.div`
  margin-bottom: 1rem;
`;

export const SidebarBottomPadding = styled.div`
  margin-bottom: 0.5rem;
`;

export const CollapsibleTopPadding = styled.div`
  margin: 0;
`;

export const ClickableCardContainer = styled.div`
  cursor: pointer;
`;

export const CardContentsContainer = styled.div`
  flex-grow: 1;
`;

export const OverviewSidebarContainer = styled(FloatingSidebarContainer)`
  & > :nth-child(1) > [data-state='open'] {
    width: 496px;
  }

  svg {
    height: 16px;
    width: 16px;
  }
`;

export const StyledFlashingStatusPill = styled(Pill)`
  ${props =>
    props.isFlashing &&
    `
    animation: blinker 1s step-start infinite;

    @keyframes blinker {
    50% {
      opacity: 0.5;
    }`}
`;

export const DeviceCardTitleRowWithEllipsis = styled(Row)`
  max-width: 240px;
  cursor: ${props => (props.pointer ? 'pointer' : 'default')};

  h3 {
    width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const IconButtonNoHighlight = styled(IconButton)`
  &:hover {
    background-color: transparent !important;
  }
`;

export const DeviceErrorContainer = styled.div`
  background-color: ${theme.colors.red_50};
  padding: 16px 24px 16px 32px;
  gap: 8px;
  radius: 4px;
  border: 1px;
  height: 96px;
  line-height: 20px;
  border: 1px solid ${theme.colors.red_500};
  margin-top: 1rem;
`;
