// External Dependencies
import { mapbox, Layers, Layer } from 'mapbox-gl';

// RaptorUI
import { Tooltip } from '@raptormaps/tooltip';

// Internal Dependencies
import MapLayerSlider from './MapLayerSlider/MapLayerSlider';
import { EquipmentLayerData } from '../mapDetails';
import Mixpanel from '../../../utils/mixpanel/utils';
import MixpanelEvents from '../../../utils/mixpanel/events';
import { FlightDetailLayers } from '../mapDetailsConstants';

interface EquipmentSliderProps {
  map: mapbox.Map;
  equipmentLayerData?: EquipmentLayerData;
  disabled?: boolean;
  disabledTooltipMessage?: string;
  visibility: { opacity: number; visible: boolean };
  setVisibility: (newState: { opacity?: number; visible?: boolean }) => void;
}

export const EquipmentSlider = ({
  map,
  equipmentLayerData = { name: 'No layers', layers: [], data: null },
  disabled = false,
  disabledTooltipMessage = 'Layer is not available',
  visibility,
  setVisibility,
}: EquipmentSliderProps) => {
  const { name, layers } = equipmentLayerData;
  const { opacity, visible } = visibility || { opacity: 100, visible: true };

  const handleChangeOpacity = (opacity: number, layers: Layers) => {
    layers.map((layer: Layer) => {
      if (layer.type === 'symbol') {
        map.setPaintProperty(layer.id, 'icon-opacity', opacity / 100);
        map.setPaintProperty(layer.id, 'text-opacity', opacity / 100);
      }
      if (layer.type === 'line') {
        map.setPaintProperty(layer.id, 'line-opacity', opacity / 100);
      }
      if (layer.type === 'fill') {
        map.setPaintProperty(layer.id, 'fill-opacity', opacity / 100);
      }
      if (layer.type === 'raster') {
        map.setPaintProperty(layer.id, 'raster-opacity', opacity / 100);
      }
    });
    setVisibility({ opacity });
  };

  const handleHideShowLayer = () => {
    if (name === FlightDetailLayers.flightPath) {
      Mixpanel.track(MixpanelEvents.PlanWaypointIconToggle);
    }
    layers.map((layer: Layer) => {
      if (map.getLayoutProperty(layer.id, 'visibility') != 'none') {
        map.setLayoutProperty(layer.id, 'visibility', 'none');
        setVisibility({ visible: false });
      } else {
        map.setLayoutProperty(layer.id, 'visibility', 'visible');
        setVisibility({ visible: true });
      }
    });
  };

  return disabled ? (
    <Tooltip
      colorVariant="dark"
      delay={100}
      placement={{
        align: 'center',
        side: 'right',
      }}
      tip={disabledTooltipMessage}
      shouldWrapChildren={true}
    >
      <MapLayerSlider
        name={name}
        opacity={opacity}
        layers={layers}
        visible={visible}
        disabled={disabled}
        handleChangeOpacity={handleChangeOpacity}
        handleHideShowLayer={handleHideShowLayer}
      />
    </Tooltip>
  ) : (
    <MapLayerSlider
      name={name}
      opacity={opacity}
      layers={layers}
      visible={visible}
      handleChangeOpacity={handleChangeOpacity}
      handleHideShowLayer={handleHideShowLayer}
    />
  );
};
