import { useCallback, useEffect, useMemo, useState } from 'react';

// Hooks
import { useToast } from '@raptormaps/toast';
import { useMutation } from 'react-query';

// API
import {
  StartLiveStreamLiveStreamStartPostRequest,
  StopLiveStreamLiveStreamStopPostRequest,
  UpdateVideoQualityLiveStreamVideoQualityPostRequest,
  LiveStreamApi,
} from '@raptormaps/dji-dock-api-ts';
import { useRoboticsHubApi } from './useRoboticsHubApi';
import {
  DeviceType,
  DolbyDeviceResponse,
} from '@raptormaps/raptor-flight-client-ts';

// Types
import { DockDevice, LiveCapacityStatus } from '../types/devices';
import {
  DJILiveCapacity,
  DJILiveStatus,
} from '../constants/djiTelemetryConstants';
import { ERROR_TOAST_DURATION } from '../constants/appConstants';

// Dolby
import {
  DolbyLivestreamState,
  useDolbyLivestreamStore,
  WHIP_ENDPOINT_BASE,
} from '../stores/DolbyLivestreamStore';

// Telemetry
import { useShallow } from 'zustand/react/shallow';
import { useTelemetryStore } from '../stores/TelemetryWebsocket/TelemetryWebsocketStore';
import { DJILiveCapacityConverter } from '../utils/telemetryUtils';
import {
  TELEMETRY_EVENTS,
  TelemetryEmitter,
} from '../stores/TelemetryWebsocket/TelemetryEmitter';
const ROBOTICS_API_ENDPOINT = window.ROBOTICS_HUB_DJI_DOCK_API_ENDPOINT;

interface useLivestreamProps {
  dockDevice: DockDevice;
}

interface startDeviceLivestreamProps {
  dolbyCredentials: DolbyDeviceResponse;
  deviceSn: string;
  deviceType: DeviceType;
}

export const useLivestream = ({ dockDevice }: useLivestreamProps) => {
  const [startingLivestreamInProgress, setStartingLivestreamInProgress] =
    useState(false);
  const [stoppingLivestreamInProgress, setStoppingLivestreamInProgress] =
    useState(false);

  const toast = useToast();

  const startDeviceLivestreamMutation = useStartDeviceLivestream();
  const stopDeviceLivestreamMutation = useStopDeviceLivestream();

  const handleSetStartLivestreamComplete = useCallback(() => {
    setStartingLivestreamInProgress(false);
  }, []);
  const handleSetStopLivestreamComplete = useCallback(() => {
    setStoppingLivestreamInProgress(false);
  }, []);

  useEffect(() => {
    TelemetryEmitter.on(
      TELEMETRY_EVENTS.LIVESTREAM_BECAME_AVAILABLE,
      handleSetStartLivestreamComplete,
    );

    return () => {
      TelemetryEmitter.off(
        TELEMETRY_EVENTS.LIVESTREAM_BECAME_AVAILABLE,
        handleSetStartLivestreamComplete,
      );
    };
  }, []);

  useEffect(() => {
    TelemetryEmitter.on(
      TELEMETRY_EVENTS.LIVESTREAM_BECAME_UNAVAILABLE,
      handleSetStopLivestreamComplete,
    );

    return () => {
      TelemetryEmitter.off(
        TELEMETRY_EVENTS.LIVESTREAM_BECAME_UNAVAILABLE,
        handleSetStopLivestreamComplete,
      );
    };
  }, []);

  // Unconverted Base Value
  const telemetryLiveCapacityState: DJILiveCapacity = useTelemetryStore(
    useShallow(
      state =>
        state?.devicesTelemetry[dockDevice.deviceSn]?.liveCapacity?.value,
    ),
  );

  // Unconverted Base Value
  const telemetryLivestreamStatus: DJILiveStatus[] = useTelemetryStore(
    useShallow(
      state =>
        state?.devicesTelemetry[dockDevice.deviceSn]?.livestreamStatus?.value,
    ),
  );

  const viewerCount = useDolbyLivestreamStore(
    useShallow((state: DolbyLivestreamState) => state.viewerCount),
  );

  const isLastViewer = useMemo(() => viewerCount <= 1, [viewerCount]);

  // Converted Live Capacity State
  const devicesLiveCapacityState: LiveCapacityStatus[] = useMemo(() => {
    return DJILiveCapacityConverter(telemetryLiveCapacityState);
  }, [telemetryLiveCapacityState]);

  const livestreamInProgress = useMemo(() => {
    return !!telemetryLivestreamStatus?.length;
  }, [telemetryLivestreamStatus]);

  const activeDeviceLiveStatus: DJILiveStatus = useMemo(() => {
    if (!telemetryLivestreamStatus?.length) {
      return null;
    }

    return telemetryLivestreamStatus[0];
  }, [telemetryLivestreamStatus]);

  const generateVideoId = (deviceSn: string) => {
    if (!dockDevice || !devicesLiveCapacityState?.length) {
      return null;
    }

    const deviceLivestreamState = devicesLiveCapacityState.find(
      device => device.sn === deviceSn,
    );

    if (!deviceLivestreamState) {
      return null;
    }

    const defaultCameraIndex = deviceLivestreamState.cameras[0].index;
    const defaultVideoIndex =
      deviceLivestreamState.cameras[0].videoList[0].index;

    // DJI Video ID Structure: {sn}/{camera_index}/{video_index}
    return `${deviceSn}/${defaultCameraIndex}/${defaultVideoIndex}`;
  };

  const generateWhipPublishingUrl = (
    dolbyCredentials: DolbyDeviceResponse,
  ): string | null => {
    if (!dolbyCredentials || !devicesLiveCapacityState?.length) {
      return null;
    }
    return `${WHIP_ENDPOINT_BASE + dolbyCredentials.streamName}?token=${
      dolbyCredentials.publishingToken
    }`;
  };

  const deviceIsAlreadyStreaming = (deviceSn: string): boolean => {
    const deviceLiveStatus = telemetryLivestreamStatus.find(
      (deviceLiveStatus: DJILiveStatus) => {
        const deviceLiveStatusSn = deviceLiveStatus.video_id.split('/')[0];
        return deviceLiveStatusSn === deviceSn;
      },
    );

    return !!deviceLiveStatus;
  };

  const startDeviceLivestream = async ({
    dolbyCredentials,
    deviceSn,
    deviceType,
  }: startDeviceLivestreamProps) => {
    try {
      if (deviceIsAlreadyStreaming(deviceSn)) {
        return;
      }

      if (deviceType === DeviceType.Dock) {
        setStartingLivestreamInProgress(true); // Updates on emitted event
      }
      const videoId = generateVideoId(deviceSn);

      if (!videoId) {
        throw new Error(
          'Unable to start live stream. Missing required device video data.',
        );
      }

      const url = generateWhipPublishingUrl(dolbyCredentials);

      if (!url) {
        throw new Error(
          'Unable to start live stream. Missing required credentials.',
        );
      }

      await startDeviceLivestreamMutation.mutateAsync({
        dockSn: dockDevice.deviceSn,
        videoId,
        url,
      });
    } catch (err) {
      toast.error(err.message);
      setStartingLivestreamInProgress(false);
    }
  };

  // Part of a hacky workaround to support changing camera
  const stopDroneLivestream = () => {
    try {
      const droneSn = dockDevice.devices.find(
        device => device.deviceType === DeviceType.AerialDrone,
      )?.deviceSn;

      if (!droneSn) {
        throw new Error('No active live stream to stop.');
      }

      const droneLiveStatus = telemetryLivestreamStatus.find(
        (deviceStatus: DJILiveStatus) => {
          const deviceStatusSn = deviceStatus.video_id.split('/')[0];
          return deviceStatusSn === droneSn;
        },
      );

      // Structure {sn}/{camera_index}/{video_index}
      const videoId = droneLiveStatus?.video_id;

      if (!videoId) {
        throw new Error('Unable to locate drone stream.');
      }

      stopDeviceLivestreamMutation.mutateAsync({
        dockSn: dockDevice.deviceSn,
        videoId,
      });
    } catch (err) {
      toast.error(err.message);
    }
  };

  const stopAllDeviceLivestreams = () => {
    try {
      if (!telemetryLivestreamStatus?.length) {
        throw new Error('No active live stream to stop.');
      }

      setStoppingLivestreamInProgress(true); // Updates on emitted event

      telemetryLivestreamStatus.forEach((deviceLiveStatus: DJILiveStatus) => {
        const videoId = deviceLiveStatus.video_id;
        stopDeviceLivestreamMutation.mutate({
          dockSn: dockDevice.deviceSn,
          videoId,
        });
      });
    } catch (err) {
      toast.error(err.message);
    }
  };

  return {
    devicesLiveCapacityState,
    telemetryLivestreamStatus,
    livestreamInProgress,
    isLastViewer,
    startDeviceLivestream,
    startingLivestreamInProgress,
    stoppingLivestreamInProgress,
    activeDeviceLiveStatus,
    stopAllDeviceLivestreams,
    stopDroneLivestream,
  };
};

export const useStartDeviceLivestream = () => {
  const api = useRoboticsHubApi(LiveStreamApi, {
    basePath: window.ROBOTICS_HUB_DJI_DOCK_API_ENDPOINT,
  });

  return useMutation({
    mutationKey: 'startLivestream',
    mutationFn: async ({
      dockSn,
      videoId,
      url,
    }: StartLiveStreamLiveStreamStartPostRequest) => {
      return await api.startLiveStreamLiveStreamStartPost({
        dockSn,
        videoId,
        url,
      });
    },
  });
};

export const useStopDeviceLivestream = () => {
  const api = useRoboticsHubApi(LiveStreamApi, {
    basePath: window.ROBOTICS_HUB_DJI_DOCK_API_ENDPOINT,
  });

  return useMutation({
    mutationKey: 'stopLivestream',
    mutationFn: async ({
      dockSn,
      videoId,
    }: StopLiveStreamLiveStreamStopPostRequest) => {
      return await api.stopLiveStreamLiveStreamStopPost({
        dockSn,
        videoId,
      });
    },
  });
};

export const useUpdateLivestreamVideoQuality = () => {
  const api = useRoboticsHubApi(LiveStreamApi, {
    basePath: window.ROBOTICS_HUB_DJI_DOCK_API_ENDPOINT,
  });

  return useMutation({
    mutationKey: 'updateLivestreamVideoQuality',
    mutationFn: async ({
      dockSn,
      videoId,
      videoQuality,
    }: UpdateVideoQualityLiveStreamVideoQualityPostRequest) => {
      return await api.updateVideoQualityLiveStreamVideoQualityPost({
        dockSn,
        videoId,
        videoQuality,
      });
    },
  });
};

export const useDockLensSwitch = () => {
  const toast = useToast();
  const api = useRoboticsHubApi(LiveStreamApi, {
    basePath: ROBOTICS_API_ENDPOINT,
  });

  return useMutation({
    mutationKey: 'droneLens',
    mutationFn: async ({ dockSn, videoId, videoType }: any) => {
      if (!dockSn || !videoId || !videoType) {
        toast.error(`Failed to switch drone lens`, {
          duration: ERROR_TOAST_DURATION,
          dismissable: true,
        });
        return;
      }
      return await api.updateLensLiveStreamLensPost({
        dockSn,
        videoId,
        videoType,
      });
    },
    onSuccess: response => {
      response?.status &&
        toast.success(`Lens switched:${JSON.stringify(response.status)}`);
    },
    onError: error => {
      toast.error(`Failed to switch drone lens: ${error}`, {
        duration: ERROR_TOAST_DURATION,
        dismissable: true,
      });
    },
  });
};
