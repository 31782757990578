import {
  useEffect,
  useState,
  useRef,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';

// Raptor UI
import { Card } from '@raptormaps/card';
import { IconButton } from '@raptormaps/button';
import { Stack, Row } from '@raptormaps/layout';

// Components
import { LivestreamViewerModalHeader } from './LiveStreamViewerModalHeader';

// Styles
import {
  LiveSreamViewerModalContainer,
  ViewerBackgroundContainer,
  VIDEO_WIDTH,
  VIDEO_HEIGHT,
} from './DockOverview.styled';

// Draggable
import Draggable from 'react-draggable';

// Live Stream Store
import {
  DolbyLivestreamState,
  useDolbyLivestreamStore,
} from '@/shared/stores/DolbyLivestreamStore';

// Constants
import {
  DeviceType,
  DolbyDeviceResponse,
} from '@raptormaps/raptor-flight-client-ts';
import { DockDevice } from '@/shared/types/devices';

interface LiveStreamViewerModalProps {
  dolbyCredentials: DolbyDeviceResponse;
  setVideoPlayerLoading: Dispatch<SetStateAction<boolean>>;
  dockDevice: DockDevice;
  handleSwitchDeviceCamera: (deviceSn: string, deviceType: DeviceType) => void;
}

const LiveStreamViewerModal = ({
  dolbyCredentials,
  setVideoPlayerLoading,
  dockDevice,
  handleSwitchDeviceCamera,
}: LiveStreamViewerModalProps) => {
  const [isMinimized, setIsMinimized] = useState(false);

  const minimizeView = () => setIsMinimized(true);
  const maximizeView = () => setIsMinimized(false);

  const isSubscribed = useDolbyLivestreamStore(
    (state: DolbyLivestreamState) => state.isSubscribed,
  );

  const openDolbyStream = useDolbyLivestreamStore(
    (state: DolbyLivestreamState) => state.connectToDolbyStream,
  );

  const videoRef = useRef(null);

  const handleSetVideoPlayerLoading = useCallback(() => {
    setVideoPlayerLoading(true);
  }, []);

  const handleSetVideoPlayerNotLoading = useCallback(() => {
    setVideoPlayerLoading(false);
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener(
        'loadstart',
        handleSetVideoPlayerLoading,
      );

      videoRef.current.addEventListener(
        'loadedmetadata',
        handleSetVideoPlayerNotLoading,
      );

      return () => {
        if (videoRef.current) {
          videoRef.current.removeEventListener(
            'loadstart',
            handleSetVideoPlayerLoading,
          );

          videoRef.current.removeEventListener(
            'loadedmetadata',
            handleSetVideoPlayerNotLoading,
          );
        }
      };
    }
  });

  useEffect(() => {
    if (!dolbyCredentials || !videoRef.current || isSubscribed) return;

    openDolbyStream({
      videoRef: videoRef.current,
      streamName: dolbyCredentials.streamName,
      streamAccountId: dolbyCredentials.accountId,
      subscriberToken: dolbyCredentials.subscriberToken,
    });
  }, [videoRef, isSubscribed, dolbyCredentials]);

  return (
    <Draggable>
      <LiveSreamViewerModalContainer>
        <Card style={{ padding: 0 }}>
          <Stack gutter="xxs">
            <Row
              justify="space-between"
              align="center"
              gutter="xxs"
              style={{
                width: '100%',
                padding: '12px 12px 0 12px',
              }}
            >
              <LivestreamViewerModalHeader
                dockDevice={dockDevice}
                handleSwitchDeviceCamera={handleSwitchDeviceCamera}
              />
              <Row gutter="xxs" justify="space-between" align="center">
                {isMinimized ? (
                  <IconButton
                    aria-label="maximize-live-stream-modal-icon"
                    icon="Enlarge"
                    size="large"
                    variant="tertiary"
                    onClick={() => maximizeView()}
                  />
                ) : (
                  <IconButton
                    aria-label="minimize-live-stream-modal-icon"
                    icon="WindowMinimize"
                    size="large"
                    variant="tertiary"
                    onClick={() => minimizeView()}
                  />
                )}
              </Row>
            </Row>
            <ViewerBackgroundContainer>
              <video
                autoPlay
                playsInline
                width={VIDEO_WIDTH}
                height={VIDEO_HEIGHT}
                controls
                style={{ display: isMinimized ? 'none' : 'block' }}
                muted
                ref={videoRef}
              />
            </ViewerBackgroundContainer>
          </Stack>
        </Card>
      </LiveSreamViewerModalContainer>
    </Draggable>
  );
};

export default LiveStreamViewerModal;
