// External Dependencies
import { useMemo, useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import {
  MissionResponse,
  DeviceResponse,
  DeviceType,
} from '@raptormaps/raptor-flight-client-ts';
// RaptorUI
import { Text } from '@raptormaps/text';
import { Button } from '@raptormaps/button';
import { Stack, Row } from '@raptormaps/layout';

// Internal Dependencies
import { useGetDevices } from '@/shared/hooks/useDevices';
import { useGetDockDeviceIds } from '@/shared/hooks/useDockDevices';
import { useGetDeviceModels } from '@/shared/hooks/useDeviceModels';
import {
  MISSION_TASK_FORM_FIELDS,
  DEVICE_MODEL_NAME_TO_DRONE_TYPE,
} from '@/pages/MissionTasks/MissionTaskPlanner/missionTaskFormConstants';
// Components
import { DockDeviceCard } from '@/shared/components/DockDeviceCard/DockDeviceCard';
import { MissionCard } from '@/shared/components/MissionCard/MissionCard';
import { ComponentLoader } from '@/shared/components/ComponentLoader/ComponentLoader';
import { MissionTaskPopovers } from '@/pages/MissionTasks/MissionTaskPlanner/missionTask.d';
import { DeviceIncompatibleError } from './DeviceIncompatibleError';

interface MissionAndDeviceProps {
  activeMission: MissionResponse;
  missionTaskForm: UseFormReturn;
  solarFarmId: number;
  backButtonPopover: MissionTaskPopovers;
  isLoadingMission: boolean;
  setBackButtonPopover: (backButtonPopover: MissionTaskPopovers) => void;
  setIsSideBarOpen: (isOpen: boolean) => void;
  setVisiblePopover: (visiblePopover: MissionTaskPopovers) => void;
  setInvalidDroneForMission: (invalidDrone: boolean) => void;
}

export const MissionAndDeviceContainer = ({
  activeMission,
  missionTaskForm,
  solarFarmId,
  backButtonPopover,
  isLoadingMission,
  setBackButtonPopover,
  setIsSideBarOpen,
  setVisiblePopover,
  setInvalidDroneForMission,
}: MissionAndDeviceProps) => {
  const { data, isFetching: isLoadingDevices } = useGetDevices({ solarFarmId });
  const { data: dockDeviceIds, isFetching: isLoadingDevice } =
    useGetDockDeviceIds({ solarFarmId });
  const { data: models, isFetching: isLoadingDeviceModels } =
    useGetDeviceModels({ solarFarmId });

  const handleOpenPopover = (popover: MissionTaskPopovers) => {
    setVisiblePopover(popover);
    setIsSideBarOpen(false);
  };

  const activeDockDevice = useMemo(() => {
    const apiDock = data.solarFarmDevices.find(
      (device: DeviceResponse) =>
        device.id ===
        missionTaskForm.getValues(MISSION_TASK_FORM_FIELDS.dockId),
    );
    if (!apiDock) return null;
    else {
      const dock = { ...apiDock };
      dock.deviceIds = dockDeviceIds?.find(
        dockDevices => dockDevices.dockId === dock.id,
      )?.deviceIds;

      const dockDevices = dock.deviceIds?.map((deviceId: number) => {
        return data.solarFarmDevices.find(
          (device: DeviceResponse) => device.id === deviceId,
        );
      });

      const dockDroneModel = models.deviceModels.find(
        model =>
          model.id ===
          dockDevices?.find(
            device => device?.deviceType === DeviceType.AerialDrone,
          )?.deviceModelId,
      );
      return { ...dock, devices: dockDevices, dockDroneModel };
    }
  }, [data.solarFarmDevices, missionTaskForm]);

  const checkInvalidDrone =
    activeMission &&
    activeDockDevice &&
    activeMission.drone !=
      DEVICE_MODEL_NAME_TO_DRONE_TYPE[
        activeDockDevice?.dockDroneModel?.modelName
      ];

  useEffect(() => {
    setInvalidDroneForMission(!!checkInvalidDrone);
  }, [checkInvalidDrone]);

  return (
    <Stack style={{ width: '100%' }}>
      <Row align="center" justify="space-between">
        <Text variant="paragraph_small" align="left">
          Mission Route
        </Text>
        <Button
          icon="ArrowRight"
          iconPosition="right"
          size="small"
          variant={activeMission ? 'tertiary' : 'primary'}
          onClick={() => handleOpenPopover('missionList')}
        >
          Select Mission
        </Button>
      </Row>
      {isLoadingMission && !activeMission ? (
        <ComponentLoader message={'Loading Mission...'} />
      ) : (
        activeMission && (
          <MissionCard
            mission={activeMission}
            missionTaskForm={missionTaskForm}
            backButtonPopover={backButtonPopover}
            isSelected={false}
            setBackButtonPopover={setBackButtonPopover}
            setVisiblePopover={setVisiblePopover}
          />
        )
      )}

      <Row align="center" justify="space-between">
        <Text variant="paragraph_small" align="left">
          Device
        </Text>
        <Button
          icon="ArrowRight"
          iconPosition="right"
          size="small"
          variant={activeDockDevice ? 'tertiary' : 'primary'}
          onClick={() => handleOpenPopover('deviceList')}
        >
          Select Device
        </Button>
      </Row>
      {(isLoadingDevice ||
        isLoadingDeviceModels ||
        isLoadingDevices ||
        isLoadingMission) &&
      !activeDockDevice ? (
        <ComponentLoader message={'Loading Device...'} />
      ) : (
        <>
          {checkInvalidDrone && <DeviceIncompatibleError />}
          {activeDockDevice && (
            <DockDeviceCard
              dockDevice={activeDockDevice}
              isSelected={activeDockDevice}
            />
          )}
        </>
      )}
    </Stack>
  );
};
