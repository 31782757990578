import { GeoJSONSource } from '../map.d';
export const STRING_SOURCE_ID = 'raptor-strings';

const StringSource: GeoJSONSource = {
  id: STRING_SOURCE_ID,
  source: {
    type: 'geojson',
    data: { type: 'FeatureCollection', features: [] },
  },
};
const StringSources: GeoJSONSource[] = [StringSource];

export default StringSources;
