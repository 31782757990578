// External Dependencies
import { useState } from 'react';
import { Row } from '@raptormaps/layout';
import { Button, IconButton } from '@raptormaps/button';
import { ThreeCircles } from 'react-loader-spinner';
import { DropdownMenu } from '@raptormaps/dropdown-menu';
import theme from '@raptormaps/theme';

// Components
import SaveAsModal from '@/pages/MissionPlanner/components/shared/SaveAsModal';
import DownloadModal from '@/pages/MissionPlanner/components/shared/DownloadModal/DownloadModal';

// Utils
import { useUrlSearchParams } from '@/shared/hooks/useUrlParams';

interface SaveSectionProps {
  missionName: string;
  isLoading: boolean;
  saveAsDisabled: boolean;
  saveDisabled: boolean;
  downloadDisabled: boolean;
  downloadHelperText: string;
  buttonText: string;
  handleSave: () => void;
  handleSaveAs: (missionName: string) => void;
  handleDownload: (filename: string) => void;
}

const SaveSection = ({
  missionName,
  isLoading,
  saveDisabled,
  saveAsDisabled,
  downloadDisabled,
  buttonText,
  downloadHelperText,
  handleSave,
  handleSaveAs,
  handleDownload,
}: SaveSectionProps) => {
  const solarFarmId = useUrlSearchParams<number>('solar_farm_id', 'number');

  const [openSaveAsModal, setOpenSaveAsModal] = useState<boolean>(false);
  const [openDownloadModal, setOpenDownloadModal] = useState<boolean>(false);

  if (isLoading) {
    return (
      <Row justify="center" style={{ marginTop: '24px', marginBottom: '20px' }}>
        <ThreeCircles
          height="35"
          width="35"
          color={theme.colors.primary_400}
          visible={true}
          ariaLabel="loading spinner"
        />
      </Row>
    );
  }
  return (
    <Row style={{ marginTop: '24px', marginBottom: '20px' }}>
      <Button
        flex
        variant="primary"
        onClick={handleSave}
        disabled={saveDisabled}
        style={{ width: '100%' }}
      >
        {buttonText}
      </Button>
      <DropdownMenu
        items={[
          {
            onSelect: () => {
              setOpenSaveAsModal(true);
            },
            value: 'Save As',
            variant: 'option',
            disabled: saveAsDisabled,
          },
          {
            onSelect: () => {
              setOpenDownloadModal(true);
            },
            value: 'Export Mission',
            variant: 'option',
            disabled: downloadDisabled,
          },
        ]}
        modal={false}
      >
        <IconButton
          data-testid="options-menu"
          aria-label="Open menu."
          icon="Gear"
          variant="tertiary"
          style={{
            height: 40,
            display: 'flex',
            alignItems: 'flex-start',
            alignSelf: 'center',
          }}
        />
      </DropdownMenu>
      <DownloadModal
        modal={openDownloadModal}
        setModal={setOpenDownloadModal}
        disabled={downloadDisabled}
        handleDownload={handleDownload}
        helperText={downloadHelperText}
      />
      <SaveAsModal
        solarFarmId={solarFarmId}
        currentName={missionName}
        setOpenSaveAsModal={setOpenSaveAsModal}
        openSaveAsModal={openSaveAsModal}
        handleCreateMission={handleSaveAs}
      />
    </Row>
  );
};

export default SaveSection;
