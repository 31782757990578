import * as Yup from 'yup';

export const MissionTaskSchema = Yup.object().shape({
  missionTaskName: Yup.string()
    .label('Mission Task Name')
    .required('Mission Task Name is required'),
  dockId: Yup.number().label('Dock Id').nullable(),
  droneId: Yup.number().label('Drone Id').nullable(),
  estimatedDurationSeconds: Yup.number().label('Estimated Duration').nullable(),
  missionIds: Yup.array().of(Yup.number()).label('Mission Ids'),
  orderId: Yup.number().label('Order Id').nullable(),
  scheduledStartTsecs: Yup.number().label('Scheduled Start Time').nullable(),
});
