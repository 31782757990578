export const COLUMNS: Array<{
  name: string;
  key: string;
}> = [
  {
    name: 'Mission Name',
    key: 'missionName',
  },
  {
    name: 'Inspection Type',
    key: 'inspectionType',
  },
  {
    name: 'Drone Model',
    key: 'droneModel',
  },
  {
    name: 'Last Modified Time',
    key: 'lastModifiedTime',
  },
  {
    name: 'Last Modified By',
    key: 'lastModifiedBy',
  },
  { name: '', key: 'more' },
];
