const UNKNOWN_ERROR = 'An unknown error occurred. Please try again.';
/**
 * Parse details from a raptor flight api error response
 * @param {*} err - api error object
 * @returns - error details or empty string
 */
export const parseFlightApiErrorDetails = async (err, altMessage) => {
  try {
    const errorBody = await err.response.json();
    return errorBody?.detail ?? altMessage ?? UNKNOWN_ERROR;
  } catch (err) {
    return altMessage ?? UNKNOWN_ERROR;
  }
};

/**
 * Parse details from a customer farm api error response
 * @param {*} err - api error object
 * @returns - error details or empty string
 */
export const parseCustomerFarmApiErrorDetails = async (err, altMessage) => {
  try {
    let e = await err.text();
    e = JSON.parse(e);

    return e?.detail ?? altMessage ?? UNKNOWN_ERROR;
  } catch (err) {
    return altMessage ?? UNKNOWN_ERROR;
  }
};

export const average = array => array.reduce((a, b) => a + b) / array.length;

export const calculateBatteryAverage = (batteries: number[]) => {
  if (!batteries || batteries.length === 0) {
    return null;
  }

  return batteries.reduce((a, b) => a + b) / batteries.length;
};
