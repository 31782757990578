import { Layers } from 'mapbox-gl';
import { INVERTER_BLOCK_SOURCE_ID } from '../sources/InverterBlockSources';
import theme from '@raptormaps/theme';

// Cluster Constants
const INVERTER_BLOCK_BORDER_COLOR = theme.colors.neutral_900;
const INVERTER_BLOCK_BORDER_WIDTH = 2;
const INVERTER_BLOCK_BORDER_OPACITY = 1;
const INVERTER_BLOCK_FILL_COLOR = theme.colors.neutral_200;
export const SECTION_ZONE_CLUSTER_FILL_OPACITY = 0.5;

const InverterBlockZones: Layers = [
  {
    id: 'farm-section-zone-layer-line',
    type: 'line',
    minzoom: 12,
    source: INVERTER_BLOCK_SOURCE_ID,
    paint: {
      'line-color': INVERTER_BLOCK_BORDER_COLOR,
      'line-width': INVERTER_BLOCK_BORDER_WIDTH,
      'line-opacity': INVERTER_BLOCK_BORDER_OPACITY,
    },
  },
  {
    id: 'farm-section-zone-layer-fill',
    type: 'fill',
    minzoom: 12,
    source: INVERTER_BLOCK_SOURCE_ID,
    paint: {
      'fill-color': INVERTER_BLOCK_FILL_COLOR,
      'fill-opacity': SECTION_ZONE_CLUSTER_FILL_OPACITY,
    },
  },
];

export const InverterBlockNameLayer: Layers[number] = {
  id: `farm-section-zone-layer-text`,
  type: 'symbol',
  minzoom: 12,
  source: INVERTER_BLOCK_SOURCE_ID,
  layout: {
    'symbol-placement': 'point',
    'text-field': ['get', 'name'],
    'text-size': 14,
    'text-font': ['Roboto Black'],
    'text-anchor': 'center',
    'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
    'text-radial-offset': 0.5,
    'text-offset': [0, 0.5],
    'text-justify': 'auto',
    visibility: 'visible',
  },
  paint: {
    'text-color': 'black',
  },
};

const InverterBlockLayers: Layers = [
  ...InverterBlockZones,
  InverterBlockNameLayer,
];

export default InverterBlockLayers;
