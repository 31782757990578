import styled from 'styled-components';
import { Stack } from '@raptormaps/layout';
import theme from '@raptormaps/theme';

export const InstructionsBubble = styled(Stack)`
  width: 100%;
  background-color: ${theme.colors.blue_100};
  padding: 10px;
  padding-left: 10px;
  border-radius: 4px;
  margin-top: -10px;
  margin-bottom: -10px;
  border: 1px solid ${theme.colors.blue_500};
`;
