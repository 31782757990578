import { useSearchParams, useParams } from 'react-router-dom';

export const useUrlSearchParams = <T>(
  searchParam: string,
  type: 'string' | 'number' | 'boolean' = 'string',
): T | null => {
  const [searchParams] = useSearchParams();
  const value = searchParams.get(searchParam);

  if (!value) return null;

  switch (type) {
    case 'string':
      return value as T;
    case 'number':
      if (isNaN(parseInt(value))) {
        throw new Error(
          `Invalid number value provided for search parameter '${searchParam}'`,
        );
      }
      return parseInt(value) as T;
    case 'boolean':
      return (value === 'true') as T;
    default:
      throw new Error(`Invalid type ${type} provided`);
  }
};

export const useUrlParams = <T>(
  param: string,
  type: 'string' | 'number' = 'string',
): T | null => {
  const params = useParams();
  const value = params[param];

  if (!value) return null;

  switch (type) {
    case 'string':
      return value as T;
    case 'number':
      if (isNaN(parseInt(value))) {
        throw new Error(`Invalid number value provided for URL param ${param}`);
      }
      return parseInt(value) as T;
    default:
      throw new Error(`Invalid type ${type} provided`);
  }
};
