import { useMutation } from 'react-query';
import { WaylineApi } from '@raptormaps/dji-dock-api-ts';
import { useToast } from '@raptormaps/toast';

import { useRoboticsHubApi } from './useRoboticsHubApi';
import { useAppContext } from '../context/AppContext';
import { ERROR_TOAST_DURATION } from '../constants/appConstants';

export const useDockMission = (solarFarmId: number, realFlight: boolean) => {
  const toast = useToast();
  const api = useRoboticsHubApi(WaylineApi, {
    basePath: window.ROBOTICS_HUB_DJI_DOCK_API_ENDPOINT,
  });
  const { user } = useAppContext();
  const orgId = user.latest_org_id;

  return useMutation({
    mutationKey: 'issueMissionWayline',
    mutationFn: async ({
      dockSn,
      flightId,
    }: {
      dockSn: string;
      flightId: string;
    }) => {
      return await api.issueMissionWaylineIssuePost({
        dockSn,
        flightId,
        solarFarmId,
        orgId,
        simulateFlight: {
          enable: !realFlight,
          latitude: 0,
          longitude: 0,
        },
      });
    },
    onSuccess: response => {
      toast.success(`Wayline Issued Status:${JSON.stringify(response.status)}`);
    },
    onError: error => {
      toast.error(`Failed to Issue Wayline Mission: ${error}`, {
        duration: ERROR_TOAST_DURATION,
        dismissable: true,
      });
    },
  });
};

export const useExecuteFlight = () => {
  const toast = useToast();
  const api = useRoboticsHubApi(WaylineApi, {
    basePath: window.ROBOTICS_HUB_DJI_DOCK_API_ENDPOINT,
  });

  return useMutation({
    mutationKey: 'executeMissionWayline',
    mutationFn: async ({
      dockSn,
      flightId,
    }: {
      dockSn: string;
      flightId: string;
    }) => {
      return await api.executeMissionWaylineExecutePost({
        dockSn,
        flightId,
      });
    },
    onSuccess: response => {
      toast.success(
        `Flight Executed Status:${JSON.stringify(response.status)}`,
      );
    },
    onError: error => {
      toast.error(`Failed to Execute Wayline Flight: ${error}`, {
        duration: ERROR_TOAST_DURATION,
        dismissable: true,
      });
    },
  });
};
