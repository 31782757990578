import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient, useMutation } from 'react-query';

type UpdateOrgResponse = {
  exit_status: number;
};

type ResponseError = {
  error: string;
};

export const useUpdateLatestOrg = () => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  const mutationFn = async (org_id: number): Promise<UpdateOrgResponse> => {
    const payload = {
      org_id: org_id,
    };
    const accessToken = await getAccessTokenSilently();
    const response = await fetch(
      `${window.REACT_APP_PLANTLABELER_API_ENDPOINT}/api/v1/update_latest_org_id`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      },
    );
    if (!response.ok) {
      throw new Error('Organization update was unsuccessful');
    }
    return await response.json();
  };

  const options = {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['enter-app'],
      });
    },
  };
  return useMutation<UpdateOrgResponse, ResponseError, number>(
    mutationFn,
    options,
  );
};
