// External Dependencies
import {
  useState,
  useMemo,
  useRef,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { useParams } from 'react-router-dom';
import debounce from 'lodash/debounce';
// RaptorUI
import { Row, Gutter, Stack } from '@raptormaps/layout';
import { TextInput } from '@raptormaps/text-input';
import theme from '@raptormaps/theme';

// Components
import { IconButtonAdornment } from './missionNameInput.styles';
import UniqueNameValidationMessage from '@/pages/MissionPlanner/components/shared/ValidationMessage';

// Utils
import { useGetIsNameUnique, useGetMission } from '@/shared/hooks/useMissions';
import { generateErrorText } from '@/pages/MissionPlanner/utils/utils';

interface MissionNameInputProps {
  solarFarmId: number;
  missionName: string;
  setMissionName: Dispatch<SetStateAction<string>>;
}

const MissionNameInput = ({
  missionName,
  setMissionName,
  solarFarmId,
}: MissionNameInputProps) => {
  const { missionId } = useParams();
  const [tempMissionName, setTempMissionName] = useState(missionName);
  const handleMissionNameChange = useRef(
    debounce(e => {
      setMissionName(e.target.value);
    }, 500),
  );
  const { data: current } = useGetMission(Number(missionId), solarFarmId);
  const { data: isNameUniqueData, isFetching: isFetchingIsUnique } =
    useGetIsNameUnique(missionName?.trim(), solarFarmId);

  useEffect(() => {
    setTempMissionName(missionName);
  }, [missionName]);

  const isValidMissionName = useMemo(() => {
    return (
      /* A non-unique name is valid is it if from this mission unless saveAs is active */
      isNameUniqueData?.isUnique || missionName === current?.missionName
    );
  }, [isNameUniqueData, missionName, current]);

  const [disableMissionNameInput, setDisableMissionNameInput] =
    useState<boolean>(isValidMissionName && !!missionName);

  const missionNameRow = useRef(null);
  useEffect(() => {
    missionNameRow.current?.querySelector('input').focus();
  }, [disableMissionNameInput]);

  const errorText = generateErrorText(missionName, isValidMissionName);

  return (
    <Stack style={{ height: '78px' }}>
      <Row
        style={{
          boxSizing: 'border-box',
          display: 'flex',
        }}
      >
        <Row
          align="center"
          noWrap
          gutter={Gutter.M}
          style={{ textAlign: 'left', position: 'relative', flexGrow: 1 }}
          ref={missionNameRow}
        >
          <TextInput
            label="Mission Name"
            name="missionName"
            inputSize="small"
            placeholder="Mission Name"
            // this way the input will represent mission name that will be associated to the original mission if the users saves as with the new name
            value={tempMissionName}
            disabled={disableMissionNameInput}
            onChange={e => {
              setTempMissionName(e.target.value);
              handleMissionNameChange.current(e);
            }}
            onBlur={e => {
              e.target.value = e.target.value.trim();
              setTempMissionName(e.target.value);
              handleMissionNameChange.current(e);
              // If there is a mission name and it is valid
              missionName && isValidMissionName
                ? setDisableMissionNameInput(true)
                : setDisableMissionNameInput(false);
            }}
            className={`${errorText ? 'is-invalid' : ''}`}
            style={{
              color: theme.colors.black,
              boxSizing: 'border-box',
              flexGrow: 1,
            }}
          />
          {disableMissionNameInput && (
            <IconButtonAdornment
              aria-label="Rename Mission"
              icon={'Pen'}
              variant="tertiary"
              onClick={() => {
                setDisableMissionNameInput(false);
              }}
              onMouseDown={e => {
                e.preventDefault();
              }}
            />
          )}
        </Row>
      </Row>
      <UniqueNameValidationMessage
        isFetching={isFetchingIsUnique}
        errorText={errorText}
      />
    </Stack>
  );
};

export default MissionNameInput;
