// React
import { useEffect } from 'react';

// Components
import Uploader from './components/Uploader/Uploader';
import BreadcrumbNavigation from '../../shared/components/BreadCrumbs/Breadcrumbs';

// Mixpanel
import Mixpanel from '../../shared/utils/mixpanel/utils';
import MixpanelEvents from '../../shared/utils/mixpanel/events';

// Constants
import { PageProps } from '../../shared/types/page';

// Styles
import './FlightUploader.css';

const FlightUploader = ({ solarFarmId }: PageProps) => {
  useEffect(() => {
    Mixpanel.track(MixpanelEvents.UploadPageLoaded);
  }, []);

  return (
    <div className="uploadFlight">
      <div className="uploadFlight-header">
        <BreadcrumbNavigation
          crumbs={[
            ['Raptor Flight', '/'],
            ['Missions Library', '/missions?solar_farm_id=' + solarFarmId],
            ['Upload Flight', '/upload?solar_farm_id=' + solarFarmId],
          ]}
        />
      </div>
      <div className="uploadFlight-content">
        <Uploader solarFarmId={solarFarmId} />
      </div>
    </div>
  );
};
export default FlightUploader;
