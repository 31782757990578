import styled from 'styled-components';
import { Card } from '@raptormaps/card';
import { Row, Stack } from '@raptormaps/layout';
import { IconButton } from '@raptormaps/button';
import theme from '@raptormaps/theme';

export const MissionStyledCard = styled(Card)`
  ${props => `
    ${props.pointer && !props.isSelected && ` cursor: pointer;`};
    ${
      props.isSelected
        ? ` border: 1px solid ${theme.colors.neutral_500};
      padding: 0 0 0 4px;
      background-color: ${theme.colors.neutral_50};`
        : `padding: 1px 1px 1px 5px;`
    }
  `}
`;

export const MissionCardStyledStack = styled(Stack)`
  width: 90%;
  padding: 0 10px;

  /* inspection type pill to keep cards a fixed size */
  > div > span {
    margin-right: -3px;
  }
`;

export const MissionCardSimpleStyledStack = styled(Stack)`
  width: 100%;
  padding: 0;

  > div > span {
    margin-right: -3px;
  }
`;

export const CardTitleRowWithEllipsis = styled(Row)`
  width: 151px;
  cursor: ${props => (props.pointer ? 'pointer' : 'default')};

  h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const MissionCardDetailsButton = styled(IconButton)`
  height: 104px;
  background-color: ${theme.colors.white} !important;

  &:hover {
    background-color: ${theme.colors.neutral_50} !important;
  }
`;
