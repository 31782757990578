//External Dependencies
import {
  MissionResponseLite,
  CameraAngleMode,
  DeviceResponse,
  DockDeviceIdsResponse,
  DeviceType,
  MissionTaskInput,
} from '@raptormaps/raptor-flight-client-ts';
import { DropdownOption } from '@raptormaps/dropdown-menu';
import { Feature, FeatureCollection, Polygon, Point } from '@turf/helpers';
import { DroneType, FlightModeType } from '@raptormaps/raptor-flight-client-ts';

// Internal Dependencies
import { MISSION_TASK_FORM_FIELDS } from './missionTaskFormConstants';
import { calculateWaypointData } from '@/pages/MissionPlanner/utils/flightPlanningUtils';
import {
  DRONE_TYPE_TO_DISPLAY_NAME,
  FLIGHT_MODE_TO_DISPLAY_NAME,
} from '@/shared/constants/missionLookups';
import { MISSION_LIST_QURERY_PARAMS } from './missionTaskFormConstants';
import { CombinedFlightModeType } from '@/shared/types/tempMissions';

export const handleCalculateMission = async (
  map,
  data: MissionResponseLite,
  setWaypoints,
  setFlightPath,
  setPolygon,
) => {
  if (data.mode === FlightModeType.LinearCustom) {
    return;
  }

  const result = await calculateWaypointData({
    map,
    flightMode: data.mode as CombinedFlightModeType,
    polygon: data.flightMap.features[0] as Feature<Polygon>,
    altitude: data.altitude,
    fieldOfViewHorizontal: data.sensorFovWidth,
    fieldOfViewVertical: data.sensorFovHeight,
    pitchAngle: data.cameraPitchAngle,
    flightAngle: data.flightAngle,
    sideOverlap: data.sideOverlap,
    frontOverlap: data.frontOverlap,
    safeTakeoffAltitude: data.safeTakeoffAltitude as number,
    safeTakeoffAltitudeBool: !!data.safeTakeoffAltitude,
    markerLngLat: data.takeoffPoint ? data.takeoffPoint.coordinates : null,
    calibrationBool: false,
    calibrationBearing: 0,
    calibrationDistance: 0,
    continuousOperationsEnabled:
      data.cameraAngleMode === CameraAngleMode.FollowTracker,
    cameraInterval: data.cameraInterval,
    flightSpeed: data.flightSpeed,
  });

  setWaypoints(result.waypoints);
  setFlightPath(result.flightPath);
  setPolygon(data.flightMap.features[0]);
};

export const generateDroneOptions = (
  filters: DroneType[],
  onSelect: (param: MISSION_LIST_QURERY_PARAMS, query: DroneType | '') => void,
): DropdownOption[] => {
  const removeFilterOption: DropdownOption = {
    onSelect: () => onSelect(MISSION_LIST_QURERY_PARAMS.drone, ''),
    value: 'Clear Filter',
    variant: 'option' as const,
    style: { fontSize: '14px', justifyContent: 'start' },
  };

  const droneOptions = Object.values(DroneType)
    .filter(drone => {
      return !filters.includes(drone);
    })
    .map(drone => ({
      onSelect: () => onSelect(MISSION_LIST_QURERY_PARAMS.drone, drone),
      value: DRONE_TYPE_TO_DISPLAY_NAME[drone],
      variant: 'option' as const,
      style: { fontSize: '14px', justifyContent: 'start' },
    }));

  return [removeFilterOption, ...droneOptions];
};

export const generateFlightModeOptions = (
  filters: CombinedFlightModeType[],
  onSelect: (param: MISSION_LIST_QURERY_PARAMS, query) => void,
): DropdownOption[] => {
  const removeFilterOption: DropdownOption = {
    onSelect: () => onSelect(MISSION_LIST_QURERY_PARAMS.flightMode, ''),
    value: 'Clear Filter',
    variant: 'option' as const,
    style: { fontSize: '14px', justifyContent: 'start' },
  };

  const flightModeOptions = Object.values(FlightModeType)
    .filter(flightMode => {
      if (flightMode === FlightModeType.LinearCustom) {
        return;
      }
      return !filters.includes(flightMode);
    })
    .map(flightMode => ({
      onSelect: () =>
        onSelect(MISSION_LIST_QURERY_PARAMS.flightMode, flightMode),
      value: FLIGHT_MODE_TO_DISPLAY_NAME[flightMode],
      variant: 'option' as const,
      style: { fontSize: '14px', justifyContent: 'start' },
    }));

  return [removeFilterOption, ...flightModeOptions];
};

export const getDockAerialDrone = ({
  devices,
  dockDeviceIds,
  dockId,
}: {
  devices: DeviceResponse[];
  dockDeviceIds: DockDeviceIdsResponse[];
  dockId: number;
}) => {
  const deviceList = dockDeviceIds.find(
    dockDevices => dockDevices.dockId === dockId,
  );
  const aerialDrone = deviceList.deviceIds.map(deviceId => {
    return devices.find(
      device =>
        device.id === deviceId && device.deviceType === DeviceType.AerialDrone,
    );
  });
  return aerialDrone[0]?.id;
};

export const generateFeatureFromCoordinates = ({
  longitude,
  latitude,
}: {
  longitude: number;
  latitude: number;
}): FeatureCollection<Point> => {
  return {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [longitude, latitude],
        },
        properties: {},
      },
    ],
  };
};

export const generateMissionTaskInput = (
  missionTaskForm,
): MissionTaskInput | null => {
  /*
    Translates the form data into the expected input for the createMissionTask mutation

    Returns:
      - null if any required fields are missing
        * with the exception of orderId, which is optional
      - MissionTaskInput object if all required fields are present
  */
  const missionTaskInput = {
    taskName: missionTaskForm.getValues(
      MISSION_TASK_FORM_FIELDS.missionTaskName,
    ),
    missionIds: missionTaskForm.getValues(MISSION_TASK_FORM_FIELDS.missionIds),
    droneId: missionTaskForm.getValues(MISSION_TASK_FORM_FIELDS.droneId),
    dockId: missionTaskForm.getValues(MISSION_TASK_FORM_FIELDS.dockId),
    scheduledStartTsecs: missionTaskForm.getValues(
      MISSION_TASK_FORM_FIELDS.scheduledStartTsecs,
    ),
    estimatedDurationSeconds: missionTaskForm.getValues(
      MISSION_TASK_FORM_FIELDS.estimatedDurationSeconds,
    ),
    orderId: missionTaskForm.getValues(MISSION_TASK_FORM_FIELDS.orderId),
  };

  // Check if any of the required fields are null which is there initial value
  for (const field in missionTaskInput) {
    // ignore orderId as it is optional
    if (field === MISSION_TASK_FORM_FIELDS.orderId) continue;

    if (!!missionTaskInput[field] === false) return null;
  }

  return missionTaskInput;
};
