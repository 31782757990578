// Styled Components
import { Row } from '@raptormaps/layout';
import { Pill, PillType } from '@raptormaps/pill';

// Components
import { StaleTelemetryIcon } from './StaleTelemetryIcon';

// Telemetry
import { DockDeviceTelemetry } from '@/shared/types/telemetry.d';
import { useDockDeviceTelemetryValue } from '@/shared/stores/TelemetryWebsocket/TelemetryWebsocketStore';

// Constants
import { TELEMETRY_KEY_TO_DISPLAY_CONFIG } from '@/shared/constants/telemetryConstants';
import {
  AIRCRAFT_STATE_TO_DISPLAY,
  AIRCRAFT_CHARGE_STATE_TO_DISPLAY,
} from '@/shared/constants/deviceConstants';

interface AircraftStateTelemetryProps {
  dockSn: string;
  aircraftSn: string;
}
export const AircraftStateTelemetry = ({
  dockSn,
  aircraftSn,
}: AircraftStateTelemetryProps) => {
  const aircraftStateKey: keyof DockDeviceTelemetry = 'aircraftState';
  const aircraftChargeStateKey: keyof DockDeviceTelemetry =
    'aircraftChargeState';

  const aircraftStateTelemetry = useDockDeviceTelemetryValue({
    deviceSn: aircraftSn,
    key: aircraftStateKey,
    properties: { isStale: true, timestamp: true, lastMonitored: true },
  });
  const aircraftChargeStateTelemetry = useDockDeviceTelemetryValue({
    deviceSn: dockSn,
    key: aircraftChargeStateKey,
    properties: { isStale: true, timestamp: true, lastMonitored: true },
  });

  const showAircraftState = Boolean(aircraftStateTelemetry.value);
  const { value, isStale, timestamp, lastMonitored } = showAircraftState
    ? aircraftStateTelemetry
    : aircraftChargeStateTelemetry;

  const { toDisplay } =
    TELEMETRY_KEY_TO_DISPLAY_CONFIG[
      showAircraftState ? aircraftStateKey : aircraftChargeStateKey
    ];

  const pillColor = showAircraftState
    ? AIRCRAFT_STATE_TO_DISPLAY[value]?.pillColor
    : AIRCRAFT_CHARGE_STATE_TO_DISPLAY[value]?.pillColor;
  return (
    <Row align="center" noWrap>
      {isStale && (
        <StaleTelemetryIcon
          timestamp={timestamp}
          lastMonitored={lastMonitored}
        />
      )}
      <Pill iconPosition="right" size="small" variant={pillColor as PillType}>
        {toDisplay(value)}
      </Pill>
    </Row>
  );
};
