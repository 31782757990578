import {
  SolarFarmPopupContainer,
  SolarFarmPopupButton,
} from './SolarFarmPopup.style';
import { Icon } from '@raptormaps/icon';
import { Text } from '@raptormaps/text';

const SolarFarmPopup = ({ solarFarm, onClick }) => {
  return (
    <SolarFarmPopupContainer>
      <Text variant="paragraph_medium_bold">{solarFarm.name}</Text>
      <SolarFarmPopupButton onClick={() => onClick(solarFarm.id)}>
        <Icon icon="ArrowRightToArc" size="medium" />
      </SolarFarmPopupButton>
    </SolarFarmPopupContainer>
  );
};

export default SolarFarmPopup;
