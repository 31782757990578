//external dependancies
import { useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import {
  DockDevicesApi,
  DockDeviceIdsResponse,
  DeviceResponse,
  DeviceType,
} from '@raptormaps/raptor-flight-client-ts';
import { useToast } from '@raptormaps/toast';

// local dependancies
import { useApi } from './useApi';
import { useAppContext } from '../context/AppContext';
// hooks
import { useGetDeviceModels } from '@/shared/hooks/useDeviceModels';
import { useGetDevices } from '@/shared/hooks/useDevices';
// constants
import { ERROR_TOAST_DURATION } from '@/shared/constants/appConstants';

export const GET_DEVICES_PAGINATION_LIMIT = 500;
const THIRTY_MINUTES = 30 * 60 * 1000;

interface useGetDockDevicesParams {
  solarFarmId?: number;
  onError?: () => void;
}
export const useGetDockDeviceIds = ({
  solarFarmId,
  onError,
}: useGetDockDevicesParams) => {
  const { user } = useAppContext();
  const orgId = user.latest_org_id;
  const queryClient = useQueryClient();

  const api = useApi(DockDevicesApi, {
    basePath: window.REACT_APP_FLIGHT_API_ENDPOINT,
  });

  const cachedData = queryClient.getQueryData<DockDeviceIdsResponse[]>([
    'dockDeviceIds',
    orgId,
    solarFarmId,
  ]);

  return useQuery({
    queryKey: ['dockDeviceIds', orgId, solarFarmId],
    queryFn: async () => {
      if (!orgId || !solarFarmId) return null;
      return await api.getDockDeviceIdsV1DockDevicesIdsGet({
        solarFarmId,
        orgId,
      });
    },
    initialData: cachedData,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: !cachedData,
    onError: onError,
    staleTime: THIRTY_MINUTES,
  });
};

interface UseDockAndDevicesProps {
  solarFarmId: number;
  dockId: number;
}

export const useDockAndDevices = ({
  solarFarmId,
  dockId,
}: UseDockAndDevicesProps) => {
  const toast = useToast();
  const handleDeviceError = () =>
    toast.error('Error fetching Devices', {
      duration: ERROR_TOAST_DURATION,
      dismissable: true,
    });
  const handleDockDeviceError = () =>
    toast.error('Error fetching Dock Device Ids', {
      duration: ERROR_TOAST_DURATION,
      dismissable: true,
    });
  const handleDeviceModelsError = () =>
    toast.error('Error fetching Device Models', {
      duration: ERROR_TOAST_DURATION,
      dismissable: true,
    });

  const {
    data: { solarFarmDevices },
    isLoading: isLoadingDevices,
  } = useGetDevices({ solarFarmId, onError: handleDeviceError });
  const { data: dockDeviceIds, isLoading: isLoadingDevice } =
    useGetDockDeviceIds({ solarFarmId, onError: handleDockDeviceError });
  const {
    data: { deviceModels },
    isLoading: isLoadingDeviceModels,
  } = useGetDeviceModels({ solarFarmId, onError: handleDeviceModelsError });

  const activeDockDevice = useMemo(() => {
    const apiDock = solarFarmDevices?.find(
      (device: DeviceResponse) => device.id === dockId,
    );
    if (!apiDock) return null;
    else {
      const dock = { ...apiDock };
      dock.deviceIds = dockDeviceIds?.find(
        dockDevices => dockDevices.dockId === dock.id,
      )?.deviceIds;
      dock.dockModel = deviceModels?.filter(
        device => device.id === dock.deviceModelId,
      );

      const dockDevices = dock.deviceIds?.map((deviceId: number) => {
        return solarFarmDevices.find(
          (device: DeviceResponse) => device.id === deviceId,
        );
      });

      const dockDrone = dockDevices?.find(
        device => device?.deviceType === DeviceType.AerialDrone,
      );

      const dockDroneModel = deviceModels?.find(
        model =>
          model.id ===
          dockDevices?.find(
            device => device?.deviceType === DeviceType.AerialDrone,
          )?.deviceModelId,
      );
      return { ...dock, dockDrone, dockDroneModel, devices: dockDevices };
    }
  }, [solarFarmDevices, deviceModels, dockId]);

  const isLoading =
    isLoadingDevices || isLoadingDeviceModels || isLoadingDevice;

  return {
    activeDockDevice,
    isLoading,
  };
};
