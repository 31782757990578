import { Icon } from '@raptormaps/icon';
import { Row, Stack } from '@raptormaps/layout';
import { Text } from '@raptormaps/text';
import theme from '@raptormaps/theme';

// Components
import { StaleTelemetryIcon } from './StaleTelemetryIcon';

// Telemetry
import { useDockDeviceTelemetryValue } from '@/shared/stores/TelemetryWebsocket/TelemetryWebsocketStore';
import { DockDeviceTelemetry } from '@/shared/types/telemetry';
import { TELEMETRY_KEY_TO_DISPLAY_CONFIG } from '@/shared/constants/telemetryConstants';

interface IconTelemetryProps {
  telemetryKey: keyof DockDeviceTelemetry;
  deviceSn: string;
}

export const IconTelemetry = ({
  deviceSn,
  telemetryKey,
}: IconTelemetryProps) => {
  const { value, timestamp, isStale, lastMonitored } =
    useDockDeviceTelemetryValue({
      deviceSn,
      key: telemetryKey,
      properties: { isStale: true, timestamp: true, lastMonitored: true },
    });

  const { toDisplay, title, iconName } =
    TELEMETRY_KEY_TO_DISPLAY_CONFIG[telemetryKey];
  return (
    <Row align="center" noWrap>
      {isStale ? (
        <StaleTelemetryIcon
          timestamp={timestamp}
          lastMonitored={lastMonitored}
        />
      ) : (
        <Icon icon={iconName} size="large" color={theme.colors.black} />
      )}
      <Stack gutter="xxs">
        <Text variant="paragraph_small" align="left">
          {title}
        </Text>
        <Text variant="paragraph_large_bold" align="left">
          {toDisplay(value)}
        </Text>
      </Stack>
    </Row>
  );
};
