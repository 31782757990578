// External Dependencies
import {
  useMemo,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import dayjs from 'dayjs';
import { FormikProvider, useFormik } from 'formik';
import { Stack } from '@raptormaps/layout';

// Components
import AltitudeInput from './shared/MissionParameters/components/AltitudeInput';
import CourseAndCameraAngleSection from './shared/MissionParameters/CourseAndCameraAngleSection';
import FlightModeDropdown from './shared/MissionParameters/components/FlightModeDropdown';
import SpecialFeaturesSection from './shared/MissionParameters/SpecialFeaturesSection/SpecialFeaturesSection';
import SettingsSection from './shared/MissionParameters/SettingsSection';
import CalibrationSection from './shared/Calibration/CalibrationSection';
import MissionStatsSection from './shared/MissionStats';
import WaypointReductionSection from './shared/MissionParameters/WaypointReduction/WaypointReduction';
import WaypointsWarningBanner from '@/pages/MissionPlanner/components/shared/WaypointsWarningBanner/WaypointsWarningBanner';

import {
  GridColumnOneSpanTwo,
  FullWidthGridSection,
} from './MissionPlanningSidebar/MissionPlanningSidebar.styles';

// types
import {
  AreaMissionFormikValues,
  IntervalometerObject,
  MissionPlanningFormProps,
  MissionPlanningFormHandle,
} from '@/shared/types/missions.d';
import { CombinedFlightModeType } from '@/shared/types/tempMissions';
import {
  FlightModeType,
  MissionInput,
  SpeedControlModeType,
  SolarFarmResponse,
} from '@raptormaps/raptor-flight-client-ts';

// Constants
import { DroneFlightPlanSchema } from '@/pages/MissionPlanner/schemas/FlightPlanSchema';
import { droneFormParameters } from '@/pages/MissionPlanner/constants/FormParameters';
import {
  DEFAULT_INTERVALOMETER,
  ACCEPTED_ERRORS,
  DRONE_CAMERA_DEFAULTS,
  FLIGHT_MODE_DEFAULTS,
  TEMPORARY_FLIGHT_MODES,
  DRONE_SAFE_TAKEOFF_ALTITUDE_DEFAULT,
  DRONE_TRANSIT_SPEED_DEFAULTS,
} from '@/pages/MissionPlanner/constants/missionConstants';
import { COLLECTION_TYPE_FLIGHT_MODE } from '@/shared/constants/missionLookups';
import { DataCollectionType } from '@/shared/types/dataCollection';

// Utils
import {
  calculateWaypointData,
  isContinuousOperationsActive,
  handleDJIDroneDownload,
  DJI_FILE_TYPES,
} from '@/pages/MissionPlanner/utils/flightPlanningUtils';
import { generateMissionInputFromArea } from '@/pages/MissionPlanner/utils/missionApiUtils';
import {
  calculateFlightSpeed,
  calculateCameraInterval,
  getFieldOfViewSpacing,
} from '@/pages/MissionPlanner/utils/utils';

// Growthbook
import { GrowthbookFlags } from '@/shared/utils/GrowthbookUtils';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

const AreaMissionForm = forwardRef<
  MissionPlanningFormHandle,
  MissionPlanningFormProps
>(
  (
    {
      map,
      device,
      initialMission,
      geospatial,
      calibration,
      collectionType,
      setFormModified,
      setCalibration,
      setGeospatial,
      setSaveDisabled,
      setDownloadDisabled,
      handleAddTakeoffPoint,
      handleRemoveTakeoffPoint,
    }: MissionPlanningFormProps,
    ref,
  ) => {
    const initialStartTime = useMemo(
      () => dayjs.utc().format('YYYY-MM-DDTHH:mm:ss'),
      [],
    );

    const useTransitSpeed = useFeatureIsOn(GrowthbookFlags.TRANSIT_SPEED);

    const wpmlFileType = useFeatureIsOn(GrowthbookFlags.FLIGHT_FUNCTIONAL_WPML)
      ? DJI_FILE_TYPES.wpml
      : DJI_FILE_TYPES.legacy_wpml;

    const [intervalometer, setIntervalometer] = useState<IntervalometerObject>(
      DEFAULT_INTERVALOMETER,
    );

    const formik = useFormik<AreaMissionFormikValues>({
      initialValues: {
        cameraType: initialMission.mission.sensor.name,
        userUnits: initialMission.mission.userUnits,
        altitude: initialMission.mission.altitude,
        altitudeOffset: initialMission.mission.altitudeOffset,
        altitudeOffsetBool: initialMission.mission.altitudeOffsetBool,
        safeTakeoffAltitude:
          initialMission.mission.safeTakeoffAltitude ||
          DRONE_SAFE_TAKEOFF_ALTITUDE_DEFAULT,
        safeTakeoffAltitudeBool: !!initialMission.mission.safeTakeoffAltitude,
        frontOverlap: initialMission.mission.frontOverlap,
        sideOverlap: initialMission.mission.sideOverlap,
        cameraAngle: initialMission.mission.cameraAngle,
        flightAngle: initialMission.mission.flightAngle,
        speedControlMode: initialMission.mission.speedControlMode,
        cameraInterval: initialMission.mission.cameraInterval,
        flightSpeed: initialMission.mission.flightSpeed,
        transitSpeed:
          initialMission.mission.transitSpeed ??
          DRONE_TRANSIT_SPEED_DEFAULTS?.[device].default,
        terrainFollowBool: initialMission.mission.terrainFollowBool,
        cameraAngleMode: initialMission.mission.cameraAngleMode,
        flightMode: initialMission.mission.flightMode,
        startTime: initialStartTime,
      },
      enableReinitialize: true,

      validate: values => {
        // custom validation
        if (values.terrainFollowBool && !geospatial.markerLngLat) {
          return {
            terrainFollowBool:
              'Required field! Please set a relative altitude point or disable Terrain Following.',
          };
        }
      },
      onSubmit: (values, { setSubmitting }) => {
        setTimeout(() => {
          alert(JSON.stringify(values, null, 2));
          setSubmitting(false);
        }, 400);
      },
      validationSchema: DroneFlightPlanSchema(device),
    });

    const { values, handleChange, validateForm, errors, setFieldValue } =
      formik;

    // Re-validate the form on device change
    useEffect(() => {
      if (device) {
        const validate = async () => {
          await validateForm(values);
        };
        validate();
      }
    }, [device]);

    const { fieldOfViewHorizontal, fieldOfViewVertical } =
      DRONE_CAMERA_DEFAULTS[device][values.cameraType];

    const handleCalculateWaypoints = async () => {
      const formErrors = await validateForm(values);
      const errors = Object.keys(formErrors);
      if (
        !map ||
        !errors.every(error => ACCEPTED_ERRORS.includes(error)) ||
        !geospatial.polygon
      ) {
        setCalibration({ ...calibration, nonCalibratedFlightPath: null });
        return;
      }

      const continuousOperationsEnabled = isContinuousOperationsActive(
        values.cameraAngleMode,
      );
      const { waypoints, flightPath, nonCalibratedFlightPath } =
        await calculateWaypointData({
          map,
          fieldOfViewHorizontal,
          fieldOfViewVertical,
          flightMode: values.flightMode,
          polygon: geospatial.polygon,
          altitude: values.altitude,
          pitchAngle: values.cameraAngle,
          flightAngle: values.flightAngle,
          sideOverlap: values.sideOverlap,
          frontOverlap: values.frontOverlap,
          safeTakeoffAltitude: values.safeTakeoffAltitude,
          safeTakeoffAltitudeBool: values.safeTakeoffAltitudeBool,
          markerLngLat: geospatial.markerLngLat
            ? [geospatial.markerLngLat.lng, geospatial.markerLngLat.lat]
            : null,
          calibrationBool: calibration.active,
          calibrationBearing: calibration.rhumbBearing,
          calibrationDistance: calibration.rhumbDistance,
          continuousOperationsEnabled,
          cameraInterval: values.cameraInterval,
          flightSpeed: values.flightSpeed,
          waypointReductionBool: intervalometer.waypointReductionBool,
        });

      setGeospatial({ ...geospatial, waypoints, flightPath });
      setCalibration({
        ...calibration,
        nonCalibratedFlightPath,
      });
    };

    // Update waypoints on values changing
    useEffect(() => {
      if (!map) return;
      handleCalculateWaypoints();
    }, [
      map,
      geospatial.polygon,
      geospatial.markerLngLat,
      values.flightMode,
      calibration.active,
      fieldOfViewHorizontal,
      fieldOfViewVertical,
      values.cameraAngleMode,
      values.safeTakeoffAltitudeBool,
      intervalometer.waypointReductionBool,
    ]);

    const handleEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        handleChange(e);
        handleCalculateWaypoints();
      }
    };

    useEffect(() => {
      setFormModified(formik.dirty);
    }, [formik.dirty]);

    // Disable save and download buttons based on errors and flight mode
    useEffect(() => {
      setSaveDisabled(
        Object.keys(errors).length > 0 ||
          TEMPORARY_FLIGHT_MODES.includes(values.flightMode),
      );
      setDownloadDisabled(Object.keys(errors).length > 0);
    }, [errors, values.flightMode]);

    // Calculate controlled input based on speed control mode
    useEffect(() => {
      if (values.speedControlMode === SpeedControlModeType.Flight) {
        const calculatedCameraInterval = calculateCameraInterval({
          altitude: values.altitude,
          frontOverlap: values.frontOverlap,
          fieldOfViewHorizontal,
          flightSpeed: values.flightSpeed,
        });
        setFieldValue('cameraInterval', calculatedCameraInterval);
      } else if (values.speedControlMode === SpeedControlModeType.Shutter) {
        const calculatedFlightSpeed = calculateFlightSpeed({
          altitude: values.altitude,
          frontOverlap: values.frontOverlap,
          fieldOfViewHorizontal,
          cameraInterval: values.cameraInterval,
        });
        setFieldValue('flightSpeed', calculatedFlightSpeed);
      }
    }, [
      values.altitude,
      fieldOfViewHorizontal,
      values.frontOverlap,
      values.flightSpeed,
      values.cameraInterval,
      values.speedControlMode,
    ]);

    useEffect(() => {
      // Updates the front spacing used as the distance param for distance interval mode
      if (intervalometer.intervalometerBool) {
        const actionTriggerParam =
          Math.floor(
            getFieldOfViewSpacing(
              values.altitude,
              fieldOfViewHorizontal,
              values.frontOverlap,
            ) * 1e2,
          ) / 1e2;
        setIntervalometer({
          ...intervalometer,
          actionTriggerParam,
        });
      }
    }, [
      intervalometer.intervalometerBool,
      values.altitude,
      fieldOfViewHorizontal,
      values.frontOverlap,
    ]);

    useImperativeHandle(ref, () => ({
      generateMissionInput: (missionName: string) => {
        if (!map) return;
        return generateMissionInputFromArea({
          missionName,
          values,
          useSafeTakeoffAltitude: values.safeTakeoffAltitudeBool,
          device,
          geospatial,
          center: map.getCenter(),
          zoom: map.getZoom(),
        });
      },
      handleDownload: (
        filename: string,
        input: MissionInput,
        solarFarm: SolarFarmResponse,
      ) => {
        handleDJIDroneDownload({
          map,
          missionInput: input,
          filename,
          solarFarm,
          geospatial,
          intervalometer,
          continuousOperationStartTime: values.startTime,
          safeTakeoffAltitudeBool: values.safeTakeoffAltitudeBool,
          terrainFollowBool: values.terrainFollowBool,
          transitSpeed: values.transitSpeed,
          transitSpeedBool: useTransitSpeed,
          wpmlFileType,
        });
      },
    }));

    return (
      <FormikProvider value={formik}>
        <Stack>
          <FullWidthGridSection>
            <GridColumnOneSpanTwo>
              <FlightModeDropdown
                mode={values.flightMode}
                collectionType={collectionType}
                flightModes={
                  COLLECTION_TYPE_FLIGHT_MODE[DataCollectionType.Area]
                }
                handleChange={(flightMode: CombinedFlightModeType) => {
                  setFieldValue('flightMode', flightMode);
                  Object.keys(FLIGHT_MODE_DEFAULTS[flightMode]).forEach(key => {
                    {
                      setFieldValue(key, FLIGHT_MODE_DEFAULTS[flightMode][key]);
                    }
                  });
                }}
              />
            </GridColumnOneSpanTwo>
            <AltitudeInput
              errors={errors}
              units={values.userUnits}
              altitude={values.altitude}
              formParameters={droneFormParameters}
              handleChange={handleChange}
              handleEnterPress={handleEnterPress}
              onBlur={handleCalculateWaypoints}
            />
          </FullWidthGridSection>
          <SpecialFeaturesSection
            userUnits={values.userUnits}
            safeTakeoffAltitudeBool={values.safeTakeoffAltitudeBool}
            safeTakeoffAltitude={values.safeTakeoffAltitude}
            altitudeOffsetBool={values.altitudeOffsetBool}
            altitudeOffset={values.altitudeOffset}
            terrainFollowBool={values.terrainFollowBool}
            markerLngLat={geospatial.markerLngLat}
            errors={errors}
            formParameters={droneFormParameters}
            handleAddTakeoffpoint={handleAddTakeoffPoint}
            handleRemoveTakeoffpoint={handleRemoveTakeoffPoint}
            onBlur={handleCalculateWaypoints}
            handleEnterPress={handleEnterPress}
            handleChange={handleChange}
          />
          <CourseAndCameraAngleSection
            errors={errors}
            formParameters={droneFormParameters}
            flightMode={values.flightMode}
            cameraAngleMode={values.cameraAngleMode}
            flightAngle={values.flightAngle}
            cameraAngle={values.cameraAngle}
            startTime={values.startTime}
            handleChange={handleChange}
            onBlur={handleCalculateWaypoints}
            handleEnterPress={handleEnterPress}
          />
          <SettingsSection
            device={device}
            errors={errors}
            sideOverlap={values.sideOverlap}
            frontOverlap={values.frontOverlap}
            cameraType={values.cameraType}
            flightSpeed={values.flightSpeed}
            transitSpeed={values.transitSpeed}
            flightMode={values.flightMode}
            cameraInterval={values.cameraInterval}
            speedControlMode={values.speedControlMode}
            formParameters={droneFormParameters}
            handleChange={handleChange}
            onBlur={handleCalculateWaypoints}
            handleEnterPress={handleEnterPress}
            setFieldValue={setFieldValue}
          />
          {values.flightMode !== FlightModeType.Iimode &&
            values.flightMode !== FlightModeType.SquareOrbital && (
              <WaypointReductionSection
                geospatial={geospatial}
                intervalometer={intervalometer}
                setIntervalometer={setIntervalometer}
              />
            )}
          {values.terrainFollowBool && (
            <WaypointsWarningBanner
              waypoints={geospatial.waypoints}
              altitude={values.altitude}
            />
          )}
          <CalibrationSection
            calibration={calibration}
            setCalibration={setCalibration}
            onBlur={handleCalculateWaypoints}
          />
          <MissionStatsSection
            flightSpeed={values.flightSpeed}
            altitude={values.altitude}
            fieldOfViewVertical={fieldOfViewVertical}
            fieldOfViewHorizontal={fieldOfViewHorizontal}
            frontOverlap={values.frontOverlap}
            sideOverlap={values.sideOverlap}
            flightMode={values.flightMode}
            flightPath={geospatial.flightPath}
          />
        </Stack>
      </FormikProvider>
    );
  },
);

export default AreaMissionForm;
