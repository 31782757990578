import { Layers } from 'mapbox-gl';
import theme from '@raptormaps/theme';
import {
  GEOSPATIAL_ROW_SOURCE_ID,
  GEOSPATIAL_ROW_LABEL_SOURCE_ID,
} from '../sources/GeospatialRowSources';

export const GEOSPATIAL_ROW_LAYER_ID = 'geospatial-row-layer';
export const GEOSPATIAL_ROW_FILL_LAYER_ID = 'geospatial-row-fill-layer';
export const GEOSPATIAL_ROW_LABEL_LAYER_ID = 'geospatial-row-label-layer';

const GeospatialRowLayers: Layers = [
  {
    id: GEOSPATIAL_ROW_LAYER_ID,
    type: 'line',
    source: GEOSPATIAL_ROW_SOURCE_ID,
    minzoom: 14,
    paint: {
      'line-color': theme.colors.neutral_500,
      'line-width': 1.5,
      'line-opacity': 1,
    },
  },
];
const GeospatialRowFillLayers: Layers = [
  {
    id: GEOSPATIAL_ROW_FILL_LAYER_ID,
    type: 'fill',
    source: GEOSPATIAL_ROW_SOURCE_ID,
    minzomm: 14,
    paint: {
      'fill-color': theme.colors.neutral_300,
      'fill-opacity': 1,
    },
  },
];
const GeosptialRowLabelLayers: Layers = [
  {
    id: GEOSPATIAL_ROW_LABEL_LAYER_ID,
    type: 'symbol',
    source: GEOSPATIAL_ROW_LABEL_SOURCE_ID,
    minzoom: 17,
    layout: {
      'symbol-placement': 'point',
      'text-field': ['get', 'object_name'],
      'text-size': 16,
      'text-offset': [-2, 1],
      'text-font': ['Roboto Black'],
      'text-anchor': 'left',
      visibility: 'visible',
    },
    paint: {
      'text-color': theme.colors.white,
      'text-halo-color': '#333333',
      'text-halo-width': 0.5,
      'text-halo-blur': 2,
    },
  },
];

const RowLayers: Layers = [
  ...GeospatialRowLayers,
  ...GeospatialRowFillLayers,
  ...GeosptialRowLabelLayers,
];

export default RowLayers;
