import { GeoJSONSource } from '../map.d';
export const COMBINER_SOURCE_ID = 'raptor-combiners';

const CombinerSource: GeoJSONSource = {
  id: COMBINER_SOURCE_ID,
  source: {
    type: 'geojson',
    data: { type: 'FeatureCollection', features: [] },
  },
};

const CombinerSources: GeoJSONSource[] = [CombinerSource];

export default CombinerSources;
