import {
  DroneType,
  MissionTaskDetailedResponse,
} from '@raptormaps/raptor-flight-client-ts';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export enum MISSION_TASK_FORM_FIELDS {
  detailViewMissionId = 'detailViewMissionId',

  dockId = 'dockId',
  droneId = 'droneId',
  estimatedDurationSeconds = 'estimatedDurationSeconds',
  missionIds = 'missionIds',
  missionTaskName = 'missionTaskName',
  orderId = 'orderId',
  scheduledStartTsecs = 'scheduledStartTsecs',
}

export enum MISSION_LIST_QURERY_PARAMS {
  search = 'search',
  flightMode = 'flightMode',
  drone = 'drone',
  offset = 'offset',
}

export const DEVICE_MODEL_NAME_TO_DRONE_TYPE = {
  M3TD: DroneType.M3tdDock,
  'M30T Dock': DroneType.M30tDock,
};

export const MISSION_TASK_FORM_INITIAL_VALUES = (
  apiMissionTask: MissionTaskDetailedResponse | null,
) => {
  return {
    detailViewMissionId: null,
    // Mission task input
    dockId: apiMissionTask ? apiMissionTask.dockId : null,
    droneId: apiMissionTask ? apiMissionTask.droneId : null,
    estimatedDurationSeconds: apiMissionTask
      ? apiMissionTask.estimatedDurationSeconds
      : 1,
    missionIds: apiMissionTask ? [apiMissionTask.missions[0].id] : [],
    missionTaskName: apiMissionTask ? apiMissionTask.taskName : '',
    orderId: apiMissionTask ? apiMissionTask.orderId : null,
    scheduledStartTsecs: apiMissionTask
      ? apiMissionTask.scheduledStartTsecs
      : dayjs().utc().unix(),
  };
};
