// External Dependencies
import { MissionResponse } from '@raptormaps/raptor-flight-client-ts';
// RaptorUI
import { Text } from '@raptormaps/text';
import { Row } from '@raptormaps/layout';
import { Icon } from '@raptormaps/icon';

// Internal Dependencies
import WaypointReductionSection from '@/shared/components/WaypointReduction/WaypointReduction';
import { FullWidthRow, FullWidthStack } from '@/shared/styles/sidebar.styles';
import { ComponentLoader } from '@/shared/components/ComponentLoader/ComponentLoader';
import { MissionCardSimple } from '@/shared/components/MissionCard/MissionCardSimple';
import { IntervalometerObject } from '@/shared/types/missions.d';

interface MissionInfoProps {
  activeMission: MissionResponse;
  isLoadingMission: boolean;
  intervalometer: IntervalometerObject;
  setIntervalometer: (IntervalometerObject) => void;
}

export const MissionInfo = ({
  activeMission,
  isLoadingMission,
  intervalometer,
  setIntervalometer,
}: MissionInfoProps) => {
  return (
    <>
      <Row align="center" justify="space-between">
        <Text variant="paragraph_small" align="left">
          Mission Route
        </Text>
      </Row>
      {isLoadingMission && !activeMission ? (
        <ComponentLoader message={'Loading Mission...'} />
      ) : (
        activeMission && (
          <MissionCardSimple mission={activeMission} isSelected={false} />
        )
      )}
      <FullWidthRow gutter="xs" align="center">
        <Icon icon="TriangleExclamation" size="small" />
        <Text htmlFor="followTrackerTime" variant="paragraph_small">
          Follow tracker missions are generated with a utc time of NOW.
        </Text>
      </FullWidthRow>
      <FullWidthStack align="left">
        <FullWidthRow>
          <WaypointReductionSection
            intervalometer={intervalometer}
            setIntervalometer={setIntervalometer}
          />
        </FullWidthRow>
      </FullWidthStack>
    </>
  );
};
