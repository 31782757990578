import { Dispatch } from 'react';
import { UseFormReturn } from 'react-hook-form';
// Styled Components
import { CardTitle } from '@raptormaps/card';
import { Row } from '@raptormaps/layout';
import { Icon } from '@raptormaps/icon';
import { Pill } from '@raptormaps/pill';
import { Text } from '@raptormaps/text';
import { Tooltip } from '@raptormaps/tooltip';

import { MissionTaskPopovers } from '@/pages/MissionTasks/MissionTaskPlanner/missionTask.d';
import {
  MissionStyledCard,
  MissionCardStyledStack,
  CardTitleRowWithEllipsis,
  MissionCardDetailsButton,
} from './MissionCard.styles';
import {
  FLIGHT_MODE_TO_DISPLAY_NAME,
  DRONE_TYPE_TO_DISPLAY_NAME,
} from '@/shared/constants/missionLookups';
import { MissionResponseLite } from '@raptormaps/raptor-flight-client-ts';
import { MISSION_TASK_FORM_FIELDS } from '@/pages/MissionTasks/MissionTaskPlanner/missionTaskFormConstants';

interface MissionCardProps {
  mission: MissionResponseLite;
  missionTaskForm: UseFormReturn;
  isSelected: boolean;
  backButtonPopover: MissionTaskPopovers;
  setBackButtonPopover: (backButtonPopover: MissionTaskPopovers) => void;
  handleClick?: Dispatch<number>;
  setVisiblePopover: (visiblePopover: MissionTaskPopovers | null) => void;
}

export const MissionCard = ({
  mission,
  missionTaskForm,
  isSelected,
  backButtonPopover,
  setBackButtonPopover,
  handleClick,
  setVisiblePopover,
}: MissionCardProps) => {
  return (
    <MissionStyledCard
      onClick={!isSelected ? handleClick : null}
      isSelected={isSelected}
      pointer={handleClick}
    >
      <Row justify="space-between" align="center" gutter="none" noWrap={true}>
        <MissionCardStyledStack gutter="lg">
          <Row align="center" justify="space-between" noWrap={true}>
            <Tooltip
              colorVariant="dark"
              delay={100}
              placement={{
                align: 'center',
                side: 'right',
              }}
              tip={mission.missionName}
              shouldWrapChildren={true}
            >
              <CardTitleRowWithEllipsis
                align="center"
                justify="flex-start"
                noWrap={true}
                gutter={'xxs'}
                pointer={handleClick && !isSelected}
              >
                <Icon icon="Route" />
                <CardTitle variant="paragraph_medium_bold" align="left">
                  {mission.missionName}
                </CardTitle>
              </CardTitleRowWithEllipsis>
            </Tooltip>
            <Pill>{FLIGHT_MODE_TO_DISPLAY_NAME[`${mission.mode}`]}</Pill>
          </Row>
          <Row align="center" justify="space-between">
            <Row justify="flex-start" gutter="xxs">
              <Icon icon="Drone" />
              <Text variant="paragraph_medium" align="left">
                {DRONE_TYPE_TO_DISPLAY_NAME[`${mission.drone}`]}
              </Text>
            </Row>
            <Row justify="flex-start" gutter="xxs">
              <Icon icon="Stopwatch" />
              <Text variant="paragraph_medium" align="left">
                {/* TODO replace this with a calculation */}
                1h 21' 10"
              </Text>
            </Row>
          </Row>
        </MissionCardStyledStack>
        <MissionCardDetailsButton
          variant="tertiary"
          aria-label="display mission details"
          icon="ChevronRight"
          onClick={() => {
            missionTaskForm.setValue(
              MISSION_TASK_FORM_FIELDS.detailViewMissionId,
              mission.id,
            );
            setVisiblePopover('missionDetail');
            setBackButtonPopover(backButtonPopover);
          }}
        />
      </Row>
    </MissionStyledCard>
  );
};
