import styled from 'styled-components';
import { Text } from '@raptormaps/text';
import { FullWidthRow } from '@/shared/styles/sidebar.styles';

export const FlightIdRow = styled(FullWidthRow)`
  input {
    width: 75%;
    margin-left: -25px;
  }
  button {
    margin-left: 15px;
    min-width: 120px;
  }
`;

export const LongText = styled(Text)`
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
