import EventEmitter from 'eventemitter3';

export const TelemetryEmitter = new EventEmitter();

export enum TELEMETRY_EVENTS {
  LIVESTREAM_BECAME_AVAILABLE = 'LIVESTREAM_BECAME_AVAILABLE',
  LIVESTREAM_BECAME_UNAVAILABLE = 'LIVESTREAM_BECAME_UNAVAILABLE',
  FLIGHT_TASK_READY = 'FLIGHT_TASK_READY',
}

export const DJI_METHOD_TO_EMITTER_EVENT = {
  flighttask_ready: TELEMETRY_EVENTS.FLIGHT_TASK_READY,
};
