import {
  MissionResponse,
  DroneType,
  CameraAngleMode,
  FlightModeType,
} from '@raptormaps/raptor-flight-client-ts';
import { FeatureCollection, Polygon, Feature, Point } from '@turf/helpers';
import { useToast } from '@raptormaps/toast';
import mapboxgl from 'mapbox-gl';
import JSZip from 'jszip';
import dayjs from 'dayjs';

import {
  IntervalometerObject,
  ActionTriggerType,
} from '@/shared/types/missions.d';
import { ERROR_TOAST_DURATION } from '@/shared/constants/appConstants';
import { ActionTrigger } from '@/shared/constants/missionLookups';
import {
  DJI_FILE_TYPES,
  createDJIFiles,
} from '@/pages/MissionPlanner/utils/flightPlanningUtils';
import { getFieldOfViewSpacing } from '@/pages/MissionPlanner/utils/utils';

const clickGenerateDJIWpmlKmz = async (kml, wpml, missionId) => {
  try {
    // Workaround for incorrect timestamps for files:
    const currDate = new Date();
    const dateWithOffset = new Date(
      currDate.getTime() - currDate.getTimezoneOffset() * 60000,
    );

    // Create a new instance of JSZip
    const zip = new JSZip();
    zip.folder('wpmz').file('template.kml', kml, { date: dateWithOffset });
    zip.folder('wpmz').file('waylines.wpml', wpml, { date: dateWithOffset });

    // Add KML and WMPL content to file and add to zip folder
    const content = await zip.generateAsync({ type: 'blob' });

    // File name needs to be unique or it overwrites in S3
    const kmzFile = new File(
      [content],
      `ID${missionId}TIME${dayjs.utc().format('YYYYMMDDhhmmssA')}.kmz`,
      {
        type: 'application/vnd.google-earth.kmz',
      },
    );
    return kmzFile;
  } catch (err) {
    console.error(err);
    return;
  }
};

interface CreateKmzForUploadProps {
  map?: mapboxgl.Map;
  mission: MissionResponse;
  continuousOperationStartTime?: string;
  polygon: Feature<Polygon>;
  waypoints: FeatureCollection;
  intervalometer: IntervalometerObject;
  toast: ReturnType<typeof useToast>;
  transitSpeedBool?: boolean;
  transitSpeed?: number;
}

export const createKmzForUpload = async ({
  map,
  mission,
  continuousOperationStartTime,
  polygon,
  waypoints,
  intervalometer,
  toast,
  transitSpeedBool,
  transitSpeed,
}: CreateKmzForUploadProps) => {
  if (!map || !polygon || !waypoints) {
    toast.error('Map is not fully loaded', {
      duration: ERROR_TOAST_DURATION,
      dismissable: true,
    });
    return;
  }
  if (!mission) {
    toast.error('Mission not loaded', {
      duration: ERROR_TOAST_DURATION,
      dismissable: true,
    });
    return;
  }

  if (mission.mode === FlightModeType.LinearCustom) {
    return;
  }

  const altitudeOffsetObject = {
    offset: mission?.altitudeOffset,
    active: mission?.altitudeOffsetBool,
  };

  const actionTriggerParam =
    Math.floor(
      getFieldOfViewSpacing(
        mission.altitude,
        mission.sensorFovWidth,
        mission.frontOverlap,
      ) * 1e2,
    ) / 1e2;

  const intervalModeObject = {
    active: intervalometer.intervalometerBool,
    actionTriggerType: ActionTrigger.multipleDistance as ActionTriggerType,
    actionTriggerParam: actionTriggerParam,
  };
  const continuousOperationsEnabled =
    mission?.cameraAngleMode === CameraAngleMode.FollowTracker &&
    !!continuousOperationStartTime;

  // generate KML
  const kml = await createDJIFiles({
    filetype: DJI_FILE_TYPES.kml,
    map,
    polygon,
    flightMode: mission.mode,
    altitude: mission.altitude,
    flightAngle: mission.flightAngle,
    flightSpeed: mission.flightSpeed,
    droneType: mission.drone,
    terrainFollowBool: mission.terrainFollow,
    pitchAngle: mission.cameraPitchAngle,
    altitudeOffsetObject,
    waypoints: waypoints as FeatureCollection<Point>,
    intervalModeObject,
    waypointReductionBool: intervalometer.waypointReductionBool,
    continuousOperationsEnabled,
    safeTakeoffAltitudeBool: !!mission.safeTakeoffAltitude,
    safeTakeoffAltitude: mission.safeTakeoffAltitude,
    continuousOperationStartTime,
    transitSpeed,
    transitSpeedBool,
  });
  if (
    mission.drone === DroneType.M3t ||
    mission.drone === DroneType.M30t ||
    mission.drone === DroneType.Custom
  ) {
    toast.error(`invalid device Type ${mission.drone}`, {
      duration: ERROR_TOAST_DURATION,
      dismissable: true,
    });
    return;
  } else if (
    mission.drone == DroneType.M30tDock ||
    mission.drone === DroneType.M3tdDock
  ) {
    const wpml = createDJIFiles({
      filetype: DJI_FILE_TYPES.wpml,
      map,
      polygon,
      flightMode: mission.mode,
      altitude: mission.altitude,
      flightAngle: mission.flightAngle,
      flightSpeed: mission.flightSpeed,
      droneType: mission.drone,
      terrainFollowBool: mission.terrainFollow,
      pitchAngle: mission.cameraPitchAngle,
      altitudeOffsetObject,
      waypoints: waypoints as FeatureCollection<Point>,
      intervalModeObject,
      waypointReductionBool: intervalometer.waypointReductionBool,
      continuousOperationsEnabled,
      safeTakeoffAltitudeBool: !!mission.safeTakeoffAltitude,
      safeTakeoffAltitude: mission.safeTakeoffAltitude,
      continuousOperationStartTime,
      transitSpeed,
      transitSpeedBool,
    });

    if (kml === undefined || wpml === undefined) {
      toast.error(
        `KML or WPML undefined. KML: ${kml ? 'defined' : kml} WPML: ${
          wpml ? 'defined' : wpml
        }`,
        {
          duration: ERROR_TOAST_DURATION,
          dismissable: true,
        },
      );
      return;
    }
    // create the KMZ
    return clickGenerateDJIWpmlKmz(kml, wpml, mission.id);
  }
};
