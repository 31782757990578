export enum FlightDetailLayers {
  flightPath = 'Flight Path',
  waypoints = 'Waypoints',
}

export enum EquipmentDetailLayers {
  'module' = 'Module',
  'string' = 'String',
  'inverterBlock' = 'Inverter Block',
  'row' = 'Row',
  'combiner' = 'Combiner',
  'inverter' = 'Inverter',
  'pyranometer' = 'Pyranometer',
}
