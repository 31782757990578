import { useMutation } from 'react-query';
import { DebugApi } from '@raptormaps/dji-dock-api-ts';
import { useToast } from '@raptormaps/toast';

import { useRoboticsHubApi } from './useRoboticsHubApi';
import { ERROR_TOAST_DURATION } from '../constants/appConstants';
const ROBOTICS_API_ENDPOINT = window.ROBOTICS_HUB_DJI_DOCK_API_ENDPOINT;

export const useDockOpenDebugMode = () => {
  const toast = useToast();
  const api = useRoboticsHubApi(DebugApi, {
    basePath: ROBOTICS_API_ENDPOINT,
  });

  return useMutation({
    mutationKey: 'openDebugMode',
    mutationFn: async ({ dockSn }: any) => {
      if (!dockSn) {
        toast.error(`Dock serial number is not valid`, {
          duration: ERROR_TOAST_DURATION,
          dismissable: true,
        });
        return;
      }
      return await api.openDebugModeDebugDockOpenDebugModePost({
        dockSn,
      });
    },
    onSuccess: response => {
      toast.success(`Debug Mode Opened:${JSON.stringify(response.status)}`);
    },
    onError: error => {
      toast.error(`Failed to open debug mode: ${error}`, {
        duration: ERROR_TOAST_DURATION,
        dismissable: true,
      });
    },
  });
};

export const useDockCloseDebugMode = () => {
  const toast = useToast();
  const api = useRoboticsHubApi(DebugApi, {
    basePath: ROBOTICS_API_ENDPOINT,
  });

  return useMutation({
    mutationKey: 'closeDebugMode',
    mutationFn: async ({ dockSn }: any) => {
      return await api.closeDebugModeDebugDockCloseDebugModePost({
        dockSn,
      });
    },
    onSuccess: response => {
      toast.success(`Debug Mode Closed:${JSON.stringify(response.status)}`);
    },
    onError: error => {
      toast.error(`Failed to close debug mode: ${error}`, {
        duration: ERROR_TOAST_DURATION,
        dismissable: true,
      });
    },
  });
};

export const useDockReboot = () => {
  const toast = useToast();
  const api = useRoboticsHubApi(DebugApi, {
    basePath: ROBOTICS_API_ENDPOINT,
  });

  return useMutation({
    mutationKey: 'closeDebugMode',
    mutationFn: async ({ dockSn }: any) => {
      return await api.rebootDockDebugDockRebootPost({
        dockSn,
      });
    },
    onSuccess: response => {
      toast.success(`Dock Rebooting:${JSON.stringify(response.status)}`);
    },
    onError: error => {
      toast.error(`Failed to reboot dock: ${error}`, {
        duration: ERROR_TOAST_DURATION,
        dismissable: true,
      });
    },
  });
};
