import styled from 'styled-components';
import { Icon } from '@raptormaps/icon';

export const LiveStreamBackButtonContainer = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;
  width: 99px;
  height: 40px;
  border-radius: 6px;
  cursor: pointer;
  z-index: 1;
`;

export const CameraControlsContainer = styled.div`
  position: absolute;
  top: 24px;
  right: 66px;
  width: 212px;
  height: 104px;
  border-radius: 6px;
  cursor: pointer;
  z-index: 1;
  padding: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CameraControlIcon = styled(Icon)`
  svg {
    width: 24px;
    height: 24px;
  }
`;
