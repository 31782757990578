// External dependancies
import { useState, useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';
// RaptorUI
import {
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalTitle,
} from '@raptormaps/modal';
import { Button } from '@raptormaps/button';
import { TextInput } from '@raptormaps/text-input';
import { Row, Stack, Gutter } from '@raptormaps/layout';
import { Text } from '@raptormaps/text';

// Internal dependancies
import UniqueNameValidationMessage from '@/pages/MissionPlanner/components/shared/ValidationMessage';
// Hooks
import {
  useGetIsNameUnique,
  useGetUniqueName,
} from '@/shared/hooks/useMissions';

interface SaveAsModalProps {
  solarFarmId: number;
  currentName: string;
  openSaveAsModal: boolean;
  setOpenSaveAsModal: (value: boolean) => void;
  handleCreateMission: (missionName: string) => void;
}

const SaveAsModal = ({
  solarFarmId,
  currentName,
  openSaveAsModal,
  setOpenSaveAsModal,
  handleCreateMission,
}: SaveAsModalProps) => {
  const { data: getUniqueNameData } = useGetUniqueName(
    currentName,
    solarFarmId,
  );
  const initialUniqueName = getUniqueNameData?.uniqueName;

  const [validationName, setValidationName] = useState<string | null>(
    initialUniqueName,
  );
  const [missionName, setMissionName] = useState<string | null>();
  const handleSaveAsNameChange = useRef(
    debounce((name: string) => {
      setValidationName(name);
    }, 500),
  );
  const { data: isNameUniqueData, isFetching: isFetchingIsUnique } =
    useGetIsNameUnique(validationName?.trim(), solarFarmId);
  useEffect(() => {
    setMissionName(initialUniqueName);
    setValidationName(initialUniqueName);
  }, [initialUniqueName]);

  const handleSaveAsClick = async () => {
    try {
      await handleCreateMission(missionName.trim());
    } finally {
      setOpenSaveAsModal(false);
    }
  };

  const handleClose = () => {
    setOpenSaveAsModal(false);
    setMissionName(initialUniqueName);
  };

  const errorText = () => {
    if (!missionName) return 'Mission Name is required';
    if (!isNameUniqueData?.isUnique)
      return 'Mission name must be unique. A Mission with this name already exists for this solar farm.';
    else return '';
  };

  return (
    <Modal onClose={handleClose} open={openSaveAsModal}>
      <ModalContent>
        <ModalCloseButton />
        <Stack gutter={Gutter.XXL} style={{ width: 450, hieght: 308 }}>
          <ModalTitle>Save Mission As</ModalTitle>
          <Text style={{ padding: '5px', marginTop: '-15px' }}>
            Save the current mission as a new copy by specifying a different
            mission name, the original mission will remain unchanged.
          </Text>
          <Stack style={{ height: '75px' }}>
            <TextInput
              name="missionName"
              inputSize="small"
              placeholder="Mission Name"
              value={missionName}
              onChange={e => {
                setMissionName(e.target.value);
                handleSaveAsNameChange.current(e.target.value);
              }}
              onBlur={e => {
                setMissionName(e.target.value.trim());
                handleSaveAsNameChange.current(e.target.value.trim());
              }}
              className={`${errorText() ? 'is-invalid' : ''}`}
              style={{ width: '97.5%' }}
            />
            <UniqueNameValidationMessage
              isFetching={isFetchingIsUnique}
              errorText={errorText()}
            />
          </Stack>
          <Row>
            <Button
              variant="tertiary"
              style={{
                width: '48%',
                height: '52px',
              }}
              type="submit"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              style={{
                width: '48%',
                height: '52px',
              }}
              type="submit"
              onClick={handleSaveAsClick}
              disabled={!!errorText()}
            >
              Save As
            </Button>
          </Row>
        </Stack>
      </ModalContent>
    </Modal>
  );
};

export default SaveAsModal;
