// External Dependencies
import { useState, useMemo } from 'react';
import { useToast } from '@raptormaps/toast';

// Raptor UI
import { DropdownMenu, DropdownOption } from '@raptormaps/dropdown-menu';
import { Tabs } from '@raptormaps/tabs';
import { Text, TextVariant } from '@raptormaps/text';
import { Tooltip } from '@raptormaps/tooltip';
import { Icon } from '@raptormaps/icon';

// Internal Dependencies
import {
  NUMBER_INPUT_STEP,
  FLIGHT_MODE_DEFAULTS,
  DEFAULT_SPEED_SETTINGS,
  DRONE_TRANSIT_SPEED_DEFAULTS,
} from '@/pages/MissionPlanner/constants/missionConstants';
import {
  FullRowTabs,
  QuarterWidthTextInput,
  FormLabel,
  MinMax,
  FullWidthGridSection,
  GridColumnOneSpanFour,
  GridColumnOneSpanTwo,
  FieldErrorText,
} from '@/pages/MissionPlanner/components/MissionPlanningSidebar/MissionPlanningSidebar.styles';
import { DropdownButton } from '@/shared/styles/input.styles';
import {
  SPEED_CONTROL_TO_DISPLAY_NAME,
  DISPLAY_NAME_TO_SPEED_CONTROL,
} from '@/shared/constants/missionLookups';

// Types
import { FormParameters } from '@/shared/types/missions.d';
import {
  DroneType,
  SpeedControlModeType,
} from '@raptormaps/raptor-flight-client-ts';
import { CombinedFlightModeType } from '@/shared/types/tempMissions';

// Utils
import { handleInputScroll } from '@/pages/MissionPlanner/utils/utils';

// Growthbook
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { GrowthbookFlags } from '@/shared/utils/GrowthbookUtils';

interface SpeedControllerProps {
  errors: {
    cameraInterval?: string;
    flightSpeed?: string;
    transitSpeed?: string;
  };
  flightMode: CombinedFlightModeType;
  flightSpeed: number;
  transitSpeed?: number;
  cameraInterval: number;
  speedControlMode: SpeedControlModeType;
  formParameters: FormParameters;
  onBlur: () => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleEnterPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  device: DroneType;
  setFieldValue: (field: string, value: unknown) => void;
}

const SpeedController = ({
  errors,
  flightMode,
  flightSpeed,
  transitSpeed,
  cameraInterval,
  speedControlMode,
  formParameters,
  handleChange,
  handleEnterPress,
  onBlur,
  device,
  setFieldValue,
}: SpeedControllerProps) => {
  const toast = useToast();
  const [isCustomEnabled, setIsCustomEnabled] = useState<boolean>(
    !(
      FLIGHT_MODE_DEFAULTS[flightMode].speedControlMode === speedControlMode &&
      (FLIGHT_MODE_DEFAULTS[flightMode].flightSpeed
        ? FLIGHT_MODE_DEFAULTS[flightMode].flightSpeed === flightSpeed
        : FLIGHT_MODE_DEFAULTS[flightMode].cameraInterval === cameraInterval)
    ),
  );

  const [transitSpeedIsCustom, setTransitSpeedIsCustom] = useState<boolean>(
    device !== DroneType.MannedAirplane &&
      transitSpeed === DRONE_TRANSIT_SPEED_DEFAULTS[device].default,
  );

  const displayTransitSpeedGrowthbookFlag = useFeatureIsOn(
    GrowthbookFlags.TRANSIT_SPEED,
  );

  const SPEED_CONTROLS = Object.values(SPEED_CONTROL_TO_DISPLAY_NAME);

  const isCameraInterval = useMemo(
    () => speedControlMode === SpeedControlModeType.Shutter,
    [speedControlMode],
  );

  const displayTransitSpeed =
    displayTransitSpeedGrowthbookFlag &&
    device !== DroneType.MannedAirplane &&
    device !== DroneType.Custom;

  const preventChange = useMemo(
    () =>
      (isCameraInterval && errors.cameraInterval) ||
      (!isCameraInterval && errors.flightSpeed),
    [isCameraInterval, errors.cameraInterval, errors.flightSpeed],
  );

  const handleSetToDefault = () => {
    setIsCustomEnabled(false);
    DEFAULT_SPEED_SETTINGS[flightMode]?.forEach(target => {
      handleChange({ target: target } as React.ChangeEvent<HTMLInputElement>);
    });
  };

  const handleSetTransitSpeedToDefault = () => {
    if (
      displayTransitSpeedGrowthbookFlag &&
      device !== DroneType.Custom &&
      device !== DroneType.MannedAirplane
    ) {
      setFieldValue(
        'transitSpeed',
        DRONE_TRANSIT_SPEED_DEFAULTS[device].default,
      );
    }
  };

  const photoSpeedOptions: DropdownOption[] = [
    {
      onSelect: () => {
        handleSetToDefault();
      },
      value: 'Default',
      variant: 'option',
      style: { fontSize: '14px', justifyContent: 'start' },
    },
    {
      onSelect: () => {
        setIsCustomEnabled(true);
      },
      value: 'Custom',
      variant: 'option',
      style: { fontSize: '14px', justifyContent: 'start' },
    },
  ];

  const transitSpeedDefaultOptions: DropdownOption[] = [
    {
      onSelect: () => {
        setTransitSpeedIsCustom(false);
        handleSetTransitSpeedToDefault();
      },
      value: 'Default',
      variant: 'option',
      style: { fontSize: '14px', justifyContent: 'start' },
    },
  ];

  const handleClick = (index: number) => {
    // if the user is changing from the default speed setting switch to custom
    setIsCustomEnabled(true);
    // if there is an active error for the current input trigger a toaster
    preventChange
      ? toast.error(
          'There needs to be a valid value in the Interval / Speed input before being able to switch Speed Control modes',
        )
      : // else update the speed control mode
        handleChange({
          target: {
            name: 'speedControlMode',
            value: DISPLAY_NAME_TO_SPEED_CONTROL[SPEED_CONTROLS[index]],
          },
        } as React.ChangeEvent<HTMLInputElement>);
  };

  return (
    <>
      <FullWidthGridSection>
        <GridColumnOneSpanFour>
          <FormLabel variant="label_small">Speed Controller</FormLabel>
          <FullRowTabs>
            <Tabs
              handleClick={(index: number) => {
                handleClick(index);
              }}
              items={SPEED_CONTROLS}
              activeIndex={SPEED_CONTROLS.indexOf(
                SPEED_CONTROL_TO_DISPLAY_NAME[speedControlMode],
              )}
            />
          </FullRowTabs>
        </GridColumnOneSpanFour>
        <GridColumnOneSpanTwo>
          <FormLabel overflow="visible" variant="label_small">
            {isCameraInterval
              ? 'Camera Interval (seconds)'
              : 'Flight Speed (meters/second)'}
          </FormLabel>
          <DropdownMenu
            menuContentProps={{
              align: 'start',
              sideOffset: 0,
              side: 'bottom',
              style: {
                maxHeight: '70vh',
                width: '219px',
                minWidth: '120px',
                marginTop: 0,
              },
            }}
            items={photoSpeedOptions}
            modal={false}
          >
            <DropdownButton size="small" variant="secondary">
              {isCustomEnabled ? 'Custom' : 'Default'}
            </DropdownButton>
          </DropdownMenu>
        </GridColumnOneSpanTwo>
        <QuarterWidthTextInput
          style={{ marginTop: '6.5px' }}
          gridColumn={3}
          label={' '}
          inputSize="small"
          inputStyle="white-box"
          inputType="number"
          value={isCameraInterval ? cameraInterval : flightSpeed}
          onWheel={handleInputScroll}
          onBlur={onBlur}
          step={NUMBER_INPUT_STEP}
          min={
            isCameraInterval
              ? formParameters.cameraIntervalInput.min
              : formParameters.flightSpeedInput.min
          }
          max={
            isCameraInterval
              ? formParameters.cameraIntervalInput.max
              : formParameters.flightSpeedInput.max
          }
          onChange={handleChange}
          onKeyDownCapture={handleEnterPress}
          name={isCameraInterval ? 'cameraInterval' : 'flightSpeed'}
          disabled={!isCustomEnabled}
          className={`${
            errors.cameraInterval || errors.flightSpeed ? 'is-invalid' : ''
          }`}
        />
        <MinMax gridColumn={4}>
          <Text variant="paragraph_small">
            Min:{' '}
            {isCameraInterval
              ? formParameters.cameraIntervalInput.min
              : formParameters.flightSpeedInput.min}
          </Text>
          <Text variant="paragraph_small">
            Max:{' '}
            {isCameraInterval
              ? formParameters.cameraIntervalInput.max
              : formParameters.flightSpeedInput.max}
          </Text>
        </MinMax>
        <FieldErrorText variant={TextVariant.paragraph_small}>
          {isCameraInterval ? errors.cameraInterval : errors.flightSpeed}
        </FieldErrorText>
        {/* TRANSIT SPEED */}
        {displayTransitSpeed && (
          <>
            <GridColumnOneSpanTwo>
              <Tooltip
                colorVariant="dark"
                delay={100}
                placement={{
                  align: 'center',
                  side: 'right',
                }}
                tip="Speed from takeoff point to first waypoint"
                shouldWrapChildren={true}
              >
                <FormLabel overflow="visible" variant="label_small">
                  {'Transit Speed (meters/second)'}
                  <Icon
                    aria-label="Transit Speed Info Icon"
                    icon="CircleInfo"
                    size={'medium'}
                    style={{ paddingBottom: '15px' }}
                  />
                </FormLabel>
              </Tooltip>
              <DropdownMenu
                menuContentProps={{
                  align: 'start',
                  sideOffset: 0,
                  side: 'bottom',
                  style: {
                    maxHeight: '70vh',
                    width: '219px',
                    minWidth: '120px',
                    marginTop: 0,
                  },
                }}
                items={transitSpeedDefaultOptions}
                modal={false}
              >
                <DropdownButton size="small" variant="secondary">
                  {transitSpeedIsCustom ? 'Custom' : 'Default'}
                </DropdownButton>
              </DropdownMenu>
            </GridColumnOneSpanTwo>
            <QuarterWidthTextInput
              style={{ marginTop: '6.5px' }}
              gridColumn={3}
              label={' '}
              inputSize="small"
              inputStyle="white-box"
              inputType="number"
              value={transitSpeed}
              onWheel={handleInputScroll}
              onBlur={onBlur}
              step={NUMBER_INPUT_STEP}
              min={DRONE_TRANSIT_SPEED_DEFAULTS[device].min}
              max={DRONE_TRANSIT_SPEED_DEFAULTS[device].max}
              onChange={e => {
                setTransitSpeedIsCustom(true);
                handleChange(e);
              }}
              onKeyDownCapture={handleEnterPress}
              name={'transitSpeed'}
              className={`${errors.transitSpeed ? 'is-invalid' : ''}`}
            />
            <MinMax gridColumn={4}>
              <Text variant="paragraph_small">
                {`Min:
                ${DRONE_TRANSIT_SPEED_DEFAULTS[device].min}`}
              </Text>
              <Text variant="paragraph_small">
                {`Max: ${DRONE_TRANSIT_SPEED_DEFAULTS[device].max}`}
              </Text>
            </MinMax>
          </>
        )}
      </FullWidthGridSection>
      <FieldErrorText variant={TextVariant.paragraph_small}>
        {errors.transitSpeed}
      </FieldErrorText>
    </>
  );
};

export default SpeedController;
