import { GrowthBook } from '@growthbook/growthbook-react';

// GrowthBook instance
export const growthbookInstance = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: window.REACT_APP_GROWTHBOOK_API_KEY,
  decryptionKey: window.REACT_APP_GROWTHBOOK_DECRYPT_KEY,
  enableDevMode: true,
});

export const GrowthbookFlags = {
  LIVE_STREAM: 'robotics-live-stream',
  CAMERA_SELECT_WITH_ZOOM: 'show-camera-select-with-zoom',
  MISSION_TASK: 'robotics-mission-tasks',
  TELEMETRY_WEBSOCKET: 'robotics-use-telemetry-websocket',
  SQUARE_ORBITAL: 'robotics-square-orbitals-mode',
  LIVE_STREAM_REQUIRE_LAST_VIEWER:
    'robotics-require-last-viewer-to-close-streams',
  LIVE_STREAM_MODAL_VISIBLE: 'robotics-allow-livestream-modal',
  EXECUTE_FLIGHT: 'robotics-execute-flight',
  TRANSIT_SPEED: 'robotics-use-transit-speed',
  FLIGHT_FUNCTIONAL_WPML: 'robotics-flight-functional-wpml',
  LINEAR_MISSIONS_V2: 'robotics-linear-missions',
  DOCK_DEVELOPER_TOOLS: 'robotics-dock-developer-tools',
};
