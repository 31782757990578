// Styled Components
import { Row, Stack } from '@raptormaps/layout';
import { Text } from '@raptormaps/text';
import { Icon } from '@raptormaps/icon';
import theme from '@raptormaps/theme';

// Components
import { StaleTelemetryIcon } from './StaleTelemetryIcon';

// Telemetry
import { DockDeviceTelemetry } from '@/shared/types/telemetry.d';
import { useDockDeviceTelemetryValue } from '@/shared/stores/TelemetryWebsocket/TelemetryWebsocketStore';

// Constants
import { TELEMETRY_KEY_TO_DISPLAY_CONFIG } from '@/shared/constants/telemetryConstants';

interface BatteryPercentTelemetryProps {
  dockSn: string;
  aircraftSn: string;
  showIcon?: boolean;
}
export const BatteryPercentTelemetry = ({
  dockSn,
  aircraftSn,
  showIcon = false,
}: BatteryPercentTelemetryProps) => {
  const aircraftBatteryPercentKey: keyof DockDeviceTelemetry =
    'aircraftBatteryPercent';

  const aircraftBatteryPercentTelemetry = useDockDeviceTelemetryValue({
    deviceSn: aircraftSn,
    key: aircraftBatteryPercentKey,
    properties: { isStale: true, timestamp: true, lastMonitored: true },
  });
  const dockBatteryPercentTelemetry = useDockDeviceTelemetryValue({
    deviceSn: dockSn,
    key: aircraftBatteryPercentKey,
    properties: { isStale: true, timestamp: true, lastMonitored: true },
  });

  const showAircraftBatteryPercent = Boolean(
    aircraftBatteryPercentTelemetry.value,
  );
  const { value, isStale, timestamp, lastMonitored } =
    showAircraftBatteryPercent
      ? aircraftBatteryPercentTelemetry
      : dockBatteryPercentTelemetry;

  const { toDisplay, title, iconName } =
    TELEMETRY_KEY_TO_DISPLAY_CONFIG[aircraftBatteryPercentKey];

  return (
    <Row align="center" noWrap>
      {isStale && (
        <StaleTelemetryIcon
          timestamp={timestamp}
          lastMonitored={lastMonitored}
        />
      )}
      {!isStale && showIcon && (
        <Icon icon={iconName} size="large" color={theme.colors.black} />
      )}
      {showIcon ? (
        <Stack gutter="xxs">
          <Text variant="paragraph_small" align="left">
            {title}
          </Text>
          <Text variant="paragraph_large_bold" align="left">
            {toDisplay(value)}
          </Text>
        </Stack>
      ) : (
        <Text variant="paragraph_medium" align="right">
          {toDisplay(value as string)}
        </Text>
      )}
    </Row>
  );
};
