import styled from 'styled-components';
import { Stack } from '@raptormaps/layout';
import theme from '@raptormaps/theme';

export const WarningBannerStack = styled(Stack)`
  width: 100%;
  background-color: ${theme.colors.secondary_50};
  border: 1px solid ${theme.colors.yellow_500};
  border-radius: 4px;
  padding: 15px;
  margin-bottom: -10px;
`;
