import { LayerEvent } from '../map.d';
import handlers from '../EventHandlers/GeospatialRowEventHandlers';

import { GEOSPATIAL_ROW_FILL_LAYER_ID } from '../layers/GeospatialRowLayers';

const GeospatialRowEventListeners = (
  setActiveRowId: (id: number) => void,
  isDrawtoolActive: () => boolean,
): LayerEvent[] => [
  {
    type: 'click',
    layerIds: [GEOSPATIAL_ROW_FILL_LAYER_ID],
    handler: (e: Event) =>
      handlers.handleRowClick(e, setActiveRowId, isDrawtoolActive),
  },
];

export default GeospatialRowEventListeners;
