import { useState } from 'react';

// Components
import DroneSidebarTelemetry from './DroneSidebarTelemetry';
import DockSidebarTelemetry from './DockSidebarTelemetry';
import LiveStreamViewerModal from './LiveStreamViewerModal';

// Styled Components
import { SiteOverviewContainer } from '../Overview.styled';
import { Button } from '@raptormaps/button';
import { Text } from '@raptormaps/text';
import { useToast } from '@raptormaps/toast';

// Types
import { DockDevice } from '@/shared/types/devices';
import {
  DeviceResponse,
  DeviceType,
  DolbyDeviceResponse,
} from '@raptormaps/raptor-flight-client-ts';
import { Feature } from '@turf/helpers';

// Live Device Connections
import {
  useDolbyLivestreamStore,
  DolbyLivestreamState,
} from '@/shared/stores/DolbyLivestreamStore';
import { useLivestream } from '@/shared/hooks/useLivestream';

// Growthbook
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { GrowthbookFlags } from '@/shared/utils/GrowthbookUtils';

interface DockOverviewProps {
  navigateToSite: () => void;
  dockDevice: DockDevice;
  dolbyCredentials: DolbyDeviceResponse;
  navigateToDroneLocation: (droneLocation: Feature) => void;
}

const DockOverview = ({
  navigateToSite,
  dockDevice,
  dolbyCredentials,
  navigateToDroneLocation,
}: DockOverviewProps) => {
  const [displayLiveStreamModal, setDisplayLiveStreamModal] = useState(false);
  const [videoPlayerLoading, setVideoPlayerLoading] = useState(false);

  const toast = useToast();
  const requireLastViewerToCloseStreams = useFeatureIsOn(
    GrowthbookFlags.LIVE_STREAM_REQUIRE_LAST_VIEWER,
  );

  // Live Stream
  const closeDolbyStream = useDolbyLivestreamStore(
    (state: DolbyLivestreamState) => state.closeDolbyStream,
  );

  const {
    devicesLiveCapacityState,
    livestreamInProgress,
    isLastViewer,
    startDeviceLivestream,
    startingLivestreamInProgress,
    stoppingLivestreamInProgress,
    stopAllDeviceLivestreams,
    stopDroneLivestream,
  } = useLivestream({ dockDevice });

  const openLivestreamIsDisabled =
    !dolbyCredentials ||
    !dockDevice ||
    !devicesLiveCapacityState ||
    stoppingLivestreamInProgress ||
    startingLivestreamInProgress ||
    videoPlayerLoading;

  const livestreamErrorMessage = () => {
    if (!dolbyCredentials) {
      return 'Missing required credentials.';
    } else if (!devicesLiveCapacityState) {
      return 'Device livestream functionality is unavailable.';
    } else if (stoppingLivestreamInProgress) {
      return 'Livestream shut down in progress.';
    } else if (startingLivestreamInProgress) {
      return 'Livestream starting...';
    } else if (videoPlayerLoading) {
      return '';
    } else {
      return 'Unable to start live stream.';
    }
  };

  const closeLivestream = () => {
    setDisplayLiveStreamModal(false);
    closeDolbyStream();

    // Only close device connection if no other viewers are connected
    // This is an attempt to prevent dangling streams that use Dolby bandwidth
    if (requireLastViewerToCloseStreams && isLastViewer) {
      stopAllDeviceLivestreams();
    } else if (!requireLastViewerToCloseStreams) {
      stopAllDeviceLivestreams();
    }
  };

  const startLivestream = (deviceSn: string) => {
    if (!livestreamInProgress) {
      toast.neutral('Preparing live stream...');
    }
    setVideoPlayerLoading(true);
    setDisplayLiveStreamModal(true);
    startDeviceLivestream({
      dolbyCredentials,
      deviceSn: deviceSn,
      deviceType: DeviceType.Dock,
    });
  };

  /**
   * Hacky workaround to switching cameras
   * We start / stop the drone camera stream
   * While leaving the dock stream open
   */
  const handleSwitchDeviceCamera = (
    deviceSn: string,
    deviceType: DeviceType,
  ) => {
    toast.neutral('Switching camera...');

    if (deviceType === DeviceType.AerialDrone) {
      startDeviceLivestream({
        dolbyCredentials,
        deviceSn,
        deviceType: DeviceType.AerialDrone,
      });
    } else {
      stopDroneLivestream();
    }
  };

  const toggleLiveStreamModal = () => {
    if (displayLiveStreamModal) {
      closeLivestream();
    } else {
      startLivestream(dockDevice.deviceSn);
    }
  };

  return (
    <>
      {dockDevice && (
        <>
          <Button
            iconPosition="left"
            icon="ArrowLeft"
            variant="tertiary"
            onClick={navigateToSite}
          >
            Back
          </Button>
          <SiteOverviewContainer>
            {dockDevice.devices?.length ? (
              dockDevice.devices.map((device: DeviceResponse) => (
                <DroneSidebarTelemetry
                  key={device.id}
                  droneDevice={device}
                  dockSn={dockDevice.deviceSn}
                  navigateToDroneLocation={navigateToDroneLocation}
                />
              ))
            ) : (
              <Text variant="paragraph_large_bold">
                No aircraft is associated to this device.
              </Text>
            )}
            <DockSidebarTelemetry
              selectedDockDevice={dockDevice}
              displayLiveStreamModal={displayLiveStreamModal}
              toggleLiveStreamModal={toggleLiveStreamModal}
              openLivestreamIsDisabled={openLivestreamIsDisabled}
              livestreamErrorMessage={livestreamErrorMessage()}
            />
          </SiteOverviewContainer>
          {displayLiveStreamModal && (
            <LiveStreamViewerModal
              dolbyCredentials={dolbyCredentials}
              setVideoPlayerLoading={setVideoPlayerLoading}
              dockDevice={dockDevice}
              handleSwitchDeviceCamera={handleSwitchDeviceCamera}
            />
          )}
        </>
      )}
    </>
  );
};

export default DockOverview;
