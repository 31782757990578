import {
  Feature,
  FeatureCollection,
  Point,
  featureCollection,
} from '@turf/helpers';
import {
  AltitudeOffsetObject,
  IntervalModeObject,
  WaypointNames,
} from '../../types/missions.d';

import { CombinedFlightModeType } from '@/shared/types/tempMissions';

import { DroneType, FlightModeType } from '@raptormaps/raptor-flight-client-ts';
import { DJI_DRONE_VALUES } from './djiConstants';
import { XMLBuilder } from 'fast-xml-parser';
import { DEFAULT_TRANSIT_SPEED } from '@/pages/MissionPlanner/constants/missionConstants';
import { DJI_FLIGHT_MODE_VALUES } from '@/shared/constants/missionLookups';

interface generateDJITemplateProps {
  waypoints: FeatureCollection<Point>;
  altitude: number;
  cameraHeading: number;
  flightSpeed: number;
  pitchAngle: number;
  flightMode: CombinedFlightModeType;
  droneType: DroneType;
  terrainFollowBool: boolean;
  altitudeOffsetObject: AltitudeOffsetObject;
  intervalModeObject: IntervalModeObject;
  continuousOperationsEnabled: boolean;
  takeOffSecurityHeight?: number;
  transitSpeed?: number;
  transitSpeedBool?: boolean;
}
export function generateDJITemplateKML({
  waypoints,
  altitude,
  cameraHeading,
  flightSpeed,
  pitchAngle,
  flightMode,
  droneType,
  terrainFollowBool,
  altitudeOffsetObject,
  intervalModeObject,
  continuousOperationsEnabled,
  takeOffSecurityHeight,
  transitSpeed,
  transitSpeedBool,
}: generateDJITemplateProps): string {
  const kmlContent = {
    '?xml': {
      '@_version': '1.0',
      '@_encoding': 'UTF-8',
    },
    kml: {
      '@_xmlns': 'http://www.opengis.net/kml/2.2',
      '@_xmlns:wpml': 'http://www.dji.com/wpmz/1.0.3',
      Document: {
        ...setupCreationInformation(),
        ...setupMissionInformation({
          takeOffSecurityHeight,
          droneType,
          transitSpeed,
          transitSpeedBool,
        }),
        ...setupWaypointFolder({
          flightSpeed,
          altitude,
          takeOffSecurityHeight,
          cameraHeading,
          waypoints,
          terrainFollowBool,
          altitudeOffsetObject,
          pitchAngle,
          intervalModeObject,
          continuousOperationsEnabled,
          flightMode,
          droneType,
          transitSpeed,
          transitSpeedBool,
        }),
      },
    },
  };
  const kml = convertToKML(kmlContent);
  return kml;
}

export function generateDJITemplateFlightFunctionalWPML({
  waypoints,
  altitude,
  cameraHeading,
  flightSpeed,
  pitchAngle,
  flightMode,
  droneType,
  terrainFollowBool,
  altitudeOffsetObject,
  intervalModeObject,
  continuousOperationsEnabled,
  takeOffSecurityHeight,
  transitSpeed,
  transitSpeedBool,
}: generateDJITemplateProps): string {
  const wpmlContent = {
    '?xml': {
      '@_version': '1.0',
      '@_encoding': 'UTF-8',
    },
    kml: {
      '@_xmlns': 'http://www.opengis.net/kml/2.2',
      '@_xmlns:wpml': 'http://www.dji.com/wpmz/1.0.6',
      Document: {
        ...setupMissionInformation({
          takeOffSecurityHeight,
          droneType,
          transitSpeed,
          transitSpeedBool,
          wpml: true,
        }),
        ...setupWPMLWaypointFolder({
          flightSpeed,
          altitude,
          takeOffSecurityHeight,
          cameraHeading,
          waypoints,
          terrainFollowBool,
          altitudeOffsetObject,
          pitchAngle,
          intervalModeObject,
          continuousOperationsEnabled,
          flightMode,
          droneType,
          transitSpeed,
          transitSpeedBool,
        }),
      },
    },
  };

  const wpml = convertToKML(wpmlContent);

  return wpml;
}

function setupCreationInformation() {
  return {
    'wpml:author': 'Raptor Maps, Inc.',
    'wpml:createTime': Date.now(),
    'wpml:updateTime': Date.now(),
  };
}

interface SetupMissionInformationProps {
  takeOffSecurityHeight: number;
  droneType: DroneType;
  wpml?: boolean;
  transitSpeed?: number;
  transitSpeedBool?: boolean;
}
function setupMissionInformation({
  takeOffSecurityHeight,
  droneType,
  wpml,
  transitSpeed,
  transitSpeedBool,
}: SetupMissionInformationProps) {
  return {
    'wpml:missionConfig': {
      'wpml:flyToWaylineMode': 'safely',
      'wpml:finishAction': DJI_DRONE_VALUES[droneType].finishAction,
      'wpml:exitOnRCLost': 'executeLostAction',
      'wpml:executeRCLostAction': 'goBack',
      ...(takeOffSecurityHeight && {
        'wpml:takeOffSecurityHeight': takeOffSecurityHeight,
      }),
      'wpml:globalTransitionalSpeed':
        transitSpeedBool && transitSpeed >= 0
          ? transitSpeed
          : DEFAULT_TRANSIT_SPEED,
      ...(wpml && { 'wpml:globalRTHHeight': takeOffSecurityHeight }),
      'wpml:droneInfo': {
        'wpml:droneEnumValue': DJI_DRONE_VALUES[droneType].droneEnumValue,
        'wpml:droneSubEnumValue': 1,
      },
      'wpml:payloadInfo': {
        'wpml:payloadEnumValue': DJI_DRONE_VALUES[droneType].payloadEnumValue,
        'wpml:payloadSubEnumValue':
          DJI_DRONE_VALUES[droneType].payloadSubEnumValue,
        'wpml:payloadPositionIndex': 0,
      },
    },
  };
}

function setupWaypointFolder({
  waypoints,
  altitude,
  cameraHeading,
  flightSpeed,
  pitchAngle,
  flightMode,
  droneType,
  terrainFollowBool,
  altitudeOffsetObject,
  intervalModeObject,
  continuousOperationsEnabled,
  takeOffSecurityHeight,
  transitSpeed,
  transitSpeedBool,
}: generateDJITemplateProps) {
  return {
    Folder: {
      ...setupWaypointConfig(
        flightSpeed,
        altitude,
        cameraHeading,
        flightMode,
        altitudeOffsetObject,
      ),
      ...setupWaypoints({
        inputWaypoints: waypoints,
        terrainFollowBool,
        altitudeOffsetObject,
        inspectionAltitude: altitude,
        safeTakeoffAltitude: takeOffSecurityHeight,
        pitchAngle,
        cameraHeading,
        intervalModeObject,
        continuousOperationsEnabled,
        flightMode,
        transitSpeed,
        transitSpeedBool,
      }),
      ...setupPayloadParam(droneType),
    },
  };
}

function setupWPMLWaypointFolder({
  waypoints,
  altitude,
  cameraHeading,
  flightSpeed,
  pitchAngle,
  flightMode,
  droneType,
  terrainFollowBool,
  altitudeOffsetObject,
  intervalModeObject,
  continuousOperationsEnabled,
  takeOffSecurityHeight,
  transitSpeed,
  transitSpeedBool,
}: generateDJITemplateProps) {
  return {
    Folder: {
      ...setupWPMLWaypointConfig(flightSpeed, altitude, altitudeOffsetObject),
      ...setupWaypoints({
        inputWaypoints: waypoints,
        terrainFollowBool,
        altitudeOffsetObject,
        inspectionAltitude: altitude,
        safeTakeoffAltitude: takeOffSecurityHeight,
        pitchAngle,
        cameraHeading,
        intervalModeObject,
        continuousOperationsEnabled,
        flightMode,
        wpml: true,
        globalFlightSpeed: flightSpeed,
        droneType,
        transitSpeed,
        transitSpeedBool,
      }),
    },
  };
}

function setupWaypointConfig(
  flightSpeed: number,
  altitude: number,
  cameraHeading: number,
  flightMode: CombinedFlightModeType,
  altitudeOffsetObject: AltitudeOffsetObject,
) {
  if (altitudeOffsetObject.active) {
    altitude = altitude + altitudeOffsetObject.offset;
  }
  return {
    'wpml:templateType': 'waypoint',
    'wpml:templateId': 0,
    'wpml:waylineCoordinateSysParam': {
      'wpml:coordinateMode': 'WGS84',
      'wpml:heightMode': 'relativeToStartPoint',
    },
    'wpml:autoFlightSpeed': flightSpeed,
    'wpml:globalHeight': altitude,
    'wpml:caliFlightEnable': 0,
    'wpml:gimbalPitchMode': 'manual',
    'wpml:globalWaypointHeadingParam': {
      'wpml:waypointHeadingMode':
        DJI_FLIGHT_MODE_VALUES[flightMode].waypointHeadingMode,
      'wpml:waypointHeadingAngle': cameraHeading,
      'wpml:waypointPoiPoint': '0.000000,0.000000,0.000000',
      'wpml:waypointHeadingPathMode': 'followBadArc',
      'wpml:waypointHeadingPoiIndex': 0,
    },
    'wpml:globalWaypointTurnMode':
      DJI_FLIGHT_MODE_VALUES[flightMode].waypointTurnMode,
    'wpml:globalUseStraightLine': 1,
  };
}

function setupWPMLWaypointConfig(
  flightSpeed: number,
  altitude: number,
  altitudeOffsetObject: AltitudeOffsetObject,
) {
  if (altitudeOffsetObject.active) {
    altitude = altitude + altitudeOffsetObject.offset;
  }
  return {
    'wpml:templateId': 0,
    'wpml:executeHeightMode': 'relativeToStartPoint',
    'wpml:waylineId': 0,
    'wpml:distance': 5,
    'wpml:duration': 5,
    'wpml:autoFlightSpeed': flightSpeed,
  };
}

interface ModifyWaypointPropertiesForFlightMode {
  waypoints: FeatureCollection<Point>;
  altitudeOffsetObject: AltitudeOffsetObject;
  inspectionAltitude: number;
  terrainFollowBool: boolean;
  safeTakeoffAltitude: number;
  aircraftHeading: number;
  flightMode: CombinedFlightModeType;
  continuousOperationsEnabled: boolean;
  pitchAngle: number;
  transitSpeed?: number;
  transitSpeedBool?: boolean;
}

function modifyWaypointPropertiesForFlightMode({
  waypoints,
  altitudeOffsetObject,
  inspectionAltitude,
  terrainFollowBool,
  safeTakeoffAltitude,
  aircraftHeading,
  flightMode,
  continuousOperationsEnabled,
  pitchAngle,
  transitSpeed,
  transitSpeedBool,
}: ModifyWaypointPropertiesForFlightMode) {
  // Applies logic based on flight modes/parameters to individual waypoints
  // TODO: Move a lot of this logic to utils instead of here since its logic we'll want regardless of drone
  //const newWaypoints = [];
  let relativeAltitudePointExists = false;
  let safeTakeoffAltitudeExists = false;

  const newWaypoints = waypoints.features.map(
    (feature: Feature<Point>, index: number) => {
      // Deep copy waypoint object
      const thisWaypoint = JSON.parse(JSON.stringify(feature));

      // update booleans if relevant
      if (thisWaypoint.properties.name == WaypointNames.relativeAltitudePoint) {
        relativeAltitudePointExists = true;
        if (transitSpeedBool) {
          thisWaypoint.properties.flightSpeed = transitSpeed;
        }
      }
      if (thisWaypoint.properties.name == WaypointNames.safeTakeoffAltitude) {
        safeTakeoffAltitudeExists = true;
        if (transitSpeedBool) {
          thisWaypoint.properties.flightSpeed = transitSpeed;
        }
      }

      // Adjust altitude for terrain following and altitude offset
      thisWaypoint.properties.altitude =
        (terrainFollowBool ? feature.properties.altitude : inspectionAltitude) +
        (altitudeOffsetObject.active ? altitudeOffsetObject.offset : 0);

      // Adjust altitude for safe takeoff altitude and relative altitude points if safe takeoff altitude is enabled
      if (safeTakeoffAltitude) {
        if (
          thisWaypoint.properties.name == WaypointNames.relativeAltitudePoint ||
          thisWaypoint.properties.name == WaypointNames.safeTakeoffAltitude
        ) {
          thisWaypoint.properties.altitude = safeTakeoffAltitude;
        }
      }

      // Adjust aircraft yaw
      // TODO: combine rotation and gimbal heading fields in the rest of raptor flight
      // square orbitals and perimeter mode
      if (
        flightMode == FlightModeType.SquareOrbital ||
        flightMode == FlightModeType.Perimeter
      ) {
        thisWaypoint.properties.rotateYaw = feature.properties.rotation;
      } else if (flightMode == FlightModeType.Orthomosaic) {
        // don't set rotate yaw for orthomosaic mode
      }
      // cont ops
      else if (continuousOperationsEnabled) {
        thisWaypoint.properties.rotateYaw = feature.properties.gimbalHeading;
      }
      // relative alt point, safe takeoff point
      else if (
        feature.properties.name == WaypointNames.relativeAltitudePoint ||
        feature.properties.name == WaypointNames.safeTakeoffAltitude
      ) {
        thisWaypoint.properties.rotateYaw =
          feature.properties.gimbalHeading ?? aircraftHeading;
      }
      // set gimbal yaw for the first waypoint only if either no safe takeoff altitude exists or both safe takeff altitude and relative altitude points exist
      else if (
        feature.properties.name == '0' &&
        (!safeTakeoffAltitudeExists ||
          (safeTakeoffAltitudeExists && relativeAltitudePointExists))
      ) {
        thisWaypoint.properties.rotateYaw = aircraftHeading;
      }

      // Adjust gimbal pitch for first waypoint and continuous operations
      if (index == 0 || continuousOperationsEnabled) {
        thisWaypoint.properties.gimbalRotate =
          feature.properties.gimbalPitchAngle ?? pitchAngle;
      }
      return thisWaypoint;
    },
  );
  const waypointsfc: FeatureCollection<Point> = featureCollection(newWaypoints);

  return waypointsfc;
}

// Checks if this waypoint is the last waypoint at this latitude, longitude, and altitude
function isFinalWaypoint(waypoints: FeatureCollection<Point>, index: number) {
  if (index == waypoints.features.length - 1) {
    return true;
  }
  const current = waypoints.features[index];
  const next = waypoints.features[index + 1];
  const [currentLon, currentLat] = current.geometry.coordinates;
  const [nextLon, nextLat] = next.geometry.coordinates;

  if (
    currentLon !== nextLon ||
    currentLat !== nextLat ||
    current.properties.altitude !== next.properties.altitude
  ) {
    return true;
  }
  return false;
}

interface SetupWaypointsProps {
  inputWaypoints: FeatureCollection<Point>;
  terrainFollowBool: boolean;
  altitudeOffsetObject: AltitudeOffsetObject;
  inspectionAltitude: number;
  safeTakeoffAltitude: number;
  pitchAngle: number;
  cameraHeading: number;
  intervalModeObject: IntervalModeObject;
  continuousOperationsEnabled: boolean;
  flightMode: CombinedFlightModeType;
  wpml?: boolean;
  globalFlightSpeed?: number;
  droneType?: DroneType;
  transitSpeed?: number;
  transitSpeedBool?: boolean;
}
function setupWaypoints({
  inputWaypoints,
  terrainFollowBool,
  altitudeOffsetObject,
  inspectionAltitude,
  safeTakeoffAltitude,
  pitchAngle,
  cameraHeading,
  intervalModeObject,
  continuousOperationsEnabled,
  flightMode,
  wpml,
  globalFlightSpeed,
  droneType,
  transitSpeedBool,
  transitSpeed,
}: SetupWaypointsProps) {
  const waypoints: FeatureCollection<Point> =
    modifyWaypointPropertiesForFlightMode({
      waypoints: inputWaypoints,
      altitudeOffsetObject,
      inspectionAltitude,
      terrainFollowBool,
      safeTakeoffAltitude,
      aircraftHeading: cameraHeading,
      flightMode,
      continuousOperationsEnabled,
      pitchAngle,
      transitSpeed,
      transitSpeedBool,
    });

  const placemarks = [];
  let actionGroupId = 0;
  let currentActionGroupId = actionGroupId;
  let actionIndex = 0;
  let actions = { 'wpml:action': [] };
  let waypointIndex = 0;

  waypoints.features.forEach((feature: Feature<Point>, index: number) => {
    let intervalActionGroup: object;
    const flightAltitude = feature.properties.altitude;
    if (index == 0) {
      // First waypoint: sets initial camera position and starts interval mode if active or takes picture
      const actionGroupStartIndex =
        feature.properties.name === WaypointNames.relativeAltitudePoint ? 1 : 0; // skips taking photo at the takeoff point for intervalometer mode
      actions['wpml:action'].push(
        ...(flightMode != FlightModeType.Orthomosaic
          ? [rotateYawAction(actionIndex++, feature.properties.rotateYaw)]
          : []),
        gimbalRotateAction(actionIndex++, {
          pitchRotateAngle: feature.properties.gimbalRotate,
        }),
        ...(!intervalModeObject.active
          ? [
              takePhotoAction({
                actionId: actionIndex++,
                fileSuffix: `waypoint${index}`,
                wpml,
                droneType,
              }),
            ]
          : []),
      );
      if (intervalModeObject.active) {
        intervalActionGroup = {
          ...actionGroupConfig(
            ++actionGroupId,
            actionGroupStartIndex,
            waypoints.features.length - 1,
            intervalModeObject.actionTriggerType,
            intervalModeObject.actionTriggerParam,
          ),
          'wpml:action': takePhotoAction({
            actionId: 0,
            fileSuffix: null,
            wpml,
            droneType,
          }),
        };
      }
    } else if (
      // If any actions need to take place add them to the actions list
      feature.properties.rotateYaw ||
      feature.properties.gimbalRotate ||
      !intervalModeObject.active ||
      feature.properties.hover
    ) {
      actions['wpml:action'].push(
        ...(feature.properties.rotateYaw !== undefined
          ? [rotateYawAction(actionIndex++, feature.properties.rotateYaw)]
          : []),
        ...(feature.properties.gimbalRotate !== undefined
          ? [
              gimbalRotateAction(actionIndex++, {
                pitchRotateAngle: feature.properties.gimbalPitchAngle,
              }),
            ]
          : []),
        ...(feature.properties.hover
          ? [hoverAction(actionIndex++, feature.properties.hover)]
          : []),
        ...(!intervalModeObject.active
          ? [
              takePhotoAction({
                actionId: actionIndex++,
                fileSuffix: `waypoint${index}`,
                wpml,
                droneType,
              }),
            ]
          : []),
      );
    }

    if (isFinalWaypoint(waypoints, index)) {
      // When reaching the last waypoint at the current location create the waypoint with setup, add to waypoint list
      const waypointSetupSection = wpml
        ? waypointWPMLSetup(
            feature.geometry.coordinates,
            waypointIndex,
            flightAltitude,
            feature.properties.flightSpeed ?? globalFlightSpeed,
          )
        : waypointSetup(
            feature.properties.name,
            feature.geometry.coordinates,
            waypointIndex,
            flightAltitude,
            pitchAngle,
            continuousOperationsEnabled,
            feature.properties.flightSpeed,
            feature.properties.hover,
          );

      const waypointWPMLSection = {
        'wpml:waypointGimbalHeadingParam': {
          'wpml:waypointGimbalPitchAngle': 0,
          'wpml:waypointGimbalYawAngle': 0,
        },
        'wpml:isRisky': 0,
        'wpml:waypointWorkType': 0,
      };
      placemarks.push({
        ...waypointSetupSection,
        'wpml:actionGroup': [
          ...(actions['wpml:action'].length > 0
            ? [
                {
                  ...actionGroupConfig(currentActionGroupId, waypointIndex),
                  ...actions,
                },
              ]
            : []),
          ...(intervalActionGroup ? [intervalActionGroup] : []),
        ],
        ...(wpml && waypointWPMLSection),
      });
      // reset or increment all relevant variables
      actions = { 'wpml:action': [] };
      waypointIndex += 1;
      actionIndex = 0;
      currentActionGroupId = ++actionGroupId;
    }
  });
  return { Placemark: placemarks };
}

function actionGroupConfig(
  id: number,
  startIndex: number,
  endIndex = startIndex,
  triggerType = 'reachPoint',
  triggerParam?,
) {
  return {
    'wpml:actionGroupId': id,
    'wpml:actionGroupStartIndex': startIndex,
    'wpml:actionGroupEndIndex': endIndex,
    'wpml:actionGroupMode': 'sequence',
    'wpml:actionTrigger': {
      'wpml:actionTriggerType': triggerType,
      'wpml:actionTriggerParam': triggerParam,
    },
  };
}

function waypointSetup(
  name: string,
  coordinate: number[],
  index: number,
  altitude: number,
  pitchAngle: number,
  continuousOperationsEnabled: boolean,
  flightSpeed?: number,
  hover?: number,
) {
  const waypointSetup = {
    name: name,
    Point: {
      coordinates: `${coordinate[0]},${coordinate[1]}`,
    },
    'wpml:index': index,
    'wpml:ellipsoidHeight': altitude,
    'wpml:height': altitude,
    'wpml:useGlobalSpeed': flightSpeed ? 0 : 1,
    ...(flightSpeed && { 'wpml:waypointSpeed': flightSpeed }),
    'wpml:useGlobalHeadingParam': 1,
    'wpml:useGlobalTurnParam': hover ? 0 : 1,
    ...(hover && {
      'wpml:waypointTurnParam': [
        { 'wpml:waypointTurnMode': 'toPointAndStopWithContinuityCurvature' },
      ],
    }),
  };

  if (index == 0 || continuousOperationsEnabled) {
    waypointSetup['wpml:gimbalPitchAngle'] = pitchAngle;
  } else {
    waypointSetup['wpml:useStraightLine'] = 1;
    waypointSetup['wpml:gimbalPitchAngle'] = -80;
  }
  return waypointSetup;
}

function waypointWPMLSetup(
  coordinate: number[],
  index: number,
  altitude: number,
  flightSpeed: number,
) {
  const waypointSetup = {
    Point: {
      coordinates: `${coordinate[0]},${coordinate[1]}`,
    },
    'wpml:index': index,
    'wpml:executeHeight': altitude,
    'wpml:waypointSpeed': flightSpeed,
    'wpml:waypointHeadingParam': {
      'wpml:waypointHeadingMode': 'manually',
      'wpml:waypointHeadingAngle': 0,
      'wpml:waypointPoiPoint': '0.000000,0.000000,0.000000',
      'wpml:waypointHeadingAngleEnable': 0,
      'wpml:waypointHeadingPathMode': 'followBadArc',
      'wpml:waypointHeadingPoiIndex': 0,
    },
    'wpml:waypointTurnParam': {
      'wpml:waypointTurnMode': 'toPointAndStopWithDiscontinuityCurvature',
      'wpml:waypointTurnDampingDist': 0,
    },
    'wpml:useStraightLine': 1,
  };

  return waypointSetup;
}

interface takePhotoActionProps {
  actionId: number;
  fileSuffix?: string;
  wpml?: boolean;
  droneType?: DroneType;
}
function takePhotoAction({
  actionId,
  fileSuffix,
  wpml,
  droneType,
}: takePhotoActionProps) {
  return {
    'wpml:actionId': actionId,
    'wpml:actionActuatorFunc': 'takePhoto',
    'wpml:actionActuatorFuncParam': {
      ...(fileSuffix && { 'wpml:fileSuffix': fileSuffix }),
      'wpml:payloadPositionIndex': 0,
      'wpml:useGlobalPayloadLensIndex': 1,
      ...(wpml && {
        'wpml:payloadLensIndex': DJI_DRONE_VALUES[droneType].imageFormat,
      }),
    },
  };
}

function rotateYawAction(actionId: number, yawAngle: number) {
  return {
    'wpml:actionId': actionId,
    'wpml:actionActuatorFunc': 'rotateYaw',
    'wpml:actionActuatorFuncParam': {
      'wpml:aircraftHeading': yawAngle,
      'wpml:aircraftPathMode': 'counterClockwise',
    },
  };
}

function hoverAction(actionId: number, hoverTime: number) {
  return {
    'wpml:actionId': actionId,
    'wpml:actionActuatorFunc': 'hover',
    'wpml:actionActuatorFuncParam': {
      'wpml:hoverTime': hoverTime,
    },
  };
}

function gimbalRotateAction(actionId: number, gimbalRotateObject) {
  return {
    'wpml:actionId': actionId,
    'wpml:actionActuatorFunc': 'gimbalRotate',
    'wpml:actionActuatorFuncParam': {
      'wpml:gimbalHeadingYawBase': 'north',
      'wpml:gimbalRotateMode': 'absoluteAngle',
      'wpml:gimbalPitchRotateEnable': gimbalRotateObject.pitchRotateAngle
        ? 1
        : 0,
      'wpml:gimbalPitchRotateAngle': gimbalRotateObject.pitchRotateAngle ?? 0,
      'wpml:gimbalRollRotateEnable': 0,
      'wpml:gimbalRollRotateAngle': 0,
      'wpml:gimbalYawRotateEnable': gimbalRotateObject.yawRotateAngle ? 1 : 0,
      'wpml:gimbalYawRotateAngle': gimbalRotateObject.yawRotateAngle ?? 0,
      'wpml:gimbalRotateTimeEnable': 0,
      'wpml:gimbalRotateTime': 0,
      'wpml:payloadPositionIndex': 0,
    },
  };
}

function convertToKML(kmlArray) {
  const builder = new XMLBuilder({
    format: true,
    ignoreAttributes: false,
  });
  return builder.build(kmlArray);
}

function setupPayloadParam(droneType: DroneType) {
  return {
    'wpml:payloadParam': {
      'wpml:payloadPositionIndex': 0,
      'wpml:meteringMode': 'average',
      'wpml:dewarpingEnable': 0,
      'wpml:returnMode': 'singleReturnStrongest',
      'wpml:samplingRate': 240000,
      'wpml:scanningMode': 'nonRepetitive',
      'wpml:modelColoringEnable': 0,
      'wpml:imageFormat': DJI_DRONE_VALUES[droneType].imageFormat,
    },
  };
}

interface GenerateDJITemplateKMLProps {
  droneType: DroneType;
  safeTakeoffAltitude?: number;
  transitSpeedBool?: boolean;
  transitSpeed?: number;
}
export function generateDJITemplateWPML({
  droneType,
  safeTakeoffAltitude,
  transitSpeedBool,
  transitSpeed,
}: GenerateDJITemplateKMLProps): string {
  const wpmlContent = {
    '?xml': {
      '@_version': '1.0',
      '@_encoding': 'UTF-8',
    },
    kml: {
      '@_xmlns': 'http://www.opengis.net/kml/2.2',
      '@_xmlns:wpml': 'http://www.dji.com/wpmz/1.0.4',
      Document: {
        ...setupCreationInformation(),
        ...setupMissionInformation({
          takeOffSecurityHeight: safeTakeoffAltitude,
          droneType,
          transitSpeed,
          transitSpeedBool,
        }),
      },
    },
  };

  const wpml = convertToKML(wpmlContent);

  return wpml;
}
