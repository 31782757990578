import styled from 'styled-components';
import { Card } from '@raptormaps/card';
import { Row } from '@raptormaps/layout';
import theme from '@raptormaps/theme';

export const DeviceCard = styled(Card)`
  ${props => `
    ${
      props.pointer &&
      !props.isSelected &&
      `
        cursor: pointer;
        &:hover{background-color: ${theme.colors.neutral_50};
      }`
    };
    ${
      props.isSelected &&
      props.pointer &&
      `
      border: 2px solid ${theme.colors.neutral_500};
      padding: 14px;
    `
    }
  `}
`;

export const DeviceCardTitleRowWithEllipsis = styled(Row)`
  max-width: 240px;
  cursor: ${props => (props.pointer ? 'pointer' : 'default')};

  h3 {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const DockDeviceError = styled(Row)`
  border: 2px solid ${theme.colors.error_400};
  border-radius: 5px;
  background-color: ${theme.colors.error_50};
  padding: 15px;
  svg {
    color: ${theme.colors.error_400};
  }
`;
