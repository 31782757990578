import styled from 'styled-components';
import { IconButton, Button } from '@raptormaps/button';

export const IconButtonAdornment = styled(IconButton)`
  height: 32px;
  display: flex;
  align-items: center;
  align-self: center;
  margin-bottom: -24px;
  margin-left: -24px;
  margin-right: -55px;
  border-left: none;
  z-index: 1;
  border-radius: 0;
`;

export const DropdownButton = styled(Button)`
  width: 100%;
  justify-content: space-between;
`;
