import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { FeatureCollection, Geometries } from '@turf/helpers';
import { DrawToolModes, MapboxStyles } from '../../constants/mapConstants';

export const initDrawTool = (
  map: mapboxgl.Map,
  defaultMode: DrawToolModes,
  onDrawChange: ({ features }: MapboxDraw.DrawCreateEvent) => void,
  onDrawDelete: () => void,
) => {
  const draw = new MapboxDraw({
    displayControlsDefault: false,
    controls: {
      polygon: true,
      trash: true,
    },
    defaultMode,
  });
  map.addControl(draw);
  map.on('draw.create', onDrawChange);
  map.on('draw.delete', onDrawDelete);
  map.on('draw.update', onDrawChange);
  return draw;
};

interface UpdateDrawToolProps {
  map: mapboxgl.Map;
  drawToolMode: DrawToolModes;
  onDrawChange: ({ features }: MapboxDraw.DrawCreateEvent) => void;
  onDrawDelete: () => void;
  prevDrawTool: MapboxDraw;
}
export const updateDrawTool = ({
  map,
  drawToolMode,
  onDrawChange,
  onDrawDelete,
  prevDrawTool,
}: UpdateDrawToolProps) => {
  map.removeControl(prevDrawTool);

  const draw = new MapboxDraw({
    displayControlsDefault: false,
    controls: {
      polygon: drawToolMode === DrawToolModes.Polygon,
      line_string: drawToolMode === DrawToolModes.Line,
      trash: true,
    },
    defaultMode: drawToolMode,
  });
  map.addControl(draw);
  map.on('draw.create', onDrawChange);
  map.on('draw.delete', onDrawDelete);
  map.on('draw.update', onDrawChange);
  return draw;
};

/**
 * convert GeoJSON polygons to GeoJSON points obj
 * use this to place the text label of each row.
 * @param geoJSON - {json} - a geoJson object
 * @data - new geoJSON data for points
 */
export const GeoJSONPolygonToPoint = (
  geoJSON: FeatureCollection<Geometries>,
) => {
  const data = [];
  geoJSON?.features.map(item => {
    data.push({
      id: item?.id,
      geometry: {
        type: 'Point',
        coordinates: item?.geometry?.coordinates[0][0],
      },
      properties: {
        ...item?.properties,
      },
    });
  });
  return {
    features: data,
    type: 'FeatureCollection',
  } as FeatureCollection<Geometries>;
};

export const getFarmTileMapUrl = (tileMap, solarFarm, orgId) => {
  /** Builds url to pass to tile map source for map (specifically to access asbilts on Solar Assets Map)
   * arg - tileMap
   * ret - url (str)
   */
  const farmUUID = solarFarm.uuid;

  const id = tileMap.id.toString();
  // url parameters
  const params = `/${id}/{z}/{x}/{y}.png`;

  const route = sessionStorage.tileServerUrl + '/api/v1/ortho';

  // build url
  const url = `${route}${params}?farm_uuid=${farmUUID}&org_id=${orgId}&authorization_token=${sessionStorage.accessToken}`;
  return url;
};

export const initTerrainSource = (map: mapboxgl.Map) => {
  map.addSource('mapbox-dem', {
    type: 'raster-dem',
    url: MapboxStyles.TerrainV1,
    tileSize: 512,
    maxzoom: 14,
  });
  // add the DEM source as a terrain layer with exaggerated height
  map.setTerrain({ source: 'mapbox-dem', exaggeration: 1.5 });
};

export const initNavControl = (map: mapboxgl.Map) => {
  const nav = new mapboxgl.NavigationControl({
    visualizePitch: true,
  });
  map.addControl(nav, 'bottom-right');
};
