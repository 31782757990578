import styled from 'styled-components';
import { PopoverAnchor, PopoverContent } from '@raptormaps/popover';
import { IconButton } from '@raptormaps/button';

import theme from '@raptormaps/theme';

export const MapDetailsPopoverAnchor = styled(PopoverAnchor)`
  position: fixed;
  right: 0;
`;

export const MapDetailsPopover = styled(PopoverContent)`
  position: fixed;
  min-height: 50vh;
  max-height: 94vh;
  width: 496px;
  z-index: 1;
  bottom: 30px;
  right: 45px;
  padding: 21px;
`;

export const MapDetailsTrigger = styled(IconButton)`
  position: fixed;
  z-index: 1;
  bottom: 30px;
  right: 10px;
  background-color: ${theme.colors.white} !important;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${theme.colors.grey_100} !important;
    transition: background-color 0.3s;
  }
`;
