//External Dependancies
import { DroneType } from '@raptormaps/raptor-flight-client-ts';

// Styled Components
import { CardTitle } from '@raptormaps/card';
import { Stack, Row } from '@raptormaps/layout';
import { Icon } from '@raptormaps/icon';
import { Text } from '@raptormaps/text';
import { Tooltip } from '@raptormaps/tooltip';

// Components
import { DockCoverStateTelemetry } from '@/pages/Overview/Shared/DockCoverStateTelemetry';

// Internal Dependancies

import { DockDevice } from '@/shared/types/devices.d';
import {
  DeviceCard,
  DeviceCardTitleRowWithEllipsis,
  DockDeviceError,
} from './DockDeviceStyle';
import { DEVICE_MODEL_NAME_TO_DRONE_TYPE } from '@/pages/MissionTasks/MissionTaskPlanner/missionTaskFormConstants';
import { AircraftStateTelemetry } from '@/pages/Overview/Shared/AircraftStateTelemetry';

interface DockDeviceCardProps {
  dockDevice: DockDevice;
  isSelected: boolean;
  activeMissionDevice?: DroneType;
  handleClick?: () => void;
}

export const DockDeviceCard = ({
  dockDevice,
  isSelected,
  activeMissionDevice,
  handleClick,
}: DockDeviceCardProps) => {
  const deviceSn = dockDevice?.deviceSn;
  const aircraftSn = dockDevice?.devices?.length
    ? dockDevice?.devices[0]?.deviceSn
    : null;

  return (
    <>
      <DeviceCard
        isSelected={isSelected}
        pointer={handleClick}
        onClick={!isSelected ? handleClick : null}
      >
        <Stack gutter="lg">
          <Row align="center" justify="space-between">
            <Tooltip
              colorVariant="dark"
              delay={100}
              placement={{
                align: 'center',
                side: 'right',
              }}
              tip={`${dockDevice.deviceName} ID#${dockDevice.id}`}
              shouldWrapChildren={true}
            >
              <DeviceCardTitleRowWithEllipsis
                align="center"
                justify="flex-start"
                gutter={'xxs'}
              >
                <Icon icon="HouseDrone" />
                <CardTitle variant="paragraph_medium_bold" align="left">
                  {`${dockDevice.deviceName} ID#${dockDevice.id}`}
                </CardTitle>
              </DeviceCardTitleRowWithEllipsis>
            </Tooltip>
            <DockCoverStateTelemetry deviceSn={deviceSn} />
          </Row>
          <Row align="center" justify="space-between">
            <Text variant="paragraph_medium" align="left">
              Aircraft Model
            </Text>
            <Text variant="paragraph_medium" align="left">
              {dockDevice?.dockDroneModel
                ? dockDevice.dockDroneModel.modelName
                : 'Drone Not Found'}
            </Text>
          </Row>
          {activeMissionDevice &&
          activeMissionDevice !=
            DEVICE_MODEL_NAME_TO_DRONE_TYPE[
              dockDevice?.dockDroneModel?.modelName
            ] ? (
            <>
              <DockDeviceError>
                <Row noWrap>
                  <Icon icon={'CircleXmark'} size={'medium'} />
                  <Text variant="paragraph_large_bold" align="left">
                    Device Incompatible with current mission!
                  </Text>
                </Row>
              </DockDeviceError>
            </>
          ) : (
            <>
              <Row align="center" justify="space-between">
                <Text variant="paragraph_medium" align="left">
                  Aircraft status
                </Text>
                <AircraftStateTelemetry
                  dockSn={deviceSn}
                  aircraftSn={aircraftSn}
                />
              </Row>
            </>
          )}
        </Stack>
      </DeviceCard>
    </>
  );
};
