// State Management
import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { produce } from 'immer';
import { Director, View } from '@millicast/sdk';

export enum DolbyBroadcastEvents {
  Active = 'active', // Fires when the live stream is, or has started broadcasting
  Inactive = 'inactive', // Fires when the stream has stopped broadcasting, but is still available.
  Stopped = 'stopped', // Fires when the stream has stopped for a given reason
  ViewerCount = 'viewercount', // Fires when the viewer count changes
}

export const WHIP_ENDPOINT_BASE = 'https://director.millicast.com/api/whip/';

export interface ConnectToDolbyStreamParams {
  videoRef: HTMLVideoElement;
  streamName: string;
  streamAccountId: string;
  subscriberToken?: string;
}

interface DolbyLivestreamInitialState {
  dolbyView: View | null;
  isSubscribed: boolean;
  isReconnecting: boolean;
  viewerCount: number;
}

export interface DolbyLivestreamState extends DolbyLivestreamInitialState {
  closeDolbyStream: () => void;
  connectToDolbyStream: (params: ConnectToDolbyStreamParams) => void;
}

const initialState: DolbyLivestreamInitialState = {
  dolbyView: null,
  isSubscribed: false,
  isReconnecting: false,
  viewerCount: 0,
};

const mutations = (setState, getState) => {
  return {
    async closeDolbyStream() {
      const dolbyView = getState().dolbyView;
      if (dolbyView) {
        dolbyView.removeAllListeners();
        await dolbyView.stop();
        setState(
          produce((state: DolbyLivestreamInitialState) => {
            state.dolbyView = null;
            state.isSubscribed = false;
            state.isReconnecting = false;
            state.viewerCount = 0;
          }),
        );
      }
    },

    async connectToDolbyStream({
      videoRef,
      streamName,
      streamAccountId,
      subscriberToken,
    }: ConnectToDolbyStreamParams) {
      let dolbyView = getState().dolbyView;
      const isSubscribed = getState().isSubscribed;

      if (dolbyView && isSubscribed) {
        return;
      }

      if (!streamName || !streamAccountId) {
        throw new Error('Stream name and account ID are required');
      }

      const dolbyTokenGenerator = async () => {
        const subscriberConnection = await Director.getSubscriber({
          streamName,
          streamAccountId,
          subscriberToken,
        });
        return subscriberConnection;
      };

      if (!dolbyView) {
        setState(
          produce((state: DolbyLivestreamInitialState) => {
            state.dolbyView = new View(
              streamName,
              dolbyTokenGenerator,
              null,
              true /* auto reconnect */,
            );
            state.isReconnecting = false;
            state.isSubscribed = true;
          }),
        );
      }

      dolbyView = getState().dolbyView;

      await dolbyView.on('track', event => {
        if (videoRef) {
          videoRef.srcObject = event.streams[0];
          videoRef.hidden = false;
          videoRef.autoplay = true;
          setState(
            produce((state: DolbyLivestreamInitialState) => {
              state.isReconnecting = false;
            }),
          );
        }
      });

      await dolbyView.on('reconnect', () => {
        const isReconnecting = getState().isReconnecting;

        if (isReconnecting) {
          return;
        }

        setState(
          produce((state: DolbyLivestreamInitialState) => {
            state.isReconnecting = true;
          }),
        );
      });

      await dolbyView.on('broadcastEvent', event => {
        if (event.name === DolbyBroadcastEvents.ViewerCount) {
          setState(
            produce((state: DolbyLivestreamInitialState) => {
              state.viewerCount = event.data.viewercount;
            }),
          );
        }
      });
      try {
        await dolbyView.connect({
          events: [
            DolbyBroadcastEvents.Active,
            DolbyBroadcastEvents.Inactive,
            DolbyBroadcastEvents.Stopped,
            DolbyBroadcastEvents.ViewerCount,
          ],
        });
      } catch (err) {
        console.warn(err.message);
      }
    },
  };
};

export const useDolbyLivestreamStore = create(combine(initialState, mutations));
