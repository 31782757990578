import { Text, TextVariant } from '@raptormaps/text';
import { Row, Stack, Gutter } from '@raptormaps/layout';
import noDataImage from '@/assets/no-data-image.svg';
import './EmptyTable.css';

export const NoResults = () => {
  return (
    <div className="no-data">
      <Row justify="center" align="center">
        <Stack gutter={Gutter.S}>
          <img src={noDataImage} />
          <Text variant={TextVariant.paragraph_large_bold}>
            No results found
          </Text>
          <Text variant={TextVariant.paragraph_small}>
            Please change search term or solar site
          </Text>
        </Stack>
      </Row>
    </div>
  );
};
