import styled from 'styled-components';
import { FloatingSidebarContainer } from '@raptormaps/sidebar';
import { Stack, Row } from '@raptormaps/layout';
import { PopoverContent } from '@raptormaps/popover';
import { TextInput } from '@raptormaps/text-input';
import { IconButton } from '@raptormaps/button';

import searchIcon from '@/assets/search.svg';

export const MissionTaskFloatingSidebarContainer = styled(
  FloatingSidebarContainer,
)`
  // <Sidebar> component
  > div > div > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100%;
  }

  h3 {
    padding-bottom: 0;

    svg {
      height: 16px;
      width: 16px;
    }
  }
`;

export const MissionTaskCollapsableContainer = styled(Stack)`
  height: 18%;
  justify-content: flex-end;
  padding: 0 10px;
`;

export const SidebarCompanionPopoverContent = styled(PopoverContent)`
  max-height: 100vh;
  height: 100vh;
  width: 416px;
  border-radius: 0;
  padding: 30px 25px;
`;

export const StyledSearchBarRow = styled(Row)`
  width: 82%;
  text-align: left;
  margin-top: -1px;
`;

export const StyledSearchBarWithIcon = styled(TextInput)`
  width: 95%;
  margin-top: 10px;

  input {
    font-size: 13px;
    background: url(${searchIcon}) no-repeat 7px 7px;
    padding-left: 30px;
    background-color: #f8f8f8;
  }
`;

export const CardListContainer = styled(Stack)`
  margin-top: 25px;
  overflow-y: auto;
  max-height: 67vh;
`;

export const SidebarBottomButtonContainer = styled(Stack)`
  padding-top: 20px;
`;

export const BottomButtonsStack = styled(Stack)`
  margin-bottom: 30px;
`;

export const SearchBarIconButtonAdornment = styled(IconButton)`
  height: 25px;
  display: flex;
  align-items: center;
  align-self: center;
  margin-bottom: -30px;
  margin-left: -30px;
  margin-right: -50px;
  border-left: none;
  z-index: 1;
  border-radius: 0;
`;

export const FullHeightStackSpaceBetween = styled(Stack)`
    height: 100%;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
  }
`;
