import { useEffect } from 'react';

// Styled Components
import { Card, CardTitle } from '@raptormaps/card';
import { Stack, Row } from '@raptormaps/layout';
import { Icon } from '@raptormaps/icon';
import { Text } from '@raptormaps/text';
import {
  CardPadding,
  ClickableCardContainer,
  CardContentsContainer,
  DeviceCardTitleRowWithEllipsis,
  IconButtonNoHighlight,
} from '../Overview.styled';
import { Tooltip } from '@raptormaps/tooltip';

// Components
import { AircraftStateTelemetry } from '../Shared/AircraftStateTelemetry';
import { BatteryPercentTelemetry } from '../Shared/BatteryPercentTelemetry';
import { DockCoverStateTelemetry } from '../Shared/DockCoverStateTelemetry';

// Types
import { DockDevice } from '@/shared/types/devices.d';
import {
  TelemetryWebsocketState,
  useGetDockLocation,
  useTelemetryStore,
} from '@/shared/stores/TelemetryWebsocket/TelemetryWebsocketStore';
import { useShallow } from 'zustand/react/shallow';
import { Feature, Point } from '@turf/helpers';

interface DeviceCardProps {
  dockDevice: DockDevice;
  navigateToDockOverview: (
    dockDevice: DockDevice,
    dockLocation: Feature<Point>,
  ) => void;
}

const DeviceCard = ({
  dockDevice,
  navigateToDockOverview,
}: DeviceCardProps) => {
  const deviceSn = dockDevice.deviceSn;
  const aircraftSn = dockDevice.devices[0]?.deviceSn;

  const dockLocation = useGetDockLocation(deviceSn);

  const socketIsOpen = useTelemetryStore(
    useShallow((state: TelemetryWebsocketState) => state.socketIsOpen),
  );
  const actions = useTelemetryStore(
    useShallow((state: TelemetryWebsocketState) => state.actions),
  );

  useEffect(() => {
    if (socketIsOpen) {
      actions.subscribeToDevices([dockDevice], dockDevice.solarFarmId);
    }
  }, [socketIsOpen]);

  return (
    <ClickableCardContainer
      onClick={() =>
        navigateToDockOverview(dockDevice, dockLocation as Feature<Point>)
      }
    >
      <Card>
        <Row justify="space-between" align="center" noWrap>
          <CardContentsContainer>
            <Stack gutter="xl">
              <Row align="center" justify="space-between">
                <Tooltip
                  colorVariant="dark"
                  delay={100}
                  placement={{
                    align: 'center',
                    side: 'right',
                  }}
                  tip={`${dockDevice.deviceName} ID#${dockDevice.id}`}
                  shouldWrapChildren={true}
                >
                  <DeviceCardTitleRowWithEllipsis
                    align="center"
                    justify="flex-start"
                    gutter="xxs"
                  >
                    <Icon icon="HouseDrone" />
                    <CardTitle variant="paragraph_medium_bold" align="left">
                      {`${dockDevice.deviceName} ID#${dockDevice.id}`}
                    </CardTitle>
                  </DeviceCardTitleRowWithEllipsis>
                </Tooltip>
                <DockCoverStateTelemetry deviceSn={deviceSn} />
              </Row>
              <Row align="center" justify="space-between">
                <Text variant="paragraph_medium" align="left">
                  Upcoming Mission
                </Text>
                <Text variant="paragraph_medium" align="right">
                  None
                </Text>
              </Row>
              <Row align="center" justify="space-between">
                <Text variant="paragraph_medium" align="left">
                  Aircraft status
                </Text>
                <AircraftStateTelemetry
                  dockSn={deviceSn}
                  aircraftSn={aircraftSn}
                />
              </Row>
              <Row align="center" justify="space-between">
                <Text variant="paragraph_medium" align="left">
                  Aircraft battery
                </Text>
                <BatteryPercentTelemetry
                  dockSn={deviceSn}
                  aircraftSn={aircraftSn}
                />
              </Row>
            </Stack>
          </CardContentsContainer>
          <IconButtonNoHighlight
            aria-label="Select dock"
            icon="ChevronRight"
            size="medium"
            variant="tertiary"
          />
        </Row>
      </Card>
      <CardPadding />
    </ClickableCardContainer>
  );
};

export default DeviceCard;
