// Internal Dependancies
import { MissionTaskStatusPill } from '../MissionTasksTable.styles';
import { MissionTaskStatus } from '../missionTasksTableConstants';

export const TaskStatus = ({ missionTask }) => {
  return (
    <MissionTaskStatusPill
      taskStatus={missionTask.taskStatus}
      variant={MissionTaskStatus[missionTask.taskStatus].pillColor}
    >
      {MissionTaskStatus[missionTask.taskStatus].displayName}
    </MissionTaskStatusPill>
  );
};
